<div class="detail-container">
  <nb-card>
    <nb-card-header>
      <button nbButton ghost status="primary" (click)="navigateBack()"><nb-icon pack="eva" icon="arrow-back-outline"></nb-icon></button>
      <h5>Email</h5>
    </nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="form">
        <form [formGroup]="form">
          <div class="sub-card-body">
            <div class="gird">
              <div>
                <label class="label" for="subject">Betreff</label>
                <input fullWidth id="subject" type="text" nbInput formControlName="subject">
              </div>
              <div *ngIf="isMultiple">
                <label class="label" for="recipients">Empfänger</label>
                <div>
                <nb-tag-list id="recipients">
                  <nb-tag *ngFor="let recipient of form.get('recipients')?.value" [text]="recipient">
                  </nb-tag>
                  <input nbTagInput type="text" disabled fullWidth>
                </nb-tag-list>
                </div>
              </div>
              <div *ngIf="!isMultiple">
                <label class="label" for="recipient">Empfänger</label>
                <input fullWidth id="recipient" type="text" nbInput formControlName="recipients">
              </div>
              <div>
                <hau-form-field label="Inhalt">
                  <wr-rich-text-editor formControlName="body"></wr-rich-text-editor>
                </hau-form-field>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </nb-card-body>
  </nb-card>
</div>
