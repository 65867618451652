import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, Input, Output } from '@angular/core';
import { MaintenanceTimerItemDto } from '@artemis-software/wr-api';
import { Router } from '@angular/router';
import { Tracked } from '@/utils/signals/tracked';

@Component({
  selector: 'wr-maintenance-timer-item-card[maintenanceTimer]',
  templateUrl: './maintenance-timer-item-card.component.html',
  styleUrls: ['./maintenance-timer-item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTimerItemCardComponent {

  readonly router = inject(Router);

  @Input()
  @Tracked()
  maintenanceTimer!: MaintenanceTimerItemDto;

  @Output()
  delete = new EventEmitter<{maintenanceTimer: MaintenanceTimerItemDto}>();
  @Output()
  edit = new EventEmitter<{ maintenanceTimer: MaintenanceTimerItemDto }>();

  readonly daysLeft = computed(() => {
    const nextDate = new Date(this.maintenanceTimer.nextDate);
    const currentDate = new Date();
    const timeDiff = nextDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  });

  readonly expired = computed(() => {
    return this.daysLeft() <= 0;
  });

  goToBuildingEquipment() {
    this.router.navigate(['buildingEquipment', this.maintenanceTimer.buildingEquipmentId]);
  }

  deleteEntity($event: MouseEvent) {
    $event.stopPropagation();
    this.delete.emit({ maintenanceTimer: this.maintenanceTimer });
  }

  editEntity($event: MouseEvent) {
    $event.stopPropagation();
    this.edit.emit({ maintenanceTimer: this.maintenanceTimer });
  }
}
