import { signal, WritableSignal } from '@angular/core';
import { watch } from '@/utils/signals/watch';

export function useLocalStorage<T extends string = string>(key: string): WritableSignal<T | undefined>;

export function useLocalStorage<T extends string = string>(key: string, defaultValue: T): WritableSignal<T>;

export function useLocalStorage(key: string, defaultValue?: string): WritableSignal<string | undefined> {
  const value = signal(
    localStorage.getItem(key) ?? defaultValue,
  );

  watch(value, (value) => {
    if (value !== undefined)
      localStorage.setItem(key, value);
    else
      localStorage.removeItem(key);
  });

  return value;
}
