<div class="building-equipments-cockpit">
  <div class="list-view">
    <nb-alert>
      <div class="info-alert">
        <nb-icon icon="info-outline" pack="eva"></nb-icon>
        <span>Nur TGA relevante Prüfpunkte werden angezeigt</span>
      </div>
    </nb-alert>
    <wr-grouped-list-view [groups]="filteredInspectionEntriesGrouped$|async"
                          [searchText]="textFilter$.value"
                          (searchTextChange)="textFilter$.next($event)"
                          (selectedChange)="selectedItem$.next($event)">
      <ng-template wrListItem let-item>
        <div class="inspection-entry-item">
          <div class="name">{{getCheckpointString(item)}}</div>
          <div *ngIf="getBuildingEquipmentCount(item) as count; else noBuildingEquipment"
               class="building-equipment-indicator"
               nbTooltip="Hat {{count}} {{count | pluralize:'TGA':'TGAs'}}">
            <span>{{count}}</span>
            <nb-icon icon="cube" pack="fas"></nb-icon>
          </div>

          <ng-template #noBuildingEquipment>
            <div class="building-equipment-indicator" nbTooltip="Hat keine TGAs" nbTooltipStatus="basic">
              <nb-icon icon="slash" pack="eva" status="basic"></nb-icon>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </wr-grouped-list-view>
  </div>
  <div class="detail-view">
    <ng-container *ngIf="selectedItem$|async as selected; else noSelection">
      <wr-cockpit-entry-detail-view [formWrapper]="formWrapper" [entry]="selected"></wr-cockpit-entry-detail-view>
    </ng-container>
    <ng-template #noSelection>
      <div class="no-selection">
        <nb-icon icon="slash" pack="eva"></nb-icon>
        <span>Keine Prüfpunktzeile ausgewählt</span>
      </div>
    </ng-template>
  </div>
</div>
