<div class="flex-container">
  <wr-building-section-select [building]="inspection.building"
                              [selectedSection]="selectedSection$|async"
                              (selectedSectionChange)="selectedSection$.next($event)">
  </wr-building-section-select>

  <button nbButton
          status="basic"
          size="small"
          *ngIf="isAdmin()"
          [disabled]="!canImportTemplate"
          (click)="importTemplate()">
    <nb-icon icon="download-outline" pack="eva"></nb-icon>
    Vorlage importieren
  </button>
</div>

<div class="split-view" *ngIf="selectedSection$.value; else noSelection">
  <nb-card size="large">
    <nb-card-header>
      <wr-search-bar [search]="search$|async" (searchChange)="search$.next($event)"></wr-search-bar>
      <button nbButton
              ghost
              status="primary"
              nbTooltip="Prüfpunktzeile hinzufügen"
              *ngIf="isAdmin()"
              [disabled]="formWrapper.disabled"
              (click)="showAddInspectionEntryDialog()">
        <nb-icon icon="plus-outline" pack="eva"></nb-icon>
      </button>
      <ng-container *ngIf="sectionContextMenu.items.length; else moreActionsDisabled">
        <button nbButton
                ghost
                nbTooltip="Weitere Aktionen"
                [nbContextMenu]="sectionContextMenu.items"
                [nbContextMenuTag]="sectionContextMenu.tag">
          <nb-icon icon="more-vertical-outline" pack="eva"></nb-icon>
        </button>
      </ng-container>
      <ng-template #moreActionsDisabled>
        <button nbButton ghost disabled>
          <nb-icon icon="more-vertical-outline" pack="eva"></nb-icon>
        </button>
      </ng-template>
    </nb-card-header>
    <nb-card-body class="no-padding">
      <wr-inspection-entry-group-list *ngIf="inspectionEntriesGrouped$|async as groups"
                                      [groups]="groups"
                                      [disabled]="formWrapper.disabled"
                                      [selectedEntry]="selectedEntry$|async"
                                      (selectedEntry$)="selectedEntry$.next($event)"
                                      [(selectedGroupId)]="selectedGroupId"
                                      (deleteGroup)="deleteGroup($event)">
      </wr-inspection-entry-group-list>
    </nb-card-body>
  </nb-card>
  <nb-card size="large" *ngIf="selectedEntry$|async as selectedEntry">
    <nb-card-header>
      <span>{{ getCheckpointString(selectedEntry) }}</span>
      <div class="flex-grow"></div>
      <button nbButton
              *ngIf="entriesThatCanBeAppliedFromOtherSections$.value.length"
              class="glowing"
              status="primary"
              nbTooltip="Prüfpunkt übernehmen"
              (click)="applyEntryFromOtherSection()">
        <nb-icon icon="download-outline" pack="eva"></nb-icon>
      </button>
      <button nbButton
              ghost
              [nbContextMenu]="entryContextMenu.items"
              [nbContextMenuTag]="entryContextMenu.tag"
              nbTooltip="Ausblenden">
        <nb-icon icon="more-vertical" pack="eva"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body>
      <wr-inspection-entry-form [formWrapper]="formWrapper" [entry]="selectedEntry"></wr-inspection-entry-form>
    </nb-card-body>
  </nb-card>
</div>

<ng-template #noSelection>
  <div class="text-hint">Gebäudeteil auswählen um Einträge anzuzeigen</div>
</ng-template>
