import { Component } from '@angular/core';
import { TagItemDto } from '@artemis-software/wr-api';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TagState } from '@/store/tag/tag.state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChangeTagFilter } from '@/store/tag/tag.action';
import { Router } from '@angular/router';
import { defineStoreUsage } from '@/utils/storeUsage';

@Component({
  selector: 'wr-tag-overview-page',
  templateUrl: './tag-overview-page.component.html',
  styleUrls: ['./tag-overview-page.component.scss'],
})
export class TagOverviewPageComponent {

  tagStoreUsage = defineStoreUsage({
    filterAction: ChangeTagFilter,
    countSelector: TagState.count,
    loadingSelector: TagState.tagsLoading
  });

  @Select(TagState.filteredTags)
  tags$!: Observable<TagItemDto[]>;
  @Select(TagState.count)
  count$!: Observable<number>;
  @Select(TagState.tagsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('tagFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
    });
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeTagFilter(this.form.value));
    localStorage.setItem('tagFilter', JSON.stringify(this.form.value));
  }

  add(): void {
    this.router.navigate(['tag', 'new']);
  }

  navigateToTag(tag: TagItemDto): void {
    this.router.navigate(['tag', tag.id]);
  }
}
