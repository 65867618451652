import { Component, computed, effect } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BuildingEquipmentState } from '@/store/buildingEquipment/buildingEquipment.state';
import { Router } from '@angular/router';
import { BuildingEquipmentItemDto } from '@artemis-software/wr-api';
import { ChangeBuildingEquipmentFilter } from '@/store/buildingEquipment/buildingEquipment.action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BuildingDetailDto } from '@artemis-software/wr-api/model/buildingDetailDto';
import { defineStoreUsage } from '@/utils/storeUsage';
import { currentUser } from '@/utils/admin-utils';

@Component({
  selector: 'wr-cooperative-building-equipment-overview-page',
  templateUrl: './cooperative-building-equipment-overview-page.component.html',
  styleUrls: ['./cooperative-building-equipment-overview-page.component.scss'],
})
export class CooperativeBuildingEquipmentOverviewPageComponent {
  currentUser = currentUser();
  currentOrganisationId = computed(() => this.currentUser()?.organisation.id);

  buildingEquipmentStoreUsage = defineStoreUsage({
    filterAction: ChangeBuildingEquipmentFilter,
    countSelector: BuildingEquipmentState.count,
    loadingSelector: BuildingEquipmentState.buildingEquipmentsLoading
  });

  @Select(BuildingEquipmentState.filteredBuildingEquipments)
  buildingEquipments$!: Observable<BuildingEquipmentItemDto[]>;
  @Select(BuildingEquipmentState.count)
  count$!: Observable<number>;
  @Select(BuildingEquipmentState.buildingEquipmentsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(private readonly store: Store,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder) {
    effect(() => {
      const currentUser = this.currentUser();
      if (currentUser) {
        this.initFilter(currentUser.organisation.id);
        this.triggerFilter();
      }
    });
  }

  initFilter(organisationId: string) {
    const filter = localStorage.getItem('cooperativeBuildingEquipmentFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text, organisationId);
      this.addSpecialFields(filterValue.building);
    } else {
      this.initFilterForm('', organisationId);
    }
  }

  initFilterForm(text: string, organisationId: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      organisationId: this.formBuilder.control(organisationId),
      isPropertyManager: this.formBuilder.control(false),
      buildingId: this.formBuilder.control(''),
      building: this.formBuilder.control(undefined),
    });

    this.form.get('building')?.valueChanges.subscribe((building: BuildingDetailDto) => {
      this.form.get('buildingId')?.setValue(building?.id);
    });
  }

  async addSpecialFields(building: BuildingDetailDto): Promise<void> {
    this.form.patchValue({
      'building': building,
    });
  }

  navigateToBuildingEquipment(buildingEquipment: BuildingEquipmentItemDto): void {
    this.router.navigate(['buildingEquipment', buildingEquipment.id]);
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeBuildingEquipmentFilter(this.form.value));
    localStorage.setItem('cooperativeBuildingEquipmentFilter', JSON.stringify(this.form.value));
  }
}
