import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wr-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input()
  page$!: BehaviorSubject<number>;
  @Input()
  maxPage = 0;

  startPage(): void {
    this.page$.next(0);
  }

  nextPage(): void {
    this.page$.next(this.page$.value + 1);
  }

  previousPage(): void {
    this.page$.next(this.page$.value - 1);
  }

  endPage(): void {
    this.page$.next(this.maxPage);
  }
}
