export function toDate(date: Date | string): Date {
  if (typeof date === 'string') return new Date(date);
  return date;
}

export function dateToTimeAgoString(date: Date | string): string {
  const dateFrom = toDate(date);
  const dateTo = new Date();
  const diff = dateTo.getTime() - dateFrom.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 24) {
    return dateFrom.toLocaleDateString();
  } else if (hours > 0) {
    return `vor ${hours} Stunde${hours !== 1 ? 'n' : ''}`;
  } else if (minutes > 0) {
    return `vor ${minutes} Minute${minutes !== 1 ? 'n' : ''}`;
  } else {
    return `vor ${seconds} Sekunde${seconds !== 1 ? 'n' : ''}`;
  }
}
