<nb-card>
  <div class="maintenance-timer noselect"
       (click)="goToBuildingEquipment()"
       [class.expired]="expired()">
    <div class="equipment-name">{{ maintenanceTimer.equipmentName }}</div>
    <div class="days-left">
      {{ daysLeft() }} {{ daysLeft() | pluralize: 'Tag': 'Tage' }}
    </div>
    <div class="type">{{ maintenanceTimer.type | maintenanceTimerType }}</div>
    <div class="label-and-text">
      <label class="label">Dauer</label>
      <span>{{ maintenanceTimer.durationDays }} {{ maintenanceTimer.durationDays | pluralize: 'Tag': 'Tage' }}</span>
    </div>
    <div class="label-and-text">
      <label class="label">Nächste {{ maintenanceTimer.type | maintenanceTimerType }}</label>
      <span>{{ maintenanceTimer.nextDate | date: 'dd.MM.yyyy' }}</span>
    </div>
    <nb-icon icon="external-link-outline"
             pack="eva"
             class="external-link-icon">
    </nb-icon>
    <div class="action-group">
      <button nbButton size="medium" status="basic" ghost (click)="editEntity($event)">
        <nb-icon icon="edit-outline" pack="eva"></nb-icon>
      </button>
      <button nbButton size="medium" status="basic" ghost (click)="deleteEntity($event)">
        <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
      </button>
    </div>
  </div>
</nb-card>
