import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InspectionEntryDetailDto } from '@artemis-software/wr-api';
import { SelectWithRecommendedOption } from '../select-with-recommended-option';

@Component({
  selector: 'wr-action-select',
  templateUrl: './action-select.component.html',
  styleUrls: ['./action-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActionSelectComponent),
      multi: true,
    },
  ],
})
export class ActionSelectComponent extends SelectWithRecommendedOption<InspectionEntryDetailDto.ActionEnum> {
  override enumValues = Object.values(InspectionEntryDetailDto.ActionEnum);
}
