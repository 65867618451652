import { Component, forwardRef, Input } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { NbDateService, NbTrigger } from '@nebular/theme';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

type DateRange = { start: Date, end: Date };

@Component({
  selector: 'wr-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
  ],
})
export class DateRangePickerComponent extends AbstractFormControl<DateRange> {

  @Input()
  placeholder = 'Datum wählen';

  @Input()
  showTodayButton = false;

  constructor(private readonly dateService: NbDateService<Date>) {
    super();
  }

  setRangeFilterToday(): void {
    const end = this.dateService.today();
    const start = this.dateService.addMonth(end, -1);
    this.updateValue({ start, end });
  }

  updateValue(dateRange: DateRange): void {
    if (dateRange) {
      dateRange.end = this.dateService.setHours(dateRange.end, 23);
      dateRange.end = this.dateService.setMinutes(dateRange.end, 59);
      this.value = dateRange;
    } else {
      this.value = undefined;
    }
  }
}
