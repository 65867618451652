import { Component, forwardRef, Input } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { Select } from '@ngxs/store';
import { CheckpointGroupState } from '@/store/checkpointGroup/checkpointGroup.state';
import { Observable } from 'rxjs';
import { CheckpointItemDto } from '@artemis-software/wr-api';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-checkpoint-group-select',
  templateUrl: './checkpoint-group-select.component.html',
  styleUrls: ['./checkpoint-group-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckpointGroupSelectComponent),
      multi: true,
    },
  ],
})
export class CheckpointGroupSelectComponent extends AbstractFormControl<string> {

  @Select(CheckpointGroupState.checkpointGroups)
  checkpointGroups$!: Observable<CheckpointItemDto[]>;

  @Input()
  showEmptyOption = false;
}
