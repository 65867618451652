<wr-list-detail-split-view [nbSpinner]="!!(loading$|async)"
                           [data$]="fields$"
                           [searchText]="searchText$.value"
                           [loadDetailFunc]="loadDetail"
                           (searchTextChange)="searchText$.next($event)"
                           (addItemClicked)="showAddDialog()">
  <ng-template wrListItem let-item>
    <div class="custom-field-wrapper">
      <span class="name">{{item.name}}</span>
      <span class="type">{{item.elementType | elementTypeTranslation}}
        - {{item.valueType | valueTypeTranslation}}</span>
    </div>
  </ng-template>

  <ng-template wrDetailView let-detail>
    <wr-form-actions [form]="customFieldForm.form" [deleteAction]="deleteAction"
                     [deleteDialog]="deleteDialogAction"
                     [saveAction]="saveAction">
      <wr-custom-field-form #customFieldForm [customField]="detail">
      </wr-custom-field-form>
    </wr-form-actions>
  </ng-template>
</wr-list-detail-split-view>
