import { computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { NbSpinnerService } from '@nebular/theme';
import { watch } from '@/utils/signals/watch';

export type LoaderInstance = WritableSignal<boolean> & { stop: () => void };

export type Loader = Signal<boolean> & { add: () => LoaderInstance };

export function loader(): Loader {
  const loaders = [] as WritableSignal<boolean>[];

  const loader = computed(() => {
    return loaders.some((loader) => loader());
  });

  const addLoader = (): LoaderInstance => {
    const loader = signal(false) as LoaderInstance;
    loaders.push(loader);

    loader.stop = () => {
      loaders.splice(loaders.indexOf(loader), 1);
    };

    return loader;
  };

  return Object.assign(loader, { add: addLoader });
}

export function unwrapLoader(value: WritableSignal<boolean> | Loader): WritableSignal<boolean>;
export function unwrapLoader(
  value: WritableSignal<boolean> | Loader | undefined,
): WritableSignal<boolean> | undefined;

export function unwrapLoader(value: WritableSignal<boolean> | Loader | undefined): unknown {
  return value ? ('add' in value ? value.add() : value) : undefined;
}

export function globalLoader(): Loader {
  const service = inject(NbSpinnerService);

  const isLoading = loader();

  watch(isLoading, (isLoading) => {
    if (isLoading) {
      service.load();
    } else {
      service.clear();
    }
  });

  return isLoading;
}
