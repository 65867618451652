import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { AuthenticationResultDto, Configuration } from '@artemis-software/wr-api';
import { filter, firstValueFrom, map, Observable, ReplaySubject } from 'rxjs';
import { AuthCredentials } from '@models/auth-credentials';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  authCredentials$ = new ReplaySubject<AuthCredentials | undefined>(1);
  loggedIn$: Observable<void>;

  constructor(
    private readonly apiConfig: Configuration,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly nbToastrService: NbToastrService) {
    this.initJwt();
    this.loggedIn$ = this.authCredentials$.pipe(
      filter(credentials => credentials !== undefined),
      map(() => undefined),
    );
  }

  public initJwt(): void {
    const jwtString = localStorage.getItem('wr-jwt');
    if (jwtString !== null) {
      this.authCredentials$.next(JSON.parse(jwtString));
    } else {
      this.authCredentials$.next(undefined);
    }
  }

  public async authenticate(username: string, password: string): Promise<AuthCredentials | null> {
    try {
      const result = await firstValueFrom(this.http.post<AuthenticationResultDto>(
        this.apiConfig.basePath + '/api/auth/login',
        {
          'userName': username,
          password,
        },
      ));

      if (result.error) {
        this.nbToastrService.danger(result.error.message, 'Fehler');

        throw Error(result.error.message);
      }

      const credentials = new AuthCredentials(
        result.result!.id,
        result.result!.userName,
        result.result!.token,
      );

      localStorage.setItem('wr-jwt', JSON.stringify(credentials));
      Sentry.setUser({
        id: credentials.id,
        username: credentials.username,
      });
      this.authCredentials$.next(credentials);
      return credentials;
    } catch (error) {
      console.error(error);
      this.authCredentials$.next(undefined);
      return null;
    }
  }

  public async logout(): Promise<void> {
    localStorage.removeItem('wr-jwt');
    this.authCredentials$.next(undefined);
    Sentry.setUser(null);
    await this.router.navigate(['/login']);
  }
}
