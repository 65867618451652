/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomFieldItemDto { 
    id: string;
    name: string;
    description: string;
    organisationId: string;
    elementType: CustomFieldItemDto.ElementTypeEnum;
    valueType: CustomFieldItemDto.ValueTypeEnum;
}
export namespace CustomFieldItemDto {
    export type ElementTypeEnum = 'Single' | 'Multiple';
    export const ElementTypeEnum = {
        Single: 'Single' as ElementTypeEnum,
        Multiple: 'Multiple' as ElementTypeEnum
    };
    export type ValueTypeEnum = 'Boolean' | 'Integer' | 'Double' | 'String' | 'Longtext';
    export const ValueTypeEnum = {
        Boolean: 'Boolean' as ValueTypeEnum,
        Integer: 'Integer' as ValueTypeEnum,
        Double: 'Double' as ValueTypeEnum,
        String: 'String' as ValueTypeEnum,
        Longtext: 'Longtext' as ValueTypeEnum
    };
}


