import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'wr-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  showPassword = false;
  usernameError = false;
  passwordError = false;

  public get username(): FormControl {
    return this.loginForm.get('username') as FormControl;
  }

  public get password(): FormControl {
    return this.loginForm.get('password') as FormControl;
  }

  loginForm = this.formBuilder.group({
    username: [''],
    password: [''],
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly auth: AuthService,
    private nbToastr: NbToastrService,
    private router: Router) {
    console.log(environment.tag);
    console.log(environment.apiUrl);
  }

  getInputType(): string {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  async onSubmit(): Promise<void> {
    this.usernameError = false;
    this.passwordError = false;
    this.loginForm.markAllAsTouched();
    const usernameControl = this.loginForm.get('username');
    if (usernameControl?.value && usernameControl.value.length <= 0) {
      this.usernameError = true;
    }

    const passwordControl = this.loginForm.get('password');
    if (passwordControl?.value && passwordControl.value.length <= 0) {
      this.passwordError = true;
    }

    if (this.usernameError || this.passwordError) {
      return;
    }

    const username: string = this.username.value;
    const password: string = this.password.value;
    const result = await this.auth.authenticate(username, password);
    if (result) {
      this.nbToastr.success('Login war erfolgreich!', 'Login');
      this.router.navigate(['/inspections']);
    }
  }

  usernameChange(): void {
    this.usernameError = false;
  }

  passwordChange(): void {
    this.passwordError = false;
  }

}
