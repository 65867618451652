<div *ngIf="value?.length; else noItems"
     class="building-equipments"
     [sortableArray]="value"
     [sortableOptions]="sortableOptions">
  <div class="building-equipment" *ngFor="let be of value">
    <div></div>
    <nb-icon pack="fas" icon="cube"></nb-icon>
    <span>{{be.equipmentName}}</span>
    <button nbButton ghost (click)="removeBuildingEquipment(be)" *ngIf="!isDisabled">
      <nb-icon pack="eva" icon="close"></nb-icon>
    </button>
  </div>
</div>

<ng-template #noItems>
  <div class="text-hint">Keine TGAs ausgewählt</div>
</ng-template>

<button *ngIf="!isDisabled"
        nbButton
        type="button"
        [nbPopover]="popoverMenu"
        [nbPopoverPlacement]="NbPosition.RIGHT">
  <nb-icon icon="plus-outline" pack="eva"></nb-icon>
</button>

<ng-template #popoverMenu>
  <div class="building-equipment-add">
    <wr-list-view [items]="notSelectedBuildingEquipments$|async"
                  [searchText]="text$.value"
                  (searchTextChange)="text$.next($event)"
                  (selectedChange)="addBuildingEquipment($event)">
      <ng-template wrListItem let-item>
        <div class="building-equipment-add-item">
          <nb-icon pack="fas" icon="cube"></nb-icon>
          <div class="building-equipment-list-item-name">
            <span>{{item.equipmentName}}</span>
            <span class="address">{{item.sectionText}}</span>
          </div>
        </div>
      </ng-template>
    </wr-list-view>
  </div>
</ng-template>
