<nb-card>
  <nb-card-header>
    <span>Reparaturaufträge</span>
    <div class="spacer"></div>
    <div>
      <wr-repair-task-status-select [(ngModel)]="status" [showAll]="true" />
    </div>
    <div>
      <nb-checkbox [(checked)]="overdue">Überfällig</nb-checkbox>
    </div>
  </nb-card-header>
  <wr-table [rows]="repairTasks()" [loading]="isLoading()" [rowLink]="getRepairTaskLink">
    <ng-template column let-repairTask [of]="repairTasks()" header="Name">
      {{ repairTask.title }}
    </ng-template>
    <ng-template column let-repairTask [of]="repairTasks()" header="Prüfpunktzeile">
      <ng-container *ngIf="repairTask.inspectionEntry">
        {{repairTask.inspectionEntry.note || "-"}}
      </ng-container>
    </ng-template>
    <ng-template column let-repairTask [of]="repairTasks()" header="Frist">
      <ng-container *ngIf="repairTask.deadline">
        {{ repairTask.deadline | date: 'dd.MM.yyyy' }}
      </ng-container>
    </ng-template>
    <ng-template column let-repairTask [of]="repairTasks()" header="Status">
      <wr-repair-task-status-text [status]="repairTask.status"></wr-repair-task-status-text>
    </ng-template>
    <ng-template column let-repairTask [of]="repairTasks()" header="Firma">
      <div *ngIf="repairTask.contractor">
        {{ repairTask.contractor.name }}
      </div>
    </ng-template>
    <wr-table-pagination [totalRows]="count()"
                         [(pageSize)]="pageSize"
                         [(page)]="page"></wr-table-pagination>
  </wr-table>
</nb-card>
