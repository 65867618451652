import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { EmailItemDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-email-type-select',
  templateUrl: './email-type-select.component.html',
  styleUrls: ['./email-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailTypeSelectComponent),
      multi: true,
    },
  ],
})
export class EmailTypeSelectComponent extends AbstractFormControl<EmailItemDto.EmailTypeEnum> {
  readonly EmailType = EmailItemDto.EmailTypeEnum;
  EmailTypes = [
    this.EmailType.Inspection,
    this.EmailType.RepairTask,
  ];
}
