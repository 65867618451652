<nb-card>
  <nb-card-header>
    <h5>
      <nb-icon icon="clipboard-check" pack="fas"></nb-icon>
      Begehungen
    </h5>
  </nb-card-header>
  <wr-data-table *ngIf="count$ | async as count; else noInspections"
                 [data$]="inspections$"
                 [sortKey]="'inspectionStatisticsSort'"
                 [storeUsage]="inspectionStoreUsage"
                 [defaultSort]="'continuationNumber'"
                 [defaultPageSize]="5">
    <ng-template dataColumn
                 let-inspection
                 key="continuationNumber"
                 display="FolgeNr.">
      <ng-template *ngIf="inspection.continuationNumber > 0">{{ inspection.continuationNumber }}</ng-template>
    </ng-template>
    <ng-template dataColumn
                 let-inspection
                 key="status"
                 display="Status"
    >
      <wr-status-text [status]="inspection.status"/>
    </ng-template>
    <ng-template dataColumn
                 let-inspection
                 key="fromTimestamp"
                 display="Datum"
    >{{ inspection.fromTimestamp | date: 'dd.MM.yyyy' }}
    </ng-template>
    <ng-template dataColumn
                 let-inspection
                 key="building.sectionText"
                 display="Adresse">{{ inspection.building.sectionText }}
    </ng-template>
  </wr-data-table>
</nb-card>

<ng-template #noInspections>
  <nb-card-body>
    <h5 class="empty-message">Keine Einträge</h5>
  </nb-card-body>
</ng-template>
