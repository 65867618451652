import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[cypressId]',
  standalone: true,
})
export class CypressIdDirective {
  @Input('cypressId') id!: string;
  @HostBinding('attr.data-cy')
  get dataCy(): string {
    return this.id;
  }
}
