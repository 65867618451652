import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { ChangeBuildingFilter, CountBuildings, FilterBuildings, SelectDashboardBuilding, LoadAllBuildings } from './building.action';
import { BuildingFilterDto, BuildingItemDto, BuildingService } from '@artemis-software/wr-api';
import { AuthService } from '@services/auth.service';

type BuildingStateModel = {
  buildings: BuildingItemDto[],
  filteredBuildings: BuildingItemDto[],
  count: number,
  loading: boolean,
  filter: BuildingFilterDto,
  selectedDashboardBuilding?: BuildingItemDto,
};

@State<BuildingStateModel>({
  name: 'buildings',
  defaults: {
    buildings: [],
    filteredBuildings: [],
    count: 0,
    loading: false,
    filter: {} as BuildingFilterDto,
    selectedDashboardBuilding: undefined,
  },
})
@Injectable()
export class BuildingState implements NgxsOnInit{

  readonly store = inject(Store);
  readonly buildingService = inject(BuildingService);
  readonly authService = inject(AuthService);

  ngxsOnInit(): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllBuildings());
    });
  }

  @Selector()
  static buildings(state: BuildingStateModel): BuildingItemDto[] {
    return state.buildings;
  }

  @Selector()
  static filteredBuildings(state: BuildingStateModel): BuildingItemDto[] {
    return state.filteredBuildings;
  }

  @Selector()
  static buildingsLoading(state: BuildingStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: BuildingStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: BuildingStateModel): BuildingFilterDto {
    return state.filter;
  }

  @Selector()
  static selectedDashboardBuilding(state: BuildingStateModel): BuildingItemDto | undefined {
    return state.selectedDashboardBuilding;
  }

  @Action(LoadAllBuildings)
  loadAllBuildings(ctx: StateContext<BuildingStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.buildingService.findAll({}).subscribe((buildings: BuildingItemDto[]) => {
      ctx.patchState({
        buildings,
        loading: false,
      });
    });
  }

  @Action(FilterBuildings)
  filterBuildings(ctx: StateContext<BuildingStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    const newFilter = this.createFilter(ctx.getState().filter);
    this.buildingService.findAll(newFilter).subscribe((buildings: BuildingItemDto[]) => {
      ctx.patchState({
        filteredBuildings: buildings,
        loading: false,
      });
    });
  }

  @Action(CountBuildings)
  countBuildings(ctx: StateContext<BuildingStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    const newFilter = this.createFilter(ctx.getState().filter);
    this.buildingService.getCount(newFilter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeBuildingFilter)
  changeBuildingFilter(ctx: StateContext<BuildingStateModel>, action: ChangeBuildingFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountBuildings());
    this.store.dispatch(new FilterBuildings());
  }

  @Action(SelectDashboardBuilding)
  selectDashboardBuilding(ctx: StateContext<BuildingStateModel>, action: SelectDashboardBuilding): void {
    ctx.patchState({
      selectedDashboardBuilding: action.building,
    });
  }

  private createFilter(filter: BuildingFilterDto) {
    return {
      text: filter.text,
      organisationId: filter.organisationId,
      page: filter.page,
      size: filter.size,
      sort: filter.sort,
      buildingType: filter.buildingType && filter.buildingType.length > 0 ? filter.buildingType : undefined,
      buildingClass: filter.buildingClass && filter.buildingClass.length > 0 ? filter.buildingClass : undefined,
    };
  }
}
