import { Component, Input } from '@angular/core';
import { CheckboxGroup } from '../../grouped-checkbox-list/grouped-checkbox-list.component';
import { BuildingItemDto } from '@artemis-software/wr-api';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { InspectionEntryFormType } from '@pages/inspection-detail-page-v2/inspection-util';

export type DuplicateSectionDialogResult = {
  targetBuildingSection: BuildingSectionDetailDto;
  entriesToDuplicate: InspectionEntryFormType[];
};

@Component({
  selector: 'wr-duplicate-section-dialog',
  templateUrl: './duplicate-section-dialog.component.html',
  styleUrls: ['./duplicate-section-dialog.component.scss'],
})
export class DuplicateSectionDialogComponent {

  selectedSection?: BuildingSectionDetailDto;
  building!: BuildingItemDto;
  groups: CheckboxGroup<string>[] = [];

  readonly onSubmit = () => this.getResult();

  private _entries: InspectionEntryFormType[] = [];

  @Input()
  set entries(entries: InspectionEntryFormType[]) {
    const map = new Map<string, InspectionEntryFormType[]>;
    entries.forEach(entry => {
      const groupId = entry.value.checkpointGroupId!;
      if (!map.has(groupId)) {
        map.set(groupId, []);
      }
      map.get(groupId)!.push(entry);
    });
    this.groups = Array.from(map.values()).map((entries) => ({
      name: this.getGroupName(entries[0]),
      checked: true,
      items: entries.map(entry => ({
        name: this.getCheckpointName(entry),
        checked: true,
        item: entry.value.checkpointId!,
      })),
    }));
    this._entries = entries;
  }

  private getResult(): DuplicateSectionDialogResult {
    const checkpointIds = this.groups.flatMap((group) => group.items
      .filter((item) => item.checked)
      .map((item) => item.item));
    const entriesToDuplicate = this._entries.filter((entry) => checkpointIds.includes(entry.value.checkpointId!));
    return {
      targetBuildingSection: this.selectedSection!,
      entriesToDuplicate,
    };
  }

  private getGroupName(entry: InspectionEntryFormType): string {
    return `${entry.value.checkpointGroupNumber} ${entry.value.checkpointGroupName}`;
  }

  private getCheckpointName(entry: InspectionEntryFormType): string {
    return `${entry.value.checkpointGroupNumber}.${entry.value.checkpointNumber} ${entry.value.checkpointName}`;
  }
}
