import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ChangeTemplateFilter, CountTemplates, FilterTemplates, LoadAllTemplates } from './template.action';
import { TemplateFilterDto, TemplateItemDto, TemplateService } from '@artemis-software/wr-api';
import { AuthService } from '@services/auth.service';

type TemplateStateModel = {
  templates: TemplateItemDto[],
  count: number,
  loading: boolean,
  filter: TemplateFilterDto,
  filteredTemplates: TemplateItemDto[],
}

@State<TemplateStateModel>({
  name: 'template',
  defaults: {
    templates: [],
    loading: false,
    count: 0,
    filter: {} as TemplateFilterDto,
    filteredTemplates: [],
  },
})

@Injectable()
export class TemplateState implements NgxsOnInit {

  constructor(private readonly store: Store,
    private readonly templateService: TemplateService,
    private readonly authService: AuthService) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllTemplates());
    });
  }

  @Selector()
  static templates(state: TemplateStateModel): TemplateItemDto[] {
    return state.templates;
  }

  @Selector()
  static templateLoading(state: TemplateStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static filteredTemplates(state: TemplateStateModel): TemplateItemDto[] {
    return state.filteredTemplates;
  }

  @Action(LoadAllTemplates)
  loadAllTemplate(ctx: StateContext<TemplateStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.templateService.findAll({}).subscribe(templates => {
      ctx.patchState({
        templates: templates,
        loading: false,
      });
    });
  }

  @Selector()
  static count(state: TemplateStateModel): number {
    return state.count;
  }

  @Action(CountTemplates)
  countTemplates(ctx: StateContext<TemplateStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    const newFilter = this.createFilter(ctx.getState().filter);
    this.templateService.getCount(newFilter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeTemplateFilter)
  changeTemplateFilter(ctx: StateContext<TemplateStateModel>, action: ChangeTemplateFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountTemplates());
    this.store.dispatch(new FilterTemplates());
  }

  @Action(FilterTemplates)
  filterTemplates(ctx: StateContext<TemplateStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    const newFilter = this.createFilter(ctx.getState().filter);
    this.templateService.findAll(newFilter).subscribe((templates: TemplateItemDto[]) => {
      ctx.patchState({
        filteredTemplates: templates,
        loading: false,
      });
    });
  }

  private createFilter(filter: TemplateFilterDto) {
    return {
      text: filter.text,
      page: filter.page,
      size: filter.size,
      sort: filter.sort,
    };
  }
}
