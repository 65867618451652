import { Injectable, signal } from '@angular/core';
import { UserDetailDto, UserService } from '@artemis-software/wr-api';
import { AuthService } from '@services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {

  currentUser = signal<UserDetailDto | undefined>(undefined);

  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthService
  ) {
    this.authService.loggedIn$.subscribe(() => {
      this.updateUser();
    });
  }

  updateUser(): void {
    this.userService.findCurrentUser().subscribe((user) => {
      this.currentUser.set(user);
    });
  }
}
