<div class='list-container'>

  <nb-card class='list-filter-card'>
    <nb-card-body>
      <div class='list-filter'>
        <div class='list-filter-title'>
          <nb-icon pack='fas' icon='tag'></nb-icon>
          Tags
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
              <button nbButton ghost status="primary" (click)="add()">
                <nb-icon pack="eva" icon="plus-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
    [data$]="tags$"
    (lineClick)="navigateToTag($event)"
    [sortKey]="'tagSort'"
    [defaultSort]="'name'"
    [storeUsage]="tagStoreUsage"
  >
    <ng-template
      dataColumn
      let-tag
      key="name"
      display="Name">
      <div *ngIf="tag.name">
        {{tag.name}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-tag
      key="color"
      display="Farbe">
      <wr-color-badge [color]="tag.color"></wr-color-badge>
    </ng-template>
    <ng-template
      dataColumn
      let-tag
      key="description"
      display="Beschreibung">
      <div *ngIf="tag.description">
        {{tag.description |  truncate:100}}
      </div>
    </ng-template>
  </wr-data-table>
</div>
