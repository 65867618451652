import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SortDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';

let activeKey = '';

@Component({
  selector: 'wr-order-toggle-button',
  templateUrl: './order-toggle-button.component.html',
  styleUrls: ['./order-toggle-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OrderToggleButtonComponent,
    },
  ],
})
export class OrderToggleButtonComponent extends AbstractFormControl<SortDto> {

  readonly DOWN_ARROW = 'arrow-ios-downward-outline';
  readonly UP_ARROW = 'arrow-ios-upward-outline';

  @Input() title: string = '';
  @Input() sortable = true;
  toggled = true;

  private _key: string = '';

  @Input()
  set key(value: string) {
    this._key = value;
    this.value = {
      sort: value,
      order: SortDto.OrderEnum.Asc,
    };
  }

  get key(): string {
    return this._key;
  }

  toggleButton(): void {
    if (this.value != null && this.key != null && this.sortable) {
      activeKey = this.key;
      this.toggled = !this.toggled;
      this.value = {
        sort: this.key,
        order: this.toggled ? SortDto.OrderEnum.Asc : SortDto.OrderEnum.Desc,
      };
    }
  }

  get isActive(): boolean {
    return this.key === activeKey;
  }
}
