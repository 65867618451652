import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { BuildingItemDto, IdReferenceDto } from '@artemis-software/wr-api';
import { Select } from '@ngxs/store';
import { BuildingState } from '@/store/building/building.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'wr-building-multi-select',
  templateUrl: './building-multi-select.component.html',
  styleUrls: ['./building-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingMultiSelectComponent),
      multi: true,
    },
  ],
})
export class BuildingMultiSelectComponent extends AbstractFormControl<IdReferenceDto>{
  readonly equalFn = (c1: IdReferenceDto, c2: IdReferenceDto) => c1.value === c2.value;

  @Select(BuildingState.buildings)
  buildings$!: Observable<BuildingItemDto[]>;

  @Input()
  showNoneOption = false;

}
