<wr-base-dialog header="Wiederholungen">
  <nb-card>
    <nb-card-body>
      <div class="table-container">
        <table>
          <thead>
          <tr>
            <th>Datum</th>
            <th>Verspätet</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of maintenanceTimerCheckDates">
            <td>{{ item.checkDate }}</td>
            <td>{{ item.checkedInTime ? 'Nein' : 'Ja' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </nb-card-body>
  </nb-card>
</wr-base-dialog>
