import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BuildingDetailDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '../abstract-form-control';

@Component({
  selector: 'wr-building-class-select',
  templateUrl: './building-class-select.component.html',
  styleUrls: ['./building-class-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingClassSelectComponent),
      multi: true,
    },
  ],
})
export class BuildingClassSelectComponent extends AbstractFormControl<BuildingDetailDto.BuildingClassEnum> {
  readonly BuildingClass = Object.values(BuildingDetailDto.BuildingClassEnum);

  @Input()
  showEmptyOption = false;
}
