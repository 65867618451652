<nb-card [nbSpinner]="!!(loading$|async)">
  <nb-card-header>
    <div class="header">
      <span>{{buildingIdentifier}}</span>
      <wr-building-condition *ngIf="building?.buildingCondition?.conditionValue as condition"
                             [condition]="condition"
                             [fontSize]="'1.5rem'">
      </wr-building-condition>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="addresses">
      <span>{{building?.sectionText}}</span>
    </div>
    <div class="last-inspection" *ngIf="lastInspection$|async as inspection; else noInspection">
      <div>
        <span>Rundgang vom {{inspection.fromTimestamp | date: 'dd.MM.yyyy'}}</span>
        <wr-status-text [status]="inspection.status"></wr-status-text>
      </div>
      <button nbButton
              routerLink="/inspection/{{inspection.id}}"
              nbTooltip="Zum Rundgang"
              nbTooltipStatus="basic">
        <nb-icon icon="external-link-outline" pack="eva"></nb-icon>
      </button>
    </div>

    <ng-template #noInspection>
      <ng-container *ngIf="!(loading$|async)">
        Kein Rundgang gefunden
      </ng-container>
    </ng-template>
  </nb-card-body>
</nb-card>
