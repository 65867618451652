<nb-select class="fixed-size-select"
           placeholder="Gebäudeteil auswählen"
           multiple
           [(selected)]="value"
           [disabled]="isDisabled"
           [compareWith]="equalFn">
  <nb-option *ngFor="let buildingSection of buildingSections$|async"
             [value]="{value: buildingSection.id}">
    {{ buildingSection.address.street}} {{ buildingSection.address.number}}
  </nb-option>
</nb-select>
