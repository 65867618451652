<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="house"></nb-icon>
          Gebäude
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <div class="filter-control-group">
                <label class="label" for="buildingClass">Gebäude Klasse</label>
                <wr-building-class-select id="buildingClass"
                                          formControlName="buildingClass"
                                          [showEmptyOption]="true">
                </wr-building-class-select>
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
    <wr-data-table
      [data$]="buildings$"
      (lineClick)="navigateToBuilding($event)"
      [actions]="actions"
      [sortKey]="'cooperativeBuildingSort'"
      [storeUsage]="buildingStoreUsage"
      [defaultSort]="'address'">
      <ng-template
        dataColumn
        let-building
        key="address"
        display="Adresse">
        <div *ngIf="building.sectionText">
          {{building.sectionText}}
        </div>
      </ng-template>
      <ng-template
        dataColumn
        let-building
        key="name"
        display="Name">
        <div *ngIf="building.name">
          {{building.name}}
        </div>
      </ng-template>
      <ng-template
        dataColumn
        let-building
        key="organisationName"
        display="Genossenschaft">
        <div *ngIf="building.organisationName">
          {{building.organisationName}}
        </div>
      </ng-template>
      <ng-template
        dataColumn
        let-building
        key="internalNumber"
        display="Interne Nummer">
        <div *ngIf="building.internalNumber">
          {{building.internalNumber}}
        </div>
      </ng-template>
      <ng-template
        dataColumn
        let-building
        key="buildingCondition.conditionValue"
        display="Zustand"
        [sortable]="false">
          <wr-building-condition *ngIf="building.buildingCondition.conditionValue as condition" [condition]="condition"></wr-building-condition>
      </ng-template>
    </wr-data-table>
</div>
