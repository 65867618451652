<div class="notification-item" [class.read]="!!notification.readAt">
  <div class="notification-badge">
    <nb-badge *ngIf="!notification.readAt" [position]="'center right'" status="danger" [dotMode]="true"></nb-badge>
  </div>

  <nb-icon [icon]="icon"
           pack="eva"
           [status]="statusMap[notification.variant]"></nb-icon>

  <div class="notification-title">
    <a *ngIf="getUrl() as url; else titleNoLink" [href]="'#' + notification.url"
       (click)="markAsRead.emit(notification)">
      {{ notification.title }}
    </a>
    <ng-template #titleNoLink>
      {{ notification.title }}
    </ng-template>
  </div>
  <div class="notification-message">
    {{ notification.message }}
  </div>
  <div class="notification-badge">
    <nb-badge [text]="notificationTypeText" [position]="'center right'" status="primary"></nb-badge>
  </div>
  <div class="notification-date"
       [nbTooltip]="(notification.date | date : 'dd.MM.yyyy hh:mm') ?? ''"
       [nbTooltipStatus]="statusMap[notification.variant]">
    {{ timePassed }}
  </div>
  <div class="actions">
    <button *ngIf="!notification.readAt"
            nbButton
            ghost
            size="tiny"
            status="primary"
            (click)="markAsRead.emit(notification)">
      <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
      gelesen
    </button>
  </div>
</div>
