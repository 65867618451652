import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[wrControlTemplate]',
})
export class GenericMultiControlTemplateDirective {

  constructor(
    public readonly templateRef: TemplateRef<any>,
  ) {
  }
}
