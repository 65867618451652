<div class="download-item">
  <nb-icon icon="file-text-outline" pack="eva"></nb-icon>

  <div class="download-text">
    <b>{{label}}</b>
      <ng-container *ngIf="inspectionPdf$ | async as inspectionPdf; else notAvailable">
        <span>Zuletzt geändert: {{inspectionPdf.lastGenerated | date: 'dd.MM.yyyy HH:mm'}}</span>
        <span *ngIf="inspectionPdf.inspectionVersion != inspection.version"
              class="warning">(veraltet)</span>
      </ng-container>
      <ng-template #notAvailable>
        <span *ngIf="!inspectionPdf$">Nicht vorhanden</span>
      </ng-template>
  </div>
  <ng-container *ngIf="inspectionPdfType == InspectionPdfTypeEnum.Shortlist && isAdmin()">
    <form [formGroup]="filter">
      <wr-contractor-multi-select id="contractors" formControlName="contractors" [showNoneOption]="true"></wr-contractor-multi-select>
    </form>
  </ng-container>
  <ng-container *ngIf="showBuildingSectionFilter(inspectionPdfType) && isAdmin()">
    <form [formGroup]="filter">
        <wr-building-section-multi-select
          id="buildingSections"
          formControlName="buildingSections"
          [buildingId]="inspection.building.id">
        </wr-building-section-multi-select>
    </form>
  </ng-container>
  <nb-button-group class="download-buttons" [nbSpinner]="!!(loading$|async)">
    <ng-container *ngIf="inspectionPdf$ | async as inspectionPdf; else generateButton">
      <button *ngIf="inspectionPdf$ && isAdmin()"
              (click)="generatePdf()"
              nbButton
              status="basic"
              nbTooltip="Neu generieren">
        <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
      </button>
      <button *ngIf="inspectionPdf$"
              (click)="downloadPdf()"
              nbButton
              status="basic"
              nbTooltip="{{label}} herunterladen">
        <nb-icon pack="eva" icon="download-outline"></nb-icon>
      </button>
      <button *ngIf="inspectionPdf$"
              (click)="openPdfLink()"
              nbButton
              status="basic"
              nbTooltip="{{label}} öffnen">
        <nb-icon pack="eva" icon="external-link-outline"></nb-icon>
      </button>
      <button *ngIf="inspectionPdf$ && isAdmin()"
              (click)="openPdfShareLink()"
              nbButton
              status="basic"
              nbTooltip="{{label}} teilen">
        <nb-icon pack="eva" icon="share-outline"></nb-icon>
      </button>
    </ng-container>
    <ng-template #generateButton>
      <button *ngIf="isAdmin()"
              (click)="generatePdf()"
              nbButton
              status="basic"
              nbTooltip="Generieren">GENERIEREN
      </button>
      <div *ngIf="!isAdmin()">Nicht generiert!</div>
    </ng-template>
  </nb-button-group>
</div>
