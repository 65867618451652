<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="square-check"></nb-icon>
          Prüfpunkte
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <div class="filter-control-group">
                <label class="label" for="checkpointGroup">Prüfpunktgruppe</label>
                <wr-checkpoint-group-select id="checkpointGroup" formControlName="checkpointGroupId"
                                            [showEmptyOption]="true"></wr-checkpoint-group-select>
              </div>
              <div class="filter-control-group">
                <label class="label" for="deprecated">Überholte Prüfpunkte anzeigen</label>
                <nb-select id="deprecated" formControlName="deprecated">
                  <nb-option value="false">Nicht überholte</nb-option>
                  <nb-option value="true">Überholte</nb-option>
                </nb-select>
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
              <button nbButton ghost status="primary" (click)="add()">
                <nb-icon pack="eva" icon="plus-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
  [data$]="checkpoints$"
  (lineClick)="navigateToCheckpoint($event)"
  [sortKey]="'checkpointSort'"
  [storeUsage]="checkpointStoreUsage"
  [defaultSort]="'number'"
  >
    <ng-template
      dataColumn
      let-checkpoint
      key="number"
      display="Nummer">
      <div *ngIf="checkpoint.number">
        {{getCheckpointNumber(checkpoint)}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-checkpoint
      key="groupName"
      display="Gruppe">
      <div *ngIf="checkpoint">
        {{getCheckpointGroupName(checkpoint)}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-checkpoint
      key="name"
      display="Name">
      <div *ngIf="checkpoint.name">
        {{checkpoint.name}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-checkpoint
      key="weight"
      display="Gewicht">
      <div *ngIf="checkpoint.weight">
        <wr-checkpoint-weight [weight]="checkpoint.weight"></wr-checkpoint-weight>
      </div>
    </ng-template>
  </wr-data-table>
</div>

