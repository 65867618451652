<div [ngClass]="status" [ngSwitch]="status">
  <ng-container *ngSwitchCase="StatusEnum.Draft">
    <nb-icon icon="edit-outline" pack="eva"></nb-icon>
    Vorlage
  </ng-container>
  <ng-container *ngSwitchCase="StatusEnum.Todo">
    <nb-icon icon="radio-button-on-outline" pack="eva"></nb-icon>
    Offen
  </ng-container>
  <ng-container *ngSwitchCase="StatusEnum.InProgress">
    <nb-icon icon="play-circle-outline" pack="eva"></nb-icon>
    In Arbeit
  </ng-container>
  <ng-container *ngSwitchCase="StatusEnum.InReview">
    <nb-icon icon="refresh-outline" pack="eva"></nb-icon>
    In Prüfung
  </ng-container>
  <ng-container *ngSwitchCase="StatusEnum.Done">
    <nb-icon icon="checkmark-outline" pack="eva"></nb-icon>
    Abgeschlossen
  </ng-container>
  <ng-container *ngSwitchCase="StatusEnum.Sent">
    <nb-icon icon="done-all-outline" pack="eva"></nb-icon>
    Gesendet
  </ng-container>
</div>
