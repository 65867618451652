import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[wrDetailView]'
})
export class DetailViewDirective {

  constructor(
    public readonly templateRef: TemplateRef<any>,
  ) {
  }
}
