<ng-container *ngIf="form">
  <wr-base-dialog status="info"
                  header="Reparaturauftrag"
                  [submitResponse]="onSubmit"
                  [disableSubmit]="!form.valid || !isAdmin()">
    <hau-form [formGroup]="form">
      <hau-form-field label="Titel">
        <input type="text" formControlName="title" nbInput fullWidth>
      </hau-form-field>
      <hau-form-field label="Beschreibung">
        <wr-rich-text-editor formControlName="description"></wr-rich-text-editor>
      </hau-form-field>
      <hau-form-field label="Status">
        <wr-repair-task-status-select formControlName="status"></wr-repair-task-status-select>
      </hau-form-field>
      <hau-form-field label="Externe Firma" [validation]="form.validation.contractor">
        <wr-contractor-select formControlName="contractor"
                              [contractors]="contractorForRepairTask"></wr-contractor-select>
      </hau-form-field>
    </hau-form>
  </wr-base-dialog>
</ng-container>
