import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { LoadAllUsers } from '@/store/user/user.action';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { UserDetailDto, UserMergeDto, UserService } from '@artemis-software/wr-api';
import { PendingChanges } from '@guards/pending-changes.guard';
import {
  Attachment,
  attachmentsFromDetails,
  attachmentToMerge,
} from '@shared/form-controls/attachment-upload/attachment-utils';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-user-detail-page',
  templateUrl: './user-detail-page.component.html',
  styleUrls: ['./user-detail-page.component.scss'],
})
export class UserDetailPageComponent implements PendingChanges {
  isAdmin = isAdmin();
  isEditing = false;
  firstSubmit = false;
  form?: FormGroup;

  saving$ = new BehaviorSubject(false);

  constructor(
    route: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router,
    private readonly dialogService: NbDialogService,
    private readonly userService: UserService,
    private readonly toastrService: NbToastrService,
  ) {
    route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        if (id === 'new') {
          this.isEditing = true;
          this.initForm();
        } else {
          this.userService.findById(id).subscribe((user) => {
            this.initForm(user);
            this.form?.disable();
          });
        }
      } else {
        this.router.navigate(['users']);
      }
    });
  }

  initForm(dto?: UserDetailDto): void {
    this.form = new FormGroup({
      id: new FormControl(dto?.id),
      userName: new FormControl(dto?.userName, [Validators.required]),
      firstName: new FormControl(dto?.firstName, [Validators.required]),
      lastName: new FormControl(dto?.lastName, [Validators.required]),
      abbreviation: new FormControl(dto?.abbreviation, [Validators.required]),
      email: new FormControl(dto?.email, [Validators.required]),
      organisationId: new FormControl(dto?.organisation?.id, [Validators.required]),
      password: new FormControl(null),
      repeatPassword: new FormControl(null),
      attachments: new FormControl(attachmentsFromDetails(dto?.attachments ?? [])),
    });
  }

  hasError(controlName: string, errorName = 'required'): boolean {
    const control = this.form?.get(controlName);
    return this.isEditing && this.firstSubmit && (control?.hasError(errorName) ?? false) && ((control?.touched ?? false) || (control?.dirty ?? false));
  }

  onEdit(): void {
    this.isEditing = true;
    this.form?.enable();
  }

  async submit(): Promise<void> {
    this.firstSubmit = true;
    this.form?.markAsTouched();

    if (!this.form?.valid) {
      this.toastrService.danger('Benutzer kann nicht gespeichert werden, es gibt noch Fehler.', 'Benutzer');
      return;
    }

    if (this.form?.value.password && this.form?.value.password.length > 0) {
      if (this.form?.value.password !== this.form?.value.repeatPassword) {
        this.toastrService.danger('Passwörter stimmen nicht überein.', 'Benutzer');
        return;
      } else if (this.form?.value.password.length < 8) {
        this.toastrService.danger('Passwort muss mindestens 8 Zeichen lang sein.', 'Benutzer');
        return;
      } else if (this.form?.value.password.length > 50) {
        this.toastrService.danger('Passwort darf maximal 50 Zeichen lang sein.', 'Benutzer');
        return;
      } else if (!/[A-Z]/.test(this.form?.value.password)) {
        this.toastrService.danger('Passwort muss mindestens einen Großbuchstaben enthalten.', 'Benutzer');
        return;
      } else if (!/[a-z]/.test(this.form?.value.password)) {
        this.toastrService.danger('Passwort muss mindestens einen Kleinbuchstaben enthalten.', 'Benutzer');
        return;
      } else if (!/[0-9]/.test(this.form?.value.password)) {
        this.toastrService.danger('Passwort muss mindestens eine Zahl enthalten.', 'Benutzer');
        return;
      }
    }

    if (this.form?.value.organisationId === '') {
      this.form?.addControl('organisationId', new FormControl(undefined));
    }
    try {
      this.saving$.next(true);
      const userDto: UserMergeDto = {
        ...this.form?.value,
        attachments: this.form?.value?.attachments?.map((dto: Attachment) => attachmentToMerge(dto)),
      };
      const user = await firstValueFrom(this.userService.merge(userDto));
      this.isEditing = false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.store.dispatch(new LoadAllUsers());
        this.router.navigate(['user', user.id]).then(() => {
          this.toastrService.success('Benutzer wurde erfolgreich gespeichert', 'Benutzer');
        });
      });
    } catch (e: unknown) {
      console.error(e);
      this.toastrService.danger('Benutzer konnte nicht gespeichert werden', 'Fehler');
    } finally {
      this.saving$.next(false);
    }
  }

  onDelete(): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Benutzer löschen',
        message: 'Bist du sicher, dass du den Benutzer löschen willst?',
      },
    }).onClose.subscribe({
      next: (result) => {
        if (result) {
          this.userService.deleteById(this.form?.value.id).subscribe(() => {
            this.store.dispatch(new LoadAllUsers());
            this.router.navigate(['users']);
            this.toastrService.success('Benutzer wurde erfolgreich gelöscht', 'Benutzer');
          });
        }
      }, error: () => {
        this.toastrService.danger('Beim Löschen des Benutzers ist ein Fehler aufgetreten', 'Benuzter');
      },
    });
  }

  async cancel(): Promise<void> {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['user', this.form?.value.id]);
    });
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['users']);
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.isEditing;
  }
}
