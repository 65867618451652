import { BuildingFilterDto, BuildingItemDto } from '@artemis-software/wr-api';

export class LoadAllBuildings {
  static readonly type = '[Building] Load All Buildings';
}

export class FilterBuildings {
  static readonly type = '[Building] Load filtered buildings';
}

export class CountBuildings {
  static readonly type = '[Building] Count buildings';
}

export class ChangeBuildingFilter {
  static readonly type = '[Building] Change filter';

  constructor(public filter: BuildingFilterDto) {
  }
}

export class SelectDashboardBuilding {
  static readonly type = '[Building] Select dashboard building';

  constructor(public building: BuildingItemDto) {
  }
}
