import { Component, inject } from '@angular/core';
import { BuildingItemDto } from '@artemis-software/wr-api';
import { defineStoreUsage } from '@/utils/storeUsage';
import { ChangeBuildingFilter } from '@/store/building/building.action';
import { BuildingState } from '@/store/building/building.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'wr-cooperative-statistics-building-overview',
  templateUrl: './cooperative-statistics-building-overview.component.html',
  styleUrls: ['./cooperative-statistics-building-overview.component.scss'],
})
export class CooperativeStatisticsBuildingOverviewComponent {
  router = inject(Router);
  store = inject(Store);

  buildingStoreUsage = defineStoreUsage({
    filterAction: ChangeBuildingFilter,
    countSelector: BuildingState.count,
    loadingSelector: BuildingState.buildingsLoading,
  });

  @Select(BuildingState.filteredBuildings)
  buildings$!: Observable<BuildingItemDto[]>;
  @Select(BuildingState.count)
  count$!: Observable<number>;
  @Select(BuildingState.buildingsLoading)
  loading$!: Observable<boolean>;

  navigateToBuilding(event: BuildingItemDto): void {
    this.router.navigate(['building', event.id]);
  }
}
