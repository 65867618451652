import { Component, computed } from '@angular/core';
import { map } from 'rxjs';
import {
  BuildingDetailDto,
  CooperativeInspectionItemDto,
  InspectionFilterDto,
  InspectionService,
} from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDateService } from '@nebular/theme';
import { currentUser, isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-cooperative-inspection-overview-page',
  templateUrl: './cooperative-inspection-overview-page.component.html',
  styleUrls: ['./cooperative-inspection-overview-page.component.scss'],
})
export class CooperativeInspectionOverviewPageComponent {
  isAdmin = isAdmin();
  currentUser = currentUser();
  currentOrganisationId = computed(() => this.currentUser()?.organisation.id);
  form!: FormGroup;

  readonly loadInspections = (filter: InspectionFilterDto) => this.inspectionService.findAllFinishedByCooperative(filter);
  readonly getCount = (filter: InspectionFilterDto) => this.loadInspections(filter).pipe(map((inspections) => inspections.length));

  constructor(private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly dateService: NbDateService<Date>,
              private readonly inspectionService: InspectionService) {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(''),
      buildingId: this.formBuilder.control(''),
      building: this.formBuilder.control(undefined),
    });

    this.form.get('building')?.valueChanges.subscribe((building: BuildingDetailDto) => {
      this.form.get('buildingId')?.setValue(building?.id);
    });
  }

  navigateToInspection(entry: CooperativeInspectionItemDto): void {
    this.router.navigate(['inspection', entry.id]);
  }
}
