<button
  nbButton
  class="notifications-button"
  [nbPopover]="notificationsPopover"
  nbPopoverPlacement="bottom-end"
>
  <div class="notifications-button-container">
    <nb-icon pack="eva" icon="bell-outline"></nb-icon>
    <nb-badge
      *ngIf="hasUnreadNotifications"
      class="notifications-badge"
      [text]="formattedUnreadCount"
      status="danger"
      position="top right"></nb-badge>
  </div>
</button>

<ng-template #notificationsPopover>
  <div class="notifications-list">
    <nb-list nbInfiniteList [threshold]="50" (bottomThreshold)="loadNotifications.next()">
      <nb-list-item class="actions-header">
        <button nbButton fullWidth (click)="markAllAsRead()" ghost status="primary">
          <nb-icon icon="done-all-outline" pack="eva"></nb-icon>
          Alle gelesen
        </button>
      </nb-list-item>
      <nb-list-item *ngFor="let notification of notifications | async">
        <wr-notification-item [notification]="notification" (markAsRead)="markAsRead($event)"></wr-notification-item>
      </nb-list-item>
    </nb-list>
  </div>
</ng-template>
