<div class="list-container">
  <nb-card class="list-filter-card" style="min-height: 1px">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="image"></nb-icon>
          Archiv
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <div class="filter-control-group">
                <label class="label" for="tag">Tags</label>
                <nb-select fullWidth formControlName="tag" id="tag">
                  <nb-option [value]="''">Kein Tag</nb-option>
                  <nb-option *ngFor="let tag of tags()" [value]="tag">
                    <div class="icon-text-container">
                      <nb-icon class="fixed-icon"
                               icon="pricetags-outline"
                               pack="eva"
                               [style.color]="tag.color"></nb-icon>
                      <div class="text-content">{{ tag.name }}</div>
                    </div>
                  </nb-option>
                </nb-select>
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <div class="attachments-grid">
    <ng-container *ngFor="let attachment of attachments()">
      <wr-attachment-preview
        [isDisabled]="true"
        [attachment]="attachment">
      </wr-attachment-preview>
    </ng-container>
  </div>
</div>
