<nb-form-field>
  <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
  <input nbInput
         fullWidth
         type="text"
         placeholder="Suchen"
         [ngModel]="search"
         (ngModelChange)="searchChange.next($event)">
  <nb-icon *ngIf="search" nbSuffix icon="close-outline" pack="eva" (click)="clear($event)"></nb-icon>
</nb-form-field>
