import { AttachmentDetailDto } from '@artemis-software/wr-api';

export const getAcceptForFileType = (type: AttachmentDetailDto.TypeEnum): string => {
  switch (type) {
  case AttachmentDetailDto.TypeEnum.File:
    return '*';
  case AttachmentDetailDto.TypeEnum.Image:
    return 'image/*';
  }
  throw new Error('Unknown type');
};
