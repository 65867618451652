<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="clipboard-check"></nb-icon>
          Rundgänge
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <div class="filter-control-group">
                <label class="label" for="datePicker">Zeitraum</label>
                <wr-date-range-picker id="datePicker" formControlName="selectedRange"
                                      [showTodayButton]="true"></wr-date-range-picker>
              </div>
              <div class="filter-control-group">
                <label class="label" for="building">Gebäude</label>
                <wr-building-select id="building"
                                    formControlName="building"
                                    [isPropertyManager]="true">
                </wr-building-select>
              </div>
              <div class="filter-control-group">
                <label class="label">Zustand</label>
                <wr-condition-select id="buildingCondition" formControlName="condition">
                </wr-condition-select>
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
    [data$]="inspections$"
    (lineClick)="navigateToInspection($event)"
    sortKey="propertyManagerInspectionSort"
    [storeUsage]="inspectionStoreUsage"
    [defaultSort]="'address'">
    <ng-template
      dataColumn
      let-inspection
      key="continuationNumber"
      display="FolgeNr">
      <div *ngIf="inspection.continuationNumber">
        {{ inspection.continuationNumber }}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-inspection
      key="status"
      display="Status">
      <div *ngIf="inspection.status">
        <wr-status-text [status]="inspection.status"></wr-status-text>
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-inspection
      key="date"
      display="Datum">
      <div *ngIf="inspection.fromTimestamp">
        {{ inspection.fromTimestamp | date: 'dd.MM.yyyy' }}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-inspection
      key="address"
      display="Adresse">
      <div *ngIf="inspection.building.sectionText">
        {{ inspection.building.sectionText }}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-inspection
      key="technician"
      display="Techniker*in">
      <div *ngIf="inspection.technician.firstName && inspection.technician.lastName">
        {{ inspection.technician.firstName }} {{ inspection.technician.lastName }}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-inspection
      key="clerk"
      display="Sachbearbeiter*in">
      <div *ngIf="inspection.clerk ; else noClerk">
        {{ inspection.clerk.firstName }} {{ inspection.clerk.lastName }}
      </div>
      <ng-template #noClerk>
        <div>Nicht zugewiesen</div>
      </ng-template>
    </ng-template>
    <ng-template
      dataColumn
      let-inspection
      key="organisation"
      display="Genossenschaft">
      <div *ngIf="inspection.building.organisationId">
        {{ getOrganisationName(inspection.building.organisationId) | async }}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-inspection
      key="condition"
      display="Zustand"
      [sortable]="false">
      <div *ngIf="inspection.condition.conditionValue">
        <wr-building-condition
          [condition]="inspection.condition.conditionValue"></wr-building-condition>
      </div>
    </ng-template>
  </wr-data-table>
</div>

