<div [formGroup]="formGroup" class="grid">
  <div class="col-4">
    <label class="label" for="zipCode">Postleitzahl</label>
    <input nbInput
           fullWidth
           id="zipCode"
           type="text"
           placeholder="PLZ"
           formControlName="zipCode">
    <div class="error" *ngIf="hasError('zipCode')">Postleitzahl ist erforderlich!</div>
  </div>
  <div class="col-4">
    <label class="label" for="city">Stadt</label>
    <input nbInput
           fullWidth
           id="city"
           type="text"
           placeholder="Stadt"
           formControlName="city">
    <div class="error" *ngIf="hasError('city')">Stadt ist erforderlich!</div>
  </div>
  <div class="col-4">
    <label class="label" for="province">Bundesland</label>
    <input nbInput
           fullWidth
           id="province"
           type="text"
           placeholder="Bundesland"
           formControlName="province">
  </div>
</div>
