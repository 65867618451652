import { Pipe, PipeTransform } from '@angular/core';
import { MaintenanceTimerDetailDto } from '@artemis-software/wr-api';

@Pipe({
  name: 'maintenanceTimerType',
})
export class MaintenanceTimerTypePipe implements PipeTransform {

  transform(type: MaintenanceTimerDetailDto.TypeEnum): string {
    switch (type) {
    case MaintenanceTimerDetailDto.TypeEnum.Maintenance:
      return 'Wartung';
    case MaintenanceTimerDetailDto.TypeEnum.Inspection:
      return 'Inspektion';
    default:
      return '';
    }
  }

}
