import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';

const apiUrl = environment.apiUrl;
type HttpOptions = {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any | null;
};

@Injectable({
  providedIn: 'root',
})
export class InspectionPdfDownloadService {
  private headers = new HttpHeaders()
    .set('Content-Type', 'application/json');
  private httpOptions: HttpOptions = {
    headers: this.headers,
  };

  constructor(private readonly http: HttpClient) {
  }

  downloadPdf(fileName: string, inspectionPdfId: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({}),
      responseType: 'blob',
    };
    return this.http.post<string>(apiUrl + '/api/inspectionPdf/download/' + inspectionPdfId, {
      inspectionPdfId: inspectionPdfId,
    }, this.getOptions(httpOptions)).pipe(map(buffer => {
      const data: Blob = new Blob([buffer], { type: 'application/blob' });
      const pdfUrl = window.URL.createObjectURL(data);
      const pdfLink = document.createElement('a');
      pdfLink.href = pdfUrl;
      pdfLink.download = fileName + '.pdf';
      pdfLink.click();
    }));
  }

  getOptions(options?: any): HttpOptions {
    return { ...this.httpOptions, ...options } as HttpOptions;
  }
}
