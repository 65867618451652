import {
  MaintenanceTimerDetailDto,
  MaintenanceTimerFilterDto,
  MaintenanceTimerService,
} from '@artemis-software/wr-api';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {
  ChangeMaintenanceTimerFilter,
  CountMaintenanceTimers,
  FilterMaintenanceTimers,
  LoadAllMaintenanceTimers,
} from '@/store/maintenanceTimer/maintenanceTimer.action';

type MaintenanceTimerStateModel = {
  maintenanceTimers: MaintenanceTimerDetailDto[],
  filteredMaintenanceTimers: MaintenanceTimerDetailDto[],
  count: number,
  loading: boolean,
  filter: MaintenanceTimerFilterDto,
}

@State<MaintenanceTimerStateModel>({
  name: 'maintenanceTimers',
  defaults: {
    maintenanceTimers: [],
    filteredMaintenanceTimers: [],
    count: 0,
    loading: false,
    filter: {} as MaintenanceTimerFilterDto,
  },
})
@Injectable()
export class MaintenanceTimerState implements NgxsOnInit {
  constructor(
    private readonly store: Store,
    private readonly maintenanceTimerService: MaintenanceTimerService,
    private readonly authService: AuthService,
  ) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllMaintenanceTimers());
    });
  }

  @Selector()
  static maintenanceTimers(state: MaintenanceTimerStateModel): MaintenanceTimerDetailDto[] {
    return state.maintenanceTimers;
  }

  @Selector()
  static filteredMaintenanceTimers(state: MaintenanceTimerStateModel): MaintenanceTimerDetailDto[] {
    return state.filteredMaintenanceTimers;
  }

  @Selector()
  static maintenanceTimersLoading(state: MaintenanceTimerStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: MaintenanceTimerStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: MaintenanceTimerStateModel): MaintenanceTimerFilterDto {
    return state.filter;
  }

  @Action(LoadAllMaintenanceTimers)
  loadAllMaintenanceTimers(ctx: StateContext<MaintenanceTimerStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.maintenanceTimerService.findAllDetailDtos({}).subscribe((maintenanceTimers: MaintenanceTimerDetailDto[]) => {
      ctx.patchState({
        maintenanceTimers,
        loading: false,
      });
    });
  }

  @Action(FilterMaintenanceTimers)
  filterMaintenanceTimers(ctx: StateContext<MaintenanceTimerStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.maintenanceTimerService.findAllDetailDtos(ctx.getState().filter).subscribe((maintenanceTimers: MaintenanceTimerDetailDto[]) => {
      ctx.patchState({
        filteredMaintenanceTimers: maintenanceTimers,
        loading: false,
      });
    });
  }

  @Action(CountMaintenanceTimers)
  countMaintenanceTimers(ctx: StateContext<MaintenanceTimerStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.maintenanceTimerService.getCount(ctx.getState().filter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeMaintenanceTimerFilter)
  changeMaintenanceTimerFilter(ctx: StateContext<MaintenanceTimerStateModel>, action: ChangeMaintenanceTimerFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountMaintenanceTimers());
    this.store.dispatch(new FilterMaintenanceTimers());
  }
}
