import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  ChangeOrganisationFilter,
  CountOrganisations,
  FilterOrganisations,
  LoadAllOrganisations,
} from './organisation.action';
import {
  OrganisationDetailDto,
  OrganisationFilterDto,
  OrganisationItemDto,
  OrganisationService,
} from '@artemis-software/wr-api';
import { AuthService } from '@services/auth.service';

type OrganisationStateModel = {
  organisations: OrganisationDetailDto[],
  filteredOrganisations: OrganisationDetailDto[],
  count: number,
  loading: boolean,
  filter: OrganisationFilterDto,
}

@State<OrganisationStateModel>({
  name: 'organisations',
  defaults: {
    organisations: [],
    filteredOrganisations: [],
    count: 0,
    loading: false,
    filter: {} as OrganisationFilterDto,
  },
})

@Injectable()
export class OrganisationState implements NgxsOnInit {

  constructor(private readonly store: Store,
    private readonly organisationService: OrganisationService,
    private readonly authService: AuthService) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllOrganisations());
    });
  }

  @Selector()
  static organisations(state: OrganisationStateModel): OrganisationItemDto[] {
    return state.organisations;
  }

  @Selector()
  static filteredOrganisations(state: OrganisationStateModel): OrganisationItemDto[] {
    return state.filteredOrganisations;
  }

  @Selector()
  static organisationsLoading(state: OrganisationStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: OrganisationStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: OrganisationStateModel): OrganisationFilterDto {
    return state.filter;
  }

  @Action(LoadAllOrganisations)
  loadAllOrganisations(ctx: StateContext<OrganisationStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.organisationService.findAllDetailDtos({}).subscribe((organisations: OrganisationDetailDto[]) => {
      ctx.patchState({
        organisations,
        loading: false,
      });
    });
  }

  @Action(FilterOrganisations)
  filterOrganisations(ctx: StateContext<OrganisationStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.organisationService.findAllDetailDtos(ctx.getState().filter).subscribe((organisations: OrganisationDetailDto[]) => {
      ctx.patchState({
        filteredOrganisations: organisations,
        loading: false,
      });
    });
  }

  @Action(CountOrganisations)
  countOrganisations(ctx: StateContext<OrganisationStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.organisationService.getCount(ctx.getState().filter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeOrganisationFilter)
  changeOrganisationFilter(ctx: StateContext<OrganisationStateModel>, action: ChangeOrganisationFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountOrganisations());
    this.store.dispatch(new FilterOrganisations());
  }
}
