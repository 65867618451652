import { Component, forwardRef, Input } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { IdReferenceDto, UserDetailDto, UserItemDto } from '@artemis-software/wr-api';
import { Select } from '@ngxs/store';
import { UserState } from '@/store/user/user.state';
import { Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectComponent),
      multi: true,
    },
  ],
})
export class UserSelectComponent extends AbstractFormControl<IdReferenceDto> {

  readonly notAssignedOption: IdReferenceDto = {
    value: undefined,
  };
  @Select(UserState.users)
  users$!: Observable<UserItemDto[]>;

  @Input()
  showEmptyOption = false;
  @Input()
  showNotAssignedOption = true;

  placeholder = this.showEmptyOption ? '' : 'Alle';

  readonly compareWith = (a?: IdReferenceDto, b?: IdReferenceDto) => {
    if (a && b) {
      return a.value === b.value;
    }
    return a == b;
  };
}
