<div class="control-buttons">
  <button *ngIf="edit.observed" nbButton ghost status="primary" (click)="edit.emit()">
    <nb-icon pack="eva" icon="edit-outline"></nb-icon>
  </button>
  <button *ngIf="delete.observed" nbButton ghost status="primary" (click)="delete.emit()">
    <nb-icon pack="eva" icon="trash-2-outline"></nb-icon>
  </button>
  <button *ngIf="close.observed" nbButton ghost status="basic" (click)="close.emit()">
    <nb-icon pack="eva" icon="close-outline"></nb-icon>
  </button>
</div>
