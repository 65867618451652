<nb-tag-list *ngIf="tags.length" [class.has-tags]="tags.length > 0">
  <nb-tag *ngFor="let tag of tags"
          [text]="tag.name"
          [style.background-color]="tag.color"
          [style.color]="getColor(tag.color)">
  </nb-tag>
</nb-tag-list>

<span class="text-hint" *ngIf="!tags.length">
  Keine Tags ausgewählt
</span>
