import { Component, HostListener, Input } from '@angular/core';
import { AttachmentDetailDto } from '@artemis-software/wr-api';
import { NbComponentSize } from '@nebular/theme';

@Component({
  selector: 'wr-attachments-carousel',
  templateUrl: './attachments-carousel.component.html',
  styleUrls: ['./attachments-carousel.component.scss'],
})
export class AttachmentsCarouselComponent {
  @Input()
  attachments: AttachmentDetailDto[] = [];
  @Input()
  disabledPreview = false;
  @Input()
  size: NbComponentSize = 'medium';

  selectedIndex?: number;

  get selected() {
    return this.selectedIndex !== undefined ? this.attachments[this.selectedIndex] : undefined;
  }

  outsideClick() {
    this.selectedIndex = undefined;
  }

  insideClick(event: Event) {
    event.stopPropagation();
  }

  next() {
    if (this.selectedIndex !== undefined) {
      this.selectedIndex = (this.selectedIndex + 1) % this.attachments.length;
    }
  }

  previous() {
    if (this.selectedIndex !== undefined) {
      this.selectedIndex = (this.selectedIndex - 1 + this.attachments.length) % this.attachments.length;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.previous();
    } else if (event.key === 'ArrowRight') {
      this.next();
    }
  }

  select(index: number): void {
    if (!this.disabledPreview) {
      this.selectedIndex = index;
    }
  }

}
