<wr-chart
  *ngIf="repairTaskData()"
  [labels]="repairTaskLabels()"
  [showLegend]="false">
  <ng-container *ngFor="let dataset of repairTaskData(); let i = index">
    <wr-dataset
      type="bar"
      [data]="dataset.data"
      [label]="dataset.label"
      [backgroundColor]="dataset.backgroundColor"
      indexAxis="y"
      stack="repairTasks">
    </wr-dataset>
  </ng-container>
</wr-chart>
