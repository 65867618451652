import { Component, computed, inject, signal } from '@angular/core';
import { MaintenanceTimerFilterDto, MaintenanceTimerService } from '@artemis-software/wr-api';
import { select } from '@/utils/signals/select';
import { BuildingState } from '@/store/building/building.state';
import { loader } from '@/utils/signals/loader';
import { asyncComputed } from '@/utils/signals/asyncComputed';

@Component({
  selector: 'wr-building-dashboard-maintenance',
  templateUrl: './building-dashboard-maintenance.component.html',
  styleUrls: ['./building-dashboard-maintenance.component.scss'],
})
export class BuildingDashboardMaintenanceComponent {
  readonly maintenanceTimerService = inject(MaintenanceTimerService);

  readonly building = select(BuildingState.selectedDashboardBuilding);

  readonly loading = loader();

  readonly page = signal(0);
  readonly pageSize = signal(100);

  readonly filter = computed(() => {
    const building = this.building();
    if (!building) {
      return undefined;
    }
    return {
      buildingId: building.id,
      page: this.page(),
      size: this.pageSize(),
    } as MaintenanceTimerFilterDto;
  });

  readonly maintenanceTimers = asyncComputed(() => {
    const filter = this.filter();
    return filter ? this.maintenanceTimerService.findAll(filter) : [];
  });
}
