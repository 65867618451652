<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="users"></nb-icon>
          Genossenschaftsstatistik
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="cooperativeSelect">Genossenschaft</label>
                <wr-organisation-select id="cooperativeSelect" formControlName="cooperativeId"
                                        [showEmptyOption]="true"></wr-organisation-select>
              </div>
              <div class="filter-control-group">
                <label class="label" for="datePicker">Zeitraum</label>
                <wr-date-range-picker id="datePicker" formControlName="selectedRange"
                                      [showTodayButton]="true"></wr-date-range-picker>
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
</div>
<div class="statistics-container">
  <wr-cooperative-statistics-charts [filter]="filter()"></wr-cooperative-statistics-charts>
  <wr-cooperative-statistics-building-overview></wr-cooperative-statistics-building-overview>
  <wr-cooperative-statistics-repair-task-overview></wr-cooperative-statistics-repair-task-overview>
  <wr-cooperative-statistics-inspection-overview></wr-cooperative-statistics-inspection-overview>
</div>
