import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BuildingState } from '@/store/building/building.state';
import { Router } from '@angular/router';
import {
  BuildingItemDto
} from '@artemis-software/wr-api';
import { ChangeBuildingFilter } from '@/store/building/building.action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RowAction } from '@components/data-table/data-table.component';
import { defineStoreUsage } from '@/utils/storeUsage';
import { QrCodeService } from '@services/qr-code-service';

@Component({
  selector: 'wr-property-manager-building-overview-page',
  templateUrl: './property-manager-building-overview-page.component.html',
  styleUrls: ['./property-manager-building-overview-page.component.scss'],
})
export class PropertyManagerBuildingOverviewPageComponent {
  buildingStoreUsage = defineStoreUsage({
    filterAction: ChangeBuildingFilter,
    countSelector: BuildingState.count,
    loadingSelector: BuildingState.buildingsLoading,
  });

  @Select(BuildingState.filteredBuildings)
  buildings$!: Observable<BuildingItemDto[]>;
  @Select(BuildingState.count)
  count$!: Observable<number>;
  @Select(BuildingState.buildingsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  actions: RowAction[] = [
    {
      pack: 'fas',
      icon: 'boxes-stacked',
      toolTip: 'Gebäudeausstattung',
      status: 'info',
      click: (building: BuildingItemDto) => {
        this.navigateToEquipment(building.id);
      },
    },
    {
      pack: 'fas',
      icon: 'qrcode',
      toolTip: 'QR-Code',
      status: 'info',
      click: (building: BuildingItemDto) => {
        this.qrCodeService.generateBuildingQRCode(building);
      }
    }
  ];

  constructor(private readonly store: Store,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly qrCodeService: QrCodeService
  ) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('propertyManagerBuildingFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
      this.addSpecialFields(filterValue.buildingClass, filterValue.buildingType);
    } else {
      this.initFilterForm('');
    }
    this.triggerFilter();
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      buildingClass: this.formBuilder.control(''),
      buildingType: this.formBuilder.control(''),
      organisationId: this.formBuilder.control(undefined),
      isPropertyManager: this.formBuilder.control(true)
    });
  }

  addSpecialFields(buildingClass: string, buildingType: string): void {
    this.form.patchValue({
      'buildingClass': buildingClass,
      'buildingType': buildingType,
    });
  }

  navigateToBuilding(event: BuildingItemDto): void {
    this.router.navigate(['building', event.id]);
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeBuildingFilter(this.form.value));
    localStorage.setItem('propertyManagerBuildingFilter', JSON.stringify(this.form.value));
  }

  navigateToEquipment(id: string): void {
    this.router.navigate(['/buildingEquipments'], { queryParams: { buildingId: id } });
  }
}
