import { Component, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { CustomFormComponent } from '@shared/custom-form/custom-form/custom-form.component';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  shareReplay,
} from 'rxjs';
import {
  BuildingEquipmentMergeDto,
  BuildingEquipmentService,
  BuildingItemDto,
  BuildingSectionItemDto,
  CustomFieldCollectionDetailDto,
  CustomFieldCollectionService,
  EquipmentItemDto,
  EquipmentService,
} from '@artemis-software/wr-api';
import { CustomFormValueDto } from '@artemis-software/wr-api/model/customFormValueDto';
import { BuildingEquipmentDetailDto } from '@artemis-software/wr-api/model/buildingEquipmentDetailDto';
import { createForm } from '@sonofabit/ng-core';
import { Validators } from '@angular/forms';
import { attachmentsFromDetails, attachmentToMerge } from '@shared/form-controls/attachment-upload/attachment-utils';
import { FilterBuildingSections } from '@/store/buildingSection/buildingSection.action';
import { tryWithLoading } from '@/utils/async-utils';
import { LoadAllBuildingEquipments } from '@/store/buildingEquipment/buildingEquipment.action';
import { Select, Store } from '@ngxs/store';
import { BuildingState } from '@/store/building/building.state';
import { BuildingDetailDto } from '@artemis-software/wr-api/model/buildingDetailDto';
import { BuildingSectionState } from '@/store/buildingSection/buildingSection.state';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { PendingChanges } from '@guards/pending-changes.guard';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { isAdmin } from '@/utils/admin-utils';

export type BuildingAndSection = {
  building: BuildingItemDto;
  section: BuildingSectionItemDto;
}

export type BuildingEquipmentOrBuilding = {
  buildingEquipment?: BuildingEquipmentDetailDto;
  building?: BuildingAndSection;
}

@Component({
  selector: 'wr-building-equipment-detail-form[buildingEquipment]',
  templateUrl: './building-equipment-detail-form.component.html',
  styleUrls: ['./building-equipment-detail-form.component.scss'],
})
export class BuildingEquipmentDetailFormComponent implements PendingChanges {
  isAdmin = isAdmin();

  @Select(BuildingState.buildings)
  buildings$!: Observable<BuildingDetailDto[]>;
  @Select(BuildingSectionState.filteredBuildingSections)
  buildingSections$!: Observable<BuildingSectionDetailDto[]>;

  @Input()
  set buildingEquipment(value: BuildingEquipmentDetailDto | null) {
    this.init(value ? { buildingEquipment: value } : null);
  }

  @Input()
  set buildingAndSection(value: BuildingAndSection | null) {
    this.init(value ? { building: value } : null);
  }

  @Input() showCard = true;
  @Input() showTitle = true;
  @Input() disableEdit = false;

  @Output()
  cancel = new EventEmitter();
  @Output()
  submitSuccess = new EventEmitter<BuildingEquipmentDetailDto>();
  @Output()
  close = new EventEmitter();
  @Output()
  deleteSuccess = new EventEmitter<string>();

  @ViewChildren(CustomFormComponent)
  customForms!: CustomFormComponent[];

  form?: ReturnType<typeof this.initForm>;

  isNew = false;
  firstSubmit = false;
  loading$ = new BehaviorSubject(false);

  customFieldCollections$ = new BehaviorSubject<CustomFieldCollectionDetailDto[]>([]);
  customFieldCollectionValues$ = new BehaviorSubject<CustomFormValueDto | undefined>(undefined);
  customFieldCollectionsAndValues$ = combineLatest([this.customFieldCollections$, this.customFieldCollectionValues$])
    .pipe(
      debounceTime(100),
      map(([collections, values]) =>
        collections.map(c => {
          return {
            collection: c,
            values: values?.collections?.find(v => v.collection?.id === c.id),
          };
        }),
      ));

  get allForms() {
    return [
      this.form,
      ...this.customForms.map(c => c.customForm?.form).filter(f => !!f),
    ];
  }

  constructor(
    private readonly store: Store,
    private readonly equipmentService: EquipmentService,
    private readonly collectionService: CustomFieldCollectionService,
    private readonly toastrService: NbToastrService,
    private readonly buildingEquipmentService: BuildingEquipmentService,
    private readonly dialogService: NbDialogService,
  ) {
  }

  private init(dto: BuildingEquipmentOrBuilding | null) {
    this.form = this.initForm(dto);
    this.isNew = !dto?.buildingEquipment?.id;
    if (!this.isNew) {
      this.form.disable();
      setTimeout(() => {
        this.disable();
      }, 100);
    }
  }

  initForm(dto: BuildingEquipmentOrBuilding | null) {
    const form = createForm(({ field, mapToId }) => {
      return {
        id: field({ value: dto?.buildingEquipment?.id }),
        building: field({
          value: dto?.buildingEquipment?.building || dto?.building?.building,
          validators: [Validators.required],
        }),
        buildingSectionId: field({
          value: dto?.buildingEquipment?.buildingSection?.id || dto?.building?.section.id,
          validators: [Validators.required],
        }),
        equipment: field({
          value: dto?.buildingEquipment?.equipment,
          validators: [Validators.required],
          alias: 'equipmentId',
          toDto: entity => mapToId(entity),
        }),
        quantity: field({ value: dto?.buildingEquipment?.quantity, validators: [Validators.required] }),
        attachments: field({
          value: attachmentsFromDetails(dto?.buildingEquipment?.attachments ?? []),
          toDto: attachments => attachments?.map(attachmentToMerge),
        }),
      };
    });

    const buildingControl = form.controls.building;

    buildingControl.valueChanges.subscribe((building: BuildingItemDto | null | undefined) => {
      const currentSection = form.value.buildingSectionId;
      if (!building?.sections?.find(s => s.id === currentSection)) {
        form.controls.buildingSectionId.setValue(undefined);
      }
      this.store.dispatch(new FilterBuildingSections(building?.id));
    });

    if (dto?.buildingEquipment?.building || dto?.building) {
      buildingControl.setValue(dto.buildingEquipment?.building || dto.building?.building);
    }

    if (dto?.buildingEquipment?.id) {
      form.controls.equipment.disable();
    }

    form.controls.equipment.valueChanges.subscribe(async (equipment: EquipmentItemDto | null | undefined) => {
      if (equipment) {
        const equipmentDetail = await firstValueFrom(this.equipmentService.findById(equipment.id));
        const collections = equipmentDetail.customFieldCollections
          .sort(c => c.ranking)
          .map(c => c.collection);
        const observables = collections.map(c => this.collectionService.findById(c.id).pipe(shareReplay(1)));
        const collectionDetails = observables.length ? await firstValueFrom(forkJoin(observables)) : [];
        this.customFieldCollections$.next(collectionDetails);
      }
    });

    if (dto?.buildingEquipment?.customFormValues) {
      this.customFieldCollectionValues$.next(dto.buildingEquipment.customFormValues);
    }

    return form;
  }


  enable(): void {
    this.allForms.forEach(f => f?.enable());
  }

  disable(): void {
    this.allForms.forEach(f => f?.disable());
  }


  async submit(): Promise<void> {
    this.firstSubmit = true;

    this.allForms.forEach(f => f?.markAllAsTouched());

    if (this.allForms.some(f => f?.invalid)) {
      this.toastrService.danger('Gebäude kann nicht gespeichert werden, es fehlen noch Daten.', 'Gebäudeausstattung');
      return;
    }

    await tryWithLoading(this.loading$, async () => {
      const mergeDto: BuildingEquipmentMergeDto = {
        ...await this.form!.getMappedValue() as BuildingEquipmentMergeDto,
        customForm: {
          collections: this.customForms.map(form => form.getMergeDto()),
        },
      };
      const buildingEquipment = await firstValueFrom(this.buildingEquipmentService.merge(mergeDto));
      this.customForms.forEach(cf => cf.markAsPristine());
      this.form = this.initForm({ buildingEquipment });
      this.disable();
      this.store.dispatch(new LoadAllBuildingEquipments());
      this.toastrService.success('Gebäudeausstattung wurde erfolgreich gespeichert', 'Gebäude');
      this.submitSuccess.emit(buildingEquipment);
    });
  }


  async onDelete(): Promise<void> {
    const id = this.form!.controls.id.value;
    if (await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Gebäudeausstattung löschen',
        message: 'Bist du sicher, dass du diese Gebäudeausstattung löschen willst?',
      },
    }).onClose)) {
      await tryWithLoading(this.loading$, async () => {
        await firstValueFrom(this.buildingEquipmentService.deleteById(id!));
        this.store.dispatch(new LoadAllBuildingEquipments());
        this.toastrService.success('Gebäudeausstattung wurde erfolgreich gelöscht', 'Gebäudeausstattung');
        this.deleteSuccess.emit(id!);
      });
    }
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return (this.form?.enabled ?? false) && this.allForms.some(f => f?.dirty);
  }
}
