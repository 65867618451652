import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildingEquipmentService } from '@artemis-software/wr-api';
import { PendingChanges } from '@guards/pending-changes.guard';
import {
  BuildingEquipmentDetailFormComponent,
} from './building-equipment-detail-form/building-equipment-detail-form.component';
import { BuildingEquipmentDetailDto } from '@artemis-software/wr-api/model/buildingEquipmentDetailDto';


@Component({
  selector: 'wr-building-equipment-detail-page',
  templateUrl: './building-equipment-detail-page.component.html',
  styleUrls: ['./building-equipment-detail-page.component.scss'],
})
export class BuildingEquipmentDetailPageComponent implements PendingChanges {
  @ViewChild(BuildingEquipmentDetailFormComponent)
  buildingEquipmentDetailFormComponent?: BuildingEquipmentDetailFormComponent;

  buildingEquipment: BuildingEquipmentDetailDto | null | undefined = undefined;

  readonly buildingEquipmentReady = () => this.buildingEquipment !== undefined;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router,
    private readonly buildingEquipmentService: BuildingEquipmentService,
  ) {
    route.paramMap.subscribe(async (params) => {
      const id = params.get('id');
      if (id) {
        if (id === 'new') {
          this.buildingEquipment = null;
        } else {
          this.buildingEquipmentService.findById(id).subscribe((buildingEquipmentDetailDto) => {
            this.buildingEquipment = buildingEquipmentDetailDto;
          });
        }
      } else {
        await this.router.navigate(['buildingEquipments']);
      }
    });
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.buildingEquipmentDetailFormComponent?.hasPendingChanges() ?? false;
  }

  async cancel(): Promise<void> {
    await this.router.navigate(['buildingEquipments']);
    const id = this.buildingEquipmentDetailFormComponent?.form?.controls.id.value;
    if (id) {
      await this.router.navigate(['buildingEquipment', id]);
    }
  }

  async submit(buildingEquipment: BuildingEquipmentDetailDto): Promise<void> {
    await this.router.navigate(['buildingEquipment', buildingEquipment.id]);
  }

  async navigateBack(): Promise<void> {
    window.history.back();
  }
}
