<nb-card [nbSpinner]="(loading$|async) === true">
  <form [formGroup]="form">
    <table>
      <thead>
      <tr>
        <th *ngFor="let c of columns">
          <wr-order-toggle-button [sortable]="c.sortable" [title]="c.display" [key]="c.key"
                                  formControlName="sort"></wr-order-toggle-button>
        </th>
      </tr>
      </thead>
      <tbody *ngIf="data$|async as data">
      <tr *ngFor="let item of data" (click)="lineClick.emit(item)">
        <td *ngFor="let column of columns">
          <ng-container *ngTemplateOutlet="column.template; context: { $implicit: item }"></ng-container>
        </td>
        <td class="action-column">
          <button *ngFor="let action of actions" nbButton [status]="action.status"
                  [nbTooltip]="action.toolTip" [nbTooltipStatus]="action.status"
                  (click)="onActionClicked($event, action, item)" class="action">
            <nb-icon [pack]=action.pack [icon]="action.icon"></nb-icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="pagination">
      <wr-page-size-selector formControlName="size"></wr-page-size-selector>
      <wr-pagination-button-group *ngIf="count$ | async as count" [entityCount]="count" [size]="size" formControlName="page"></wr-pagination-button-group>
    </div>
  </form>
</nb-card>
