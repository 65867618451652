import { Component, forwardRef } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Time } from '@angular/common';

export class TimeUtils {
  static fromString(value?: string): Time {
    if (!value) {
      return { hours: 0, minutes: 0 };
    }
    const parts = value.split(':');
    return { hours: parseInt(parts[0]), minutes: parseInt(parts[1]) };
  }

  static fromDateString(value?: string): Time {
    if (!value) {
      return { hours: 0, minutes: 0 };
    }
    return this.fromDate(new Date(value));
  }

  static fromDate(value?: Date): Time {
    if (!value) {
      return { hours: 0, minutes: 0 };
    }
    const localDate = this.getLocalizedDate(value)!;
    return { hours: localDate.getHours(), minutes: localDate.getMinutes() };
  }

  static getLocalizedDate(value?: Date): Date | undefined {
    return value ? new Date(value.getTime() - value.getTimezoneOffset() * 60000) : undefined;
  }

  static normalizeDateString(value?: string): Date | undefined {
    const date = value ? this.parseDateOrUndefined(value) : undefined;
    return this.getLocalizedDate(date);
  }

  static parseDateOrUndefined(value: string): Date | undefined {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return undefined;
    }
    return date;
  }

  static toString(time: Time): string {
    return `${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}`;
  }

  static atTime(date: Date | null | undefined, time: Time | null | undefined): Date | undefined {
    if (!date || !time) {
      return undefined;
    }
    const localDate = this.getLocalizedDate(date)!;
    localDate.setHours(time.hours);
    localDate.setMinutes(time.minutes);

    return new Date(localDate.getTime() + date.getTimezoneOffset() * 60000);
  }
}

@Component({
  selector: 'wr-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
})
export class TimePickerComponent extends AbstractFormControl<Time> {
  timeToString(value: Time | string | undefined): string {
    if (typeof value === 'string') {
      return value;
    }
    if (value) {
      return TimeUtils.toString(value);
    }
    return '';
  }

  stringToTime(value: string | undefined): Time | undefined {
    return value ? TimeUtils.fromString(value) : undefined;
  }
}
