/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coordinates } from './coordinates';


export interface Address { 
    id?: string;
    created: string;
    street: string;
    number: string;
    zipCode: string;
    city: string;
    state: string;
    country: Address.CountryEnum;
    coordinates?: Coordinates;
}
export namespace Address {
    export type CountryEnum = 'UNDEFINED' | 'AC' | 'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AN' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BU' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CP' | 'CR' | 'CS' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DG' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EA' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'EU' | 'EZ' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'FX' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'IC' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NT' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SF' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SU' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TA' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TP' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UK' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'XI' | 'XU' | 'XK' | 'YE' | 'YT' | 'YU' | 'ZA' | 'ZM' | 'ZR' | 'ZW';
    export const CountryEnum = {
        Undefined: 'UNDEFINED' as CountryEnum,
        Ac: 'AC' as CountryEnum,
        Ad: 'AD' as CountryEnum,
        Ae: 'AE' as CountryEnum,
        Af: 'AF' as CountryEnum,
        Ag: 'AG' as CountryEnum,
        Ai: 'AI' as CountryEnum,
        Al: 'AL' as CountryEnum,
        Am: 'AM' as CountryEnum,
        An: 'AN' as CountryEnum,
        Ao: 'AO' as CountryEnum,
        Aq: 'AQ' as CountryEnum,
        Ar: 'AR' as CountryEnum,
        As: 'AS' as CountryEnum,
        At: 'AT' as CountryEnum,
        Au: 'AU' as CountryEnum,
        Aw: 'AW' as CountryEnum,
        Ax: 'AX' as CountryEnum,
        Az: 'AZ' as CountryEnum,
        Ba: 'BA' as CountryEnum,
        Bb: 'BB' as CountryEnum,
        Bd: 'BD' as CountryEnum,
        Be: 'BE' as CountryEnum,
        Bf: 'BF' as CountryEnum,
        Bg: 'BG' as CountryEnum,
        Bh: 'BH' as CountryEnum,
        Bi: 'BI' as CountryEnum,
        Bj: 'BJ' as CountryEnum,
        Bl: 'BL' as CountryEnum,
        Bm: 'BM' as CountryEnum,
        Bn: 'BN' as CountryEnum,
        Bo: 'BO' as CountryEnum,
        Bq: 'BQ' as CountryEnum,
        Br: 'BR' as CountryEnum,
        Bs: 'BS' as CountryEnum,
        Bt: 'BT' as CountryEnum,
        Bu: 'BU' as CountryEnum,
        Bv: 'BV' as CountryEnum,
        Bw: 'BW' as CountryEnum,
        By: 'BY' as CountryEnum,
        Bz: 'BZ' as CountryEnum,
        Ca: 'CA' as CountryEnum,
        Cc: 'CC' as CountryEnum,
        Cd: 'CD' as CountryEnum,
        Cf: 'CF' as CountryEnum,
        Cg: 'CG' as CountryEnum,
        Ch: 'CH' as CountryEnum,
        Ci: 'CI' as CountryEnum,
        Ck: 'CK' as CountryEnum,
        Cl: 'CL' as CountryEnum,
        Cm: 'CM' as CountryEnum,
        Cn: 'CN' as CountryEnum,
        Co: 'CO' as CountryEnum,
        Cp: 'CP' as CountryEnum,
        Cr: 'CR' as CountryEnum,
        Cs: 'CS' as CountryEnum,
        Cu: 'CU' as CountryEnum,
        Cv: 'CV' as CountryEnum,
        Cw: 'CW' as CountryEnum,
        Cx: 'CX' as CountryEnum,
        Cy: 'CY' as CountryEnum,
        Cz: 'CZ' as CountryEnum,
        De: 'DE' as CountryEnum,
        Dg: 'DG' as CountryEnum,
        Dj: 'DJ' as CountryEnum,
        Dk: 'DK' as CountryEnum,
        Dm: 'DM' as CountryEnum,
        Do: 'DO' as CountryEnum,
        Dz: 'DZ' as CountryEnum,
        Ea: 'EA' as CountryEnum,
        Ec: 'EC' as CountryEnum,
        Ee: 'EE' as CountryEnum,
        Eg: 'EG' as CountryEnum,
        Eh: 'EH' as CountryEnum,
        Er: 'ER' as CountryEnum,
        Es: 'ES' as CountryEnum,
        Et: 'ET' as CountryEnum,
        Eu: 'EU' as CountryEnum,
        Ez: 'EZ' as CountryEnum,
        Fi: 'FI' as CountryEnum,
        Fj: 'FJ' as CountryEnum,
        Fk: 'FK' as CountryEnum,
        Fm: 'FM' as CountryEnum,
        Fo: 'FO' as CountryEnum,
        Fr: 'FR' as CountryEnum,
        Fx: 'FX' as CountryEnum,
        Ga: 'GA' as CountryEnum,
        Gb: 'GB' as CountryEnum,
        Gd: 'GD' as CountryEnum,
        Ge: 'GE' as CountryEnum,
        Gf: 'GF' as CountryEnum,
        Gg: 'GG' as CountryEnum,
        Gh: 'GH' as CountryEnum,
        Gi: 'GI' as CountryEnum,
        Gl: 'GL' as CountryEnum,
        Gm: 'GM' as CountryEnum,
        Gn: 'GN' as CountryEnum,
        Gp: 'GP' as CountryEnum,
        Gq: 'GQ' as CountryEnum,
        Gr: 'GR' as CountryEnum,
        Gs: 'GS' as CountryEnum,
        Gt: 'GT' as CountryEnum,
        Gu: 'GU' as CountryEnum,
        Gw: 'GW' as CountryEnum,
        Gy: 'GY' as CountryEnum,
        Hk: 'HK' as CountryEnum,
        Hm: 'HM' as CountryEnum,
        Hn: 'HN' as CountryEnum,
        Hr: 'HR' as CountryEnum,
        Ht: 'HT' as CountryEnum,
        Hu: 'HU' as CountryEnum,
        Ic: 'IC' as CountryEnum,
        Id: 'ID' as CountryEnum,
        Ie: 'IE' as CountryEnum,
        Il: 'IL' as CountryEnum,
        Im: 'IM' as CountryEnum,
        In: 'IN' as CountryEnum,
        Io: 'IO' as CountryEnum,
        Iq: 'IQ' as CountryEnum,
        Ir: 'IR' as CountryEnum,
        Is: 'IS' as CountryEnum,
        It: 'IT' as CountryEnum,
        Je: 'JE' as CountryEnum,
        Jm: 'JM' as CountryEnum,
        Jo: 'JO' as CountryEnum,
        Jp: 'JP' as CountryEnum,
        Ke: 'KE' as CountryEnum,
        Kg: 'KG' as CountryEnum,
        Kh: 'KH' as CountryEnum,
        Ki: 'KI' as CountryEnum,
        Km: 'KM' as CountryEnum,
        Kn: 'KN' as CountryEnum,
        Kp: 'KP' as CountryEnum,
        Kr: 'KR' as CountryEnum,
        Kw: 'KW' as CountryEnum,
        Ky: 'KY' as CountryEnum,
        Kz: 'KZ' as CountryEnum,
        La: 'LA' as CountryEnum,
        Lb: 'LB' as CountryEnum,
        Lc: 'LC' as CountryEnum,
        Li: 'LI' as CountryEnum,
        Lk: 'LK' as CountryEnum,
        Lr: 'LR' as CountryEnum,
        Ls: 'LS' as CountryEnum,
        Lt: 'LT' as CountryEnum,
        Lu: 'LU' as CountryEnum,
        Lv: 'LV' as CountryEnum,
        Ly: 'LY' as CountryEnum,
        Ma: 'MA' as CountryEnum,
        Mc: 'MC' as CountryEnum,
        Md: 'MD' as CountryEnum,
        Me: 'ME' as CountryEnum,
        Mf: 'MF' as CountryEnum,
        Mg: 'MG' as CountryEnum,
        Mh: 'MH' as CountryEnum,
        Mk: 'MK' as CountryEnum,
        Ml: 'ML' as CountryEnum,
        Mm: 'MM' as CountryEnum,
        Mn: 'MN' as CountryEnum,
        Mo: 'MO' as CountryEnum,
        Mp: 'MP' as CountryEnum,
        Mq: 'MQ' as CountryEnum,
        Mr: 'MR' as CountryEnum,
        Ms: 'MS' as CountryEnum,
        Mt: 'MT' as CountryEnum,
        Mu: 'MU' as CountryEnum,
        Mv: 'MV' as CountryEnum,
        Mw: 'MW' as CountryEnum,
        Mx: 'MX' as CountryEnum,
        My: 'MY' as CountryEnum,
        Mz: 'MZ' as CountryEnum,
        Na: 'NA' as CountryEnum,
        Nc: 'NC' as CountryEnum,
        Ne: 'NE' as CountryEnum,
        Nf: 'NF' as CountryEnum,
        Ng: 'NG' as CountryEnum,
        Ni: 'NI' as CountryEnum,
        Nl: 'NL' as CountryEnum,
        No: 'NO' as CountryEnum,
        Np: 'NP' as CountryEnum,
        Nr: 'NR' as CountryEnum,
        Nt: 'NT' as CountryEnum,
        Nu: 'NU' as CountryEnum,
        Nz: 'NZ' as CountryEnum,
        Om: 'OM' as CountryEnum,
        Pa: 'PA' as CountryEnum,
        Pe: 'PE' as CountryEnum,
        Pf: 'PF' as CountryEnum,
        Pg: 'PG' as CountryEnum,
        Ph: 'PH' as CountryEnum,
        Pk: 'PK' as CountryEnum,
        Pl: 'PL' as CountryEnum,
        Pm: 'PM' as CountryEnum,
        Pn: 'PN' as CountryEnum,
        Pr: 'PR' as CountryEnum,
        Ps: 'PS' as CountryEnum,
        Pt: 'PT' as CountryEnum,
        Pw: 'PW' as CountryEnum,
        Py: 'PY' as CountryEnum,
        Qa: 'QA' as CountryEnum,
        Re: 'RE' as CountryEnum,
        Ro: 'RO' as CountryEnum,
        Rs: 'RS' as CountryEnum,
        Ru: 'RU' as CountryEnum,
        Rw: 'RW' as CountryEnum,
        Sa: 'SA' as CountryEnum,
        Sb: 'SB' as CountryEnum,
        Sc: 'SC' as CountryEnum,
        Sd: 'SD' as CountryEnum,
        Se: 'SE' as CountryEnum,
        Sf: 'SF' as CountryEnum,
        Sg: 'SG' as CountryEnum,
        Sh: 'SH' as CountryEnum,
        Si: 'SI' as CountryEnum,
        Sj: 'SJ' as CountryEnum,
        Sk: 'SK' as CountryEnum,
        Sl: 'SL' as CountryEnum,
        Sm: 'SM' as CountryEnum,
        Sn: 'SN' as CountryEnum,
        So: 'SO' as CountryEnum,
        Sr: 'SR' as CountryEnum,
        Ss: 'SS' as CountryEnum,
        St: 'ST' as CountryEnum,
        Su: 'SU' as CountryEnum,
        Sv: 'SV' as CountryEnum,
        Sx: 'SX' as CountryEnum,
        Sy: 'SY' as CountryEnum,
        Sz: 'SZ' as CountryEnum,
        Ta: 'TA' as CountryEnum,
        Tc: 'TC' as CountryEnum,
        Td: 'TD' as CountryEnum,
        Tf: 'TF' as CountryEnum,
        Tg: 'TG' as CountryEnum,
        Th: 'TH' as CountryEnum,
        Tj: 'TJ' as CountryEnum,
        Tk: 'TK' as CountryEnum,
        Tl: 'TL' as CountryEnum,
        Tm: 'TM' as CountryEnum,
        Tn: 'TN' as CountryEnum,
        To: 'TO' as CountryEnum,
        Tp: 'TP' as CountryEnum,
        Tr: 'TR' as CountryEnum,
        Tt: 'TT' as CountryEnum,
        Tv: 'TV' as CountryEnum,
        Tw: 'TW' as CountryEnum,
        Tz: 'TZ' as CountryEnum,
        Ua: 'UA' as CountryEnum,
        Ug: 'UG' as CountryEnum,
        Uk: 'UK' as CountryEnum,
        Um: 'UM' as CountryEnum,
        Us: 'US' as CountryEnum,
        Uy: 'UY' as CountryEnum,
        Uz: 'UZ' as CountryEnum,
        Va: 'VA' as CountryEnum,
        Vc: 'VC' as CountryEnum,
        Ve: 'VE' as CountryEnum,
        Vg: 'VG' as CountryEnum,
        Vi: 'VI' as CountryEnum,
        Vn: 'VN' as CountryEnum,
        Vu: 'VU' as CountryEnum,
        Wf: 'WF' as CountryEnum,
        Ws: 'WS' as CountryEnum,
        Xi: 'XI' as CountryEnum,
        Xu: 'XU' as CountryEnum,
        Xk: 'XK' as CountryEnum,
        Ye: 'YE' as CountryEnum,
        Yt: 'YT' as CountryEnum,
        Yu: 'YU' as CountryEnum,
        Za: 'ZA' as CountryEnum,
        Zm: 'ZM' as CountryEnum,
        Zr: 'ZR' as CountryEnum,
        Zw: 'ZW' as CountryEnum
    };
}


