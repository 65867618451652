import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InspectionDetailDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-status-text',
  templateUrl: './status-text.component.html',
  styleUrls: ['./status-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusTextComponent {
  readonly StatusEnum = InspectionDetailDto.StatusEnum;

  @Input()
  status?: InspectionDetailDto.StatusEnum;

}
