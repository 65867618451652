import { Component, Input, OnInit } from '@angular/core';
import { MaintenanceTimerCheckDateDetailDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-check-dates-dialog',
  templateUrl: './check-dates-dialog.component.html',
  styleUrls: ['./check-dates-dialog.component.scss']
})
export class CheckDatesDialogComponent implements OnInit {

  @Input()
  maintenanceTimerCheckDates: MaintenanceTimerCheckDateDetailDto[] = [];

  ngOnInit() {
    this.sortCheckDates();
  }

  private sortCheckDates() {
    this.maintenanceTimerCheckDates.sort((a, b) => {
      return new Date(a.checkDate).getTime() - new Date(b.checkDate).getTime();
    });
  }

}
