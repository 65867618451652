<div class="detail-container">
  <nb-card [nbSpinner]="!!(saving$|async)">
    <nb-card-header>
      <button nbButton ghost status="primary" (click)="navigateBack()">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>Prüfpunkt Gruppe</h5>
      <wr-edit-delete-button-group (edit)="onEdit()" (delete)="onDelete()"></wr-edit-delete-button-group>
    </nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="form">
        <form [formGroup]="form">
          <div class="sub-card-body">
            <input hidden formControlName="id">
            <div class="grid">
              <div class="col-6">
                <label class="label" for="number">Nummer</label>
                <input fullWidth id="number" type="number" min="0" step="1" nbInput formControlName="number">
                <div class="error" *ngIf="hasError('number')">Nummer ist erforderlich!</div>
              </div>
              <div class="col-6">
                <label class="label" for="name">Name</label>
                <input fullWidth id="name" type="text" nbInput formControlName="name">
                <div class="error" *ngIf="hasError('name')">Name ist erforderlich!</div>
              </div>
              <div class="col-12">
                <label class="label" for="description">Beschreibung</label>
                <textarea fullWidth id="description" type="text" nbInput formControlName="description"></textarea>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </nb-card-body>
    <nb-card-footer>
      <button size="medium" [disabled]="!isEditing" nbButton status="primary" (click)="submit()">Speichern</button>
      <button size="medium" [disabled]="!isEditing" nbButton status="basic" (click)="cancel()">Zurück</button>
    </nb-card-footer>
  </nb-card>
</div>
