import { TemplateFilterDto } from '@artemis-software/wr-api';

export class LoadAllTemplates {
  static readonly type = '[Template] Load all Template';
}

export class FilterTemplates {
  static readonly type = '[Template] Load filter templates';
}

export class CountTemplates {
  static readonly type = '[Template] Count templates';
}

export class ChangeTemplateFilter {
  static readonly type = '[Template] Change filter';

  constructor(public filter: TemplateFilterDto) {
  }
}
