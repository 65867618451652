import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFormComponent } from '@shared/custom-form/custom-form/custom-form.component';
import {
  CustomFormElementComponent,
} from '@shared/custom-form/custom-form/custom-field-form-element/custom-form-element.component';
import { HauFormsModule } from '@sonofabit/ng-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NebularModule } from '@shared/nebular/nebular.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import {
  GenericMultiControlComponent,
} from '@shared/custom-form/generic-multi-control/generic-multi-control.component';
import {
  GenericMultiControlTemplateDirective,
} from '@shared/custom-form/generic-multi-control/generic-multi-control-template.directive';

@NgModule({
  declarations: [
    CustomFormComponent,
    CustomFormElementComponent,
    GenericMultiControlComponent,
    GenericMultiControlTemplateDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HauFormsModule,
    ReactiveFormsModule,
    PipesModule,
    NebularModule,
  ],
  exports: [
    CustomFormComponent,
  ],
})
export class CustomFormModule {
}
