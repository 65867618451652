import { Component, computed, inject } from '@angular/core';
import { useLocalStorage } from '@/utils/signals/localStorage';
import { InspectionItemDto, InspectionService, SortDto } from '@artemis-software/wr-api';
import { loader } from '@/utils/signals/loader';
import { Tracked } from '@/utils/signals/tracked';
import { asyncComputed } from '@/utils/signals/asyncComputed';

@Component({
  selector: 'wr-user-dashboard-inspection-overview',
  templateUrl: './user-dashboard-inspection-overview.component.html',
  styleUrls: ['./user-dashboard-inspection-overview.component.scss'],
})
export class UserDashboardInspectionOverviewComponent {
  inspectionMode = useLocalStorage<'upcoming' | 'active'>('inspectionMode', 'active');

  inspectionService = inject(InspectionService);

  isLoading = loader();

  @Tracked() page = 0;
  @Tracked() pageSize = 10;
  @Tracked() sort: SortDto = { sort: 'fromTimestamp', order: 'DESC' };

  results = asyncComputed(() => {
    if (this.inspectionMode() === 'upcoming') {
      return this.inspectionService.getOwnUpcomingInspections({
        page: this.page,
        size: this.pageSize,
        sort: [`${this.sort.sort},${this.sort.order.toLowerCase()}`],
      });
    }
    return this.inspectionService.getOwnActiveInspections({
      page: this.page,
      size: this.pageSize,
      sort: [`${this.sort.sort},${this.sort.order.toLowerCase()}`],
    });
  }, undefined, this.isLoading);

  inspections = computed(() => this.results()?.content ?? []);

  inspectionCount = computed(() => this.results()?.numberOfElements ?? 0);

  readonly getInspectionLink = (inspection: InspectionItemDto) => (['inspection', inspection.id]);
}
