<div class="filter-container">
  <form *ngIf="form" [formGroup]="form">
    <div class="filter-item">
      <label class="label" for="organisationSelect">Genossenschaften</label>
      <wr-organisation-select id="organisationSelect"
                              formControlName="organisationId"
                              [filterIds]="myOrganisationIds()"
                              [showEmptyOption]="showAll()"></wr-organisation-select>
    </div>
  </form>
</div>
<div id="map"></div>
<div #hidden id="hidden"></div>
