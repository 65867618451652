<nb-form-field>
  <input nbInput
         fullWidth
         [nbDatepicker]="rangepicker"
         [ngModel]="value"
         (ngModelChange)="updateValue($event)"
         [placeholder]="placeholder">
  <nb-rangepicker #rangepicker></nb-rangepicker>

  <nb-icon *ngIf="showTodayButton"
           nbSuffix
           icon="calendar-outline"
           pack="eva"
           (click)="setRangeFilterToday()"
           nbTooltip="Heute"></nb-icon>

</nb-form-field>
