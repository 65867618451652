import { Component, forwardRef, Input } from '@angular/core';
import { IdReferenceDto, UserItemDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserState } from "@/store/user/user.state";

@Component({
  selector: 'wr-user-multi-select',
  templateUrl: './user-multi-select.component.html',
  styleUrls: ['./user-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserMultiSelectComponent),
      multi: true,
    },
  ],
})
export class UserMultiSelectComponent extends AbstractFormControl<IdReferenceDto[]> {
  readonly equalFn = (c1: IdReferenceDto, c2: IdReferenceDto) => c1.value === c2.value;

  @Select(UserState.users)
  users!: Observable<UserItemDto[]>;

  @Input()
  showNoneOption = false;

  onSelectionChange(selected: IdReferenceDto[]) {
    let allOptionSelected = selected.some(option => option.value === 'all');
    const otherOptionsSelected = selected.filter(option => option.value !== 'all');
    const currentlyAllSelected = this.value?.some(option => option.value === 'all');

    if (currentlyAllSelected && selected.length !== this.value?.length) {
      this.value = otherOptionsSelected;
      return;
    }

    if (allOptionSelected) {
      this.selectAllUsers();
      return;
    }
    this.value = selected;
  }

  selectAllUsers() {
    this.users.subscribe(users => {
      this.value = users.map(user => ({ value: user.id }));
      if (this.showNoneOption) {
        this.value = [...this.value, { value: 'all' }];
      }
    });
  }

  override set value(value: IdReferenceDto[] | undefined) {
    this._value = value;
    this.onChange?.(this._value?.filter(option => option.value !== 'all'));
    this.onTouch?.();
    this.value$.next(this._value);
  }

  override get value(): IdReferenceDto[] | undefined {
    return this._value;
  }
}
