<wr-base-dialog status="primary"
                [showCancelButton]="false"
                [showSubmitButton]="false"
                header="Neuer Wartungstimer">
  <div class="wrapper noselect">

    <ng-container *ngIf="!hasBuilding(); else buildingEquipmentSelection">
      <wr-building-select [ngModel]="undefined" (ngModelChange)="buildingSelected($event)"></wr-building-select>
    </ng-container>

    <ng-template #buildingEquipmentSelection>
      <div class="reset-building" (click)="clearSelection()">
        <nb-icon icon="chevron-left-outline" pack="eva"></nb-icon>
        <span>zurück</span>
      </div>
      <div class="building-equipments" *ngIf="buildingEquipments()?.length; else noBuildingEquipments">
        <div class="building-equipment"
             *ngFor="let be of buildingEquipments()"
             (click)="selectedBuildingEquipmentId.set(be.id)">
          {{ be.equipmentName }}
          <div class="selected" *ngIf="selectedBuildingEquipmentId() === be.id">
            <nb-icon icon="checkmark-outline" pack="eva"></nb-icon>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #noBuildingEquipments>
      <nb-alert>Keine TGAs für dieses Gebäude gefunden</nb-alert>
    </ng-template>

    <ng-container *ngIf="selectedBuildingEquipmentId() as id">
      <wr-maintenance-timer [buildingEquipmentId]="id" (update)="close()" [showCancelButton]="false"></wr-maintenance-timer>
    </ng-container>

  </div>
</wr-base-dialog>
