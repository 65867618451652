import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExternalContractorService, RepairTaskDetailDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-building-repair-task-overview-page',
  templateUrl: './building-repair-task-overview-page.component.html',
  styleUrls: ['./building-repair-task-overview-page.component.scss']
})
export class BuildingRepairTaskOverviewPageComponent implements OnInit{

  buildingId?: string;
  contractorId?: string;
  repairTasks: RepairTaskDetailDto[] = [];

  constructor(private readonly route: ActivatedRoute,
              private readonly externalContractorService: ExternalContractorService) {}

  ngOnInit(): void {
    this.buildingId = this.route.snapshot.paramMap.get('id')!;
    this.contractorId = this.route.snapshot.queryParamMap.get('contractorId')!;
    this.externalContractorService.findAllByContractorByBuildingAndStatusTodo(this.buildingId, this.contractorId).subscribe((repairTasks) => {
      this.repairTasks = repairTasks;
    });
  }

  loadRepairTasks(): void {
    this.externalContractorService.findAllByContractorByBuildingAndStatusTodo(this.buildingId!, this.contractorId!).subscribe((repairTasks) => {
      this.repairTasks = repairTasks;
    });
  }

}
