<nb-select placeholder="Firmen auswählen"
           multiple
           [(selected)]="value"
           [disabled]="isDisabled"
           [compareWith]="equalFn">
  <nb-option *ngFor="let contractor of contractors$ | async"
             [value]="{value: contractor.id}">
    {{ contractor.name }}
  </nb-option>
  <nb-option *ngIf="showNoneOption" [value]="{}">Keine Firma</nb-option>
</nb-select>
