import { RepairTaskDetailDto } from '@artemis-software/wr-api';
import { EnumTranslationPipe } from '@shared/pipes/enum-translation.pipe';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'repairTaskStatusTranslation',
})
export class RepairTaskStatusTranslationPipe implements EnumTranslationPipe<RepairTaskDetailDto.StatusEnum>{
  transform(value: RepairTaskDetailDto.StatusEnum): string {
    switch (value) {
    case RepairTaskDetailDto.StatusEnum.Draft:
      return 'Entwurf';
    case RepairTaskDetailDto.StatusEnum.Todo:
      return 'Offen';
    case RepairTaskDetailDto.StatusEnum.InProgress:
      return 'In Bearbeitung';
    case RepairTaskDetailDto.StatusEnum.Finished:
      return 'Abgeschlossen';
    case RepairTaskDetailDto.StatusEnum.Declined:
      return 'Abgelehnt';
    default:
      throw new Error(`Unknown status: ${value}`);
    }
  }
}
