<div class="inspection-entry-form"
     *ngIf="getInspectionEntryStatusFromFormGroup(entry) as status">
  <div *ngIf="hasEquipment()" class="status-text info">
    <nb-icon
      status="info"
      icon="cube"
      pack="fas"></nb-icon>
    TGA relevanter Prüfpunkt
  </div>
  <div *ngIf="getInspectionEntryStatusText(status) as statusText"
       class="status-text"
       [ngClass]="status">
    <nb-icon icon="info-outline" pack="eva"></nb-icon>
    {{ statusText }}
  </div>
  <div *ngIf="hasRepairTask()" class="status-text info clickable" (click)="editRepairTask(entry)">
    <nb-icon
      status="info"
      icon="screwdriver-wrench"
      pack="fas"></nb-icon>
    <span class="underline">Reparaturauftrag vorhanden</span>
  </div>
  <hau-form [formGroup]="entry">
    <hau-form-field label="Feststellung" [validation]="entry.validation.predefinedAssessment">
      <wr-assessment-preset-select [checkpointId]="entry.controls.checkpointId.value"
                                   [formControl]="entry.controls.predefinedAssessment">
      </wr-assessment-preset-select>
    </hau-form-field>
    <hau-form-field label="Maßnahme" [validation]="entry.validation.action" cols="6">
      <wr-action-select [formControl]="entry.controls.action"
                        [recommendedValue]="getRecommendedAction()">
      </wr-action-select>
    </hau-form-field>
    <hau-form-field label="Frist" [validation]="entry.validation.deadline" cols="6">
      <wr-deadline-select [formControl]="entry.controls.deadline"
                          [recommendedValue]="getRecommendedDeadline()">
      </wr-deadline-select>
    </hau-form-field>
    <hau-form-field label="Beschreibung" [validation]="entry.validation.note" cols="12">
      <textarea fullWidth nbInput [formControl]="entry.controls.note"></textarea>
    </hau-form-field>
    <hau-form-field label="Bilder & Dokumentenliste" [validation]="entry.validation.attachments">
      <wr-multi-attachment-control
        [formControl]="entry.controls.attachments"></wr-multi-attachment-control>
    </hau-form-field>
    <hau-form-field label="TGA" [validation]="entry.validation.buildingEquipments" cols="12">
      <wr-building-equipment-multi-select [formControl]="entry.controls.buildingEquipments"
                                          [building]="building"
                                          [buildingSection]="buildingSection"></wr-building-equipment-multi-select>
    </hau-form-field>
    <hau-form-field label="Kommentare" cols="12">
      <wr-inspection-entry-comments [formGroup]="entry"></wr-inspection-entry-comments>
    </hau-form-field>
  </hau-form>
</div>
