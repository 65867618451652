import { EnumTranslationPipe } from './enum-translation.pipe';
import { CustomFieldItemDto } from '@artemis-software/wr-api';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'valueTypeTranslation',
})
export class ValueTypeTranslationPipe implements EnumTranslationPipe<CustomFieldItemDto.ValueTypeEnum> {
  transform(value: CustomFieldItemDto.ValueTypeEnum): string {
    switch (value) {
    case CustomFieldItemDto.ValueTypeEnum.String:
      return 'Kurzer Text';
    case CustomFieldItemDto.ValueTypeEnum.Longtext:
      return 'Langer Text';
    case CustomFieldItemDto.ValueTypeEnum.Integer:
      return 'Ganze Zahl';
    case CustomFieldItemDto.ValueTypeEnum.Double:
      return 'Dezimalzahl';
    case CustomFieldItemDto.ValueTypeEnum.Boolean:
      return 'Ja/Nein';
    default:
      throw new Error(`Unknown value type: ${value}`);
    }
  }

}
