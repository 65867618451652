import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { BuildingSectionService } from '@artemis-software/wr-api';

@Injectable({ providedIn: 'root' })
export class BuildingSectionResolverService {

  private sections = new Map<string, Observable<BuildingSectionDetailDto>>();

  constructor(
    private readonly buildingSectionService: BuildingSectionService,
  ) {
  }

  getBuildingSection(buildingSectionId: string): Observable<BuildingSectionDetailDto> {
    if (!this.sections.has(buildingSectionId)) {
      const observable = this.buildingSectionService.findById(buildingSectionId).pipe(shareReplay(1));
      this.sections.set(buildingSectionId, observable);
    }
    return this.sections.get(buildingSectionId) as Observable<BuildingSectionDetailDto>;
  }

}
