import { Component, forwardRef, Input } from '@angular/core';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExternalContractorService } from '@artemis-software/wr-api';
import { NbToastrService } from '@nebular/theme';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'wr-pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinInputComponent),
      multi: true,
    },
  ],
})
export class PinInputComponent extends AbstractFormControl<string> {

  @Input()
  contractorId?: string;

  constructor(private readonly externalContractorService: ExternalContractorService,
              private readonly nbToastrService: NbToastrService) {
    super();
  }

  async generateNewPin() {
      this.value = await firstValueFrom(this.externalContractorService.generatePin());
      this.nbToastrService.success('Neuer PIN wurde generiert.', 'Erfolgreich');
  }

  async copyToClipboard() {
    if (this.value) {
      await navigator.clipboard.writeText(this.value);
      this.nbToastrService.success('PIN wurde in die Zwischenablage kopiert.', 'Erfolgreich');
    } else {
      this.nbToastrService.danger('Fehler beim Kopieren des PINs.', 'Fehler');
    }
  }
}
