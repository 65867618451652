import { Component, Input, OnInit } from '@angular/core';
import { AttachmentDetailDto } from '@artemis-software/wr-api';
import { FormControl, FormGroup } from '@angular/forms';
import { Attachment } from '@shared/form-controls/attachment-upload/attachment-utils';

@Component({
  selector: 'wr-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss'],
})
export class AttachmentDialogComponent implements OnInit {

  @Input()
  attachment!: Attachment;

  form!: FormGroup;

  onSubmit = () => this.form.value;

  constructor() {

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      tags: new FormControl(this.attachment?.tags),
      comment: new FormControl(this.attachment?.comment),
    });
  }


  getImage(attachment: AttachmentDetailDto): string {
    if (attachment.type === 'IMAGE') {
      return 'url("' + attachment.presignedUrl + '")';
    }
    return 'url("https://i.imgur.com/RBVnLkt.png")';
  }

  getTransform(): string {
    return `rotate(${this.attachment.rotationDegrees}deg)`;
  }
}
