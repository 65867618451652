import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[dataTableFilter]'
})
export class DataTableFilterDirective{
  constructor(public template: TemplateRef<any>) {
  }

}
