import { Directive, EventEmitter, Input, OnChanges } from '@angular/core';
import { ChartDataset, ChartType } from 'chart.js/auto';

@Directive()
export abstract class DatasetDirective<TType extends ChartType = any> implements OnChanges {
  abstract getDataset(): ChartDataset<TType>;

  readonly onChange = new EventEmitter<void>();

  ngOnChanges(): void {
    this.onChange.emit();
  }

  @Input() data!: number[];

  @Input() label?: string;
}
