import { AbstractFormControl } from './abstract-form-control';
import { ChangeDetectorRef, Directive, Input } from '@angular/core';

@Directive()
export abstract class SelectWithRecommendedOption<T> extends AbstractFormControl<T> {

  abstract readonly enumValues: T[];

  private _recommendedValue?: T;

  @Input()
  set recommendedValue(value: T | undefined) {
    this._recommendedValue = undefined;
    this.cdr.detectChanges();
    setTimeout(() => {
      this._recommendedValue = value;
      this.cdr.detectChanges();
    });
  }

  get recommendedValue(): T | undefined {
    return this._recommendedValue;
  }

  get enumValuesWithoutRecommended(): T[] {
    return this.enumValues.filter(action => action !== this.recommendedValue);
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }
}
