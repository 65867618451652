<ng-template #body>
  <div class="filter-row">
    <ng-container *ngIf="!insideCard">
      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="filterForm.template"></ng-container>
    <button nbButton ghost status="primary" (click)="onSubmit()">
      <nb-icon pack="eva" icon="search-outline"></nb-icon>
    </button>
  </div>

  <ng-container *ngIf="data$|async as data">
    <table [cypressId]="localStorageIdentifier">
      <thead>
      <tr>
        <th *ngFor="let c of columnsFiltered()" [class.sortable]="c.sortable" (click)="toggleSort(c)">
          <div class="th-flex">
          <span>
            {{ c.display }}
          </span>
            <ng-container *ngIf="sorting$.value?.sort === c.key">
              <ng-container [ngSwitch]="sorting$.value?.order">
                <nb-icon pack="eva" *ngSwitchCase="'ASC'" icon="arrow-ios-downward"></nb-icon>
                <nb-icon pack="eva" *ngSwitchCase="'DESC'" icon="arrow-ios-upward"></nb-icon>
              </ng-container>
            </ng-container>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of data.items; let i = index" (click)="lineClick.emit(item)" [attr.data-cy]="'table-row-' + i">
        <td *ngFor="let column of columnsFiltered()">
          <ng-container *ngTemplateOutlet="column.template; context: { $implicit: item }"></ng-container>
        </td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="data.items.length===0" class="no-entries" cypressId="empty-list">Keine Einträge gefunden</div>
  </ng-container>
</ng-template>

<ng-template #footer>
  <div class="footer">
    <div class="page-size">
      <nb-select [ngModel]="pageSize$|async" (ngModelChange)="changePageSize($event)">
        <nb-option *ngFor="let size of pageSizes" [value]="size">{{ size }}</nb-option>
      </nb-select>
    </div>
    <ng-container *ngIf="data$|async as data">
      <wr-pagination [page$]="page$" [maxPage]="ceil(data.count / pageSize$.value) - 1"></wr-pagination>
    </ng-container>
    <div class="placeholder"></div>
  </div>
</ng-template>

<!-- Render content with card around it -->
<nb-card *ngIf="insideCard" [nbSpinner]="(loading$|async) === true">
  <nb-card-header *ngIf="headerText">
    <div class="header">
      <span>{{ headerText }}</span>
      <ng-container *ngIf="insideCard">
        <ng-container *ngTemplateOutlet="actions"></ng-container>
      </ng-container>
    </div>
  </nb-card-header>

  <nb-card-body>
    <ng-container *ngTemplateOutlet="body"></ng-container>
  </nb-card-body>

  <nb-card-footer>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </nb-card-footer>
</nb-card>

<!-- Render content without card around it -->
<div class="cardless" *ngIf="!insideCard" [nbSpinner]="(loading$|async) === true">
  <ng-container *ngTemplateOutlet="body"></ng-container>
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</div>

<ng-template #actions>
  <div class="flex-div">
    <button *ngFor="let action of customActions"
            class="action"
            nbButton
            [status]="action.status"
            outline
            [nbTooltip]="action.tooltip!"
            [nbTooltipStatus]="action.status"
            [nbTooltipDisabled]="!action.tooltip"
            (click)="action.action()">
      <nb-icon *ngIf="action.icon as icon" [config]="icon"></nb-icon>
      <span *ngIf="action.title as title">{{ title }}</span>
    </button>
    <button nbButton status="primary" *ngIf="isAdmin() && showNewButton" (click)="newClick.emit()" cypressId="create-button">
      <nb-icon pack="eva" icon="plus-outline"></nb-icon>
    </button>
  </div>
</ng-template>
