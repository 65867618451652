import {Component, Input} from '@angular/core';
import {TagItemDto} from '@artemis-software/wr-api';
import {getColor} from '../../utils/tag-utils';

@Component({
  selector: 'wr-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent {
  private _tags: TagItemDto[] = [];

  @Input()
  set tags(value: TagItemDto[]) {
    if (value) {
      this._tags = value;
    } else {
      this._tags = [];
    }
  }

  get tags(): TagItemDto[] {
    return this._tags;
  }

  protected readonly getColor = getColor;
}
