import { RepairTaskFilterDto, RepairTaskItemDto, RepairTaskService } from '@artemis-software/wr-api';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {
  ChangeRepairTaskFilter,
  CountRepairTasks,
  FilterRepairTasks,
  LoadAllRepairTasks
} from '@/store/repairTask/repairTask.action';

type RepairTaskModel = {
  repairTasks: RepairTaskItemDto[],
  filteredRepairTasks: RepairTaskItemDto[],
  count: number,
  loading: boolean,
  filter: RepairTaskFilterDto,
}

@State<RepairTaskModel>({
  name: 'repairTasks',
  defaults: {
    repairTasks: [],
    filteredRepairTasks: [],
    count: 0,
    loading: false,
    filter: {} as RepairTaskFilterDto,
  },
})
@Injectable()
export class RepairTaskState implements NgxsOnInit {

  constructor(private readonly authService: AuthService,
              private readonly store: Store,
              private readonly repairTaskService: RepairTaskService) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllRepairTasks());
    });
  }

  @Selector()
  static RepairTasks(state: RepairTaskModel): RepairTaskItemDto[] {
    return state.repairTasks;
  }

  @Selector()
  static filteredRepairTasks(state: RepairTaskModel): RepairTaskItemDto[] {
    return state.filteredRepairTasks;
  }

  @Selector()
  static repairTasksLoading(state: RepairTaskModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: RepairTaskModel): number {
    return state.count;
  }

  @Action(LoadAllRepairTasks)
  loadAllRepairTasks(ctx: StateContext<RepairTaskModel>, action: LoadAllRepairTasks): void {
    ctx.patchState({ loading: true });
    this.repairTaskService.findAll({}).subscribe((repairTasks) => {
      ctx.patchState({
        repairTasks,
        loading: false,
      });
    });
  }

  @Action(FilterRepairTasks)
  filterRepairTasks(ctx: StateContext<RepairTaskModel>, action: FilterRepairTasks): void {
    ctx.patchState({ loading: true });
    const newFilter = this.createFilter(ctx.getState().filter);
    this.repairTaskService.findAll(newFilter).subscribe((repairTasks) => {
      ctx.patchState({
        filteredRepairTasks: repairTasks,
        loading: false,
      });
    });
  }

  @Action(CountRepairTasks)
  countRepairTasks(ctx: StateContext<RepairTaskModel>, action: CountRepairTasks): void {
    ctx.patchState({ loading: true });

    const newFilter = this.createFilter(ctx.getState().filter);
    this.repairTaskService.getCount(newFilter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeRepairTaskFilter)
  changeRepairTaskFilter(ctx: StateContext<RepairTaskModel>, action: ChangeRepairTaskFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountRepairTasks());
    this.store.dispatch(new FilterRepairTasks());
  }

  private createFilter(filter: RepairTaskFilterDto): RepairTaskFilterDto {
    return {
      ...filter,
    };
  }
}
