import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckpointState } from '../../store/checkpoint/checkpoint.state';
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { CheckpointGroupService, CheckpointItemDto, CheckpointService, SortDto } from '@artemis-software/wr-api';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChangeCheckpointFilter, CountCheckpoints, FilterCheckpoints } from '../../store/checkpoint/checkpoint.actions';
import { defineStoreUsage } from '../../utils/storeUsage';

@Component({
  selector: 'wr-checkpoint-overview-page',
  templateUrl: './checkpoint-overview-page.component.html',
  styleUrls: ['./checkpoint-overview-page.component.scss'],
})

export class CheckpointOverviewPageComponent {

  checkpointStoreUsage = defineStoreUsage({
    filterAction: ChangeCheckpointFilter,
    countSelector: CheckpointState.count,
    loadingSelector: CheckpointState.checkpointsLoading
  });

  @Select(CheckpointState.filteredCheckpoints)
  checkpoints$!: Observable<CheckpointItemDto[]>;
  @Select(CheckpointState.count)
  count$!: Observable<number>;
  @Select(CheckpointState.checkpointsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly checkpointService: CheckpointService,
    private readonly checkpointGroupService: CheckpointGroupService,
    private readonly dialogService: NbDialogService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('checkpointFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
      this.form.patchValue({
        checkpointGroupId: filterValue.checkpointGroupId,
        deprecated: filterValue.deprecated,
      });
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      checkpointGroupId: this.formBuilder.control(''),
      deprecated: this.formBuilder.control(''),
    });
  }

  getCheckpointNumber(checkpoint: CheckpointItemDto): string {
    return checkpoint.checkpointGroupNumber + '.' + checkpoint.number;
  }

  getCheckpointGroupName(checkpoint: CheckpointItemDto): string {
    return checkpoint.checkpointGroupName.substring(0, 30) + '';
  }

  navigateToCheckpoint(checkpoint: CheckpointItemDto): void {
    this.router.navigate(['checkpoint', checkpoint.id]);
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeCheckpointFilter(this.form.value));
    localStorage.setItem('checkpointFilter', JSON.stringify(this.form.value));
  }

  add(): void {
    this.router.navigate(['checkpoint', 'new']);
  }
}
