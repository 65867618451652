import { Component, computed, inject, signal } from '@angular/core';
import { addMonths, addWeeks, endOfMonth, format, startOfMonth } from 'date-fns';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { loader } from '@/utils/signals/loader';
import { MaintenanceTimerService } from '@artemis-software/wr-api';
import { CalendarEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { watch } from '@/utils/watch';

@Component({
  selector: 'wr-maintenance-timer-calendar-page',
  templateUrl: './maintenance-timer-calendar-page.component.html',
  styleUrls: ['./maintenance-timer-calendar-page.component.scss'],
})
export class MaintenanceTimerCalendarPageComponent {
  readonly maintenanceTimerService = inject(MaintenanceTimerService);

  readonly viewDate = signal(new Date());

  readonly refresh$ = new Subject();

  readonly from = computed(() => {
    return addWeeks(startOfMonth(this.viewDate()), -1);
  });

  readonly to = computed(() => {
    return addWeeks(endOfMonth(this.viewDate()), 1);
  });

  readonly loading = loader();

  readonly maintenanceTimers = asyncComputed(() => {
    return this.maintenanceTimerService.findAll({
      fromDate: this.from().toISOString(),
      toDate: this.to().toISOString(),
    });
  }, [], this.loading);

  readonly events = computed(() => {
    const timers = this.maintenanceTimers();
    return timers.map(timer => {
      return {
        title: timer.equipmentName,
        start: new Date(timer.nextDate),
        allDay: true,
        end: new Date(timer.nextDate),
        resizable: {
          afterEnd: false,
          beforeStart: false,
        },
        meta: timer,
      } as CalendarEvent;
    });
  });

  readonly currentMonth = computed(() => {
    return format(this.viewDate(), 'LLLL yyyy');
  });

  constructor() {
    watch(this.events, () => {
      this.refresh$.next(undefined);
    });
  }

  nextMonth() {
    this.viewDate.set(addMonths(this.viewDate(), 1));
  }

  previousMonth() {
    this.viewDate.set(addMonths(this.viewDate(), -1));
  }
}
