<ng-container *ngIf="isDisabled; then readonly; else editing"></ng-container>

<ng-template #readonly>
  <nb-form-field>
    <input nbInput
           type="text"
           placeholder="Gebäude suchen"
           [ngModel]="value?.sectionText"
           fullWidth
           [disabled]="true" />
    <button nbSuffix
            nbButton
            ghost
            *ngIf="value"
            (click)="goToBuilding()"
            nbTooltip="Zum Gebäude">
      <nb-icon icon="external-link-outline"
               pack="eva">
      </nb-icon>
    </button>
  </nb-form-field>
</ng-template>

<ng-template #editing>
  <nb-form-field>
    <input nbInput
           type="text"
           #searchInput
           [ngModel]="value?.sectionText"
           (ngModelChange)="filterText$.next($event)"
           placeholder="Gebäude suchen"
           (click)="focusIn()"
           (focusout)="focusOut()"
           fullWidth
           [nbAutocomplete]="autocomplete" />
    <button *ngIf="value" nbSuffix nbButton ghost (click)="clear()">
      <nb-icon icon="close-outline"
               pack="eva">
      </nb-icon>
    </button>
  </nb-form-field>

  <nb-autocomplete #autocomplete
                   (selectedChange)="onSelectionChange($event)"
                   [handleDisplayFn]="viewHandle">
    <nb-option *ngFor="let building of filteredBuildings$ | async" [value]="building">
      {{ building.sectionText }}
    </nb-option>
  </nb-autocomplete>
</ng-template>
