import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { IdReferenceDto, OrganisationItemDto } from '@artemis-software/wr-api';
import { Select } from '@ngxs/store';
import { OrganisationState } from '@/store/organisation/organisation.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'wr-organisation-multi-select',
  templateUrl: './organisation-multi-select.component.html',
  styleUrls: ['./organisation-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganisationMultiSelectComponent),
      multi: true,
    },
  ],
})
export class OrganisationMultiSelectComponent extends AbstractFormControl<IdReferenceDto>{
  readonly equalFn = (c1: IdReferenceDto, c2: IdReferenceDto) => c1.value === c2.value;

  @Select(OrganisationState.organisations)
  organisations$!: Observable<OrganisationItemDto[]>;

  @Input()
  showNoneOption = false;

}
