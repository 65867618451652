import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BuildingSectionService } from '@artemis-software/wr-api';
import { FilterBuildingSections } from './buildingSection.action';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { firstValueFrom } from 'rxjs';

type BuildingSectionStateModel = {
  filteredBuildingSections: BuildingSectionDetailDto[],
  loading: boolean
};

@State<BuildingSectionStateModel>({
  name: 'buildingSections',
  defaults: {
    filteredBuildingSections: [],
    loading: false,
  },
})
@Injectable()
export class BuildingSectionState {

  constructor(
    private readonly store: Store,
    private readonly buildingSectionService: BuildingSectionService,
  ) {
  }

  @Selector()
  static filteredBuildingSections(state: BuildingSectionStateModel): BuildingSectionDetailDto[] {
    return state.filteredBuildingSections;
  }

  @Selector()
  static buildingSectionsLoading(state: BuildingSectionStateModel): boolean {
    return state.loading;
  }

  @Action(FilterBuildingSections)
  async filterBuildingSections(ctx: StateContext<BuildingSectionStateModel>, action: FilterBuildingSections): Promise<void> {
    ctx.patchState({
      loading: true,
    });

    const buildingId = action.buildingId;

    if (buildingId) {
      const filteredBuildingSections = await firstValueFrom(this.buildingSectionService.findAllByBuildingId(buildingId));
      ctx.patchState({
        filteredBuildingSections,
        loading: false,
      });
    } else {
      ctx.patchState({
        filteredBuildingSections: [],
        loading: false,
      });
    }
  }
}
