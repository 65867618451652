<div class="file-entry">
  <div class="file-image"
       [class.selected]="selected"
       [class.disabled]="isDisabled"
       (click)="!isDisabled && imageEditClick.emit()">
    <div class="image"
         [style.transform]="getTransform()"
         [style.background-image]="getImage(attachment)"></div>
    <div>

      <div class="status-icons">
        <nb-icon *ngIf="hasTags()"
                 class="icon-tag"
                 pack="eva"
                 icon="bookmark"
                 nbTooltip="{{createTagTooltip()}}"></nb-icon>

        <nb-icon *ngIf="hasComment()"
                 class="icon-comment"
                 pack="eva"
                 icon="message-circle"
                 nbTooltip="{{createCommentTooltip()}}"></nb-icon>
      </div>

      <nb-icon *ngIf="!isDisabled && tagsEditClick.observed"
               class="icon-edit"
               pack="eva"
               icon="edit-outline"
               (click)="editTagsClick($event)"></nb-icon>
    </div>
  </div>
  <div class="file-row" *ngIf="getFileName(attachment) as fileName">
    <a class="filename" href="{{attachment.presignedUrl}}" target="_blank" [nbTooltip]="fileName">{{fileName}}</a>
  </div>
</div>
