import { Component, forwardRef, Input } from '@angular/core';
import { AbstractFormControl } from '../../abstract-form-control';
import { BehaviorSubject } from 'rxjs';
import { FileUploadService } from '@services/file-upload.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AttachmentDetailDto } from '@artemis-software/wr-api';
import { getAcceptForFileType } from '../file-utils';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { NbDialogService } from '@nebular/theme';
import { Attachment } from '@shared/form-controls/attachment-upload/attachment-utils';

@Component({
  selector: 'wr-single-attachment-control',
  templateUrl: './single-attachment-control.component.html',
  styleUrls: ['./single-attachment-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleAttachmentControlComponent),
      multi: true,
    },
  ],
})
export class SingleAttachmentControlComponent extends AbstractFormControl<Attachment> {

  @Input()
  type: AttachmentDetailDto.TypeEnum = AttachmentDetailDto.TypeEnum.File;

  loading$ = new BehaviorSubject(false);

  readonly getAcceptForFileType = getAcceptForFileType;

  constructor(private readonly fileUploadService: FileUploadService,
    private readonly dialogService: NbDialogService) {
    super();
  }

  async upload(file: File): Promise<void> {
    this.loading$.next(true);
    this.fileUploadService.uploadSingleFileNoProgress(file).subscribe((res) => {
      this.loading$.next(false);
      this.value = {
        s3key: res.key,
        presignedUrl: res.publicUrl,
        type: this.getType(file.type),
        fileName: file.name,
        fileEnding: file.name.split('.').pop(),
        tags: [],
        rotationDegrees: 0,
        created: '',
        comment: '',
        deleted: false,
      };
    });
  }

  onFilesSelected(event: any): void {
    const files = Array.from(event.target.files) as File[];
    this.upload(files[0]);
  }

  private getType(type: string): AttachmentDetailDto.TypeEnum {
    if (type.includes('image')) {
      return AttachmentDetailDto.TypeEnum.Image;
    }
    return this.type;
  }

  deleteFile(): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Datei löschen?',
        message: 'Bist du sicher, dass du die ausgewählte Datei löschen willst?',
      },
    }).onClose.subscribe(async (res) => {
      if (res && this.value) {
        this.value = {
          ...this.value,
          deleted: true,
        };
      }
    });
  }
}
