<div class="detail-container">
  <nb-card [nbSpinner]="!!(saving$|async)">
    <nb-card-header>
      <button nbButton ghost status="primary" (click)="navigateBack()">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>Prüfpunkt</h5>
      <wr-edit-delete-button-group (edit)="onEdit()" (delete)="onDelete()"></wr-edit-delete-button-group>
    </nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="form">
        <nb-tabset>
          <nb-tab tabTitle="Allgemein">
            <form [formGroup]="form">
              <div class="sub-card-body">
                <input hidden formControlName="id">
                <div class="grid">
                  <div class="col-12">
                    <label class="label" for="name">Bezeichnung</label>
                    <input fullWidth id="name" type="text" nbInput formControlName="name">
                    <div class="error" *ngIf="hasError('name')">Bezeichnung ist erforderlich!</div>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6">
                    <label class="label" for="number">Prüfpunktnummer</label>
                    <input fullWidth id="number" type="text" nbInput formControlName="number">
                    <div class="error" *ngIf="hasError('number')">Nummer ist erforderlich!</div>
                  </div>
                  <div class="col-6">
                    <label class="label" for="checkpointGroupSelect">Gruppe</label>
                    <wr-checkpoint-group-select id="checkpointGroupSelect" formControlName="checkpointGroupId"></wr-checkpoint-group-select>
                    <div class="error" *ngIf="hasError('checkpointGroupId')">Prüfpunkt Gruppe ist erforderlich!</div>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-12">
                    <label class="label" for="description">Beschreibung</label>
                    <textarea fullWidth id="description" nbInput formControlName="description"></textarea>
                    <div class="error" *ngIf="hasError('description')">Beschreibung ist erforderlich!</div>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6">
                    <label class="label" for="weight">Gewichtung</label>
                    <input fullWidth id="weight" type="number" nbInput formControlName="weight">
                    <div class="error" *ngIf="hasError('weight')">Gewichtung ist erforderlich!</div>
                    <div class="error" *ngIf="hasError('weight', 'max')">Maximale Gewichtung beträgt 10</div>
                    <div class="error" *ngIf="hasError('weight', 'min')">Minimale Gewichtung beträgt 0</div>
                  </div>
                  <div class="col-6">
                    <label class="label" for="contractors">Externe Firmen</label>
                    <wr-contractor-multi-select id="contractors" formControlName="contractors"></wr-contractor-multi-select>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6">
                    <label class="label" for="deprecated">Überholt</label>
                    <nb-toggle id="deprecated" formControlName="deprecated"></nb-toggle>
                  </div>
                  <div class="col-6">
                    <label class="label" for="building-equipment-related">Hat TGA?</label>
                    <nb-toggle id="building-equipment-related" formControlName="buildingEquipmentRelated"></nb-toggle>
                  </div>
                </div>
              </div>
            </form>
          </nb-tab>
          <nb-tab [tabTitle]="'Feststellungen (' + assessmentPresets.length + ')'">
            <form [formGroup]="form">
              <div class="sub-card-body">
                <div class="grid">
                  <div class="col-12">
                    <div class="assessment-preset-list-header">
                      <div class="info-text">
                        <nb-icon pack="eva" icon="alert-circle-outline"></nb-icon>
                        Jeder Prüfpunkt kann beliebig viele Feststellungen haben.
                      </div>
                      <button [disabled]="!isEditing" (click)="addAssessment()" nbButton ghost status="primary">
                        <nb-icon pack="eva" icon="plus-outline"></nb-icon>
                        Hinzufügen
                      </button>
                    </div>
                    <div class="assessment-preset-list" *ngIf="assessmentPresets && assessmentPresets.length > 0">
                      <div formArrayName="assessmentPresets">
                        <ng-container *ngFor="let _ of assessmentPresets.controls; let i = index">
                          <div class="assessment-preset-item" [formGroupName]="i">
                            <div class="assessment-preset-header">
                              <wr-action-select formControlName="action"></wr-action-select>
                              <wr-deadline-select formControlName="checkInterval"></wr-deadline-select>
                              <div class="toggle-container">
                                <label class="label" for="assessment-preset-building-equipment-related">Hat TGA?</label>
                                <nb-toggle id="assessment-preset-building-equipment-related" formControlName="buildingEquipmentRelated"></nb-toggle>
                                <label class="label" for="standardAssessment">Standard</label>
                                <nb-toggle id="standardAssessment" formControlName="standardAssessment"></nb-toggle>
                              </div>
                              <button nbButton ghost status="primary" [disabled]="!isEditing"
                                      (click)="deleteAssessment(i)">
                                <nb-icon pack="eva" icon="trash-2-outline"></nb-icon>
                              </button>
                            </div>
                            <textarea fullWidth type="text" formControlName="text" nbInput></textarea>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </nb-tab>
        </nb-tabset>
      </ng-container>
    </nb-card-body>
    <nb-card-footer>
      <button size="medium" [disabled]="!isEditing" nbButton status="primary" (click)="submit()">Speichern</button>
      <button size="medium" [disabled]="!isEditing" nbButton status="basic" (click)="cancel()">Zurück</button>
    </nb-card-footer>
  </nb-card>
</div>
