import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentDetailDto } from '@artemis-software/wr-api';
import { PendingAttachment } from '../attachment-utils';

@Component({
  selector: 'wr-attachment-upload-entry',
  templateUrl: './attachment-upload-entry.component.html',
  styleUrls: ['./attachment-upload-entry.component.scss'],
})
export class AttachmentUploadEntryComponent {

  @Input()
  attachment?: PendingAttachment;

  @Output()
  delete: EventEmitter<PendingAttachment> = new EventEmitter<PendingAttachment>();

  deleteFile(): void {
    this.delete.emit(this.attachment);
  }

  getFilename(): string {
    return this.attachment?.fileName ?? 'Kein Dateiname';
  }

  getIcon(): string {
    if (this.attachment?.type === AttachmentDetailDto.TypeEnum.Image) {
      return 'image-outline';
    }
    return 'file-outline';
  }
}
