<wr-list-detail-split-view [nbSpinner]="!!(loading$|async)"
                           [data$]="collections$"
                           [searchText]="searchText$.value"
                           [loadDetailFunc]="loadDetail"
                           (searchTextChange)="searchText$.next($event)"
                           (addItemClicked)="showAddDialog()">
  <ng-template wrListItem let-collection>
    <div class="custom-field-wrapper">
      <span class="name">{{ collection.title }}</span>
    </div>
  </ng-template>

  <ng-template wrDetailView let-detail>
    <wr-form-actions [form]="customFieldCollectionForm.form" [deleteAction]="deleteAction"
                     [deleteDialog]="deleteDialogAction"
                     [saveAction]="saveAction">
      <wr-custom-field-collection-form #customFieldCollectionForm [customFieldCollection]="detail">
      </wr-custom-field-collection-form>
    </wr-form-actions>
  </ng-template>
</wr-list-detail-split-view>
