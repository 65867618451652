import { Component, computed, inject } from '@angular/core';
import { RepairTaskItemDto, RepairTaskService, SortDto } from '@artemis-software/wr-api';
import { Tracked } from '@/utils/signals/tracked';
import { loader } from '@/utils/signals/loader';
import { asyncComputed } from '@/utils/signals/asyncComputed';

@Component({
  selector: 'wr-cooperative-dashboard-repair-task-overview',
  templateUrl: './cooperative-dashboard-repair-task-overview.component.html',
  styleUrls: ['./cooperative-dashboard-repair-task-overview.component.scss'],
})
export class CooperativeDashboardRepairTaskOverviewComponent {
  repairTaskService = inject(RepairTaskService);

  @Tracked()
  status?: RepairTaskItemDto.StatusEnum;

  @Tracked()
  overdue?: boolean;

  @Tracked() page = 0;
  @Tracked() pageSize = 10;
  @Tracked() sort: SortDto = { sort: 'fromTimestamp', order: 'DESC' };

  isLoading = loader();

  result = asyncComputed(() => this.repairTaskService.getForOrganisation({},
    this.status,
    this.overdue ? true : undefined,
    this.page,
    this.pageSize,
  ), undefined, this.isLoading);

  repairTasks = computed(() => this.result()?.content ?? []);

  count = computed(() => this.result()?.totalElements ?? 0);

  readonly getRepairTaskLink = (repairTask: RepairTaskItemDto) => (['inspection', repairTask.inspectionEntry!.inspectionId]);
}
