import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserStatisticsFilterDto, UserStatisticsDto } from '@artemis-software/wr-api';
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  private readonly apiUrl = `${apiUrl}/api/statistics/user`;

  constructor(private http: HttpClient) {
  }

  getUserStatistics(filter: UserStatisticsFilterDto): Observable<UserStatisticsDto> {
    return this.http.post<UserStatisticsDto>(`${this.apiUrl}`, filter, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
