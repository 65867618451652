import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailDetailDto, EmailService } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-email-detail-page',
  templateUrl: './email-detail-page.component.html',
  styleUrls: ['./email-detail-page.component.scss'],
})
export class EmailDetailPageComponent {

  isMultiple: boolean = true;

  form?: FormGroup;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly emailService: EmailService,
  ) {
    route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.emailService.findById(id).subscribe((email) => {
          this.initForm(email);
          this.form?.disable();
        });
      } else {
        this.router.navigate(['emails']);
      }
    });
  }

  initForm(emailDto: EmailDetailDto) {
    this.form = new FormGroup({
      id: new FormControl(emailDto.id),
      subject: new FormControl(emailDto.subject),
      recipients: new FormControl(emailDto.recipients),
      body: new FormControl(emailDto.htmlBody),
    });
    this.isMultiple = emailDto.recipients.length > 1;
  }


  async navigateBack(): Promise<void> {
    await this.router.navigate(['emails']);
  }
}
