import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EquipmentDetailDto, EquipmentItemDto } from '@artemis-software/wr-api';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EquipmentState } from '@/store/equipment/equipment.state';
import { Router } from '@angular/router';
import { ChangeEquipmentFilter } from '@/store/equipment/equipment.action';
import { defineStoreUsage } from '@/utils/storeUsage';


@Component({
  selector: 'wr-equipment-overview-page',
  templateUrl: './equipment-overview-page.component.html',
  styleUrls: ['./equipment-overview-page.component.scss'],
})
export class EquipmentOverviewPageComponent {

  equipmentStoreUsage = defineStoreUsage({
    filterAction: ChangeEquipmentFilter,
    countSelector: EquipmentState.count,
    loadingSelector: EquipmentState.equipmentsLoading,
  });

  @Select(EquipmentState.filteredEquipments)
  equipments$!: Observable<EquipmentDetailDto[]>;
  @Select(EquipmentState.count)
  count$!: Observable<number>;
  @Select(EquipmentState.equipmentsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(private readonly store: Store,
              private readonly router: Router,
              private readonly formBuilder: FormBuilder) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('equipmentFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
      this.addSpecialFields(filterValue.equipmentType, filterValue.maintenanceInterval);
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      equipmentType: new FormControl(''),
      maintenanceInterval: new FormControl(''),
    });
  }

  addSpecialFields(equipmentType: string, maintenanceInterval: string): void {
    this.form.patchValue({
      equipmentType: equipmentType,
      maintenanceInterval: maintenanceInterval,
    });
  }

  navigateToEquipment(equipment: EquipmentItemDto): void {
    this.router.navigate(['equipment', equipment.id]);
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeEquipmentFilter(this.form.value));
    localStorage.setItem('equipmentFilter', JSON.stringify(this.form.value));
  }

  add(): void {
    this.router.navigate(['equipment', 'new']);
  }
}
