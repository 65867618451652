import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Chart } from 'chart.js/auto';
import { DatasetDirective } from './datasets';
import { concat, merge, of, switchMap } from 'rxjs';
import { whenever } from '@/utils/whenever';

@Component({
  selector: 'wr-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() labels?: string[];

  @Input() title?: string;

  @Input() showLegend?: boolean = true;

  @Input() dynamicSize: boolean = false;

  @ViewChild('canvas')
  canvas!: ElementRef<HTMLCanvasElement>;

  @ContentChildren(DatasetDirective)
  datasets!: QueryList<DatasetDirective>;

  chart?: Chart;

  @HostListener('window:resize')
  onWindowResize() {
    if(!this.dynamicSize){
      this.chart?.resize(500, 400);
    } else if (this.chart && this.dynamicSize) {
      this.resizeCanvas();
      this.chart.resize();
    }
  }

  private resizeCanvas(): void {
    const canvasElement = this.canvas.nativeElement;
    const parentElement = canvasElement.parentElement;
    if (parentElement) {
      canvasElement.width = parentElement.clientWidth;
      canvasElement.height = Math.min(parentElement.clientHeight, this.datasets.length * 50);
    }
  }

  private readonly shouldUpdate = signal(false);

  constructor() {
    whenever(this.shouldUpdate, () => {
      this.chart?.update('active');

      this.shouldUpdate.set(false);
    });
  }

  ngAfterViewInit(): void {
    this.initChart(this.canvas.nativeElement);
  }

  initChart(canvas: HTMLCanvasElement): void {
    if (this.dynamicSize) {
      this.resizeCanvas();
    }
    this.chart = new Chart(canvas, {
      data: {
        datasets: this.datasets.map((factory) => factory.getDataset()),
        labels: this.labels,
      },
      options: {
        resizeDelay: 100,
        plugins: {
          legend: {
            display: this.showLegend,
          },
          tooltip: {
            position: 'average',
            xAlign: 'left',
          }
        },
      },
    });

    concat(of(undefined), this.datasets.changes)
      .pipe(
        switchMap(() =>
          merge(
            ...this.datasets.map((factory, index) =>
              merge(of(index), factory.onChange.pipe(switchMap(() => of(index)))),
            ),
          ),
        ),
      )
      .subscribe((index) => {
        const chart = this.chart;
        if (!chart) return;
        const datasetFactory = this.datasets.get(index)!;
        Object.assign(chart.data.datasets[index], datasetFactory.getDataset());
        this.shouldUpdate.set(true);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['labels'] && this.chart) {
      this.chart.data.labels = changes['labels'].currentValue;
      this.shouldUpdate.set(true);
    }
  }

  ngOnDestroy(): void {
    this.chart?.destroy();
  }
}
