import { CheckpointGroupFilterDto } from '@artemis-software/wr-api';

export class LoadAllCheckpointGroups {
  static readonly type = '[CheckpointGroup] Load all CheckpointGroups';
}

export class FilterCheckpointGroups {
  static readonly type = '[CheckpointGroup] Filter CheckpointGroups';
}

export class CountCheckpointGroups {
  static readonly type = '[CheckpointGroup] Count checkpointGroups';
}

export class ChangeCheckpointGroupFilter {
  static readonly type = '[CheckpointGroup] Change CheckpointGroup filter';

  constructor(public filter: CheckpointGroupFilterDto) {
  }
}
