import { Component, computed, effect } from '@angular/core';
import {
  ContractorItemDto,
  RepairTaskDetailDto,
  RepairTaskItemDto,
  RepairTaskMergeDto,
} from '@artemis-software/wr-api';
import { Validators } from '@angular/forms';
import { createForm } from '@sonofabit/ng-core';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-repair-task-edit-dialog',
  templateUrl: './repair-task-edit-dialog.component.html',
  styleUrls: ['./repair-task-edit-dialog.component.scss'],
})
export class RepairTaskEditDialogComponent {
  isAdmin = isAdmin();

  contractorForRepairTask: ContractorItemDto[] = [];
  form?: ReturnType<typeof this.initForm>;

  constructor() {
    effect(() => {
      if (!this.isAdmin()) {
        this.form?.disable();
      }
    });
  }


  onSubmit = async (): Promise<RepairTaskMergeDto> => {
    return await this.form!.getMappedValue() as RepairTaskMergeDto;
  };

  initDialog(
    repairTask: RepairTaskDetailDto | null,
    contractorForRepairTask: ContractorItemDto[],
    inspectionEntryId?: string,
    initTitle?: string,
    initDescription?: string,
  ) {
    this.contractorForRepairTask = contractorForRepairTask;
    this.form = this.initForm(repairTask, inspectionEntryId, initTitle, initDescription);
    this.form.controls.contractor.markAsTouched();
  }

  private initForm(repairTask: RepairTaskDetailDto | null, inspectionEntryId?: string, initTitle?: string, initDescription?: string) {
    return createForm(({ field }) => {
      return {
        id: field({ value: repairTask?.id }),
        inspectionEntryId: field({ value: inspectionEntryId }),
        title: field({ value: repairTask?.title ?? initTitle }),
        description: field({ value: repairTask?.description ?? initDescription }),
        status: field({ value: repairTask?.status ?? RepairTaskItemDto.StatusEnum.Todo }),
        contractor: field({
          value: repairTask?.contractor ?? (this.contractorForRepairTask.length === 1 ? this.contractorForRepairTask[0] : undefined),
          validators: [Validators.required],
          toDto: (value: ContractorItemDto | undefined) => value?.id,
          alias: 'contractorId',
        }),
      };
    });
  }
}
