import { Component, ViewChild } from '@angular/core';
import { CustomFieldFormComponent } from '../custom-field-form/custom-field-form.component';

@Component({
  selector: 'wr-custom-field-dialog',
  templateUrl: './custom-field-dialog.component.html',
  styleUrls: ['./custom-field-dialog.component.scss'],
})
export class CustomFieldDialogComponent {

  readonly submitAction = () => this.form?.form.getMappedValue();

  @ViewChild(CustomFieldFormComponent)
  form?: CustomFieldFormComponent;
}
