import { Component, Input } from '@angular/core';
import { CustomFieldCollectionDetailDto, CustomFieldCollectionMemberMergeDto } from '@artemis-software/wr-api';
import { createForm } from '@sonofabit/ng-core';
import { Validators } from '@angular/forms';

@Component({
  selector: 'wr-custom-field-collection-form',
  templateUrl: './custom-field-collection-form.component.html',
  styleUrls: ['./custom-field-collection-form.component.scss'],
})
export class CustomFieldCollectionFormComponent {
  @Input()
  set customFieldCollection(value: CustomFieldCollectionDetailDto | null) {
    this.form = this.createFormFromDetail(value);
  }

  form = this.createFormFromDetail(null);

  createFormFromDetail(detail: CustomFieldCollectionDetailDto | null) {
    return createForm(({ field, mapToIds }) => {
      return {
        id: field({ value: detail?.id }),
        title: field({ value: detail?.title, validators: [Validators.required] }),
        description: field({ value: detail?.description }),
        tags: field({
          value: detail?.tags ?? [],
          toDto: mapToIds,
          alias: 'tagIds',
        }),
        fields: field({
          value: (detail?.members ?? []).map(member => ({
            fieldId: member.field.id,
            required: member.required ?? false,
          } as CustomFieldCollectionMemberMergeDto)),
        }),
      };
    });
  }
}
