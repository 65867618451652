import { Component, computed, OnInit } from '@angular/core';
import { NbIconLibraries, NbSidebarService } from '@nebular/theme';
import { AuthService } from '@services/auth.service';
import { OrganisationDetailDto, UserDetailDto } from '@artemis-software/wr-api';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserState } from './store/user/user.state';
import { SessionService } from '@services/session.service';
import { WrMenuItem } from '@shared/components/menu/menu.component';
import { currentUser } from '@/utils/admin-utils';
import OrganisationTypeEnum = OrganisationDetailDto.OrganisationTypeEnum;

@Component({
  selector: 'wr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser = currentUser();
  organisationName = computed(() => {
    return this.currentUser()?.organisation.name ?? '';
  });
  menuItems = computed(() => {
    const currentUser = this.currentUser();
    return this.filterMenu([...this.items], currentUser).map(item => {
      let newChildren: WrMenuItem[] | undefined = undefined;
      if (item.children) {
        newChildren = this.filterMenu([...item.children], currentUser);
      }
      return {
        ...item,
        children: newChildren,
      };
    });
  });
  @Select(UserState.currentUser)
  currentUser$!: Observable<UserDetailDto | undefined>;

  loggedIn = false;
  expanded = false;

  items: WrMenuItem[] = [
    {
      title: 'Dashboards',
      icon: {
        pack: 'fas',
        icon: 'diagram-project',
      },
      children: [
        {
          title: 'Benutzer',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'user',
          },
          link: '/dashboard/user',
        },
        {
          title: 'Genossenschaften',
          organisationTypes: [OrganisationTypeEnum.Admin, OrganisationTypeEnum.Cooperative],
          icon: {
            pack: 'fas',
            icon: 'users',
          },
          link: '/dashboard/organisation',
        },
        {
          title: 'Gebäude',
          icon: {
            pack: 'fas',
            icon: 'house',
          },
          link: '/dashboard/building',
        },
        {
          title: 'Wartungstimer',
          icon: { pack: 'fas', icon: 'hourglass-half' },
          link: '/dashboard/maintenanceTimers',
        },
      ],
    },
    {
      title: 'Rundgänge',
      organisationTypes: [OrganisationTypeEnum.Admin],
      icon: {
        pack: 'fas',
        icon: 'clipboard-check',
      },
      link: '/inspections',
    },
    {
      title: 'Vorlagen',
      organisationTypes: [OrganisationTypeEnum.Admin],
      link: '/templates',
      icon: {
        pack: 'fas',
        icon: 'file'
      },
    },
    {
      title: 'Rundgänge',
      organisationTypes: [OrganisationTypeEnum.Cooperative],
      icon: {
        pack: 'fas',
        icon: 'clipboard-check',
      },
      link: '/cooperative/inspections',
    },
    {
      title: 'Rundgänge',
      organisationTypes: [OrganisationTypeEnum.PropertyManager],
      icon: {
        pack: 'fas',
        icon: 'clipboard-check',
      },
      link: '/propertyManager/inspections',
    },
    {
      title: 'Gebäudeverwaltung',
      icon: {
        pack: 'fas',
        icon: 'house',
      },
      children: [
        {
          title: 'Gebäude',
          organisationTypes: [OrganisationTypeEnum.Cooperative],
          icon: {
            pack: 'fas',
            icon: 'house',
          },
          link: '/cooperative/buildings',
        },
        {
          title: 'Gebäude',
          organisationTypes: [OrganisationTypeEnum.PropertyManager],
          icon: {
            pack: 'fas',
            icon: 'house',
          },
          link: '/propertyManager/buildings',
        },
        {
          title: 'Gebäude',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'house',
          },
          link: '/buildings',
        },
        {
          title: 'Übersichtskarte',
          icon: {
            pack: 'fas',
            icon: 'map',
          },
          link: '/map',
        },
      ],
    },
    {
      title: 'Benutzer',
      organisationTypes: [OrganisationTypeEnum.Cooperative, OrganisationTypeEnum.PropertyManager],
      icon: {
        pack: 'fas',
        icon: 'user',
      },
      link: '/cooperative/users',
    },
    {
      title: 'Benutzerverwaltung',
      organisationTypes: [OrganisationTypeEnum.Admin],
      icon: {
        pack: 'fas',
        icon: 'user',
      },
      children: [
        {
          title: 'Benutzer',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'user',
          },
          link: '/users',
        },
        {
          title: 'Genossenschaften',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'users',
          },
          link: '/organisations',
        },
      ],
    },
    {
      title: 'Prüfpunkte',
      organisationTypes: [OrganisationTypeEnum.Admin],
      icon: {
        pack: 'fas',
        icon: 'square-check',
      },
      children: [
        {
          title: 'Prüfpunkt Gruppen',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'check-to-slot',
          },
          link: '/checkpointGroups',
        },
        {
          title: 'Prüfpunkte',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'square-check',
          },
          link: '/checkpoints',
        },
      ],
    },
    {
      title: 'TGA',
      icon: {
        pack: 'fas',
        icon: 'cube',
      },
      children: [
        {
          title: 'TGA Typen',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: { pack: 'fas', icon: 'cube' },
          link: '/equipments',
        },
        {
          title: 'TGAs',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: { pack: 'fas', icon: 'cubes' },
          link: '/buildingEquipments',
        },
        {
          title: 'TGAs',
          organisationTypes: [OrganisationTypeEnum.Cooperative],
          icon: { pack: 'fas', icon: 'cubes' },
          link: '/cooperative/buildingEquipments',
        },
        {
          title: 'TGAs',
          organisationTypes: [OrganisationTypeEnum.PropertyManager],
          icon: { pack: 'fas', icon: 'cubes' },
          link: '/propertyManager/buildingEquipments',
        },
        {
          title: 'Wartungstimer',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: { pack: 'fas', icon: 'hourglass-half' },
          link: '/maintenanceTimers/overview',
        },
        {
          title: 'Wartungskalender',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: { pack: 'fas', icon: 'calendar-days' },
          link: '/maintenanceTimers/calendar',
        },
        {
          title: 'Wartungstimer',
          organisationTypes: [OrganisationTypeEnum.Cooperative],
          icon: { pack: 'fas', icon: 'hourglass-half' },
          link: '/cooperative/maintenanceTimers',
        },
        {
          title: 'Wartungstimer',
          organisationTypes: [OrganisationTypeEnum.PropertyManager],
          icon: { pack: 'fas', icon: 'hourglass-half' },
          link: '/propertyManager/maintenanceTimers',
        },
      ],
      link: '/equipments',
    },
    {
      title: 'Externe Firmen',
      organisationTypes: [OrganisationTypeEnum.Admin],
      icon: {
        pack: 'fas',
        icon: 'truck',
      },
      link: '/contractors',
    },
    {
      title: 'Tags',
      organisationTypes: [OrganisationTypeEnum.Admin],
      icon: {
        pack: 'fas',
        icon: 'tag',
      },
      link: '/tags',
    },
    {
      title: 'Emails',
      icon: {
        pack: 'fas',
        icon: 'envelope'
      },
      link: '/emails'
    },
    {
      title: 'Archiv',
      organisationTypes: [OrganisationTypeEnum.Admin],
      icon: {
        pack: 'fas',
        icon: 'image',
      },
      link: '/archive',
    },
    {
      title: 'Statistiken',
      organisationTypes: [OrganisationTypeEnum.Admin],
      icon: {
        pack: 'fas',
        icon: 'chart-bar',
      },
      children: [
        {
          title: 'Mitarbeiter',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'user',
          },
          link: '/statistics/user',
        },
        {
          title: 'Genossenschaften',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'users',
          },
          link: '/statistics/cooperative',
        },
        {
          title: 'Wartungstimer',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'hourglass-half',
          },
          link: '/statistics/maintenance-timer',
        }
      ]
    },
    {
      title: 'Benachrichtigungen',
      icon: {
        pack: 'fas',
        icon: 'bell',
      },
      link: '/notifications',
    },
    {
      title: 'Einstellungen',
      organisationTypes: [OrganisationTypeEnum.Admin],
      icon: {
        pack: 'fas',
        icon: 'gear',
      },
      children: [
        {
          title: 'Benutzerdefinierte Felder',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'list-check',
          },
          link: '/settings/customFields',
        },
        {
          title: 'Standardwerte',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'sliders',
          },
          link: '/settings',
        },
        {
          title: 'Debug',
          organisationTypes: [OrganisationTypeEnum.Admin],
          icon: {
            pack: 'fas',
            icon: 'bug',
          },
          link: '/debug',
        },
      ],
    },
    {
      title: 'Ausloggen',
      icon: {
        pack: 'fas',
        icon: 'right-from-bracket',
      },
      link: '/logout',
    },
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private readonly authService: AuthService,
    private iconLibraries: NbIconLibraries,
    private readonly sessionService: SessionService,) {
    this.iconLibraries.registerFontPack('fas', {
      packClass: 'fas',
      iconClassPrefix: 'fa',
    });
    this.iconLibraries.registerFontPack('far', {
      packClass: 'far',
      iconClassPrefix: 'fa',
    });
    this.iconLibraries.registerFontPack('fab', {
      packClass: 'fab',
      iconClassPrefix: 'fa',
    });
    this.iconLibraries.setDefaultPack('far');

    this.authService.initJwt();
    this.authService.authCredentials$.subscribe(cred => this.loggedIn = !!cred);
  }


  getUserProfile(user: UserDetailDto): string {
    const dummyUser = 'https://i.imgur.com/ujW6tKI.png';
    const attachments = user.attachments;
    if (attachments) {
      if (attachments.length > 0) {
        return attachments[0].presignedUrl ?? dummyUser;
      }
    }
    return dummyUser;
  }

  toggleSidebar(): void {
    this.expanded = !this.expanded;
    this.sidebarService.toggle(true);
  }

  ngOnInit() {
    this.sessionService.init();
  }

  filterMenu(items: WrMenuItem[], user: UserDetailDto | undefined): WrMenuItem[] {
    if (!user) {
      return [];
    }
    const organisationType = user.organisationType;
    return items.filter(item => {
      if (!item.organisationTypes) {
        return true;
      }
      return item.organisationTypes.includes(organisationType);
    });
  }
}
