import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { CheckpointGroupState } from '../../store/checkpointGroup/checkpointGroup.state';
import {
  CheckpointGroupDetailDto,
  CheckpointGroupItemDto,
  CheckpointGroupService,
} from '@artemis-software/wr-api';
import {
  ChangeCheckpointGroupFilter,
  CountCheckpointGroups,
  FilterCheckpointGroups,
} from '../../store/checkpointGroup/checkpointGroup.actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { defineStoreUsage } from '../../utils/storeUsage';

@Component({
  selector: 'wr-checkpoint-group-overview-page',
  templateUrl: './checkpoint-group-overview-page.component.html',
  styleUrls: ['./checkpoint-group-overview-page.component.scss'],
})
export class CheckpointGroupOverviewPageComponent {

  checkpointGroupStoreUsage = defineStoreUsage({
    filterAction: ChangeCheckpointGroupFilter,
    countSelector: CheckpointGroupState.count,
    loadingSelector: CheckpointGroupState.checkpointGroupsLoading
  });

  @Select(CheckpointGroupState.filteredCheckpointGroups)
  checkpointGroups$!: Observable<CheckpointGroupDetailDto[]>;
  @Select(CheckpointGroupState.count)
  count$!: Observable<number>;
  @Select(CheckpointGroupState.checkpointGroupsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(private readonly store: Store,
    private readonly router: Router,
    private readonly dialogService: NbDialogService,
    private readonly checkpointGroupService: CheckpointGroupService,
    private readonly formBuilder: FormBuilder, ) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('checkpointGroupFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
    });
  }

  navigateToUser(checkpointGroup: CheckpointGroupItemDto): void {
    this.router.navigate(['checkpointGroup', checkpointGroup.id]);
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeCheckpointGroupFilter(this.form.value));
    localStorage.setItem('checkpointGroupFilter', JSON.stringify(this.form.value));
  }

  add(): void {
    this.router.navigate(['checkpointGroup', 'new']);
  }

  cleanName(name: string): string {
    return name.replace(/_/g, ' ');
  }
}
