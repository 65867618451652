import { Component, Input } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomFieldItemDto } from '@artemis-software/wr-api';

@Component({
  selector: 'dok-value-type-select',
  templateUrl: './value-type-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ValueTypeSelectComponent,
      multi: true,
    },
  ],
})
export class ValueTypeSelectComponent extends AbstractFormControl<CustomFieldItemDto.ValueTypeEnum> {
  readonly values = Object.values(CustomFieldItemDto.ValueTypeEnum);

  @Input()
  placeholder = 'Wertetyp wählen';
}
