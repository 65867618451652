import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SettingsMergeDto, SettingsService } from '@artemis-software/wr-api';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'wr-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
})
export class SettingsPageComponent {

  isEditing = false;
  form?: FormGroup;
  saving$ = new BehaviorSubject(false);

  constructor(private readonly settingsService: SettingsService) {
    this.settingsService.getSettings().subscribe((settings) => {
      this.form = new FormGroup({
        id: new FormControl(settings?.id),
        conditionThresholds: new FormControl(settings?.conditionThresholds),
      });
      this.form.disable();
    });
  }

  onEdit(): void {
    this.isEditing = true;
    this.form?.enable();
  }

  async submit(): Promise<void> {
    this.form?.markAsTouched();
    this.saving$.next(true);
    const settingsMergeDto: SettingsMergeDto = {
      ...this.form?.value,
    };
    try {
      await firstValueFrom(this.settingsService.merge(settingsMergeDto));
    } catch (error) {
      console.error(error);
    } finally {
      this.saving$.next(false);
      this.isEditing = false;
      this.form?.disable();
    }

  }
}
