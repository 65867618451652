import { Component, Input } from '@angular/core';

@Component({
  selector: 'wr-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  @Input() pageTitle!: string;

  @Input() icon?: string;

  @Input() iconPack: string = 'eva';

  @Input() backLink?: string | any[] | null;

}
