import { Component, Input } from '@angular/core';
import { CustomFieldItemDto } from '@artemis-software/wr-api';
import { CustomFieldFormElement } from '@shared/custom-form/custom-form/custom-form.component';
import { AbstractControl, FormArray } from '@angular/forms';

@Component({
  selector: 'wr-custom-form-element',
  templateUrl: './custom-form-element.component.html',
  styleUrls: ['./custom-form-element.component.scss'],
})
export class CustomFormElementComponent {
  @Input()
  formElement?: CustomFieldFormElement;

  get isMultiControl(): boolean {
    return this.formElement?.field.elementType === CustomFieldItemDto.ElementTypeEnum.Multiple;
  }

  get formArray(): FormArray | undefined {
    return this.formElement?.formControl as FormArray | undefined;
  }

  readonly ValueTypeEnum = CustomFieldItemDto.ValueTypeEnum;
  protected readonly AbstractControl = AbstractControl;
}
