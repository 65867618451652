<wr-base-dialog header="Reparaturaufträge erstellen"
                size="large"
                status="primary"
                [submitResponse]="onSubmit"
                [disableSubmit]="!selectedSection">
  <ng-container>
    <label class="label">Gebäudeteil auswählen</label>
    <wr-building-section-select [building]="building"
                                [(selectedSection)]="selectedSection">
    </wr-building-section-select>
    <label class="label">Prüfpunkte auswählen</label>
    <wr-grouped-checkbox-list [(groups)]="selectedGroups"></wr-grouped-checkbox-list>
  </ng-container>
</wr-base-dialog>
