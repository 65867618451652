import { ChangeDetectionStrategy, Component, signal, WritableSignal } from '@angular/core';
import { MaintenanceTimerService, MaintenanceTimerStatisticsFilterDTO } from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { loader } from '@/utils/signals/loader';
import { asyncComputed } from '@/utils/signals/asyncComputed';

@Component({
  selector: 'wr-maintenance-timer-statistics-page',
  templateUrl: './maintenance-timer-statistics-page.component.html',
  styleUrls: ['./maintenance-timer-statistics-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTimerStatisticsPageComponent {

  readonly isLoading = loader();

  readonly filter: WritableSignal<MaintenanceTimerStatisticsFilterDTO> = signal({
    buildingIds: [],
    organisationIds: [],
  });

  readonly statistics = asyncComputed(
    () => {
      const filter = this.filter();
      return this.maintenanceTimerService.getStatistics({
        buildingIds: filter.buildingIds ?? [],
        organisationIds: filter.organisationIds ?? [],
      });
    },
    undefined,
    this.isLoading,
  );

  form!: FormGroup;

  readonly localStorageKey = 'maintenanceTimerStatisticsFilter';

  constructor(
    private readonly maintenanceTimerService: MaintenanceTimerService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem(this.localStorageKey);
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm();

      this.filter.set({
        organisationIds: filterValue.organisationIds ? filterValue.organisationIds.map((organisation: any) => organisation.value) : [],
        buildingIds: filterValue.buildingIds ? filterValue.buildingIds.map((building: any) => building.value) : [],
      });

      this.addSpecialFields(filterValue.organisationIds, filterValue.buildingIds);
    } else {
      this.initFilterForm();
    }
  }

  initFilterForm() {
    this.form = new FormGroup({
      organisationIds: this.formBuilder.control([]),
      buildingIds: this.formBuilder.control([]),
    });
  }

  addSpecialFields(organisationIds: any, buildingIds: any) {
    this.form.patchValue({
      'organisationIds': organisationIds,
      'buildingIds': buildingIds,
    });
  }

  triggerFilter(): void {
    const formValue = this.form.value;

    this.filter.set({
      organisationIds: formValue.organisationIds ? formValue.organisationIds.map((organisation: any) => organisation.value) : [],
      buildingIds: formValue.buildingIds ? formValue.buildingIds.map((building: any) => building.value) : [],
    });
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.form.value));
  }

  maintenanceTimerData() {
    return [
      {
        data: [this.statistics()?.overdueMaintenances ?? 0, 0, 0],
        label: this.maintenanceTimerLabels()[0],
        backgroundColor: '#ffc94d',
      },
      {
        data: [0, this.statistics()?.completedOnTimeMaintenances ?? 0, 0],
        label: this.maintenanceTimerLabels()[1],
        backgroundColor: '#00b887',
      },
      {
        data: [0, 0, this.statistics()?.completedNotOnTimeMaintenances ?? 0, 0],
        label: this.maintenanceTimerLabels()[2],
        backgroundColor: '#ff3d71',
      },
      {
        data: [0, 0, 0, this.statistics()?.totalMaintenanceTimers ?? 0],
        label: this.maintenanceTimerLabels()[3],
        backgroundColor: '#0095ff',
      },
    ];
  }

  maintenanceTimerLabels() {
    return ['überfällige Wartungen', 'pünktlich Abgeschlossene Wartungen', 'nicht Pünktlich Abgeschlossene Wartungen', 'gesamt Wartungstimer'];
  }
}
