<wr-base-dialog status="info" header="Prüfpunktzeile gefunden" [showSubmitButton]="false" size="large">
  <div class="flex-container">
    <div>Eine oder mehrere Prüfpunktzeilen mit dem selben Prüfpunkt wurden in anderen Gebäudeteilen gefunden.</div>
    <nb-alert outline="basic" *ngIf="checkpointString">
      {{checkpointString}}
    </nb-alert>
    <div><b>Prüfzeile auswählen und übernehmen:</b></div>
    <nb-list>
      <nb-list-item *ngFor="let entry of entries" (click)="select(entry)">
        <b>{{getSectionTitle(entry)}}</b>
        <span>{{getEntryDescription(entry)}}</span>
        <div class="flex-grow"></div>
        <nb-icon status="success" icon="checkmark-outline" pack="eva"></nb-icon>
      </nb-list-item>
    </nb-list>
  </div>
</wr-base-dialog>
