import { RepairTaskFilterDto } from "@artemis-software/wr-api";

export class LoadAllRepairTasks {
  static readonly type = '[RepairTask] Load all RepairTasks';
}

export class FilterRepairTasks {
  static readonly type = '[RepairTask] Filter RepairTasks';
}

export class CountRepairTasks {
  static readonly type = '[RepairTask] Count RepairTasks';
}

export class ChangeRepairTaskFilter {
  static readonly type = '[RepairTask] Change RepairTask Filter';

  constructor(public filter: RepairTaskFilterDto) {
  }
}
