<div class="custom-fields-select" [sortableArray]="value" [sortableOptions]="sortableOptions" (sortableChange)="onSort()">
  <div *ngFor="let field of selectedCustomFields$|async" class="custom-field noselect" >
    <nb-icon pack="eva" icon="more-vertical-outline"></nb-icon>
    <b class="name">{{field.name}}</b>
    <div class="grow"></div>
    <nb-checkbox [(checked)]="field.required" [disabled]="isDisabled" (checkedChange)="toggleRequired(field, $event)">erforderlich</nb-checkbox>
    <button nbButton ghost *ngIf="!isDisabled" (click)="remove(field)">
      <nb-icon pack="eva" icon="close-outline"></nb-icon>
    </button>
  </div>
</div>

<div *ngIf="!isDisabled && contextMenu.length"
     class="add-button"
     [nbContextMenu]="contextMenu"
     [nbContextMenuTag]="contextMenuTag">
  <nb-icon pack="eva" icon="plus-outline"></nb-icon>
</div>
