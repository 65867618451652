<div class="maintenance-timer-calendar noselect">
  <div class="calendar-header">
    <button nbButton status="basic" ghost (click)="previousMonth()">
      <nb-icon icon="chevron-left" pack="eva"></nb-icon>
    </button>
    <span class="month">
      {{ currentMonth() }}
    </span>
    <button nbButton status="basic" ghost (click)="nextMonth()">
      <nb-icon icon="chevron-right" pack="eva"></nb-icon>
    </button>
  </div>
  <mwl-calendar-month-view [viewDate]="viewDate()"
                           [nbSpinner]="loading()"
                           [events]="events()"
                           [refresh]="refresh$"
                           [cellTemplate]="customCell"></mwl-calendar-month-view>

  <ng-template #customCell let-day="day">
    <wr-maintenance-timer-calendar-cell [day]="day"></wr-maintenance-timer-calendar-cell>
  </ng-template>
</div>
