/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganisationLicenseDto } from './organisationLicenseDto';
import { AddressItemDto } from './addressItemDto';


export interface OrganisationDetailDto { 
    id: string;
    name: string;
    contractDate?: string;
    paymentAddress: AddressItemDto;
    license: OrganisationLicenseDto;
    loggedInUsers: number;
    organisationType: OrganisationDetailDto.OrganisationTypeEnum;
}
export namespace OrganisationDetailDto {
    export type OrganisationTypeEnum = 'PROPERTY_MANAGER' | 'COOPERATIVE' | 'ADMIN';
    export const OrganisationTypeEnum = {
        PropertyManager: 'PROPERTY_MANAGER' as OrganisationTypeEnum,
        Cooperative: 'COOPERATIVE' as OrganisationTypeEnum,
        Admin: 'ADMIN' as OrganisationTypeEnum
    };
}


