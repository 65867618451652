import { computed, inject, Signal } from '@angular/core';
import { OrganisationDetailDto, UserDetailDto } from '@artemis-software/wr-api';
import { CurrentUserService } from '@services/current-user.service';
import OrganisationTypeEnum = OrganisationDetailDto.OrganisationTypeEnum;

export function isAdmin(): Signal<boolean> {
  const adminService = inject(CurrentUserService);
  return computed(() => {
    return adminService.currentUser()?.organisationType === OrganisationTypeEnum.Admin;
  });
}

export function getRole(): Signal<OrganisationTypeEnum | undefined> {
  const adminService = inject(CurrentUserService);
  return computed(() => {
    const currentUser = adminService.currentUser();
    if (currentUser) {
      return currentUser.organisationType;
    }
    return undefined;
  });
}

export function currentUser(): Signal<UserDetailDto | undefined> {
  const adminService = inject(CurrentUserService);
  return computed(() => adminService.currentUser());
}
