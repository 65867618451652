import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import * as Sentry from '@sentry/angular-ivy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from './store/store.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesModule } from '@pages/pages.module';
import { CommonModule, registerLocaleData } from '@angular/common';
import {
  NbActionsModule,
  NbBadgeModule,
  NbButtonModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbRadioModule,
  NbSidebarModule,
  NbThemeModule,
  NbTimepickerModule,
  NbToastrModule,
  NbTooltipModule,
  NbUserModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Configuration, ConfigurationParameters, wrApiModule } from '@artemis-software/wr-api';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { Router } from '@angular/router';
import { PendingChangesGuard } from '@guards/pending-changes.guard';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { SessionService } from '@services/session.service';
import { SharedModule } from '@shared/shared.module';
import { NotificationsModule } from '@/notifications/notifications.module';
import { ServerSentEventsService } from '@services/event.service';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CustomDateFormatter } from '@/utils/custom-date-formatter';

import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import localeDe from '@angular/common/locales/de';

setDefaultOptions({ locale: de });

registerLocaleData(localeDe);

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiUrl,
  };
  return new Configuration(params);
}

const angularModules = [
  BrowserModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  BrowserAnimationsModule,
  HttpClientModule,
];

const modules = [
  AppRoutingModule,
  PagesModule,
  StoreModule,
  SharedModule,
  NotificationsModule,
  CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  },
  {
    dateFormatter: {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  }),
];

const nbModules = [
  NbThemeModule.forRoot({ name: 'wr' }),
  NbEvaIconsModule,
  NbSidebarModule.forRoot(),
  NbButtonModule,
  NbLayoutModule,
  NbCardModule,
  NbDialogModule.forRoot(),
  NbIconModule,
  NbToastrModule.forRoot({
    duplicatesBehaviour: 'all',
    preventDuplicates: true,
  }),
  NbCalendarModule,
  NbCalendarRangeModule,
  NbDatepickerModule.forRoot(),
  NbTimepickerModule.forRoot(),
  NbDatepickerModule,
  NbTimepickerModule,
  NbMenuModule.forRoot(),
  NbActionsModule,
  NbTooltipModule,
  NbUserModule,
  NbBadgeModule,
  NbRadioModule,
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ...angularModules,
    ...modules,
    ...nbModules,
    wrApiModule.forRoot(apiConfigFactory),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    PendingChangesGuard,
    SessionService,
    ServerSentEventsService,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
