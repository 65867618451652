<div class="custom-field-collections-select" [sortableArray]="value" [sortableOptions]="sortableOptions"
     (sortableChange)="onSort()">
  <div *ngFor="let collection of selectedCollections$|async" class="custom-field-collection noselect">
    <div class="header">
      <nb-icon pack="eva" icon="more-vertical-outline"></nb-icon>
      <div class="header-text">
        <b class="name">{{collection.title}}</b>
        <span>{{collection.description}}</span>
      </div>
      <div class="grow"></div>
      <button nbButton ghost *ngIf="!isDisabled" (click)="remove(collection)">
        <nb-icon pack="eva" icon="close-outline"></nb-icon>
      </button>
    </div>
    <div class="form-preview">
      <div class="form-wrapper">
        <wr-custom-form [collection]="collection" [readonly]="true"></wr-custom-form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isDisabled && contextMenu.length"
     class="add-button"
     [nbContextMenu]="contextMenu"
     [nbContextMenuTag]="contextMenuTag">
  <nb-icon pack="eva" icon="plus-outline"></nb-icon>
</div>
