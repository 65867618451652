import { Component, Input } from '@angular/core';

@Component({
  selector: 'wr-color-badge',
  templateUrl: './color-badge.component.html',
  styleUrls: ['./color-badge.component.scss']
})
export class ColorBadgeComponent{

  @Input()
  color = '#ffffff';
}
