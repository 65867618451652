import { Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFieldDetailDto } from '@artemis-software/wr-api';
import { createForm } from '@sonofabit/ng-core';

@Component({
  selector: 'wr-custom-field-form',
  templateUrl: './custom-field-form.component.html',
  styleUrls: ['./custom-field-form.component.scss'],
})
export class CustomFieldFormComponent {

  @Input()
  set customField(value: CustomFieldDetailDto | null) {
    this.form = this.createFormFromDetail(value);
  }

  form = this.createFormFromDetail(null);

  createFormFromDetail(detail: CustomFieldDetailDto | null) {
    return createForm(({ field }) => {
      return {
        id: field({ value: detail?.id }),
        name: field({ value: detail?.name, validators: [Validators.required] }),
        description: field({ value: detail?.description }),
        elementType: field({ value: detail?.elementType, validators: [Validators.required] }),
        valueType: field({ value: detail?.valueType, validators: [Validators.required] }),
      };
    });
  }
}
