import { Pipe, PipeTransform } from '@angular/core';
import { BuildingDetailDto } from '@artemis-software/wr-api';
import BuildingClassEnum = BuildingDetailDto.BuildingClassEnum;

export const buildingClassTranslations: Record<BuildingClassEnum, string> = {
  ONE: 'Gebäudeklasse 1',
  TWO: 'Gebäudeklasse 2',
  THREE: 'Gebäudeklasse 3',
  FOUR: 'Gebäudeklasse 4',
  FIVE: 'Gebäudeklasse 5',
  HIGHRISE: 'Hochhaus',
  TERRACED_HOUSES: 'Reihenhäuser',
  OTHER: 'Andere',
};

@Pipe({
  name: 'buildingClassTranslation',
})
export class BuildingClassTranslationPipe implements PipeTransform {
  transform(value?: BuildingClassEnum) {
    return value ? buildingClassTranslations[value] : undefined;
  }
}
