import { Component, Input } from '@angular/core';
import { InspectionItemDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-previous-inspection-dialog',
  templateUrl: './previous-inspection-dialog.component.html',
  styleUrls: ['./previous-inspection-dialog.component.scss'],
})
export class PreviousInspectionDialogComponent {

  @Input()
  inspection?: InspectionItemDto;

}
