<hau-form *ngIf="formWrapper.form as form" [formGroup]="form">
  <hau-form-field label="Gebäude" [validation]="form.validation.building" cols="10">
    <wr-building-select [formControl]="form.controls.building"></wr-building-select>
  </hau-form-field>
  <hau-form-field label="Folgenummer" [validation]="form.validation.continuationNumber" cols="2">
    <input fullWidth type="number" nbInput [formControl]="form.controls.continuationNumber">
  </hau-form-field>
  <hau-form-field label="Objektnummer intern" cols="6">
    <input fullWidth [value]="internalNumber" type="text" nbInput disabled>
  </hau-form-field>
  <hau-form-field label="Status" [validation]="form.validation.status" cols="6">
    <wr-status-select [formControl]="form.controls.status"></wr-status-select>
  </hau-form-field>
  <hau-form-field label="Techniker*in" [validation]="form.validation.technicianReference" cols="6">
    <wr-user-select [formControl]="form.controls.technicianReference" [showNotAssignedOption]="false"></wr-user-select>
  </hau-form-field>
  <hau-form-field label="Sachbearbeiter*in" [validation]="form.validation.clerkReference" cols="6">
    <wr-user-select [formControl]="form.controls.clerkReference" [showNotAssignedOption]="true"></wr-user-select>
  </hau-form-field>
  <hau-form-field label="Versendedatum" [validation]="form.validation.sendingDate" cols="6">
    <input nbInput [formControl]="form.controls.sendingDate" fullWidth placeholder="Datum"
           [nbDatepicker]="sendingDatepicker">
    <nb-datepicker #sendingDatepicker></nb-datepicker>
  </hau-form-field>
  <hau-form-field label="Normtyp" [validation]="form.validation.normType" cols="6">
    <wr-norm-type-select [formControl]="form.controls.normType"></wr-norm-type-select>
  </hau-form-field>
  <hau-form-field label="Rundgangsdatum" [validation]="form.validation.date" cols="4">
    <wr-date-picker [formControl]="form.controls.date"></wr-date-picker>
  </hau-form-field>
  <hau-form-field label="Anfang-Zeit" [validation]="form.validation.fromTime" cols="4">
    <wr-time-picker [formControl]="form.controls.fromTime"></wr-time-picker>
  </hau-form-field>
  <hau-form-field label="End-Zeit" [validation]="form.validation.toTime" cols="4">
    <wr-time-picker [formControl]="form.controls.toTime"></wr-time-picker>
  </hau-form-field>
  <hau-form-field label="Beschreibung" [validation]="form.validation.description">
    <textarea fullWidth nbInput [formControl]="form.controls.description"></textarea>
  </hau-form-field>
  <hau-form-field label="Bilder & Dokumentenliste" [validation]="form.validation.attachments">
    <wr-multi-attachment-control [formControl]="form.controls.attachments"></wr-multi-attachment-control>
  </hau-form-field>
  <hau-form-field label="Deckblattbild (optional)" [validation]="form.validation.coverImageReference" cols="6">
    <wr-single-attachment-control [formControl]="form.controls.coverImageReference"
                                  type="IMAGE"></wr-single-attachment-control>
  </hau-form-field>
  <hau-form-field label="Standortbild (optional)" [validation]="form.validation.locationReplacement" cols="6">
    <wr-single-attachment-control [formControl]="form.controls.locationReplacement"
                                  type="IMAGE"></wr-single-attachment-control>
  </hau-form-field>
</hau-form>
