import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentDetailDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-attachment-preview[attachment]',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss'],
})
export class AttachmentPreviewComponent {

  @Input()
  attachment!: AttachmentDetailDto;

  @Input()
  isDisabled = false;
  @Input()
  selected = false;

  @Output()
  imageEditClick = new EventEmitter<void>();

  @Output()
  tagsEditClick = new EventEmitter<void>();

  getImage(attachment: AttachmentDetailDto): string {
    if (attachment.type === 'IMAGE') {
      return 'url("' + attachment.presignedUrl + '")';
    }
    return 'url("https://i.imgur.com/RBVnLkt.png")';
  }

  getFileName(attachment: AttachmentDetailDto): string {
    if (attachment.fileName && attachment.fileEnding) {
      return attachment.fileName + '.' + attachment.fileEnding;
    }
    return 'Kein Dateiname';
  }

  getTransform(): string {
    return `rotate(${this.attachment.rotationDegrees}deg)`;
  }


  createTagTooltip(): string {
    return `Tags: ${this.attachment.tags?.map(t => t.name).join(', ') ?? ''}`;
  }

  hasComment(): boolean {
    return !!this.attachment.comment?.length;
  }

  createCommentTooltip(): string {
    return `Kommentar: ${this.attachment.comment ?? ''}`;
  }

  hasTags(): boolean {
    return !!this.attachment.tags?.length;
  }

  editTagsClick(event: MouseEvent): void {
    event.stopPropagation();
    this.tagsEditClick.emit();
  }
}
