import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { ListItemDirective } from '@shared/components/list-view/list-item.directive';
import {
  GroupedListViewHeaderDirective
} from '@shared/components/grouped-list-view/grouped-list-view-header.directive';

export type ListViewGroup<Item> = {
  id: string;
  displayName: string;
  items: Item[];
};

@Component({
  selector: 'wr-grouped-list-view',
  templateUrl: './grouped-list-view.component.html',
  styleUrls: ['./grouped-list-view.component.scss'],
})
export class GroupedListViewComponent<Item> {
  @Input()
  groups: ListViewGroup<Item>[] | null | undefined;

  @ContentChild(ListItemDirective)
  listItemTemplate?: ListItemDirective;
  @ContentChild(GroupedListViewHeaderDirective)
  groupHeaderTemplate?: GroupedListViewHeaderDirective;

  @Input()
  disableAddButton: boolean = false;
  @Input()
  selected: Item | null | undefined;
  @Input()
  searchText?: string;

  @Output()
  selectedChange = new EventEmitter<Item>();
  @Output()
  searchTextChange = new EventEmitter<string>();
  @Output()
  addItemClicked = new EventEmitter<void>();

  hiddenGroups = new Map<string, boolean>();

  toggleFunc = this.toggle.bind(this);

  toggle(id: string): void {
    this.hiddenGroups.set(id, !this.hiddenGroups.get(id));
  }

}
