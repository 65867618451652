import { Component, computed, inject, signal } from '@angular/core';
import { RepairTaskFilterDto, RepairTaskItemDto, RepairTaskService } from '@artemis-software/wr-api';
import { select } from '@/utils/signals/select';
import { BuildingState } from '@/store/building/building.state';
import { loader } from '@/utils/signals/loader';
import { asyncComputed } from '@/utils/signals/asyncComputed';

@Component({
  selector: 'wr-building-dashboard-repair-tasks',
  templateUrl: './building-dashboard-repair-tasks.component.html',
  styleUrls: ['./building-dashboard-repair-tasks.component.scss'],
})
export class BuildingDashboardRepairTasksComponent {

  readonly repairTaskService = inject(RepairTaskService);

  readonly building = select(BuildingState.selectedDashboardBuilding);

  readonly loading = loader();

  readonly page = signal(0);
  readonly pageSize = signal(10);

  readonly filter = computed(() => {
    const building = this.building();
    if (!building) {
      return undefined;
    }
    return {
      buildingId: building.id,
      page: this.page(),
      size: this.pageSize(),
      sort: {
        sort: 'title',
        order: 'ASC',
      }
    } as RepairTaskFilterDto;
  });

  readonly repairTasks = asyncComputed(() => {
    const filter = this.filter();
    return filter ? this.repairTaskService.findAll(filter) : [];
  }, [], this.loading);

  readonly count = asyncComputed(() => {
    const filter = this.filter();
    return filter ? this.repairTaskService.getCount(filter) : 0;
  }, 0, this.loading);

  readonly getInspectionLink = (repairTask: RepairTaskItemDto) => (['inspection', repairTask.inspectionEntry!.inspectionId, 'repair-tasks']);
}
