import { Component, Input } from '@angular/core';

@Component({
  selector: 'wr-delete-dialog',
  templateUrl: './delete-dialog.component.html',
})
export class DeleteDialogComponent {

  @Input()
  title = '';
  @Input()
  message = '';
}
