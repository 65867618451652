<wr-new-data-table headerText="Benutzerübersicht"
                   localStorageIdentifier="user-overview"
                   [loadItemsFunction]="loadUsers"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToUser($event)"
                   (newClick)="add()"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template dataTableFilter>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
        <div class="filter-control-group">
          <label class="label" for="organisation">Genossenschaft</label>
          <wr-organisation-select id="organisation" formControlName="organisationId"
                                  [showEmptyOption]="true"></wr-organisation-select>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="abbreviation"
    display="Kürzel">
    <div *ngIf="user.abbreviation">
      {{ user.abbreviation }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="userName"
    display="Benutzer">
    <div *ngIf="user.userName">
      {{ user.userName }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="firstName"
    display="Vorname">
    <div *ngIf="user.firstName">
      {{ user.firstName }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="lastName"
    display="Nachname">
    <div *ngIf="user.lastName">
      {{ user.lastName }}
    </div>
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="organisationName"
    display="Genossenschaft">
    {{ user.organisationName }}
  </ng-template>
  <ng-template
    dataColumn
    let-user
    key="status"
    [sortable]="false"
    display="Status">
    <div *ngIf="user">
      <nb-icon class='online-status' icon='circle' pack='fas'
               [ngClass]='user.isLoggedIn ? "online" : "offline"'></nb-icon>
      {{ user.isLoggedIn ? 'Aktiv' : 'Inaktiv' }}
    </div>
  </ng-template>
</wr-new-data-table>
