<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon icon="hourglass-half" pack="fas"></nb-icon>
          Wartungstimer
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="buildings">Gebäude</label>
                <wr-building-multi-select id="buildings" formControlName="buildingIds"
                                          [showNoneOption]="true"></wr-building-multi-select>
              </div>
              <div class="filter-control-group">
                <label class="label" for="organisations">Genossenschaft</label>
                <wr-organisation-multi-select id="organisations" formControlName="organisationIds"
                                              [showNoneOption]="true"></wr-organisation-multi-select>
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
</div>
<div class="statistics-container">
  <nb-card [nbSpinner]="isLoading()">
    <nb-card-body>
      <wr-chart
        *ngIf="maintenanceTimerData()"
        [labels]="maintenanceTimerLabels()"
        [showLegend]="false">
        <ng-container *ngFor="let dataset of maintenanceTimerData(); let i = index">
          <wr-dataset
            type="bar"
            [data]="dataset.data"
            [label]="dataset.label"
            [backgroundColor]="dataset.backgroundColor"
            indexAxis="y"
            stack="repairTasks">
          </wr-dataset>
        </ng-container>
      </wr-chart>
    </nb-card-body>
  </nb-card>
</div>
