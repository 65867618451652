<wr-split-view>
  <div left [nbSpinner]="!(data$|async)">
    <wr-list-view [items]="data$|async"
                  [selected]="selectedItem$|async"
                  (selectedChange)="selectedItem$.next($event)"
                  [searchText]="searchText"
                  (searchTextChange)="searchTextChange.emit($event)"
                  (addItemClicked)="addItemClicked.emit()">
      <ng-template wrListItem let-item>
        <ng-container *ngIf="listItemTemplate?.templateRef as template; else noTemplate"
                      [ngTemplateOutlet]="template"
                      [ngTemplateOutletContext]="{$implicit: item}">
        </ng-container>
      </ng-template>
    </wr-list-view>
  </div>
  <div *ngIf="detailItem$|async as detail" right>
    <ng-container *ngIf="detailViewTemplate?.templateRef as template; else noTemplate"
                  [ngTemplateOutlet]="template"
                  [ngTemplateOutletContext]="{$implicit: detail}">
    </ng-container>
  </div>
</wr-split-view>

<ng-template #noTemplate>
  <nb-alert status="warning">
    No template provided
  </nb-alert>
</ng-template>
