import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PendingChanges } from '@guards/pending-changes.guard';
import {
  ListDetailSplitViewComponent,
} from '@components/split-view/list-detail-split-view/list-detail-split-view.component';
import {
  CustomFieldCollectionDetailDto,
  CustomFieldCollectionItemDto,
  CustomFieldCollectionMergeDto,
  CustomFieldCollectionService,
  CustomFieldMergeDto,
} from '@artemis-software/wr-api';
import { BehaviorSubject, combineLatest, debounceTime, firstValueFrom, takeUntil } from 'rxjs';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import {
  CustomFieldCollectionDialogComponent,
} from '@pages/custom-fields-settings-page/custom-field-collection-split-view/custom-field-collection-dialog/custom-field-collection-dialog.component';

@Component({
  selector: 'wr-custom-field-collection-split-view',
  templateUrl: './custom-field-collection-split-view.component.html',
  styleUrls: ['./custom-field-collection-split-view.component.scss'],
})
export class CustomFieldCollectionSplitViewComponent implements PendingChanges, OnInit, OnDestroy {

  @ViewChild(ListDetailSplitViewComponent)
  splitView?: ListDetailSplitViewComponent<CustomFieldCollectionItemDto, CustomFieldCollectionDetailDto>;

  readonly loadDetail = (item: CustomFieldCollectionItemDto) => this.customFieldCollectionService.findById(item.id);
  readonly saveAction = (val: CustomFieldCollectionMergeDto) => this.save(val);
  readonly deleteAction = (val: CustomFieldCollectionMergeDto) => this.delete(val.id!);
  readonly deleteDialogAction = () => this.deleteDialog();

  loading$ = new BehaviorSubject<boolean>(false);
  searchText$ = new BehaviorSubject<string>('');
  reload$ = new BehaviorSubject<void>(undefined);
  collections$ = new BehaviorSubject<CustomFieldCollectionItemDto[] | undefined>(undefined);
  destroy$ = new BehaviorSubject<void>(undefined);

  constructor(
    private readonly customFieldCollectionService: CustomFieldCollectionService,
    private readonly nbDialogService: NbDialogService,
    private readonly nbToastrService: NbToastrService,
  ) {
    combineLatest([
      this.searchText$,
      this.reload$,
    ]).pipe(
      debounceTime(500),
    ).subscribe(async ([searchText]) => {
      this.collections$.next(undefined);
      const collections = await firstValueFrom(this.customFieldCollectionService.findAll({
        text: searchText.trim(),
        sort: { sort: 'title', order: 'ASC' },
      }));
      this.collections$.next(collections);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.reload$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.splitView?.refresh();
    });
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return false;
  }

  async showAddDialog(): Promise<void> {
    const result = await firstValueFrom(this.nbDialogService.open(CustomFieldCollectionDialogComponent).onClose);
    if (result) {
      await this.save(result);
    }
  }

  async save(formValue: CustomFieldMergeDto): Promise<void> {
    await firstValueFrom(this.customFieldCollectionService.merge(formValue));
    this.nbToastrService.success('Benutzerdefinierte Gruppe gespeichert', 'Erfolg');
    this.reload$.next(undefined);
  }

  async deleteDialog(): Promise<boolean> {
    return await firstValueFrom(this.nbDialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Gruppe löschen?',
        message: 'Möchten Sie die benutzerdefinierte Gruppe wirklich löschen?',
      },
    }).onClose);
  }

  async delete(id: string): Promise<void> {
    await firstValueFrom(this.customFieldCollectionService.deleteById(id));
    this.nbToastrService.success('Benutzerdefinierte Gruppe gelöscht', 'Erfolg');
    this.reload$.next(undefined);
    this.splitView?.unselect();
  }
}
