import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentDropZoneComponent } from './attachment-drop-zone/attachment-drop-zone.component';
import { AttachmentUploadEntryComponent } from './attachment-upload-entry/attachment-upload-entry.component';
import { AttachmentUploadSectionComponent } from './attachment-upload-section/attachment-upload-section.component';
import { NebularModule } from '../../nebular/nebular.module';
import { MultiAttachmentControlComponent } from './multi-attachment-control/multi-attachment-control.component';
import { SingleAttachmentControlComponent } from './single-attachment-control/single-attachment-control.component';
import { AttachmentPreviewComponent } from './attachment-preview/attachment-preview.component';
import { TagFilterListComponent } from './tag-filter-list/tag-filter-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DirectivesModule } from '@shared/directives/directives.module';


@NgModule({
  declarations: [
    MultiAttachmentControlComponent,
    AttachmentDropZoneComponent,
    AttachmentUploadEntryComponent,
    AttachmentUploadSectionComponent,
    SingleAttachmentControlComponent,
    AttachmentPreviewComponent,
    TagFilterListComponent,
  ],
  imports: [
    CommonModule,
    NebularModule,
    DragDropModule,
    DirectivesModule,
  ],
  exports: [
    SingleAttachmentControlComponent,
    MultiAttachmentControlComponent,
    AttachmentPreviewComponent,
  ],
})
export class AttachmentUploadModule {
}
