import { Component, effect, inject } from '@angular/core';
import { MaintenanceTimerDetailDto } from '@artemis-software/wr-api';
import { defineStoreUsage } from '@/utils/storeUsage';
import {
  ChangeMaintenanceTimerFilter,
  CountMaintenanceTimers,
} from '@/store/maintenanceTimer/maintenanceTimer.action';
import { MaintenanceTimerState } from '@/store/maintenanceTimer/maintenanceTimer.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { currentUser } from '@/utils/admin-utils';

@Component({
  selector: 'wr-property-manager-maintenance-timer-overview-page',
  templateUrl: './property-manager-maintenance-timer-overview-page.component.html',
})
export class PropertyManagerMaintenanceTimerOverviewPageComponent {
  currentUser = currentUser();
  storeUsage = defineStoreUsage({
    filterAction: ChangeMaintenanceTimerFilter,
    countSelector: MaintenanceTimerState.count,
    loadingSelector: MaintenanceTimerState.maintenanceTimersLoading,
  });

  store = inject(Store);

  router = inject(Router);

  constructor() {
    effect(() => {
      const currentUser = this.currentUser();
      if (currentUser) {
        this.store.dispatch(new ChangeMaintenanceTimerFilter(
          {
            organisationId: undefined
          }
        ));
        this.store.dispatch(new CountMaintenanceTimers());
      }
    });
  }

  @Select(MaintenanceTimerState.filteredMaintenanceTimers)
  maintenanceTimers$!: Observable<MaintenanceTimerDetailDto[]>;

  navigateToBuildingEquipment(entity: MaintenanceTimerDetailDto) {
    this.router.navigate(['buildingEquipment', entity.buildingEquipment.id]);
  }
}
