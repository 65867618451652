<wr-page backLink="/buildingEquipments" icon="hourglass-half" iconPack="fas" pageTitle="Wartungstimer">
  <wr-data-table
    (lineClick)="navigateToBuildingEquipment($event)"
    [data$]="maintenanceTimers$"
    [defaultSort]="'nextDate'"
    [sortKey]="'maintenanceTimerSort'"
    [storeUsage]="storeUsage"
  >
    <ng-template [sortable]="false"
                 dataColumn
                 display="TGA"
                 key="equipment"
                 let-entity
    >
      {{ entity.buildingEquipment.equipmentName }}
    </ng-template>
    <ng-template dataColumn
                 display="Typ"
                 key="type"
                 let-entity>
      {{ entity.type | maintenanceTimerType }}
    </ng-template>
    <ng-template dataColumn
                 display="Dauer"
                 key="durationDays"
                 let-entity>
      {{ entity.durationDays }} {{ entity.durationDays | pluralize: 'Tag': 'Tage' }}
    </ng-template>
    <ng-template dataColumn
                 display="Fälligkeitsdatum"
                 key="nextDate"
                 let-entity>
      {{ entity.nextDate | date: 'dd.MM.yyyy' }}
    </ng-template>
    <ng-template [sortable]="false"
                 dataColumn
                 display="Fällig in"
                 key="dueDays"
                 let-entity>
      <div class="due-days" [class.overdue]="dueDays(entity.nextDate) <= 0">
        {{ dueDays(entity.nextDate) }} {{ dueDays(entity.nextDate) | pluralize: 'Tag': 'Tage' }}
      </div>
    </ng-template>
  </wr-data-table>

</wr-page>
