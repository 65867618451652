/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuildingEquipmentItemDto } from './buildingEquipmentItemDto';
import { MaintenanceTimerCheckDateDetailDto } from './maintenanceTimerCheckDateDetailDto';


export interface MaintenanceTimerDetailDto { 
    id: string;
    created: string;
    type: MaintenanceTimerDetailDto.TypeEnum;
    buildingEquipment: BuildingEquipmentItemDto;
    startDate: string;
    durationDays: number;
    nextDate: string;
    reminderDate: string;
    reminderDurationDays: number;
    iterations: number;
    overdueDays: number;
    maintenanceTimerCheckDateDetailDto: Array<MaintenanceTimerCheckDateDetailDto>;
}
export namespace MaintenanceTimerDetailDto {
    export type TypeEnum = 'Maintenance' | 'Inspection';
    export const TypeEnum = {
        Maintenance: 'Maintenance' as TypeEnum,
        Inspection: 'Inspection' as TypeEnum
    };
}


