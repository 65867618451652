<div class="page">
  <nb-card>
    <nb-card-body class="page-header">
      <button *ngIf="backLink" nbButton ghost status="primary" class="back-button" [routerLink]="backLink">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>
        <nb-icon *ngIf="icon" [icon]="icon" [pack]="iconPack"></nb-icon>
        {{pageTitle}}
      </h5>
      <div class="spacer"></div>
      <ng-content select="[headerAppend]"></ng-content>
    </nb-card-body>
  </nb-card>
  <ng-content></ng-content>

</div>
