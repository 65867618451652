import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractFormControl } from "@shared/form-controls/abstract-form-control";
import {
  BuildingItemDto,
  BuildingSectionDetailDto,
  BuildingSectionItemDto, BuildingSectionService,
  IdReferenceDto
} from "@artemis-software/wr-api";
import { BehaviorSubject, Observable } from "rxjs";
import { Select } from "@ngxs/store";
import { BuildingSectionState } from "@/store/buildingSection/buildingSection.state";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'wr-building-section-multi-select',
  templateUrl: './building-section-multi-select.component.html',
  styleUrls: ['./building-section-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingSectionMultiSelectComponent),
      multi: true,
    },
  ],
})
export class BuildingSectionMultiSelectComponent extends AbstractFormControl<IdReferenceDto[]> implements OnInit{
  ngOnInit(): void {
    this.buildingSectionService.findAllByBuildingId(this.buildingId)
      .subscribe(sections=> this.buildingSections$.next(sections));
  }
  readonly equalFn = (c1: IdReferenceDto, c2: IdReferenceDto) => c1.value === c2.value;

  @Input()
  buildingId!: string;

  buildingSections$ = new BehaviorSubject<BuildingSectionDetailDto[]>([]);

  constructor(private readonly buildingSectionService: BuildingSectionService) {
    super();
  }
}
