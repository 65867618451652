import { Component, Input } from '@angular/core';
import { RepairTaskDetailDto } from '@artemis-software/wr-api';
import StatusEnum = RepairTaskDetailDto.StatusEnum;

@Component({
  selector: 'wr-repair-task-status-icon',
  templateUrl: './repair-task-status-icon.component.html',
  styleUrls: ['./repair-task-status-icon.component.scss'],
})
export class RepairTaskStatusIconComponent {
  @Input()
  status!: StatusEnum | null | undefined;
  protected readonly StatusEnum = StatusEnum;

  getIconName(): string {
    switch (this.status) {
    case StatusEnum.Draft:
      return 'edit-outline';
    case StatusEnum.Todo:
      return 'radio-button-on-outline';
    case StatusEnum.InProgress:
      return 'play-circle-outline';
    case StatusEnum.Finished:
      return 'checkmark-outline';
    case StatusEnum.Declined:
      return 'slash-outline';
    default:
      return '';
    }
  }
}
