import { Component, inject, signal, ViewChild } from '@angular/core';
import { MaintenanceTimerFilterDto } from '@artemis-software/wr-api';
import { NbDialogService } from '@nebular/theme';
import {
  MaintenanceTimerDialogComponent,
} from '@pages/maintenance-timer-dashboard-page/maintenance-timer-dialog/maintenance-timer-dialog.component';
import {
  MaintenanceTimerDashboardListComponent,
} from '@pages/maintenance-timer-dashboard-page/maintenance-timer-dashboard-list/maintenance-timer-dashboard-list.component';

@Component({
  selector: 'wr-maintenance-timer-dashboard-page',
  templateUrl: './maintenance-timer-dashboard-page.component.html',
  styleUrls: ['./maintenance-timer-dashboard-page.component.scss'],
})
export class MaintenanceTimerDashboardPageComponent {

  readonly dialogService = inject(NbDialogService);
  readonly filter = signal<MaintenanceTimerFilterDto>({});

  @ViewChild(MaintenanceTimerDashboardListComponent)
  maintenanceTimerDashboardListComponent!: MaintenanceTimerDashboardListComponent;

  addMaintenanceTimer(buildingId?: string) {
    const ref = this.dialogService.open(MaintenanceTimerDialogComponent, {
      context: {
        buildingId,
      },
    });
    ref.componentRef.instance.save.subscribe(() => {
      this.maintenanceTimerDashboardListComponent.refresh();
    });
  }
}
