import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { BuildingDetailDto, BuildingService } from '@artemis-software/wr-api';

@Injectable({ providedIn: 'root' })
export class BuildingResolverService {

  private buildings = new Map<string, Observable<BuildingDetailDto>>();

  constructor(
    private readonly buildingService: BuildingService,
  ) {
  }

  getBuilding(buildingId: string): Observable<BuildingDetailDto> {
    if (!this.buildings.has(buildingId)) {
      const observable = this.buildingService.findById(buildingId).pipe(shareReplay(1));
      this.buildings.set(buildingId, observable);
    }
    return this.buildings.get(buildingId) as Observable<BuildingDetailDto>;
  }

}
