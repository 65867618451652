import { EmailFilterDto } from '@artemis-software/wr-api';

export class LoadAllEmails {
  static readonly type = '[Email] Load All Emails';
}

export class FilterEmails {
  static readonly type = '[Email] Filter Emails';
}

export class CountEmails {
  static readonly type = '[Email] Count emails';
}

export class ChangeEmailFilter {
  static readonly type = '[Email] Change Email Filter';

  constructor(public filter: EmailFilterDto) {
  }
}
