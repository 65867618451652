import { Component, computed, inject } from '@angular/core';
import { InspectionItemDto, InspectionService, SortDto } from '@artemis-software/wr-api';
import { loader } from '@/utils/signals/loader';
import { Tracked } from '@/utils/signals/tracked';
import { asyncComputed } from '@/utils/signals/asyncComputed';

@Component({
  selector: 'wr-cooperative-dashboard-inspection-overview',
  templateUrl: './cooperative-dashboard-inspection-overview.component.html',
  styleUrls: ['./cooperative-dashboard-inspection-overview.component.scss'],
})
export class CooperativeDashboardInspectionOverviewComponent {

  inspectionService = inject(InspectionService);

  isLoading = loader();

  @Tracked() page = 0;
  @Tracked() pageSize = 10;
  @Tracked() sort: SortDto = { sort: 'fromTimestamp', order: 'DESC' };
  @Tracked() status = InspectionItemDto.StatusEnum.None;

  readonly getInspectionLink = (inspection: InspectionItemDto) => (['inspection', inspection.id]);

  results = asyncComputed(() => {
    return this.inspectionService.getCompletedForOrganisation({
      page: this.page,
      size: this.pageSize,
      sort: [`${this.sort.sort},${this.sort.order.toLowerCase()}`],
    }, this.status);
  }, undefined, this.isLoading);

  inspections = computed(() => this.results()?.content ?? []);

  inspectionCount = computed(() => this.results()?.numberOfElements ?? 0);

}
