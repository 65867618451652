import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NbDateService, NbDialogService, NbToastrService } from '@nebular/theme';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadAllOrganisations } from '@/store/organisation/organisation.action';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import {
  AddressMergeDto,
  AddressService,
  OrganisationDetailDto,
  OrganisationMergeDto,
  OrganisationService,
} from '@artemis-software/wr-api';
import { PendingChanges } from '@guards/pending-changes.guard';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'wr-organisation-detail-page',
  templateUrl: './organisation-detail-page.component.html',
  styleUrls: ['./organisation-detail-page.component.scss'],
})
export class OrganisationDetailPageComponent implements PendingChanges {
  isEditing = false;
  firstSubmit = false;
  form?: FormGroup;
  readonly dateTimeFormat = 'yyyy-MM-ddTHH:mm:ss.SSSZ';
  readonly dateFormat = 'yyyy-MM-dd';

  saving$ = new BehaviorSubject(false);

  constructor(private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router,
    private readonly dialogService: NbDialogService,
    private readonly organisationService: OrganisationService,
    private readonly addressService: AddressService,
    private readonly dateService: NbDateService<Date>,
    private readonly toastrService: NbToastrService) {
    route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        if (id === 'new') {
          this.isEditing = true;
          this.initForm();
        } else {
          this.organisationService.findById(id).subscribe((organisation) => {
            this.initForm(organisation);
            this.form?.disable();
          });
        }
      } else {
        this.router.navigate(['organisations']);
      }
    });
  }

  initForm(dto?: OrganisationDetailDto): void {
    let contractDate = undefined;
    if (dto?.contractDate) {
      contractDate = this.dateService.parse(dto?.contractDate, this.dateTimeFormat);
    }

    this.form = new FormGroup({
      id: new FormControl(dto?.id),
      name: new FormControl(dto?.name, [Validators.required]),
      contractDateText: new FormControl(contractDate),
      contractDate: new FormControl(undefined),
      paymentAddressId: new FormControl(dto?.paymentAddress?.id),
      street: new FormControl(dto?.paymentAddress?.street, [Validators.required]),
      zipCode: new FormControl(dto?.paymentAddress?.zipCode, [Validators.required]),
      city: new FormControl(dto?.paymentAddress?.city, [Validators.required]),
      state: new FormControl(dto?.paymentAddress?.state, [Validators.required]),
      organisationType: new FormControl(dto?.organisationType, [Validators.required]),
      country: new FormControl({ value: 'AT', disabled: true }),
      license: new FormGroup({
        maxUsers: new FormControl(dto?.license?.maxUsers ?? 0, [Validators.min(0), Validators.required]),
      }),
    });

  }

  hasError(controlName: string, errorName = 'required'): boolean {
    const control = this.form?.get(controlName);
    return this.isEditing && this.firstSubmit && (control?.hasError(errorName) ?? false) && ((control?.touched ?? false) || (control?.dirty ?? false));
  }

  onEdit(): void {
    this.isEditing = true;
    this.form?.enable();
    this.form?.get('country')?.disable();
  }

  async submit(): Promise<void> {
    this.firstSubmit = true;
    this.form?.markAsTouched();

    if (!this.form?.valid) {
      this.toastrService.danger('Genossenschaft kann nicht gespeichert werden, es gibt noch Fehler.', 'Genossenschaft');
      return;
    }

    try {
      this.saving$.next(true);
      const addressDto: AddressMergeDto = {
        id: this.form.get('paymentAddressId')?.value,
        street: this.form.get('street')?.value,
        zipCode: this.form.get('zipCode')?.value,
        city: this.form.get('city')?.value,
        state: this.form.get('state')?.value,
        country: this.form.get('country')?.value,
      };
      const address = await firstValueFrom(this.addressService.merge(addressDto));

      this.form?.patchValue({ paymentAddressId: address.id });
      const contractDate = this.form?.get('contractDateText')?.value;
      if (contractDate) {
        this.form?.controls['contractDate'].setValue(this.dateService.format(contractDate, this.dateFormat));
      }

      const organisationDto = this.form?.value as OrganisationMergeDto;
      const organisation = await firstValueFrom(this.organisationService.merge(organisationDto));

      this.isEditing = false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['organisation', organisation.id]).then(() => {
          this.store.dispatch(new LoadAllOrganisations());
          this.toastrService.success('Genossenschaft wurde erfolgreich gespeichert', 'Genossenschaft');
        });
      });
    } catch (e) {
      console.error(e);
      this.toastrService.danger('Genossenschaft konnte nicht gespeichert werden.', 'Fehler');
    } finally {
      this.saving$.next(false);
    }
  }

  onDelete(): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Genossenschaft löschen',
        message: 'Bist du sicher, dass du die Genossenschaft löschen willst?',
      },
    }).onClose.subscribe({
      next: (result) => {
        if (result) {
          this.addressService.deleteById(this.form?.value.paymentAddressId).subscribe(() => {
            this.organisationService.deleteById(this.form?.value.id).subscribe(() => {
              this.store.dispatch(new LoadAllOrganisations());
              this.router.navigate(['organisations']);
              this.toastrService.success('Genossenschaft wurde erfolgreich gelöscht', 'Genossenschaft');
            });
          });
        }
      }, error: () => {
        this.toastrService.danger('Beim Löschen der Genossenschaft ist ein Fehler aufgetreten', 'Genossenschaft');
      },
    });
  }

  async cancel(): Promise<void> {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['organisation', this.form?.value.id]);
    });
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['organisations']);
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.isEditing;
  }

  protected readonly FormGroup = FormGroup;
}
