<div class="flex-container">
  <input readonly fullWidth type="text" nbInput [disabled]="isDisabled" [value]="value"/>
  <button nbButton status="info" nbTooltip="Neuen Pin generieren" nbTooltipStatus="info" (click)="generateNewPin()">
    <nb-icon icon="rotate-right" pack="fas"></nb-icon>
  </button>
  <button nbButton status="info" nbTooltip="Pin kopieren" nbTooltipStatus="info" (click)="copyToClipboard()">
    <nb-icon icon="clipboard" pack="fas"></nb-icon>
  </button>
</div>

