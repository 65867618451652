import { NgModule } from '@angular/core';
import {
  NbAccordionModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbListModule, NbMenuModule,
  NbPopoverModule,
  NbRouteTabsetModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbTagModule,
  NbTimepickerModule,
  NbToggleModule,
  NbTooltipModule,
  NbUserModule,
  NbRadioModule,
} from '@nebular/theme';

const nbModules = [
  NbCardModule,
  NbIconModule,
  NbButtonModule,
  NbFormFieldModule,
  NbInputModule,
  NbListModule,
  NbUserModule,
  NbTagModule,
  NbSelectModule,
  NbToggleModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbCalendarRangeModule,
  NbDatepickerModule,
  NbAccordionModule,
  NbTabsetModule,
  NbFormFieldModule,
  NbSpinnerModule,
  NbAutocompleteModule,
  NbAlertModule,
  NbButtonGroupModule,
  NbTooltipModule,
  NbContextMenuModule,
  NbRouteTabsetModule,
  NbPopoverModule,
  NbMenuModule,
  NbRadioModule,
];

@NgModule({
  imports: nbModules,
  exports: nbModules,
})
export class NebularModule {
}
