import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { CheckpointState } from '@/store/checkpoint/checkpoint.state';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CheckpointGroupDetailDto, CheckpointItemDto } from '@artemis-software/wr-api';
import { ChangeCheckpointFilter, FilterCheckpoints } from '@/store/checkpoint/checkpoint.actions';
import { CheckpointGroupState } from '@/store/checkpointGroup/checkpointGroup.state';

@Component({
  selector: 'wr-checkpoint-dialog',
  templateUrl: './checkpoint-dialog.component.html',
  styleUrls: ['./checkpoint-dialog.component.scss'],
})
export class CheckpointDialogComponent implements OnInit, OnDestroy {

  @Select(CheckpointState.filteredCheckpoints)
  checkpoints$!: Observable<CheckpointItemDto[]>;

  @Select(CheckpointGroupState.checkpointGroups)
  checkpointGroups$!: Observable<CheckpointGroupDetailDto[]>;

  @Input()
  set selectedCheckpointGroupId(value: string | undefined) {
    this._selectedCheckpointGroupId = value;
    this.store.dispatch(new ChangeCheckpointFilter(
      {
        checkpointGroupId: this.selectedCheckpointGroupId,
        deprecated: false,
      }));
  }

  get selectedCheckpointGroupId(): string | undefined {
    return this._selectedCheckpointGroupId;
  }

  selectedCheckpointId?: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  onSubmit = () => this.selectedCheckpointId;


  private _selectedCheckpointGroupId?: string;

  constructor(
    private readonly dialogRef: NbDialogRef<void>,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.checkpoints$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      (checkpoints) => {
        this.selectedCheckpointId = checkpoints[0]?.id ?? '';
        this.cdr.detectChanges();
      },
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
