import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CustomFieldCollectionDetailDto,
  CustomFieldCollectionMemberItemDto,
  CustomFieldCollectionValueDto,
  CustomFieldCollectionValueMergeDto,
  CustomFieldItemDto,
  ValueDto,
} from '@artemis-software/wr-api';
import { CustomFieldValueMergeDto } from '@artemis-software/wr-api/model/customFieldValueMergeDto';
import { ArrayRequiredValidator } from '@/utils/validators';

export type CustomFieldFormElement = {
  formControl: FormControl | FormArray;
  field: CustomFieldItemDto;
  required: boolean;
}

type UnwrapValue = keyof ValueDto;

class CustomForm {
  collection: CustomFieldCollectionDetailDto;
  form: FormGroup;
  elements: CustomFieldFormElement[];

  constructor(collection: CustomFieldCollectionDetailDto) {
    this.collection = collection;
    this.elements = collection.members.map(member => this.createElement(member));
    this.form = new FormGroup({});
    this.elements.forEach(element => {
      const controlKey = element.field.id;
      this.form.addControl(controlKey, element.formControl);
    });
  }

  createElement(member: CustomFieldCollectionMemberItemDto): CustomFieldFormElement {
    const field = member.field;

    let control;
    if (field.elementType === CustomFieldItemDto.ElementTypeEnum.Multiple) {
      const validators = member.required ? [ArrayRequiredValidator.createValidator()] : null;
      control = new FormArray([new FormControl()], validators);
    } else {
      const validators = member.required ? [Validators.required] : [];
      control = new FormControl(null, validators);
    }

    return {
      formControl: control,
      field: field,
      required: member.required ?? false,
    };
  }
}

export const unwrapDtoValue = (dto: ValueDto): UnwrapValue | null => {
  const nonNullValues = Object.values(dto).filter(v => v !== null);
  return nonNullValues.length > 0 ? nonNullValues[0] : null;
};

export const unwrapDtoValues = (dtos: ValueDto[], type: CustomFieldItemDto.ElementTypeEnum) => {
  if (type === CustomFieldItemDto.ElementTypeEnum.Multiple) {
    return dtos.map(dto => unwrapDtoValue(dto)) as UnwrapValue[];
  }
  if (dtos.length > 0) {
    if (dtos.length > 1) {
      throw new Error(`Expected 0 or 1 values but got ${dtos.length}`);
    }
    return dtos.map(dto => unwrapDtoValue(dto))[0] as UnwrapValue;
  }
  return null;
};

const wrapDtoValue = (value: unknown, type: CustomFieldItemDto.ValueTypeEnum): ValueDto => {
  switch (type) {
  case 'Boolean':
    return { booleanValue: value as boolean };
  case 'Integer':
    return { intValue: value as number };
  case 'Double':
    return { doubleValue: value as number };
  case 'String':
    return { stringValue: value as string };
  case 'Longtext':
    return { longtextValue: value as string };
  }
  throw new Error(`Unknown type ${type}`);
};

@Component({
  selector: 'wr-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss'],
})
export class CustomFormComponent {

  @Input()
  set collection(collection: CustomFieldCollectionDetailDto) {
    this.customForm = new CustomForm(collection);
    this.readonly = this._readonly;
    this.updateValues(this._values);
  }

  @Input()
  set readonly(value: boolean) {
    this._readonly = value;
    if (this.customForm) {
      if (value) {
        this.customForm.form.disable();
      } else {
        this.customForm.form.enable();
      }
    }
  }

  @Input()
  set values(values: CustomFieldCollectionValueDto | undefined) {
    this._values = values;
    this.updateValues(values);
  }

  get readonly(): boolean {
    return this._readonly;
  }

  private _readonly = false;
  private _values: CustomFieldCollectionValueDto | undefined;

  customForm?: CustomForm;

  updateValues(values?: CustomFieldCollectionValueDto) {
    if (this.customForm && values && !this.customForm.form.dirty) {
      values.fields?.forEach(field => {
        const controlKey = field.field.id;
        const control = this.customForm?.form.get(controlKey);
        if (control) {
          const value = unwrapDtoValues(field.values ?? [], field.field.elementType);
          if (control instanceof FormArray) {
            console.assert(Array.isArray(value), 'Expected value to be an array');
            control.clear();
            (value as UnwrapValue[] | null)?.forEach(v => control.push(new FormControl(v)));
          } else {
            control.setValue(value);
          }
        }
      });
    }
  }

  getMergeDto(): CustomFieldCollectionValueMergeDto {
    if (!this.customForm) throw new Error('customForm is undefined');
    return {
      collectionId: this.customForm.collection.id,
      fields: this.customForm.elements.map(element => {
        const controlKey = element.field.id;
        const control = this.customForm?.form.get(controlKey);
        const value = control?.value;
        const values = Array.isArray(value) ? value : [value];

        return {
          fieldId: element.field.id,
          values: values.map((value: unknown) => wrapDtoValue(value, element.field.valueType)),
        } as CustomFieldValueMergeDto;
      }),
    } as CustomFieldCollectionValueMergeDto;
  }

  markAsPristine() {
    this.customForm?.form.markAsPristine();
  }
}
