import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { BuildingService } from '@artemis-software/wr-api';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { BuildingState } from '@/store/building/building.state';
import { CooperativeStatisticsFilter } from '@pages/cooperative-statistics-page/cooperative-statistics-page.component';
import { select } from '@/utils/signals/select';
import { Tracked } from '@/utils/signals/tracked';
import { loader } from '@/utils/signals/loader';

@Component({
  selector: 'wr-cooperative-statistics-charts',
  templateUrl: './cooperative-statistics-charts.component.html',
  styleUrls: ['./cooperative-statistics-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CooperativeStatisticsChartsComponent {

  private readonly buildingService = inject(BuildingService);

  @Input()
  @Tracked()
  filter?: CooperativeStatisticsFilter;

  readonly loading = loader();

  readonly buildingCount = select(BuildingState.count);

  readonly totalArea = asyncComputed(() => {
    const organisationId = this.filter?.cooperativeId;
    return organisationId ? this.buildingService.getTotalBuildingAreaByOrganisation(organisationId) : 0;
  }, 0, this.loading);
}
