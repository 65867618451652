import { Component, forwardRef, Input } from '@angular/core';
import { NotificationDto } from "@artemis-software/wr-api";
import { AbstractFormControl } from "@shared/form-controls/abstract-form-control";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'wr-notification-variant-select',
  templateUrl: './notification-variant-select.component.html',
  styleUrls: ['./notification-variant-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NotificationVariantSelectComponent),
      multi: true,
    },
  ],
})
export class NotificationVariantSelectComponent extends AbstractFormControl<NotificationDto.VariantEnum>{
  readonly NotificationVariant = NotificationDto.VariantEnum;

  @Input()
  showEmptyOption = false;
}
