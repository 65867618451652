import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InspectionEntryGroup } from '../inspection-entries.component';
import { InspectionEntryFormType } from '@pages/inspection-detail-page-v2/inspection-util';

@Component({
  selector: 'wr-inspection-entry-group-list[groups]',
  templateUrl: './inspection-entry-group-list.component.html',
  styleUrls: ['./inspection-entry-group-list.component.scss'],
})
export class InspectionEntryGroupListComponent {

  @Input()
  groups: InspectionEntryGroup[] = [];

  @Input()
  selectedGroupId: string | undefined;
  @Input()
  disabled = false;

  @Input()
  selectedEntry: InspectionEntryFormType | undefined | null;
  @Output()
  selectedEntry$ = new EventEmitter<InspectionEntryFormType>();
  @Output()
  selectedGroupIdChange = new EventEmitter<string | undefined>();
  @Output()
  deleteGroup = new EventEmitter<InspectionEntryGroup>();

  collapseChange(isCollapse: boolean, group: InspectionEntryGroup) {
    if (!isCollapse) {
      this.selectedGroupIdChange.next(group.checkpointGroupId);
    }
  }

  deleteGroupClick(event: MouseEvent, group: InspectionEntryGroup): void {
    event.stopPropagation();
    this.deleteGroup.emit(group);
  }
}
