<wr-base-dialog [submitResponse]="submitAddress.bind(this)" [header]="title">
  <form *ngIf="form" [formGroup]="form">
    <div class="building-section-item">
      <div class="building-section-row">
        <div class="street-col">
          <label class="label" for="street">Straße</label>
          <input nbInput
                 fullWidth
                 id="street"
                 type="text"
                 formControlName="street"
                 placeholder="Straße">
          <div class="error" *ngIf="hasError('street')">Straße ist erforderlich!</div>
        </div>
      </div>
      <div class="building-section-row">
        <div class="number-col" formArrayName="houseNumbers">
          <div class="number-item" [formGroup]="$any(form.get('houseNumbers'))">
            <nb-form-field>
              <input nbInput
                     fullWidth
                     type="text"
                     placeholder="Nr"
                     formControlName="number">
            </nb-form-field>
          </div>
        </div>
      </div>
      <wr-automatic-address
        [formGroup]="$any(form.get('automaticAddress'))"></wr-automatic-address>
    </div>
  </form>
</wr-base-dialog>
