<nb-select fullWidth [(selected)]="value" [disabled]="isDisabled">
  <nb-option [value]="''">Kein Tag</nb-option>
  <nb-option *ngFor="let tag of tags$ | async" [value]="tag">
    <div class="icon-text-container">
      <nb-icon class="fixed-icon"
               icon="pricetags-outline"
               pack="eva"
               [style.color]="tag.color"></nb-icon>
      <div class="text-content">{{tag.name}}</div>
    </div>
  </nb-option>
</nb-select>
