import { Component, inject } from '@angular/core';
import { InspectionItemDto } from '@artemis-software/wr-api';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { defineStoreUsage } from '@/utils/storeUsage';
import { Observable } from 'rxjs';
import { ChangeInspectionFilter } from '@/store/inspection/inspection.action';
import { InspectionState } from '@/store/inspection/inspection.state';

@Component({
  selector: 'wr-cooperative-statistics-inspection-overview',
  templateUrl: './cooperative-statistics-inspection-overview.component.html',
  styleUrls: ['./cooperative-statistics-inspection-overview.component.scss'],
})
export class CooperativeStatisticsInspectionOverviewComponent {

  router = inject(Router);
  store = inject(Store);

  inspectionStoreUsage = defineStoreUsage({
    filterAction: ChangeInspectionFilter,
    countSelector: InspectionState.count,
    loadingSelector: InspectionState.inspectionsLoading,
  });

  @Select(InspectionState.filteredInspections)
  inspections$!: Observable<InspectionItemDto[]>;
  @Select(InspectionState.count)
  count$!: Observable<number>;
  @Select(InspectionState.inspectionsLoading)
  loading$!: Observable<boolean>;
}
