<div *ngIf="template?.templateRef as templateRef"  class="multi-control">
  <div *ngFor="let control of formArray.controls; let i = index" class="control-row">
    <div class="control-content">
      <ng-container [ngTemplateOutlet]="templateRef"
                    [ngTemplateOutletContext]="{control:control}"></ng-container>
    </div>
    <button nbButton type="button" (click)="removeAt(i)" *ngIf="formArray?.enabled">
      <nb-icon icon="close-outline" pack="eva"></nb-icon>
    </button>
  </div>
</div>
<button nbButton type="button" ghost status="primary" (click)="add()" *ngIf="formArray?.enabled">
  <nb-icon icon="plus-outline" pack="eva"></nb-icon>
  <span>HINZUFÜGEN</span>
</button>
