import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NbComponentStatus } from '@nebular/theme';
import { useSettings } from '@/composables/useSettings';

type ConditionCategoryWithColor = {
  color: NbComponentStatus,
  tooltip: string,
};

const toPercent = (num: number) => `${(Math.round(num * 100))}%`;

@Component({
  selector: 'wr-building-condition',
  templateUrl: './building-condition.component.html',
  styleUrls: ['./building-condition.component.scss'],
})
export class BuildingConditionComponent {

  @Input()
  condition = 0.0;

  @Input()
  @HostBinding('style.fontSize')
  fontSize = '1rem';

  settings = useSettings();

  get conditionCategory(): ConditionCategoryWithColor | undefined {
    const thresholds = this.settings()?.conditionThresholds;
    if (thresholds === undefined) {
      return undefined;
    }
    if (this.condition > thresholds.majorThreshold) {
      return { color: 'success', tooltip: `Guter Zustand ( >${toPercent(thresholds.majorThreshold)} )` };
    }
    if (this.condition > thresholds.minorThreshold) {
      return { color: 'warning', tooltip: `Mittlerer Zustand ( >${toPercent(thresholds.minorThreshold)} )` };
    }
    return { color: 'danger', tooltip: `Schlechter Zustand ( <${toPercent(thresholds.minorThreshold)} )` };
  }
}
