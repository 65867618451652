<nb-card>
  <nb-card-header>
    <div class="header">
      <span>Wartungstimer</span>
      <button nbButton status="primary" (click)="addMaintenanceTimer.next()">
        <nb-icon icon="plus-outline" pack="eva"></nb-icon>
      </button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">
      <div *ngIf="isAdmin()">
        <label class="label">Genossenschaft</label>
        <wr-organisation-select formControlName="organisationId" [showEmptyOption]="true"></wr-organisation-select>
      </div>
      <div>
        <label class="label">Gebäude</label>
        <wr-building-select formControlName="building" [isPropertyManager]="isPropertyManager()" [fixedOrganisationId]="isOrganisationUser()"></wr-building-select>
      </div>
      <div>
        <label class="label">TGA Typ</label>
        <wr-equipment-type-select formControlName="equipmentType" [showEmptyOption]="true"></wr-equipment-type-select>
      </div>
      <div>
        <label class="label">Zeitraum</label>
        <wr-date-range-picker formControlName="dateRange"></wr-date-range-picker>
      </div>
      <div>
        <label class="label">Abgelaufen</label>
        <div>
          <nb-checkbox formControlName="expired">Abgelaufen</nb-checkbox>
        </div>
        <div>
          <nb-checkbox formControlName="notExpired">Nicht abgelaufen</nb-checkbox>
        </div>
        <input formControlName="maxDueDays"
               nbInput
               type="number"
               fullWidth
               placeholder="läuft in weniger als X Tagen ab">
      </div>
    </form>
  </nb-card-body>
</nb-card>
