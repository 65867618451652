<div class="grouped-checkbox-list">
  <div class="checkbox-group" *ngFor="let group of groups">
    <div class="group-header">
      <nb-checkbox [checked]="group.checked"
                   (checkedChange)="groupChecked(group, $event)"
                   [indeterminate]="group.indeterminate">
        {{group.name}}
      </nb-checkbox>
      <button nbButton ghost (click)="toggleCollapse(group)">
        <nb-icon icon="chevron-up-outline" pack="eva" *ngIf="group.collapsed"></nb-icon>
        <nb-icon icon="chevron-down-outline" pack="eva" *ngIf="!group.collapsed"></nb-icon>
      </button>
    </div>
    <ng-container *ngIf="group.collapsed">
      <div class="checkbox-item" *ngFor="let item of group.items">
        <nb-checkbox [checked]="item.checked"
                     (checkedChange)="itemChecked(group,item, $event)">
          <nb-icon *ngIf="item.icon as icon" [pack]="icon.pack"
                   [icon]="icon.name" [status]="icon.status" [nbTooltip]="icon.tooltip"
                   [nbTooltipStatus]="icon.status"></nb-icon>
          {{item.name}}
        </nb-checkbox>
      </div>
    </ng-container>
  </div>
</div>
