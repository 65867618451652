import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import Sortable from 'sortablejs';

const defaultOptions: Sortable.Options = {
  animation: 150,
  sort: true,
};

@Directive({
  selector: '[sortableArray]',
})
export class SortableArrayDirective<T> implements OnDestroy {

  private _sortableArray?: Array<T>;
  private _sortableOptions?: Sortable.Options;

  @Input()
  set sortableArray(value: Array<T> | undefined) {
    if (this._sortableArray !== value) {
      this._sortableArray = value;
      this.refresh();
    }
  }

  @Input()
  set sortableOptions(value: Sortable.Options | undefined) {
    if (JSON.stringify(this._sortableOptions) !== JSON.stringify(value)) {
      this._sortableOptions = value;
      this.refresh();
    }
  }

  @Output()
  sortableChange = new EventEmitter<Array<T>>;

  private _sortable?: Sortable;

  constructor(
    private readonly elementRef: ElementRef,
  ) {
  }

  ngOnDestroy(): void {
    this._sortable?.destroy();
    this._sortable = undefined;
  }

  refresh(): void {
    if (!this._sortableArray || !this._sortableOptions) {
      return;
    }

    const combinedOptions = {
      ...defaultOptions,
      onSort: (e: Sortable.SortableEvent) => this.orderArray(this._sortableArray!, e.oldIndex, e.newIndex),
      ...this._sortableOptions!,
    };
    if (this._sortable) {
      this._sortable.destroy();
      this._sortable = undefined;
    }
    const element = this.elementRef.nativeElement;
    this._sortable = Sortable.create(element, combinedOptions);
    element.style.cursor = this._sortableOptions.disabled ? 'default' : 'grab';
  }

  orderArray(array: Array<T>, oldIndex: number | undefined, newIndex: number | undefined): void {
    if (oldIndex === undefined || newIndex === undefined) {
      return;
    }
    const item = array[oldIndex];
    array.splice(oldIndex, 1);
    array.splice(newIndex, 0, item);
    this.sortableChange.emit(array);
  }

}
