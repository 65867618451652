import { Component, forwardRef, Input } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { OrganisationItemDto } from '@artemis-software/wr-api';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OrganisationState } from '@/store/organisation/organisation.state';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-organisation-select',
  templateUrl: './organisation-select.component.html',
  styleUrls: ['./organisation-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganisationSelectComponent),
      multi: true,
    },
  ],
})
export class OrganisationSelectComponent extends AbstractFormControl<string> {

  @Select(OrganisationState.organisations)
  organisations$!: Observable<OrganisationItemDto[]>;

  @Input()
  showEmptyOption = false;

  @Input()
  filterIds: string[] | undefined;

  @Input()
  placeholder = 'Organisation auswählen';

  getFilteredOrganisations(organisations: OrganisationItemDto[] | null): OrganisationItemDto[] {
    if (!organisations) {
      return [];
    }
    return organisations.filter((organisation) => {
      if (!this.filterIds) {
        return true;
      }
      return this.filterIds.includes(organisation.id);
    });
  }
}
