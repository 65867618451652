import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  BuildingEquipmentFilterDto,
  BuildingEquipmentItemDto,
  BuildingEquipmentService,
} from '@artemis-software/wr-api';
import {
  ChangeBuildingEquipmentFilter,
  CountBuildingEquipments,
  FilterBuildingEquipments,
  LoadAllBuildingEquipments,
} from './buildingEquipment.action';
import { AuthService } from '@services/auth.service';

type BuildingEquipmentStateModel = {
  buildingEquipments: BuildingEquipmentItemDto[],
  filteredBuildingEquipments: BuildingEquipmentItemDto[],
  count: number,
  loading: boolean,
  filter: BuildingEquipmentFilterDto,
}

@State<BuildingEquipmentStateModel>({
  name: 'buildingBuildingEquipments',
  defaults: {
    buildingEquipments: [],
    filteredBuildingEquipments: [],
    count: 0,
    loading: false,
    filter: {} as BuildingEquipmentFilterDto,
  },
})

@Injectable()
export class BuildingEquipmentState implements NgxsOnInit {

  constructor(private readonly store: Store,
    private readonly buildingEquipmentService: BuildingEquipmentService,
    private readonly authService: AuthService) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllBuildingEquipments());
    });
  }

  @Selector()
  static buildingEquipments(state: BuildingEquipmentStateModel): BuildingEquipmentItemDto[] {
    return state.buildingEquipments;
  }

  @Selector()
  static filteredBuildingEquipments(state: BuildingEquipmentStateModel): BuildingEquipmentItemDto[] {
    return state.filteredBuildingEquipments;
  }

  @Selector()
  static buildingEquipmentsLoading(state: BuildingEquipmentStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: BuildingEquipmentStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: BuildingEquipmentStateModel): BuildingEquipmentFilterDto {
    return state.filter;
  }

  @Action(LoadAllBuildingEquipments)
  loadAllBuildingEquipments(ctx: StateContext<BuildingEquipmentStateModel>): void {
    ctx.setState({
      ...ctx.getState(),
      loading: true,
    });

    this.buildingEquipmentService.findAll({}).subscribe((buildingEquipments: BuildingEquipmentItemDto[]) => {
      ctx.setState({
        ...ctx.getState(),
        buildingEquipments,
        loading: false,
      });
    });
  }

  @Action(FilterBuildingEquipments)
  filterBuildingEquipments(ctx: StateContext<BuildingEquipmentStateModel>): void {
    ctx.setState({
      ...ctx.getState(),
      loading: true,
    });

    const newFilter = this.createFilter(ctx.getState().filter);
    this.buildingEquipmentService.findAll(newFilter).subscribe((buildingEquipments: BuildingEquipmentItemDto[]) => {
      ctx.setState({
        ...ctx.getState(),
        filteredBuildingEquipments: buildingEquipments,
        loading: false,
      });
    });
  }

  @Action(CountBuildingEquipments)
  countBuildingEquipments(ctx: StateContext<BuildingEquipmentStateModel>): void {
    ctx.setState({
      ...ctx.getState(),
      loading: true,
    });

    const newFilter = this.createFilter(ctx.getState().filter);
    this.buildingEquipmentService.getCount(newFilter).subscribe((count: number) => {
      ctx.setState({
        ...ctx.getState(),
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeBuildingEquipmentFilter)
  changeBuildingEquipmentFilter(ctx: StateContext<BuildingEquipmentStateModel>, action: ChangeBuildingEquipmentFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountBuildingEquipments());
    this.store.dispatch(new FilterBuildingEquipments());
  }

  private createFilter(filter: BuildingEquipmentFilterDto) {
    return {
      text: filter.text,
      page: filter.page,
      size: filter.size,
      sort: filter.sort,
      buildingId: filter.buildingId && filter.buildingId.length > 0 ? filter.buildingId : undefined,
      organisationId: filter.organisationId && filter.organisationId.length > 0 ? filter.organisationId : undefined,
    };
  }
}
