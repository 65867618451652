import { Component, forwardRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { Options } from 'ngx-slider-v2';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThresholdDto } from '@artemis-software/wr-api';
import { GradientColors } from '@shared/form-controls/threshold-slider/gradient-colors';

type Threshold = { minorThreshold: number, majorThreshold: number }

@Component({
  selector: 'wr-threshold-slider',
  templateUrl: './threshold-slider.component.html',
  styleUrls: ['./threshold-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThresholdSliderComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ThresholdSliderComponent extends AbstractFormControl<ThresholdDto> {

  get optionsValue(): Options {
    return {
      floor: 0,
      ceil: 100,
      disabled: this.isDisabled,
      animate: false,
      translate: (value: number): string => `${value} %`,
    };
  }

  get minorValue(): number {
    if (this.value === undefined) {
      return 0;
    }
    return this.value.minorThreshold;
  }

  set minorValue(value: number) {
    this.value = {
      ...this.value ?? { minorThreshold: 0, majorThreshold: 0 },
      minorThreshold: value,
    };
    this.updateGradient();
  }

  get majorValue(): number {
    if (this.value === undefined) {
      return 0;
    }
    return this.value.majorThreshold;
  }

  set majorValue(value: number) {
    this.value = {
      ...this.value ?? { minorThreshold: 0, majorThreshold: 0 },
      majorThreshold: value,
    };
    this.updateGradient();
  }

  get minorValuePercent(): number {
    return this.minorValue * 100;
  }

  set minorValuePercent(value: number) {
    this.minorValue = value / 100;
  }

  get majorValuePercent(): number {
    return this.majorValue * 100;
  }

  set majorValuePercent(value: number) {
    this.majorValue = value / 100;
  }

  constructor(
    private readonly renderer: Renderer2,
  ) {
    super();
  }

  updateGradient(): void {
    const element = document.getElementsByClassName('ngx-slider-full-bar')[0].children[0];
    const successColor = GradientColors.SUCCESS_COLOR;
    const warningColor = GradientColors.WARNING_COLOR;
    const dangerColor = GradientColors.DANGER_COLOR;
    const smoothness = 1;
    const colorStops = [
      [dangerColor, 0],
      [dangerColor, this.minorValuePercent - smoothness],
      [warningColor, this.minorValuePercent + smoothness],
      [warningColor, this.majorValuePercent - smoothness],
      [successColor, this.majorValuePercent + smoothness],
      [successColor, 100],
    ];
    const gradient = `background: linear-gradient(to right, ${
      colorStops.map(([color, stop]) => `${color} ${stop}%`).join()
    })`;
    this.renderer.setAttribute(element, 'style', gradient);
  }

  override writeValue(value: Threshold | undefined) {
    super.writeValue(value);
    this.updateGradient();
  }
}
