<nb-select placeholder="Genossenschaft auswählen"
           multiple
           [(selected)]="value"
           [disabled]="isDisabled"
           [compareWith]="equalFn">
  <nb-option *ngFor="let organisation of organisations$ | async"
             [value]="{value: organisation.id}">
    {{organisation.name}}
  </nb-option>
  <nb-option *ngIf="showNoneOption" [value]="{}">Alle</nb-option>
</nb-select>
