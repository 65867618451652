import { Component } from '@angular/core';
import { asyncComputed } from "@/utils/signals/asyncComputed";
import { map, Observable } from "rxjs";
import { RepairTaskItemDto } from "@artemis-software/wr-api";
import { Select } from "@ngxs/store";
import { RepairTaskState } from "@/store/repairTask/repairTask.state";

@Component({
  selector: 'wr-repair-task-chart',
  templateUrl: './repair-task-chart.component.html',
})
export class RepairTaskChartComponent {

  @Select(RepairTaskState.filteredRepairTasks)
  repairTasks$!: Observable<RepairTaskItemDto[]>;

  readonly repairTaskStatusCount = asyncComputed(() => {
    return this.repairTasks$.pipe(
      map(repairTasks => {
        const repairTaskStatusCount = new Map<RepairTaskItemDto.StatusEnum, number>(
          Object.values(RepairTaskItemDto.StatusEnum).map(status => [status, 0])
        );
        repairTasks.forEach(repairTask => {
          const count = repairTaskStatusCount.get(repairTask.status) || 0;
          repairTaskStatusCount.set(repairTask.status, count + 1);
        });
        return repairTaskStatusCount;
      })
    );
  }, new Map<RepairTaskItemDto.StatusEnum, number>(
    Object.values(RepairTaskItemDto.StatusEnum).map(status => [status, 0])
  ));

  readonly repairTaskLabels = asyncComputed(() => {
    return Array.from(this.repairTaskStatusCount().keys()).map(status => this.getLabelForRepairTaskStatus(status));
  });

  readonly repairTaskData = asyncComputed(() => {
    const statusEnums = Object.values(RepairTaskItemDto.StatusEnum);
    const initialDataArray = new Array(statusEnums.length).fill(0);
    const statusArrays = new Map<RepairTaskItemDto.StatusEnum, number[]>(
      statusEnums.map(status => [status, [...initialDataArray]])
    );

    this.repairTaskStatusCount().forEach((count, status) => {
      const statusIndex = statusEnums.indexOf(status);
      if (statusIndex !== -1) {
        const dataArray = statusArrays.get(status);
        if (dataArray) {
          dataArray[statusIndex] = count;
        }
      }
    });

    return Array.from(statusArrays.values()).map((data, index) => {
      const status = statusEnums[index];
      const color = this.getColorForRepairTaskStatus(status);
      return {
        label: this.getLabelForRepairTaskStatus(status),
        data,
        borderColor: color,
        backgroundColor: color,
      };
    });
  }, []);

  private getLabelForRepairTaskStatus(status: RepairTaskItemDto.StatusEnum): string {
    const labels = {
      'DRAFT': 'Entwurf',
      'TODO': 'Offen',
      'IN_PROGRESS': 'In Bearbeitung',
      'FINISHED': 'Abgeschlossen',
      'DECLINED': 'Abgelehnt',
    };
    return labels[status];
  }

  private getColorForRepairTaskStatus(status: RepairTaskItemDto.StatusEnum): string {
    const colors = {
      'DRAFT': '#ffc94d',
      'TODO': '#0095ff',
      'IN_PROGRESS': '#3366ff',
      'FINISHED': '#00d68f',
      'DECLINED': '#ff3d71',
    };
    return colors[status];
  }
}
