<editor
  *ngIf="!isDisabled; else disabled"
  [(ngModel)]="value"
  [init]="initOptions"
  [plugins]="plugins"
  [toolbar]="toolbar"></editor>

<ng-template #disabled>
  <nb-card class="editor-disabled">
    <nb-card-body>
      <div [innerHTML]="(value ?? '') | richText"></div>
    </nb-card-body>
  </nb-card>
</ng-template>
