<div class="detail-container">
  <nb-card [nbSpinner]="!!(saving$|async)">
    <nb-card-header>
      <h5>Standardwerte</h5>
      <wr-edit-delete-button-group (edit)="onEdit()"></wr-edit-delete-button-group>
    </nb-card-header>
    <nb-card-body>
      <form *ngIf="form" [formGroup]="form">
        <div class="sub-card-body">
          <label class="label" for="conditionThresholds">Zustand Grenzwerte</label>
          <wr-threshold-slider id="conditionThresholds" formControlName="conditionThresholds"></wr-threshold-slider>
        </div>
      </form>
    </nb-card-body>
    <nb-card-footer>
      <button [disabled]="!isEditing" nbButton status="primary" (click)="submit()">Speichern</button>
    </nb-card-footer>
  </nb-card>
</div>
