<div class="pagination-container">
  <button nbButton status="primary" ghost (click)="resetPage()" [disabled]="page==0">
    <nb-icon pack="eva" icon="arrowhead-left-outline"></nb-icon>
  </button>
  <button nbButton status="primary" ghost (click)="previousPage()" [disabled]="page - 1 < 0">
    <nb-icon pack="eva" icon="chevron-left-outline"></nb-icon>
  </button>
  <span class="current-page">{{page + 1}} / {{this.maxPage + 1}}</span>
  <button nbButton status="primary" ghost (click)="nextPage()" [disabled]="page + 1 > maxPage">
    <nb-icon pack="eva" icon="chevron-right-outline"></nb-icon>
  </button>
  <button nbButton status="primary" ghost (click)="endPage()" [disabled]="page==maxPage">
    <nb-icon pack="eva" icon="arrowhead-right-outline"></nb-icon>
  </button>
</div>
