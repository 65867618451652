import { NbDateService } from '@nebular/theme';

const FORMAT_STRING = 'yyyy-MM-ddTHH:mm:ss.SSSZ';

export interface SelectedRangeDto {
  start: Date;
  end: Date;
}

export function createSelectedRange(filterValue: any, dateService: NbDateService<Date>): SelectedRangeDto | undefined {
  if (filterValue.selectedRange?.start) {
    let startDateTime = dateService.parse(filterValue.selectedRange.start, FORMAT_STRING);
    let endDateTime = dateService.parse(filterValue.selectedRange.end ?? filterValue.selectedRange.start, FORMAT_STRING);

    startDateTime = dateService.addHours(startDateTime, 1);
    endDateTime = dateService.addHours(endDateTime, 1);

    return {
      start: startDateTime,
      end: endDateTime,
    };
  }
  return undefined;
}
