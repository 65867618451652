import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { AssessmentPresetItemDto, CheckpointDetailDto, CheckpointService } from '@artemis-software/wr-api';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-assessment-preset-select',
  templateUrl: './assessment-preset-select.component.html',
  styleUrls: ['./assessment-preset-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssessmentPresetSelectComponent),
      multi: true,
    },
  ],
})
export class AssessmentPresetSelectComponent extends AbstractFormControl<CheckpointDetailDto> {

  private _checkpointId?: string;

  checkpoint?: CheckpointDetailDto;

  visible = true;

  @Input()
  set checkpointId(checkpointId: string) {
    if (this._checkpointId !== checkpointId) {
      this._checkpointId = checkpointId;
      this.checkpoint = undefined;
      this.cdr.detectChanges();
      this.checkpointService.findById(checkpointId).subscribe(checkpoint => {
        this.checkpoint = checkpoint;
        this.cdr.detectChanges();
      });
    }
  }

  readonly equals = (a?: AssessmentPresetItemDto, b?: AssessmentPresetItemDto): boolean => a?.id === b?.id;

  constructor(
    private readonly checkpointService: CheckpointService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  override writeValue(value: CheckpointDetailDto | undefined) {
    // this is a hack to prevent the select from showing the value of the previously selected item (seems like a nebular bug, this is the easiest fix)
    // the idea is to reset the nb-select component, so the previous value is lost
    // timeout is needed so the change detection is triggered in between
    // while the proper select is hidden, a placeholder select is displayed, so the user doesn't see the reset
    super.writeValue(value);
    this.visible = false;
    setTimeout(() => {
      this.visible = true;
    });
  }

  hasEquipment(value: AssessmentPresetItemDto) {
    return value.buildingEquipmentRelated;
  }

  hasSelectedEquipment() {
    return this._value?.buildingEquipmentRelated;
  }
}
