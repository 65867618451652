<wr-base-dialog status="primary" header="Share Link">
  <nb-alert *ngIf="(inspectionPdf$ | async)?.pdfShareLink?.id">
    <div class="info-alert">
      <nb-icon icon="info-outline" pack="eva"></nb-icon>
      <span>Beim neu generieren des Links wird der alte gelöscht!</span>
    </div>
  </nb-alert>
  <div [nbSpinner]="!!(loading$ | async)">
    <div class="flex-container">

      <div class="duration-container">
        <label for="duration-select">Dauer auswählen:</label>
        <nb-select id="duration-select" [(ngModel)]="selectedDuration">
          <nb-option *ngFor="let duration of durations" [value]="duration">{{duration}} Tage</nb-option>
        </nb-select>
      </div>

      <div>
        <button
          (click)="generateShareLink()"
          nbButton
          status="basic"
          nbTooltip="Generiere Share Link">
          GENERIERE SHARE LINK
        </button>
      </div>

    </div>

    <ng-container *ngIf="inspectionPdf$ | async as inspectionPdf">
      <div *ngIf="inspectionPdf.pdfShareLink?.id && inspectionPdf.pdfShareLink?.expiration">
        <div class="link-container">
          <div class="share-link">
            <label for="share-link">Share Link:</label>
            <input nbInput fullWidth id="share-link" type="text" [value]="shareLink" readonly>
          </div>
          <nb-button-group class="button-group">
            <button nbButton status="primary" (click)="copyToClipboard(shareLink)" nbTooltip="Link kopieren">
              <nb-icon pack="eva" icon="copy-outline"></nb-icon>
            </button>
            <button nbButton status="primary" (click)="openLink(shareLink)" nbTooltip="Link öffnen">
              <nb-icon pack="eva" icon="external-link-outline"></nb-icon>
            </button>
          </nb-button-group>
        </div>
        <div class="date-container">
          <p>Ablaufdatum: {{inspectionPdf.pdfShareLink?.expiration | date: 'dd.MM.yyyy HH:mm'}}</p>
        </div>
      </div>
    </ng-container>

  </div>
</wr-base-dialog>
