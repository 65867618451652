import { Component, computed, effect, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { createForm } from '@sonofabit/ng-core';
import { MaintenanceTimerDetailDto, MaintenanceTimerService } from '@artemis-software/wr-api';
import { Tracked } from '@/utils/signals/tracked';
import { Validators } from '@angular/forms';
import { watch } from '@/utils/signals/watch';
import { firstValueFrom } from 'rxjs';
import { NbDialogService, NbSpinnerService, NbToastrService } from '@nebular/theme';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { isAdmin } from '@/utils/admin-utils';
import { CheckDatesDialogComponent } from '@components/dialog/check-dates-dialog/check-dates-dialog.component';

@Component({
  selector: 'wr-maintenance-timer',
  templateUrl: './maintenance-timer.component.html',
  styleUrls: ['./maintenance-timer.component.scss'],
})
export class MaintenanceTimerComponent {

  readonly isAdmin = isAdmin();

  @Input()
  @Tracked()
  maintenanceTimer?: MaintenanceTimerDetailDto;

  @Input()
  @Tracked()
  buildingEquipmentId!: string;

  @Input()
  @Tracked()
  initialMaintenanceDays!: number;

  @Input()
  showCancelButton = true;

  @Output()
  editCancel = new EventEmitter<void>();

  @Output()
  update = new EventEmitter<MaintenanceTimerDetailDto>();

  @Output()
  delete = new EventEmitter<void>();

  TypeEnum = MaintenanceTimerDetailDto.TypeEnum;

  maintenanceTimerService = inject(MaintenanceTimerService);

  nbDialogService = inject(NbDialogService);

  spinnerService = inject(NbSpinnerService);

  nbToastrService = inject(NbToastrService);

  isLoading = signal(false);

  editMode = signal(false);

  isEditing = computed(() => this.editMode() || !this.maintenanceTimer);

  readonly daysLeft = computed(() => {
    if (!this.maintenanceTimer) return 0;
    const nextDate = new Date(this.maintenanceTimer.nextDate);
    const currentDate = new Date();
    const diffTime = nextDate.getTime() - currentDate.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  });

  form = computed(() => {
    return createForm(({ field }) => {
      return {
        id: field({
          value: this.maintenanceTimer?.id,
        }),
        buildingEquipmentId: field({
          value: this.buildingEquipmentId,
        }),
        type: field({
          value: this.maintenanceTimer?.type ?? MaintenanceTimerDetailDto.TypeEnum.Maintenance,
        }),
        startDate: field({
          value: this.maintenanceTimer ? new Date(this.maintenanceTimer.startDate) : new Date(),
          validators: [Validators.required],
          toDto: (value: Date) => value.toISOString(),
        }),
        durationDays: field({
          value: this.initialMaintenanceDays,
          validators: [Validators.min(1)],
        }),
        reminderDurationDays: field({
          value: this.maintenanceTimer?.reminderDurationDays ?? 0,
        }),
      };
    });
  });

  get nextDate() {
    const startDate = this.form().value.startDate;
    const durationDays = this.form().value.durationDays;

    if (startDate == undefined || durationDays === undefined) return;

    const nextDate = new Date(startDate.getTime());
    nextDate.setDate(nextDate.getDate() + durationDays);

    return nextDate;
  }

  get reminderDate() {
    const nextDate = this.nextDate;
    const reminderDurationDays = this.form().value.reminderDurationDays;

    if (nextDate == undefined || reminderDurationDays === undefined) return;

    const nextReminderDate = new Date(nextDate.getTime());
    nextReminderDate.setDate(nextReminderDate.getDate() - reminderDurationDays);

    return nextReminderDate;
  }

  constructor() {
    watch(() => this.maintenanceTimer, maintenanceTimer => {
      if (maintenanceTimer) {
        this.editMode.set(false);
      }
    });

    effect(() => {
      if (this.isEditing())
        this.form().enable();
      else
        this.form().disable();
    });
  }

  async save() {
    this.form().markAllAsTouched();
    if (this.form().invalid) return;
    this.isLoading.set(true);
    try {
      const dto = await this.form().getMappedValue();
      const entity = await firstValueFrom(this.maintenanceTimerService.merge(dto));
      this.update.emit(entity);
      this.nbToastrService.success('Wartungstimer wurde erfolgreich gespeichert.', 'Wartungstimer');
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading.set(false);
    }
  }

  cancelEditing() {
    this.editMode.set(false);
    this.editCancel.emit();
  }

  async deleteEntity() {
    const shouldDelete = await firstValueFrom(this.nbDialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Wartungstimer löschen',
        message: 'Bist du sicher, dass du den Wartungstimer löschen willst?',
      },
    }).onClose);
    if (shouldDelete) {
      const result = firstValueFrom(this.maintenanceTimerService.deleteById(this.maintenanceTimer!.id));
      this.spinnerService.registerLoader(result);
      await result;
      this.delete.emit();
      this.nbToastrService.success('Wartungstimer wurde erfolgreich gelöscht.', 'Wartungstimer');
    }
  }

  finishMaintenanceTimer() {
    if (!this.maintenanceTimer) return;
    this.maintenanceTimerService.finishMaintenanceTimer(this.maintenanceTimer.id).subscribe((entity) => {
      this.update.emit(entity);
      this.nbToastrService.success('Wartungstimer wurde erfolgreich abgeschlossen.', 'Wartungstimer');
    });
  }

  showCheckDates() {
    this.nbDialogService.open(CheckDatesDialogComponent, {
      context: {
        maintenanceTimerCheckDates: this.maintenanceTimer?.maintenanceTimerCheckDateDetailDto,
      },
    });
  }

  startEdit() {
    this.editMode.set(true);
  }
}
