import { firstValueFrom } from 'rxjs';
import { BuildingService, IdReferenceDto } from '@artemis-software/wr-api';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';

export class UniqueInternalNumberValidator {
  static createValidator(buildingService: BuildingService, buildingIdControl: AbstractControl): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      const internalNumber = control.value;
      const buildingId = buildingIdControl.value;
      if (!internalNumber) {
        return null;
      }
      const isUnique = await firstValueFrom(buildingService.isInternalNumberUnique({
        internalNumber,
        buildingId,
      }));
      if (!isUnique) {
        return { duplicateInternalNumber: true };
      }
      return null;
    };
  }
}

export class ArrayRequiredValidator {
  static createValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const array = control.value as Array<unknown>;
      if (!array?.length) {
        return { required: true };
      }
      return array.every((v) => {
        return v !== null && v !== undefined;
      }) ? null : { required: true };
    };
  }
}

export class EntityReferenceRequiredValidator {
  static createValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const entityReference: IdReferenceDto = control.value;
      if (!entityReference.value) {
        return { required: true };
      }
      return null;
    };
  }
}
