import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AddressDetailDto, AddressService } from '@artemis-software/wr-api';
import { LoadAddress, LoadAddresses } from './address.actions';
import { firstValueFrom } from 'rxjs';

type AddressStateModel = {
  addresses: {
    [aid: string]: AddressDetailDto
  },
};

@State<AddressStateModel>({
  name: 'address',
  defaults: {
    addresses: {},
  },
})

@Injectable()
export class AddressState {

  constructor(private readonly addressService: AddressService) {
  }

  @Selector()
  static addresses(state: AddressStateModel): { [aid: string]: AddressDetailDto } {
    return state.addresses;
  }

  @Selector()
  static addressResolver(state: AddressStateModel): (addressId?: string) => AddressDetailDto | undefined {
    return (addressId?: string) => addressId ? state.addresses[addressId] : undefined;
  }

  @Action(LoadAddress)
  addAddress(ctx: StateContext<AddressStateModel>, action: LoadAddress): void {
    const addressId = action.addressId;
    if (addressId in ctx.getState().addresses) {
      return;
    }

    this.addressService.findById(addressId).subscribe((address) => {
      ctx.patchState({
        addresses: {
          ...ctx.getState().addresses,
          [addressId]: address,
        },
      });
    });
  }

  @Action(LoadAddresses)
  async addAddresses(ctx: StateContext<AddressStateModel>, action: LoadAddresses): Promise<void> {
    const addresses = await Promise.all(action.addressIds.map((addressId) => {
      return firstValueFrom(this.addressService.findById(addressId));
    }));

    ctx.patchState({
      addresses: {
        ...ctx.getState().addresses,
        ...addresses.reduce((acc, address) => {
          acc[address.id] = address;
          return acc;
        }, {} as { [aid: string]: AddressDetailDto }),
      },
    });
  }
}
