import { EquipmentFilterDto } from '@artemis-software/wr-api';

export class LoadAllEquipments {
  static readonly type = '[Equipment] Load all Equipment';
}

export class FilterEquipments {
  static readonly type = '[Equipment] Filter Equipment';
}

export class CountEquipments {
  static readonly type = '[Equipment] Count equipment';
}

export class ChangeEquipmentFilter {
  static readonly type = '[Equipment] Change equipment filter';

  constructor(public filter: EquipmentFilterDto) {
  }
}
