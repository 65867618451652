import { Component, ViewChild } from '@angular/core';
import {
  CustomFieldCollectionFormComponent,
} from '@pages/custom-fields-settings-page/custom-field-collection-split-view/custom-field-collection-form/custom-field-collection-form.component';

@Component({
  selector: 'wr-custom-field-collection-dialog',
  templateUrl: './custom-field-collection-dialog.component.html',
  styleUrls: ['./custom-field-collection-dialog.component.scss'],
})
export class CustomFieldCollectionDialogComponent {

  readonly submitAction = () => this.form?.form.getMappedValue();

  @ViewChild(CustomFieldCollectionFormComponent)
  form?: CustomFieldCollectionFormComponent;

}
