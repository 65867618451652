/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InspectionPdfMergeDto { 
    id?: string;
    s3Key?: string;
    inspectionPdfType?: InspectionPdfMergeDto.InspectionPdfTypeEnum;
    inspectionId?: string;
}
export namespace InspectionPdfMergeDto {
    export type InspectionPdfTypeEnum = 'SHORTLIST' | 'LONGLIST' | 'COVERSHEET' | 'BUILDING_EQUIPMENTS' | 'CERTIFICATE_SHEET';
    export const InspectionPdfTypeEnum = {
        Shortlist: 'SHORTLIST' as InspectionPdfTypeEnum,
        Longlist: 'LONGLIST' as InspectionPdfTypeEnum,
        Coversheet: 'COVERSHEET' as InspectionPdfTypeEnum,
        BuildingEquipments: 'BUILDING_EQUIPMENTS' as InspectionPdfTypeEnum,
        CertificateSheet: 'CERTIFICATE_SHEET' as InspectionPdfTypeEnum
    };
}


