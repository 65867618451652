import { InspectionFilterDto } from '@artemis-software/wr-api';

export class FilterInspections {
  static readonly type = '[Inspection] Filter Inspections';
}

export class CountInspections {
  static readonly type = '[Inspection] Count inspections';
}

export class ChangeInspectionFilter {
  static readonly type = '[Inspection] Change Inspection Filter';

  constructor(public filter: InspectionFilterDto) {
  }
}
