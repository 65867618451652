import { Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList } from '@angular/core';
import { DataColumnDirective } from './data-column.directive';
import { combineLatest, debounceTime, Observable, ReplaySubject, startWith, Subject, takeUntil } from 'rxjs';
import { NbComponentStatus } from '@nebular/theme';
import { SortDto } from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { StoreUsage } from '@/utils/storeUsage';

export type RowAction = {
  pack: string,
  icon: string,
  toolTip: string,
  status: NbComponentStatus,
  click: (event: any) => void
}

@Component({
  selector: 'wr-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent<Entity> implements OnInit, OnDestroy {
  @ContentChildren(DataColumnDirective)
  columns?: QueryList<DataColumnDirective>;
  @Output()
  lineClick = new EventEmitter<Entity>();
  @Input()
  data$?: Observable<Entity[]>;
  @Input()
  actions?: RowAction[];
  @Input()
  sortKey = '';
  form!: FormGroup;
  count$ = new ReplaySubject<number>(1);
  loading$ = new ReplaySubject<boolean>(1);
  @Input() storeUsage!: StoreUsage;
  @Input() defaultSort!: string;
  @Input() defaultPageSize: number = 10;

  private destroy$ = new Subject<void>();

  constructor(private readonly formBuilder: FormBuilder,
              private readonly store: Store) {
  }

  ngOnInit(): void {
    this.store.select(this.storeUsage.countSelector)
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.count$.next(value);
      });

    this.store.select(this.storeUsage.loadingSelector)
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.loading$.next(value);
      });
    this.initSort();
    this.registerValueChanges();
  }

  initSort(): void {
    const sort = localStorage.getItem(this.sortKey);
    if (sort) {
      const parsedSortValue = JSON.parse(sort);
      this.initPagination(parsedSortValue.size, parsedSortValue.page, parsedSortValue.sort);
    } else {
      this.initPagination(this.defaultPageSize, 0, {
        sort: this.defaultSort,
        order: SortDto.OrderEnum.Asc
      });
    }
  }

  onActionClicked(event: Event, action: RowAction, entry: Entity) {
    action.click(entry);
    event.stopPropagation();
  }

  initPagination(size: number, page: number, sort: SortDto): void {
    this.form = this.formBuilder.group({
      size: this.formBuilder.control(size),
      page: this.formBuilder.control(page),
      sort: this.formBuilder.control(sort),
    });
  }

  registerValueChanges(): void {
    const sizeControl = this.form.controls['size'];
    const pageControl = this.form.controls['page'];
    const sortControl = this.form.controls['sort'];

    sizeControl.valueChanges.subscribe((_: number) => {
      pageControl.setValue(0);
    });

    combineLatest([
      sizeControl.valueChanges.pipe(startWith(sizeControl.value)),
      pageControl.valueChanges.pipe(startWith(pageControl.value)),
      sortControl.valueChanges.pipe(startWith(sortControl.value)),
    ]).pipe(debounceTime(100)).subscribe(() => {
      this.sort();
    });
  }

  sort(): void {
    this.store.dispatch(new this.storeUsage.filterAction(this.form.value));
    localStorage.setItem(this.sortKey, JSON.stringify(this.form.value));
  }

  get size(): number {
    return this.form.controls['size'].value;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
