import { TagFilterDto } from '@artemis-software/wr-api';

export class LoadAllTags {
  static readonly type = '[Tag] Load all Tags';
}

export class FilterTags {
  static readonly type = '[Tag] Filter Tags';
}

export class CountTags {
  static readonly type = '[Tag] Count tags';
}

export class ChangeTagFilter {
  static readonly type = '[Tag] Change Tag Filter';

  constructor(public filter: TagFilterDto) {
  }
}
