<wr-base-dialog status="info" header="Benutzer auswählen oder eingeben" [submitResponse]="submit.bind(this)" [cancelResponse]="close.bind(this)">
  <ng-container *ngIf="form as form">
    <div>
      <nb-radio-group [(ngModel)]="selectedOption">
        <nb-radio value="user">Benutzer suchen</nb-radio>
        <nb-radio value="manual">Manuelle Eingabe</nb-radio>
      </nb-radio-group>

      <div *ngIf="selectedOption === 'user'">
        <wr-user-select [formControl]="$any(form).get('user')" [showNotAssignedOption]="false"></wr-user-select>
      </div>

      <div *ngIf="selectedOption === 'manual'">
        <form [formGroup]="form">
          <div class="flex">
            <label class="label" for="firstName">Vorname</label>
            <input id="firstName" nbInput formControlName="firstName">
          </div>
          <div class="flex">
            <label class="label" for="lastName">Nachname</label>
            <input id="lastName" nbInput formControlName="lastName">
          </div>
          <div class="flex">
            <label class="label" for="email">Email</label>
            <input id="email" nbInput formControlName="email">
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</wr-base-dialog>
