/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InspectionDetailDto } from './inspectionDetailDto';


export interface AsyncMergeJobInspectionDetailDto { 
    jobId: string;
    status: AsyncMergeJobInspectionDetailDto.StatusEnum;
    result?: InspectionDetailDto;
    error?: object;
    createdAt: string;
    completedAt?: string;
}
export namespace AsyncMergeJobInspectionDetailDto {
    export type StatusEnum = 'IN_PROGRESS' | 'COMPLETED' | 'FAILED';
    export const StatusEnum = {
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Completed: 'COMPLETED' as StatusEnum,
        Failed: 'FAILED' as StatusEnum
    };
}


