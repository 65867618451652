import { Component, forwardRef, Input } from '@angular/core';
import { EquipmentDetailDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '../abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-check-interval-select',
  templateUrl: './check-interval-select.component.html',
  styleUrls: ['./check-interval-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckIntervalSelectComponent),
      multi: true,
    },
  ],
})
export class CheckIntervalSelectComponent extends AbstractFormControl<EquipmentDetailDto.MaintenanceIntervalEnum> {

  readonly MaintenanceIntervalValues = Object.values(EquipmentDetailDto.MaintenanceIntervalEnum);

  @Input()
  showEmptyOption = false;
}
