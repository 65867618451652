import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ChangeAttachmentFilter, CountAttachments, FilterAttachments } from './attachment.action';
import { AttachmentDetailDto, AttachmentFilterDto, AttachmentService } from '@artemis-software/wr-api';

type AttachmentStateModel = {
  attachments: AttachmentDetailDto[],
  filteredAttachments: AttachmentDetailDto[],
  currentAttachment?: AttachmentDetailDto,
  count: number,
  loading: boolean,
  filter: AttachmentFilterDto,
}

@State<AttachmentStateModel>({
  name: 'attachments',
  defaults: {
    attachments: [],
    filteredAttachments: [],
    count: 0,
    loading: false,
    filter: {} as AttachmentFilterDto,
  },
})

@Injectable()
export class AttachmentState implements NgxsOnInit {

  constructor(private readonly store: Store,
    private readonly attachmentService: AttachmentService) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
  }

  @Selector()
  static attachments(state: AttachmentStateModel): AttachmentDetailDto[] {
    return state.attachments;
  }

  @Selector()
  static filteredAttachments(state: AttachmentStateModel): AttachmentDetailDto[] {
    return state.filteredAttachments;
  }

  @Selector()
  static attachmentsLoading(state: AttachmentStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: AttachmentStateModel): number {
    return state.count;
  }

  @Selector()
  static currentAttachment(state: AttachmentStateModel): AttachmentDetailDto | undefined {
    return state.currentAttachment;
  }

  @Selector()
  static filter(state: AttachmentStateModel): AttachmentFilterDto {
    return state.filter;
  }

  @Action(FilterAttachments)
  filterAttachments(ctx: StateContext<AttachmentStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.attachmentService.findAllDetailDtos(ctx.getState().filter).subscribe((attachments: AttachmentDetailDto[]) => {
      ctx.patchState({
        filteredAttachments: attachments,
        loading: false,
      });
    });
  }

  @Action(CountAttachments)
  countAttachments(ctx: StateContext<AttachmentStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.attachmentService.getCount(ctx.getState().filter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeAttachmentFilter)
  changeAttachmentFilter(ctx: StateContext<AttachmentStateModel>, action: ChangeAttachmentFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountAttachments());
    this.store.dispatch(new FilterAttachments());
  }
}
