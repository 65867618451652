<wr-new-data-table headerText="Rundgangsdashboard"
                   localStorageIdentifier="cooperative-inspection-overview"
                   [loadItemsFunction]="loadInspections"
                   [getCountFunction]="getCount"
                   (lineClick)="navigateToInspection($event)"
                   [showNewButton]="false"
                   [form]="form"
                   [isAdmin]="isAdmin">
  <ng-template #filterForm>
    <form [formGroup]="form">
      <div class="filter-items">
        <div class="filter-control-group">
          <label class="label" for="textFilter">Textfilter</label>
          <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
        </div>
        <div class="filter-control-group">
          <label class="label" for="buildingSelect">Gebäude</label>
          <wr-building-select id="buildingSelect"
                              formControlName="building"
                              [fixedOrganisationId]="currentOrganisationId()">
          </wr-building-select>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template
    dataColumn
    let-inspection
    key="continuationNumber"
    display="Folgenummer">
    {{ inspection.continuationNumber }}
  </ng-template>

  <ng-template
    dataColumn
    let-inspection
    key="date"
    display="Datum">
    {{ inspection.sendingTimestamp | date: 'dd.MM.yyyy' }}
  </ng-template>

  <ng-template
    dataColumn
    let-inspection
    key="address"
    display="Adresse">
    {{ inspection.building.sectionText }}
  </ng-template>

  <ng-template
    dataColumn
    let-inspection
    key="technician"
    display="Techniker*in">
    {{ inspection.technician.firstName }} {{ inspection.technician.lastName }}
  </ng-template>

  <ng-template
    dataColumn
    let-inspection
    key="clerk"
    display="Sachbearbeiter*in">
    {{ inspection.clerk.firstName }} {{ inspection.clerk.lastName }}
  </ng-template>

  <ng-template
    dataColumn
    let-inspection
    key="condition"
    [sortable]="false"
    display="Zustand">
    <wr-building-condition
      [condition]="inspection.condition.conditionValue"></wr-building-condition>
  </ng-template>

</wr-new-data-table>
