<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="users"></nb-icon>
          Genossenschaft
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
              <button nbButton ghost status="primary" (click)="add()">
                <nb-icon pack="eva" icon="plus-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
  [data$]="organisations$"
  [storeUsage]="organisationStoreUsage"
  [defaultSort]="'name'"
  [sortKey]="'organisationSort'"
  (lineClick)="navigateToUser($event)"
  >
    <ng-template
      dataColumn
      let-organisation
      key="name"
      display="Name">
      <div *ngIf="organisation.name">
        {{organisation.name}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-organisation
      key="paymentAddress"
      display="Straße">
      <div *ngIf="organisation.paymentAddress as address">
        <span>{{address.street}} {{address.number}}</span>
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-organisation
      key="paymentAddress"
      display="PLZ">
      <div *ngIf="organisation.paymentAddress as address">
        {{address.country}}-{{address.zipCode}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-organisation
      key="paymentAddress"
      display="Stadt">
      <div *ngIf="organisation.paymentAddress as address">
        {{address.city}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-organisation
      key="loggedInUsers"
      display="Aktive Benutzer">
      <div>
        {{ organisation.loggedInUsers }} / {{ organisation.license.maxUsers || '-' }}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-organisation
      key="organisationType"
      display="Typ">
      <div>
        {{ organisation.organisationType | organisationTypeTranslation }}
      </div>
    </ng-template>
  </wr-data-table>
</div>
