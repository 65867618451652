import { Pipe, PipeTransform } from '@angular/core';
import { ConditionDto } from '@artemis-software/wr-api';

@Pipe({
  name: 'conditionTranslation',
})
export class ConditionTranslationPipe implements PipeTransform {

  transform(value: ConditionDto.ConditionEnum | null | undefined): string | undefined {
    switch (value) {
    case ConditionDto.ConditionEnum.None:
      return 'Alle';
    case ConditionDto.ConditionEnum.NoDefects:
      return 'Keine Mängel';
    case ConditionDto.ConditionEnum.MinorDefects:
      return 'Leichte Mängel';
    case ConditionDto.ConditionEnum.MajorDefects:
      return 'Schwere Mängel';
    }
    return undefined;
  }
}
