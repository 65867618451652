import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { TemplateState } from '@/store/template/template.state';
import { Observable } from 'rxjs';
import { TemplateItemDto } from '@artemis-software/wr-api';
import { defineStoreUsage } from '@/utils/storeUsage';
import { ChangeTemplateFilter } from '@/store/template/template.action';
import { Router } from '@angular/router';

@Component({
  selector: 'wr-template-overview-page',
  templateUrl: './template-overview-page.component.html',
  styleUrls: ['./template-overview-page.component.scss']
})
export class TemplateOverviewPageComponent {

  templateStoreUsage = defineStoreUsage({
    filterAction: ChangeTemplateFilter,
    countSelector: TemplateState.count,
    loadingSelector: TemplateState.templateLoading,
  });

  @Select(TemplateState.filteredTemplates)
  templates$!: Observable<TemplateItemDto[]>;

  form!: FormGroup;

  private readonly templateFilter = 'templateFilter';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly router: Router,) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem(this.templateFilter);
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
    });
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeTemplateFilter(this.form.value));
    localStorage.setItem(this.templateFilter, JSON.stringify(this.form.value));
  }

  add(): void {
    this.router.navigate(['template', 'new']);
  }

  navigateToTemplate(event: TemplateItemDto): void {
    this.router.navigate(['template', event.id]);
  }
}
