/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user';
import { EmailAttachment } from './emailAttachment';


export interface EmailMergeDto { 
    id?: string;
    userId?: string;
    subject?: string;
    htmlBody?: string;
    emailType?: EmailMergeDto.EmailTypeEnum;
    inspectionId?: string;
    repairTasksId?: Array<string>;
    attachments?: Array<EmailAttachment>;
    userRecipients?: Array<User>;
    recipients?: Array<string>;
}
export namespace EmailMergeDto {
    export type EmailTypeEnum = 'INSPECTION' | 'REPAIR_TASK' | 'MAINTENANCE_TIMER';
    export const EmailTypeEnum = {
        Inspection: 'INSPECTION' as EmailTypeEnum,
        RepairTask: 'REPAIR_TASK' as EmailTypeEnum,
        MaintenanceTimer: 'MAINTENANCE_TIMER' as EmailTypeEnum
    };
}


