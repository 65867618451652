import { Component, forwardRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { EquipmentState } from '@/store/equipment/equipment.state';
import { Observable } from 'rxjs';
import { EquipmentItemDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-equipment-select',
  templateUrl: './equipment-select.component.html',
  styleUrls: ['./equipment-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EquipmentSelectComponent),
      multi: true,
    },
  ],
})
export class EquipmentSelectComponent extends AbstractFormControl<EquipmentItemDto>{

  @Select(EquipmentState.equipments)
  equipments$!: Observable<EquipmentItemDto[]>;

  readonly compareWith = (a?: EquipmentItemDto, b?: EquipmentItemDto) => a?.id === b?.id;
}
