import { AssessmentPresetMergeDto, EquipmentFilterDto } from '@artemis-software/wr-api';
import EquipmentTypeEnum = EquipmentFilterDto.EquipmentTypeEnum;
import CheckIntervalEnum = AssessmentPresetMergeDto.CheckIntervalEnum;

const maintenanceIntervalRecord: Record<CheckIntervalEnum, string> = {
  NONE: 'Keine Frist',
  HALF_YEAR: 'Halbjährlich',
  ONE_DAY: 'Täglich',
  YEAR: 'Jährlich',
  ONE_MONTH: 'Monatlich',
};

const equipmentTypeRecord: Record<EquipmentTypeEnum, string> = {
  DOOR: 'Tür',
  FIRE_ALARM: 'Feueralarm',
  FIRE_EXTINGUISHER: 'Feuerlöscher',
  SMOKE_DETECTOR: 'Rauchmelder',
  WINDOW: 'Fenster',
  UNDEFINED: 'Unbekannt',
};

export const getEquipmentType = (equipmentType: EquipmentTypeEnum): string => {
  return equipmentTypeRecord[equipmentType];
};

export const getMaintenanceInterval = (maintenanceInterval: CheckIntervalEnum): string => {
  return maintenanceIntervalRecord[maintenanceInterval];
};
