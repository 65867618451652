<div class="center-container">
  <form *ngIf="form" [formGroup]="form" class="inner-form">
    <hau-form-field label="Pin" [validation]="form.validation.pin">
      <input type="password" formControlName="pin" nbInput fullWidth>
    </hau-form-field>
    <div class="submit-button">
      <button nbButton status="primary" (click)="onSubmit()" size="large">Anmelden</button>
    </div>
  </form>
</div>
