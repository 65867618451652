import { Component, computed, inject } from '@angular/core';
import { BuildingItemDto, BuildingService, SortDto } from '@artemis-software/wr-api';
import { loader } from '@/utils/signals/loader';
import { Tracked } from '@/utils/signals/tracked';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'wr-cooperative-dashboard-building-overview',
  templateUrl: './cooperative-dashboard-building-overview.component.html',
})
export class CooperativeDashboardBuildingOverviewComponent {
  buildingService = inject(BuildingService);
  service = inject(AuthService);

  isLoading = loader();

  @Tracked() page = 0;
  @Tracked() pageSize = 10;
  @Tracked() sort?: SortDto;

  results = asyncComputed(() => {
    return this.buildingService.findAllForOrganisation({
      page: this.page,
      size: this.pageSize,
      sort: this.sort ? [`${this.sort.sort},${this.sort.order.toLowerCase()}`] : undefined,
    });
  }, undefined, this.isLoading);

  buildings = computed(() => this.results()?.content ?? []);

  buildingCount = computed(() => this.results()?.numberOfElements ?? 0);

  readonly getBuildingLink = (building: BuildingItemDto) => (['building', building.id]);
}
