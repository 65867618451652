<wr-base-dialog status="info" header="Prüfpunkt auswählen" [submitResponse]="onSubmit">
  <div class="flex-container">
    <div>
      <label for="selectedCheckpointGroupId">Prüfpunkt Gruppe</label>
      <nb-select id="selectedCheckpointGroupId"
                 fullWidth
                 [(selected)]="selectedCheckpointGroupId">
        <nb-option [value]="''">Alle Gruppen</nb-option>
        <nb-option *ngFor="let checkpointGroup of checkpointGroups$ | async"
                   [value]="checkpointGroup.id">
          {{checkpointGroup.number}}.{{checkpointGroup.name}}
        </nb-option>
      </nb-select>
    </div>
    <div *ngIf="selectedCheckpointGroupId">
      <label for="selectedCheckpointId">Prüfpunkt</label>
      <nb-select id="selectedCheckpointId"
                 fullWidth
                 [(selected)]="selectedCheckpointId">
        <nb-option *ngFor="let checkpoint of checkpoints$ | async"
                   [value]="checkpoint.id">
          {{checkpoint.checkpointGroupNumber}}.{{checkpoint.number}} {{checkpoint.name}}
        </nb-option>
      </nb-select>
    </div>
  </div>
</wr-base-dialog>
