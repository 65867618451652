import { EffectRef, Signal } from '@angular/core';
import { watch } from './watch';

/**
 * Triggers the given function whenever the given Signals value becomes truthy.
 * @param source The Signal to watch for truthy values.
 * @param fn The function to trigger when the source Signal emits a truthy value.
 * @returns An EffectRef that can be used to stop watching the source Signal.
 */
export function whenever<T>(
  source: Signal<T>,
  fn: (value: T, oldValue: T | undefined) => void,
): EffectRef {
  return watch(
    source,
    (value, oldValue) => {
      if (value && !oldValue) fn(value, oldValue);
    },
    { immediate: true },
  );
}
