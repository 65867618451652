import { InspectionPdfItemDto, InspectionPdfService } from '@artemis-software/wr-api';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { FilterInspectionPdfs } from './inspectionPdf.action';
import { firstValueFrom } from 'rxjs';

type InspectionPdfStateModel = {
  filteredInspectionPdfs: InspectionPdfItemDto[],
  count: number,
  loading: boolean
}

@State<InspectionPdfStateModel>({
  name: 'inspectionPdfs',
  defaults: {
    filteredInspectionPdfs: [],
    count: 0,
    loading: false,
  },
})

@Injectable()
export class InspectionPdfState {

  constructor(
    private readonly store: Store,
    private readonly inspectionPdfService: InspectionPdfService,
  ) {
  }


  @Selector()
  static filteredInspectionPdfs(state: InspectionPdfStateModel): InspectionPdfItemDto[] {
    return state.filteredInspectionPdfs;
  }

  @Selector()
  static inspectionPdfsLoading(state: InspectionPdfStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: InspectionPdfStateModel): number {
    return state.count;
  }

  @Action(FilterInspectionPdfs)
  async filterInspectionPdfs(ctx: StateContext<InspectionPdfStateModel>, action: FilterInspectionPdfs): Promise<void> {
    ctx.patchState({
      loading: true,
    });

    const [filtered, count] = await Promise.all([
      firstValueFrom(this.inspectionPdfService.findAll(action.filter)),
      firstValueFrom(this.inspectionPdfService.getCount(action.filter)),
    ]);

    ctx.patchState({
      filteredInspectionPdfs: filtered,
      count: count,
    });
  }
}
