import { Component, inject } from '@angular/core';
import { RepairTaskItemDto } from '@artemis-software/wr-api';
import { defineStoreUsage } from '@/utils/storeUsage';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RepairTaskState } from '@/store/repairTask/repairTask.state';
import { ChangeRepairTaskFilter } from '@/store/repairTask/repairTask.action';

@Component({
  selector: 'wr-cooperative-statistics-repair-task-overview',
  templateUrl: './cooperative-statistics-repair-task-overview.component.html',
  styleUrls: ['./cooperative-statistics-repair-task-overview.component.scss'],
})
export class CooperativeStatisticsRepairTaskOverviewComponent {
  store = inject(Store);

  repairTaskStoreUsage = defineStoreUsage({
    filterAction: ChangeRepairTaskFilter,
    countSelector: RepairTaskState.count,
    loadingSelector: RepairTaskState.repairTasksLoading,
  });

  @Select(RepairTaskState.filteredRepairTasks)
  repairTasks$!: Observable<RepairTaskItemDto[]>;
  @Select(RepairTaskState.count)
  count$!: Observable<number>;
  @Select(RepairTaskState.repairTasksLoading)
  loading$!: Observable<boolean>;
}
