import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';
import { AuthService } from '@services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private nbToastrService: NbToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.url.includes('login')) {
          this.nbToastrService.danger('Bitte Eingabe überprüfen und erneut versuchen.', 'Login inkorrekt');
        } else if (err.status === 401) {
          this.nbToastrService.danger('Benutzer ist nicht eingeloggt', 'Fehler');
          this.authService.logout();
        } else if (err.status === 403) {
          this.nbToastrService.danger('Diese Operation ist nicht erlaubt', 'Zugriff verweigert');
        } else if (err.status === 404) {
          this.nbToastrService.danger('Die angeforderte Ressource wurde nicht gefunden', 'Nicht gefunden');
        } else if (err.status === 409) {
          this.nbToastrService.danger('Neuere Version existiert bereits', 'Version stimmt nicht überein');
        } else if (err.status === 430) {
          this.nbToastrService.danger('Die App ist veraltet - bitte aktualisieren', 'App veraltet');
        } else {
          this.nbToastrService.danger('Ein unbekannter Fehler ist aufgetreten. Bitte erneut versuchen!', 'Fehler');
        }
        const error = err.error || err.message || err.statusText;
        return throwError(error);
      }),
    );
  }
}
