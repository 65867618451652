import { Component, EventEmitter, forwardRef, inject, Input, Output } from '@angular/core';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import {
  BuildingEquipmentFilterDto,
  BuildingEquipmentItemDto,
  BuildingEquipmentService, BuildingItemDto, BuildingSectionItemDto,
  SortDto,
} from '@artemis-software/wr-api';
import { BehaviorSubject, combineLatest, debounceTime, firstValueFrom, map } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbPosition } from '@nebular/theme';
import { SortableOptions } from 'sortablejs';

@Component({
  selector: 'wr-building-equipment-multi-select',
  templateUrl: './building-equipment-multi-select.component.html',
  styleUrls: ['./building-equipment-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingEquipmentMultiSelectComponent),
      multi: true,
    },
  ],
})
export class BuildingEquipmentMultiSelectComponent extends AbstractFormControl<BuildingEquipmentItemDto[]> {

  readonly NbPosition = NbPosition;

  buildingEquipments$ = new BehaviorSubject<BuildingEquipmentItemDto[]>([]);
  notSelectedBuildingEquipments$ = combineLatest([this.buildingEquipments$, this.value$]).pipe(
    map(([buildingEquipments, selected]) => {
      return buildingEquipments.filter(b => !selected?.find(s => s.id === b.id));
    }),
  );

  get sortableOptions(): SortableOptions {
    return {
      sort: true,
      disabled: this.isDisabled,
    };
  }

  private _building$ = new BehaviorSubject<BuildingItemDto | undefined>(undefined);
  private _buildingSection$ = new BehaviorSubject<BuildingSectionItemDto | undefined>(undefined);
  text$ = new BehaviorSubject<string>('');
  reload$ = new BehaviorSubject<void>(undefined);

  @Input()
  set building(value: BuildingItemDto | undefined) {
    this._building$.next(value);
  }

  get building(): BuildingItemDto | undefined {
    return this._building$.value;
  }

  @Input()
  set buildingSection(value: BuildingSectionItemDto | undefined) {
    this._buildingSection$.next(value);
  }

  get section(): BuildingSectionItemDto | undefined {
    return this._buildingSection$.value;
  }

  protected readonly buildingEquipmentService = inject(BuildingEquipmentService);

  constructor() {
    super();
    combineLatest([
      this._building$,
      this.text$,
      this.reload$,
    ]).pipe(
      debounceTime(100),
    ).subscribe(async ([building, text]) => {
      const filter: BuildingEquipmentFilterDto = {
        text,
        buildingId: building?.id,
        sort: { sort: 'equipmentName', order: SortDto.OrderEnum.Asc },
        page: 0,
        size: 10,
      };
      const buildingEquipments = await firstValueFrom(this.buildingEquipmentService.findAll(filter));
      this.buildingEquipments$.next(buildingEquipments);
    });
  }

  removeBuildingEquipment(be: BuildingEquipmentItemDto) {
    this.value = this.value?.filter(b => b.id !== be.id);
  }

  addBuildingEquipment(buildingEquipment: BuildingEquipmentItemDto): void {
    this.value = [...this.value ?? [], buildingEquipment];
  }
}
