<nb-accordion class="noselect">
  <nb-accordion-item *ngFor="let group of groups"
                     [expanded]="group.checkpointGroupId === selectedGroupId"
                     (collapsedChange)="collapseChange($event, group)">
    <nb-accordion-item-header>
      {{group.checkpointGroupNumber}} {{group.checkpointGroupName}}
      <div class="actions" *ngIf="!disabled">
        <div class="actions-absolute">
          <button nbButton
                  ghost
                  (click)="deleteGroupClick($event, group)"
                  nbTooltip="Gruppe Löschen">
            <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
          </button>
        </div>
      </div>
    </nb-accordion-item-header>
    <nb-accordion-item-body class="no-padding">
      <wr-inspection-entry-list-item *ngFor="let inspectionEntry of group.entries"
                                     [inspectionEntry]="inspectionEntry"
                                     [selected]="selectedEntry === inspectionEntry"
                                     (click)="selectedEntry$.next(inspectionEntry)">
      </wr-inspection-entry-list-item>
    </nb-accordion-item-body>
  </nb-accordion-item>
</nb-accordion>
