<div class="detail-container">
  <nb-card>
    <nb-card-header>
      <button nbButton ghost status="primary" (click)="navigateBack()">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>TGA</h5>
    </nb-card-header>
    <nb-card-body>
      <nb-tabset>
        <nb-tab tabTitle="Grunddaten">
          <wr-building-equipment-detail-form *ngIf="buildingEquipmentReady()"
                                             [showCard]="false"
                                             [showTitle]="false"
                                             [buildingEquipment]="buildingEquipment!"
                                             (cancel)="cancel()"
                                             (submitSuccess)="submit($event)"
                                             (deleteSuccess)="navigateBack()">
          </wr-building-equipment-detail-form>
        </nb-tab>
        <nb-tab *ngIf="buildingEquipment" tabTitle="Wartungstimer">
          <wr-maintenance-timer-overview
            *ngIf="buildingEquipmentReady()"
            [buildingEquipment]="buildingEquipment!">
          </wr-maintenance-timer-overview>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
  </nb-card>
</div>
