import { AfterViewInit, Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ZipCodeService } from '@artemis-software/wr-api';
import { debounceTime, filter, switchMap } from 'rxjs';

@Component({
  selector: 'wr-automatic-address',
  templateUrl: './automatic-address.component.html',
  styleUrls: ['./automatic-address.component.scss'],
})
export class AutomaticAddressComponent implements AfterViewInit {

  @Input() formGroup!: FormGroup;

  constructor(private readonly zipCodeService: ZipCodeService) {
  }

  get zipCodeControl(): AbstractControl {
    return this.formGroup.get('zipCode') as FormControl;
  }

  ngAfterViewInit(): void {
    this.zipCodeControl
      .valueChanges
      .pipe(
        debounceTime(250),
        switchMap((zipCode: string | undefined) => this.zipCodeService.findByZipCode(zipCode ?? '')),
        filter(address => !!address),
      )
      .subscribe(address => {
        this.formGroup.patchValue({
          city: address.city,
          province: address.province,
        });
      });
  }

  hasError(controlName: string, errorName = 'required'): boolean {
    const control = this.formGroup?.get(controlName);
    if (!control) {
      return false;
    }
    return control.hasError(errorName);
  }
}
