import { Component } from '@angular/core';
import {defineStoreUsage} from '@/utils/storeUsage';
import { ChangeAttachmentFilter, CountAttachments, FilterAttachments } from '@/store/attachment/attachment.action';
import {AttachmentState} from '@/store/attachment/attachment.state';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {
  AttachmentDetailDto,
  BuildingDetailDto, InspectionFilterDto, TagItemDto
} from '@artemis-software/wr-api';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {NbDateService} from '@nebular/theme';

@Component({
  selector: 'wr-archive-page',
  templateUrl: './archive-page.component.html',
  styleUrls: ['./archive-page.component.scss']
})
export class ArchivePageComponent {

  attachmentStorageUse = defineStoreUsage({
    filterAction: ChangeAttachmentFilter,
    countSelector: AttachmentState.count,
    loadingSelector: AttachmentState.attachmentsLoading
  })

  @Select(AttachmentState.filteredAttachments)
  attachments$!: Observable<AttachmentDetailDto[]>;
  @Select(AttachmentState.count)
  count$!: Observable<number>;
  @Select(AttachmentState.attachmentsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  readonly FORMAT_STRING = 'yyyy-MM-ddTHH:mm:ss.SSSZ';

  constructor(private readonly store: Store,
    private readonly formBuilder: FormBuilder,
    private readonly dateService: NbDateService<Date>) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('attachmentFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);

      let selectedRange = undefined;

      if (filterValue.selectedRange?.start) {
        let startDateTime = this.dateService.parse(filterValue.selectedRange.start, this.FORMAT_STRING);
        let endDateTime = this.dateService.parse(filterValue.selectedRange.end ?? filterValue.selectedRange.start, this.FORMAT_STRING);

        startDateTime = this.dateService.addHours(startDateTime, 1);
        endDateTime = this.dateService.addHours(endDateTime, 1);

        selectedRange = {
          start: startDateTime,
          end: endDateTime,
        };
      }

      this.addSpecialFields(selectedRange, filterValue.building, filterValue.organisationId, filterValue.tag, filterValue.isArchive);
    } else {
      this.initFilterForm('');
    }
  }
  initFilterForm(text: string): void {
    const startOfMonth = this.dateService.getMonthStart(this.dateService.today());
    const endOfMonth = this.dateService.addMonth(startOfMonth, 2);

    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      organisationId: this.formBuilder.control(undefined),
      building: this.formBuilder.control(undefined),
      buildingId: this.formBuilder.control(undefined),
      selectedRange: new FormControl({
        start: startOfMonth,
        end: endOfMonth,
      }),
      tag: this.formBuilder.control(''),
      tagId: this.formBuilder.control(undefined),
      isArchive: this.formBuilder.control(false),
    });
  }

  addSpecialFields(selectedRange: { start: Date; end: Date } | undefined, building: BuildingDetailDto | undefined, organisationId: string | undefined, tag: TagItemDto | undefined, isArchive: boolean): void {
    this.form.patchValue({
      'organisationId': organisationId,
      'building': building,
      'selectedRange': selectedRange,
      'tag': tag,
      'isArchive': isArchive,
    });
  }

  triggerFilter(): void {
    const buildingControl = this.form.controls['building'];
    if (buildingControl.value) {
      this.form.controls['buildingId'].setValue(buildingControl.value['id']);
    } else {
      this.form.controls['buildingId'].setValue(undefined);
    }

    const tagControl = this.form.controls['tag'];
    if (tagControl.value) {
      this.form.controls['tagId'].setValue(tagControl.value['id']);
    } else {
      this.form.controls['tagId'].setValue(undefined);
    }

    const filter: InspectionFilterDto = this.form.value;
    if (filter.selectedRange && !filter.selectedRange?.end) {
      filter.selectedRange.end = filter.selectedRange.start;
    }
    this.store.dispatch(new ChangeAttachmentFilter(this.form.value));
    localStorage.setItem('attachmentFilter', JSON.stringify(this.form.value));
  }

  displayDate(created: string): string {
    const date = new Date(created);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // month is 0-indexed
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }
}
