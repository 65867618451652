import { Component } from '@angular/core';
import { NbRouteTab } from '@nebular/theme';

@Component({
  selector: 'wr-custom-fields-settings-page',
  templateUrl: './custom-fields-settings-page.component.html',
  styleUrls: ['./custom-fields-settings-page.component.scss'],
})
export class CustomFieldsSettingsPageComponent {

  tabs: NbRouteTab[] = [
    {
      title: 'Felder',
      route: 'fields',
    },
    {
      title: 'Feldgruppen',
      route: 'field-sets',
    },
  ];
}
