import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LocalStorageSubject } from '@/utils/local-storage-subject';

@Component({
  selector: 'wr-collapsible-area[id]',
  templateUrl: './collapsible-area.component.html',
  styleUrls: ['./collapsible-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleAreaComponent {

  @Input()
  set id(id: string) {
    this.expanded$ = new LocalStorageSubject<boolean>(`collapsible-area-${id}`, false);
  }

  @Input()
  title = '';

  private expanded$?: LocalStorageSubject<boolean>;

  set expanded(expanded: boolean) {
    this.expanded$?.next(expanded);
  }

  get expanded(): boolean {
    return this.expanded$?.value ?? true;
  }

  get icon(): string {
    return this.expanded ? 'chevron-up-outline' : 'chevron-down-outline';
  }

  get tooltip(): string {
    return this.expanded ? 'Zuklappen' : 'Aufklappen';
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
