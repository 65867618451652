import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { ChartComponent } from './chart.component';

@Component({
  selector: 'wr-large-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class LargeChartComponent extends ChartComponent implements AfterViewInit, OnChanges {

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.updateChartOptions();
  }

  private updateChartOptions(): void {
    if (this.chart) {
      this.chart.options.scales =  {
        y: {
          ticks: {
            font: {
              size: 10,
              weight: 'bold',
            },
            callback: function (val) {
              if (typeof val === 'number') {
                const tickText = this.getLabelForValue(val);

                const splitIndex = tickText.substring(0, 12).lastIndexOf(' ');

                if (splitIndex !== -1 && splitIndex <= 12) {
                  const firstLine = tickText.substring(0, splitIndex);
                  let secondLine = tickText.substring(splitIndex + 1);

                  if (secondLine.length > 12) {
                    secondLine = `${secondLine.substring(0, 10)}...`;
                  }

                  return [firstLine, secondLine];
                } else {
                  return tickText.length > 12 ? `${tickText.substring(0, 10)}...` : tickText;
                }
              }
              return val;
            },
          },
        },
      };
      this.chart.update();
    }
  }
}
