import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InspectionEntryDetailDto } from '@artemis-software/wr-api';
import { SelectWithRecommendedOption } from '../select-with-recommended-option';

@Component({
  selector: 'wr-deadline-select',
  templateUrl: './deadline-select.component.html',
  styleUrls: ['./deadline-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeadlineSelectComponent),
      multi: true,
    },
  ],
})
export class DeadlineSelectComponent extends SelectWithRecommendedOption<InspectionEntryDetailDto.DeadlineEnum> {
  override enumValues = Object.values(InspectionEntryDetailDto.DeadlineEnum);
}
