import { BuildingEquipmentFilterDto } from '@artemis-software/wr-api';

export class LoadAllBuildingEquipments {
  static readonly type = '[BuildingEquipment] Load all BuildingEquipment';
}

export class FilterBuildingEquipments {
  static readonly type = '[BuildingEquipment] Filter BuildingEquipment';
}

export class CountBuildingEquipments {
  static readonly type = '[BuildingEquipment] Count buildingEquipment';
}

export class ChangeBuildingEquipmentFilter {
  static readonly type = '[BuildingEquipment] Change buildingEquipment filter';

  constructor(public filter: BuildingEquipmentFilterDto) {
  }
}


