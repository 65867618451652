import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  BuildingEquipmentMultiSelectComponent,
} from '@shared/form-controls/building-equipment-multi-select/building-equipment-multi-select.component';
import { BuildingEquipmentItemDto } from '@artemis-software/wr-api';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import {
  BuildingEquipmentDetailFormComponent,
} from '@pages/building-equipment-detail-page/building-equipment-detail-form/building-equipment-detail-form.component';
import { tryWithLoading } from '@/utils/async-utils';
import { routeParam } from '@/utils/signals/router';


@Component({
  selector: 'wr-building-equipments-editor',
  templateUrl: './building-equipments-editor.component.html',
  styleUrls: ['./building-equipments-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingEquipmentsEditorComponent),
      multi: true,
    },
  ],
})
export class BuildingEquipmentsEditorComponent extends BuildingEquipmentMultiSelectComponent {

  inspectionId = routeParam('id', true);

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly nbDialogService: NbDialogService,
  ) {
    super();
  }

  async showDetailWindow(item: BuildingEquipmentItemDto) {
    const ref = this.nbDialogService.open(BuildingEquipmentDetailFormComponent);
    ref.componentRef.instance.buildingEquipment = await firstValueFrom(this.buildingEquipmentService.findByIdAtRevision(item.id, this.inspectionId()));
    ref.componentRef.instance.close.subscribe(() => ref.close());
    ref.componentRef.instance.cancel.subscribe(() => ref.close());
    ref.componentRef.instance.submitSuccess.subscribe(async () => {
      ref.close();
      const ids = this.value?.map(v => v.id);
      await this.reloadSelection(ids);
    });
    ref.componentRef.instance.deleteSuccess.subscribe(async (buildingEquipmentId) => {
      ref.close();
      const ids = this.value?.map(v => v.id);
      await this.reloadSelection(ids?.filter(id => id !== buildingEquipmentId));
    });
  }

  createNewBuildingEquipment(): void {
    const ref = this.nbDialogService.open(BuildingEquipmentDetailFormComponent, {
      context: {
        disableEdit: false,
      }
    });
    ref.componentRef.instance.buildingAndSection = (this.building && this.section) ? {
      building: this.building,
      section: this.section,
    } : null;
    ref.componentRef.instance.close.subscribe(() => ref.close());
    ref.componentRef.instance.cancel.subscribe(() => ref.close());
    ref.componentRef.instance.submitSuccess.subscribe(async (newBuildingEquipment) => {
      ref.close();
      const ids = this.value?.map(v => v.id);
      await this.reloadSelection([...ids ?? [], newBuildingEquipment.id]);
    });
  }

  private async reloadSelection(ids?: string[]) {
    this.reload$.next(undefined);
    if (ids?.length) {
      await tryWithLoading(this.loading$, async () => {
        this.value = await firstValueFrom(this.buildingEquipmentService.findByIdsAtRevision(ids, this.inspectionId()));
      });
    } else {
      this.value = [];
    }
  }

  isBuildingSectionMismatch(item: BuildingEquipmentItemDto) {
    return item.buildingSectionId !== this.section?.id;
  }
}
