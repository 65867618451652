import { Component, Input, ViewChildren } from '@angular/core';
import { InspectionFormWrapper } from '../inspection-form';
import {
  MultiAttachmentControlComponent,
} from '@shared/form-controls/attachment-upload/multi-attachment-control/multi-attachment-control.component';
import {
  SingleAttachmentControlComponent,
} from '@shared/form-controls/attachment-upload/single-attachment-control/single-attachment-control.component';

@Component({
  selector: 'wr-detail-form[formWrapper]',
  templateUrl: './detail-form.component.html',
  styleUrls: ['./detail-form.component.scss'],
})
export class DetailFormComponent {

  @ViewChildren(MultiAttachmentControlComponent)
  multiAttachmentControls!: MultiAttachmentControlComponent[];
  @ViewChildren(SingleAttachmentControlComponent)
  singleAttachmentControls!: SingleAttachmentControlComponent[];

  @Input()
  formWrapper!: InspectionFormWrapper;

  get internalNumber(): string {
    return this.formWrapper.controls?.building.value?.internalNumber ?? '';
  }

  get hasAnyPendingAttachments(): boolean {
    return this.multiAttachmentControls.some(control => control.hasAnyPendingAttachments) ||
      this.singleAttachmentControls.some(control => control.loading$?.value);
  }
}
