import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AttachmentDetailDto } from '@artemis-software/wr-api';
import { getAcceptForFileType } from '../file-utils';

@Component({
  selector: 'wr-attachment-drop-zone',
  templateUrl: './attachment-drop-zone.component.html',
  styleUrls: ['./attachment-drop-zone.component.scss'],
})
export class AttachmentDropZoneComponent {

  readonly getAcceptForFileType = getAcceptForFileType;

  @Input()
  multiple = true;
  @Input()
  type: AttachmentDetailDto.TypeEnum = AttachmentDetailDto.TypeEnum.File;

  @Output()
  fileSelected: EventEmitter<File> = new EventEmitter<File>();

  dragDepth = 0;

  onFilesSelected(event: any): void {
    const files = Array.from(event.target.files) as File[];
    event.target.value = '';
    files.forEach((file: File) => this.fileSelected.emit(file));
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragenter', ['$event'])
  public onDragEnter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragDepth++;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragDepth--;
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragDepth = 0;
    const files = Array.from(event.dataTransfer.files) as File[];
    files.forEach((file: File) => this.fileSelected.emit(file));
  }
}
