<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="cube"></nb-icon>
          TGA Typen
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input id="textFilter" type="text" nbInput formControlName="text" placeholder="Filter Text...">
              </div>
              <div class="filter-control-group">
                <label class="label" for="equipmentType">Typ</label>
                <wr-equipment-type-select id="equipmentType" formControlName="equipmentType" [showEmptyOption]="true"></wr-equipment-type-select>
              </div>
              <div class="filter-control-group">
                <label class="label" for="maintenanceInterval">Wartungsintervall</label>
                <wr-check-interval-select id="maintenanceInterval" formControlName="maintenanceInterval" [showEmptyOption]="true"></wr-check-interval-select>
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
              <button nbButton ghost status="primary" (click)="add()">
                <nb-icon pack="eva" icon="plus-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <!--<nb-card class="list-data-card" [nbSpinner]="(loading$|async)===true">
    <nb-card-header>
      <form [formGroup]="form" class="row-header">
        <wr-order-toggle-button class="text-item" [title]="'Name'" [key]="'name'" formControlName="sort"></wr-order-toggle-button>
        <wr-order-toggle-button class="middle-item" [title]="'Typ'" [key]="'equipmentType'" formControlName="sort"></wr-order-toggle-button>
        <wr-order-toggle-button class="middle-item" [title]="'Wartungsintervall'" [key]="'checkInterval'" formControlName="sort"></wr-order-toggle-button>
      </form>
    </nb-card-header>
    <nb-card-body class="list-card-body">
      <nb-list>
        <nb-list-item *ngFor="let equipment of equipments$ | async; let i = index">
          <div class="data-container" (click)="navigateToEquipment(equipment.id!)">
            <span class="text-item">{{equipment.name}}</span>
            <span class="middle-item">{{EquipmentUtils.getEquipmentType(equipment.equipmentType)}}</span>
            <span class="middle-item">{{EquipmentUtils.getMaintenanceInterval(equipment.maintenanceInterval)}}</span>
          </div>
          <wr-edit-delete-button-group (onEdit)="navigateToEquipment(equipment.id!)" (onDelete)="onDelete(equipment.id!)"></wr-edit-delete-button-group>
        </nb-list-item>
      </nb-list>
    </nb-card-body>
    <nb-card-footer>
      <ng-container *ngIf="form">
        <form [formGroup]="form" class="pagination-row">
          <wr-page-size-selector formControlName="size"></wr-page-size-selector>
          <wr-pagination-button-group *ngIf="count$ | async as count" [entityCount]="count" [size]="size" formControlName="page"></wr-pagination-button-group>
        </form>
      </ng-container>
    </nb-card-footer>
  </nb-card>-->
  <wr-data-table
  [data$]="equipments$"
  [storeUsage]="equipmentStoreUsage"
  [sortKey]="'equipmentSort'"
  (lineClick)="navigateToEquipment($event)"
  [defaultSort]="'name'"
  >
    <ng-template
      dataColumn
      let-equipment
      key="name"
      display="Name">
      <div *ngIf="equipment.name">
        {{equipment.name}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-equipment
      key="equipmentType"
      display="Typ">
      <div *ngIf="equipment.equipmentType">
        {{equipment.equipmentType | equipmentTypeTranslation }}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-equipment
      key="maintenanceInterval"
      display="Wartungsintervall">
      <div *ngIf="equipment.maintenanceInterval">
        {{equipment.maintenanceInterval | maintenanceIntervalTranslation }}
      </div>
    </ng-template>
  </wr-data-table>
</div>

