/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MaintenanceTimerItemDto { 
    id: string;
    created: string;
    type: MaintenanceTimerItemDto.TypeEnum;
    buildingId: string;
    buildingEquipmentId: string;
    equipmentName: string;
    startDate: string;
    durationDays: number;
    nextDate: string;
    reminderDurationDays: number;
}
export namespace MaintenanceTimerItemDto {
    export type TypeEnum = 'Maintenance' | 'Inspection';
    export const TypeEnum = {
        Maintenance: 'Maintenance' as TypeEnum,
        Inspection: 'Inspection' as TypeEnum
    };
}


