<nb-select fullWidth [(selected)]="value" [disabled]="isDisabled">
  <nb-option *ngIf="recommendedValue" [value]="recommendedValue">
    {{recommendedValue | deadlineTranslation}}
    <nb-icon icon="flash" pack="eva" status="info" nbTooltip="Empfohlen durch Feststellung" nbTooltipStatus="info"></nb-icon>
  </nb-option>
  <nb-option *ngFor="let deadline of enumValuesWithoutRecommended"
             [value]="deadline">
    {{deadline | deadlineTranslation}}
  </nb-option>
</nb-select>
