import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingClassSelectComponent } from './building-class-select/building-class-select.component';
import { ActionSelectComponent } from './action-select/action-select.component';
import { BuildingSelectComponent } from './building-select/building-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeadlineSelectComponent } from './deadline-select/deadline-select.component';
import { NebularModule } from '../nebular/nebular.module';
import { StatusSelectComponent } from './status-select/status-select.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { OrganisationSelectComponent } from './organisation-select/organisation-select.component';
import { BuildingTypeSelectComponent } from './building-type-select/building-type-select.component';
import { EquipmentTypeSelectComponent } from './equipment-type-select/equipment-type-select.component';
import { CheckIntervalSelectComponent } from './check-interval-select/check-interval-select.component';
import { CheckpointGroupSelectComponent } from './checkpoint-group-select/checkpoint-group-select.component';
import { AttachmentUploadModule } from './attachment-upload/attachment-upload.module';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { NormTypeSelectComponent } from './norm-type-select/norm-type-select.component';
import { AutomaticAddressComponent } from './automatic-address/automatic-address.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { AssessmentPresetSelectComponent } from './assessment-preset-select/assessment-preset-select.component';
import { BuildingAtticSelectComponent } from './building-attic-select/building-attic-select.component';
import { PipesModule } from '../pipes/pipes.module';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ThresholdSliderComponent } from './threshold-slider/threshold-slider.component';
import { ConditionSelectComponent } from '@shared/form-controls/condition-select/condition-select.component';
import { ElementTypeSelectComponent } from './element-type-select/element-type-select.component';
import { ValueTypeSelectComponent } from './value-type-select/value-type-select.component';
import {
  ContractorMultiSelectComponent,
} from '@shared/form-controls/contractor-multi-select/contractor-multi-select.component';
import { RepairTaskStatusSelectComponent } from './repair-task-status-select/repair-task-status-select.component';
import { ContractorSelectComponent } from './contractor-select/contractor-select.component';
import { CustomFieldsSelectComponent } from './custom-fields-select/custom-fields-select.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import {
  CustomFieldCollectionsSelectComponent,
} from './custom-field-collections-select/custom-field-collections-select.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { CustomFormModule } from '@shared/custom-form/custom-form.module';
import { TagSelectComponent } from './tag-select/tag-select.component';
import { TagMultiSelectComponent } from '@shared/form-controls/tag-multi-select/tag-multi-select.component';
import { EquipmentSelectComponent } from './equipment-select/equipment-select.component';
import {
  BuildingEquipmentMultiSelectComponent,
} from './building-equipment-multi-select/building-equipment-multi-select.component';
import { ComponentsModule } from '@shared/components/components.module';
import { BuildingEquipmentsEditorComponent } from './building-equipments-editor/building-equipments-editor.component';
import { PinInputComponent } from './pin-input/pin-input.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { BuildingSectionMultiSelectComponent } from './building-section-multi-select/building-section-multi-select.component';
import { UserMultiSelectComponent } from '@shared/form-controls/user-multi-select/user-multi-select.component';
import {
  OrganisationTypeSelectComponent
} from '@shared/form-controls/organisation-type-select/organisation-type-select.component';
import {
  InspectionEntryCommentComponent
} from "@shared/form-controls/inspection-entry-comment/inspection-entry-comment.component";
import { NotificationVariantSelectComponent } from './notification-variant-select/notification-variant-select.component';
import { NotificationTypeSelectComponent } from './notification-type-select/notification-type-select.component';
import { EmailTypeSelectComponent } from './email-type-select/email-type-select.component';
import { BuildingMultiSelectComponent } from './building-multi-select/building-multi-select.component';
import { OrganisationMultiSelectComponent } from './organisation-multi-select/organisation-multi-select.component';

@NgModule({
  declarations: [
    BuildingClassSelectComponent,
    ActionSelectComponent,
    BuildingSelectComponent,
    DeadlineSelectComponent,
    StatusSelectComponent,
    UserSelectComponent,
    OrganisationSelectComponent,
    BuildingTypeSelectComponent,
    EquipmentTypeSelectComponent,
    CheckIntervalSelectComponent,
    CheckpointGroupSelectComponent,
    DateRangePickerComponent,
    NormTypeSelectComponent,
    DatePickerComponent,
    AutomaticAddressComponent,
    TimePickerComponent,
    AssessmentPresetSelectComponent,
    BuildingAtticSelectComponent,
    ThresholdSliderComponent,
    ConditionSelectComponent,
    ColorPickerComponent,
    TagMultiSelectComponent,
    TagSelectComponent,
    ElementTypeSelectComponent,
    ValueTypeSelectComponent,
    ContractorMultiSelectComponent,
    RepairTaskStatusSelectComponent,
    ContractorSelectComponent,
    CustomFieldsSelectComponent,
    CustomFieldCollectionsSelectComponent,
    PinInputComponent,
    EquipmentSelectComponent,
    BuildingEquipmentMultiSelectComponent,
    BuildingEquipmentsEditorComponent,
    RichTextEditorComponent,
    BuildingSectionMultiSelectComponent,
    UserMultiSelectComponent,
    OrganisationTypeSelectComponent,
    InspectionEntryCommentComponent,
    NotificationVariantSelectComponent,
    NotificationTypeSelectComponent,
    EmailTypeSelectComponent,
    BuildingMultiSelectComponent,
    OrganisationMultiSelectComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    NebularModule,
    ReactiveFormsModule,
    PipesModule,
    DirectivesModule,
    NgxSliderModule,
    CustomFormModule,
    ComponentsModule,
    EditorComponent,
  ],
  exports: [
    BuildingClassSelectComponent,
    ActionSelectComponent,
    BuildingSelectComponent,
    DeadlineSelectComponent,
    StatusSelectComponent,
    OrganisationSelectComponent,
    UserSelectComponent,
    BuildingTypeSelectComponent,
    EquipmentTypeSelectComponent,
    CheckIntervalSelectComponent,
    CheckpointGroupSelectComponent,
    AttachmentUploadModule,
    DateRangePickerComponent,
    NormTypeSelectComponent,
    DatePickerComponent,
    AutomaticAddressComponent,
    TimePickerComponent,
    AssessmentPresetSelectComponent,
    BuildingAtticSelectComponent,
    ThresholdSliderComponent,
    ConditionSelectComponent,
    ColorPickerComponent,
    TagMultiSelectComponent,
    ElementTypeSelectComponent,
    ValueTypeSelectComponent,
    ContractorMultiSelectComponent,
    RepairTaskStatusSelectComponent,
    ContractorSelectComponent,
    CustomFieldsSelectComponent,
    CustomFieldCollectionsSelectComponent,
    EquipmentSelectComponent,
    BuildingEquipmentMultiSelectComponent,
    BuildingEquipmentsEditorComponent,
    PinInputComponent,
    RichTextEditorComponent,
    TagSelectComponent,
    BuildingSectionMultiSelectComponent,
    UserMultiSelectComponent,
    OrganisationTypeSelectComponent,
    InspectionEntryCommentComponent,
    NotificationVariantSelectComponent,
    NotificationTypeSelectComponent,
    EmailTypeSelectComponent,
    BuildingMultiSelectComponent,
    OrganisationMultiSelectComponent,
  ],
})
export class FormControlsModule {
}
