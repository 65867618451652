import { Component } from '@angular/core';
import { PendingChanges } from '@guards/pending-changes.guard';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CheckpointDetailDto, CheckpointGroupItemDto, CheckpointGroupService, CheckpointItemDto,
  CheckpointService,
  TemplateDetailDto,
  TemplateMergeDto,
  TemplateService,
} from '@artemis-software/wr-api';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { CheckpointDialogComponent } from '@components/dialog/checkpoint-dialog/checkpoint-dialog.component';
import { Select } from '@ngxs/store';
import { CheckpointGroupState } from '@/store/checkpointGroup/checkpointGroup.state';

@Component({
  selector: 'wr-template-detail-page',
  templateUrl: './template-detail-page.component.html',
  styleUrls: ['./template-detail-page.component.scss']
})
export class TemplateDetailPageComponent implements PendingChanges {

  isEditing = false;
  firstSubmit = false;
  form!: FormGroup;

  saving$ = new BehaviorSubject(false);

  @Select(CheckpointGroupState.checkpointGroups)
  checkpointGroups$!: Observable<CheckpointGroupItemDto[]>;

  groupVisibility: { [key: number]: boolean } = {};

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly templateService: TemplateService,
    private readonly dialogService: NbDialogService,
    private readonly toastrService: NbToastrService,
    private readonly checkpointService: CheckpointService,
    private readonly nbToastrService: NbToastrService,
    private readonly checkpointGroupService: CheckpointGroupService,
  ) {
    route.paramMap.subscribe(async (params) => {
      const id = params.get('id');
      if (id) {
        if (id === 'new') {
          this.isEditing = true;
          await this.initForm();
        } else {
          const template = await firstValueFrom(this.templateService.findById(id));
          await this.initForm(template);
          this.form.disable();
        }
      } else {
        this.router.navigate(['templates']);
      }
    });
  }

  async initForm(dto?: TemplateDetailDto) {
    const idControl = new FormControl(dto?.id);
    const checkpoints = dto?.templateCheckpoints.map(checkpoint => this.createCheckpointFormGroup(checkpoint.checkpoint)) || [];
    this.sortCheckpointsArray(checkpoints);

    this.form = new FormGroup({
      id: idControl,
      name: new FormControl(dto?.name, [Validators.required]),
      checkpoints: new FormArray(checkpoints),
    });
  }

  sortCheckpointsArray(checkpoints: FormGroup[]) {
    checkpoints.sort((a, b) => {
      const groupA = String(a.get('group')?.value || '');
      const groupB = String(b.get('group')?.value || '');
      const numberA = Number(a.get('number')?.value || 0);
      const numberB = Number(b.get('number')?.value || 0);
      return groupA.localeCompare(groupB) || numberA - numberB;
    });
  }


  createCheckpointFormGroup(checkpoint: CheckpointDetailDto | CheckpointItemDto): FormGroup {
    let group = 0;
    if ('checkpointGroupName' in checkpoint) {
      group = checkpoint.checkpointGroupNumber;
    }
    if ('checkpointGroup' in checkpoint) {
      group = checkpoint.checkpointGroup.number;
    }
    const number = checkpoint.number || 0;
    return new FormGroup({
      id: new FormControl(checkpoint.id),
      name: new FormControl(checkpoint.name),
      number: new FormControl(number),
      group: new FormControl(group),
    });
  }

  get checkpoints(): FormArray {
    return this.form.get('checkpoints') as FormArray;
  }

  async addCheckpointToTemplate(checkpoint: CheckpointDetailDto | CheckpointItemDto) {
    const checkpointItem = this.createCheckpointFormGroup(checkpoint);
    this.checkpoints.push(checkpointItem);
    this.sortCheckpoints();
    this.form.markAsDirty();
    this.nbToastrService.success('Checkpunkt zur Vorlage hinzugefügt', 'Erfolg');
  }

  sortCheckpoints() {
    const sorted = [...this.checkpoints.controls].sort((a, b) => {
      const groupA = String(a.get('group')?.value || '');
      const groupB = String(b.get('group')?.value || '');
      const numberA = Number(a.get('number')?.value || 0);
      const numberB = Number(b.get('number')?.value || 0);
      return groupA.localeCompare(groupB) || numberA - numberB;
    });
    this.checkpoints.clear();
    sorted.forEach(control => this.checkpoints.push(control));
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.isEditing;
  }

  async navigateBack() {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      await this.router.navigate(['templates']);
    }
  }

  onEdit() {
    this.isEditing = true;
    this.form?.enable();
  }

  async onDelete() {
    const result = await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Vorlage löschen',
        message: 'Bist du sicher, dass du diese Vorlage löschen willst?',
      },
    }).onClose);
    if (result) {
      await firstValueFrom(this.templateService.deleteById(this.form?.value.id));
      this.router.navigate(['templates']);
      this.toastrService.success('Vorlage wurde erfolgreich gelöscht', 'Vorlage')
    }
  }

  hasError(controlName: string) {
    const control = this.form?.get(controlName);
    return this.firstSubmit && !!control?.errors;
  }

  async submit(): Promise<void> {
    this.firstSubmit = true;
    this.form?.markAsTouched();
    this.form.updateValueAndValidity();

    if (!this.form?.valid) {
      this.toastrService.danger('Vorlage kann nicht gespeichert werden, es gibt noch Fehler.', 'Gebäude');
      return;
    }

    try {
      this.saving$.next(true);

      const templateMergeDto: TemplateMergeDto = {
        ...this.form?.value,
        templateCheckpointIds: this.checkpoints.value.map((checkpoint: any, index: number) => ({
          orderSequence: index + 1,
          id: null,
          checkpointId: checkpoint.id
        }))
      };

      const updatedTemplate = await firstValueFrom(this.templateService.merge(templateMergeDto));

      this.isEditing = false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['template', updatedTemplate.id]).then(() => {
          this.toastrService.success('Vorlage wurde erfolgreich gespeichert', 'Vorlage');
        });
      });
    } catch (e) {
      console.error(e);
      this.toastrService.danger('Vorlage konnte nicht gespeichert werden.', 'Fehler');
    } finally {
      this.saving$.next(false);
    }
  }

  cancel() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['template', this.form?.value.id]);
    });
  }

  async showAddCheckpointDialog() {
    try {
      const checkpointId = await firstValueFrom(this.dialogService.open(CheckpointDialogComponent, {
        context: {},
      }).onClose);
      if (checkpointId) {
        const checkpoint = await firstValueFrom(this.checkpointService.findById(checkpointId));
        await this.addCheckpointToTemplate(checkpoint);
      }
    } catch (err) {
      console.error(err);
      this.nbToastrService.danger('Fehler beim auswählen eines Prüfpunktes', 'Fehler');
    }
  }

  deleteCheckpoint(id: string) {
    const index = this.checkpoints.controls.findIndex(control => control.value.id === id);
    if (index !== -1) {
      this.checkpoints.removeAt(index);
      this.form.markAsDirty();
      this.nbToastrService.success('Prüfpunkt entfernt', 'Erfolg');
    } else {
      console.error(`Checkpoint with ID ${id} not found.`);
    }
  }

  getFilteredCheckpoints(groupNumber: number): FormGroup[] {
    return this.checkpoints.controls.filter(c => c.value.group === groupNumber) as FormGroup[];
  }

  toggleGroupVisibility(groupNumber: number) {
    this.groupVisibility[groupNumber] = !this.groupVisibility[groupNumber];
  }

  isGroupVisible(groupNumber: number): boolean {
    return this.groupVisibility[groupNumber];
  }
}
