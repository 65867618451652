<div class="building-section-select noselect">
  <div class="button-group">
    <div class="button street"
         *ngFor="let street of streets$ | async"
         [class.selected]="street === selectedStreet$.value"
         (click)="selectStreet(street)">
      {{street}}
    </div>
  </div>
  <nb-icon icon="chevron-right-outline" pack="eva"></nb-icon>
  <div class="button-group gap">
    <div class="button number"
         *ngFor="let section of buildingSectionsByStreet$ | async"
         [class.selected]="section.id === selectedSection?.id"
         (click)="selectSection(section)">
      {{section.address.number}}
    </div>
  </div>
</div>
