import { Component, Input } from '@angular/core';

@Component({
  selector: 'wr-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {

  @Input()
  header: string = '';

  @Input()
  text: string = '';

}
