import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';
import { CustomFieldItemDto } from '@artemis-software/wr-api';

@Component({
  selector: 'dok-element-type-select',
  templateUrl: './element-type-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ElementTypeSelectComponent,
      multi: true,
    },
  ],
})
export class ElementTypeSelectComponent extends AbstractFormControl<CustomFieldItemDto.ElementTypeEnum> {
  readonly values = [CustomFieldItemDto.ElementTypeEnum.Single, CustomFieldItemDto.ElementTypeEnum.Multiple];

  @Input()
  placeholder = 'Elementtyp wählen';
}
