export function createGlobalState<T>(fn: () => T): () => T {
  let state: { value: T } | undefined;

  return () => {
    if (!state) {
      state = { value: fn() };
    }

    return state.value;
  };
}
