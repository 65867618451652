<div class="comment-container" [formGroup]="formGroup">
  <ng-container formArrayName="comments" *ngIf="comments.controls.length > 0; else noComments">
    <ng-container *ngFor="let comment of comments.controls; let i = index">
      <div class="comment" [formGroupName]="i">
        <div class="comment-infos">
          <nb-user *ngIf="(getCreatedBy(i) | async) as currentUser" size="small"
                   name="{{currentUser.firstName}} {{currentUser.lastName}}"
                   [picture]="getUserProfile(currentUser)">
          </nb-user>
          <span class="created">
              {{ getCreated(i) }}
            <span *ngIf="isEdited(i)"> (bearbeitet</span>
            <span
              *ngIf="checkedBy(i) as checkedBy">, akzeptiert von {{ checkedBy.firstName + ' ' + checkedBy.lastName }}</span>
            <span *ngIf="isEdited(i)">)</span>
          </span>
        </div>
        <div class="comment-row">
          <textarea nbInput fullWidth id="text" formControlName="text" rows="3"
                    [readOnly]="!(canEdit(comment.get('createdBy')?.value, currentUser$ | async))">
          </textarea>
          <div class="actions">
            <button nbButton status="success" (click)="uncheck(i)" *ngIf="isChecked(i)" [disabled]="comments.disabled"
                    nbTooltip="Status ändern">
              <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
            </button>
            <button nbButton status="basic" (click)="check(i)" *ngIf="!isChecked(i)" [disabled]="comments.disabled"
                    nbTooltip="Status ändern">
              <nb-icon pack="eva" icon="minus-outline"></nb-icon>
            </button>
            <button nbButton status="basic" (click)="removeComment(i)" nbTooltip="Kommentar entfernen"
                    [disabled]="comments.disabled">
              <nb-icon pack="eva" icon="trash-2-outline"></nb-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <button nbButton status="warning" (click)="addComment()" [disabled]="comments.disabled"
          nbTooltip="Neuen Kommentar hinzufügen">
    <nb-icon pack="eva" icon="message-circle-outline"></nb-icon>
  </button>
</div>

<ng-template #noComments>
  <span class="text-hint">Keine Kommentare</span>
</ng-template>
