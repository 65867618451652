import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wr-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {

  @Input()
  search: string | null | undefined = '';

  @Output()
  searchChange = new EventEmitter<string>();

  clear(event: Event): void {
    event.stopPropagation();
    this.search = '';
    this.searchChange.emit(this.search);
  }
}
