<div class="flex-container" [nbSpinner]="(loading$|async)!!">
  <wr-repair-task-status-icon [status]="repairTask.status"></wr-repair-task-status-icon>
  <div class="left-column">
    <div class="title">{{repairTask.title}}</div>
    <div class="deadline" *ngIf="repairTask.deadline as deadline">Frist: {{deadline | date: 'dd.MM.yyyy'}}</div>
  </div>
  <div class="middle-column">
    <wr-repair-task-status-text [status]="repairTask.status"></wr-repair-task-status-text>
  </div>
  <div class="right-column">
    <button *ngIf="showDeleteButton && isAdmin()"
            nbButton
            status="danger"
            ghost
            nbTooltip="Löschen"
            nbTooltipStatus="danger"
            (click)="showDeleteDialog($event)">
      <nb-icon pack="eva" icon="trash-2-outline"></nb-icon>
    </button>
    <button *ngIf="showConfirmButton && isAdmin()"
            nbButton
            status="success"
            nbTooltip="Bestätigen"
            nbTooltipStatus="success"
            (click)="setStatusFinished($event)">
      <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
    </button>
  </div>
</div>

