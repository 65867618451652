<div [nbSpinner]="isLoading" class="data-table">
  <table>
    <thead>
    <tr>
      <th *ngFor="let column of columns"
          [ngClass]="getHeaderClasses(column)"
          (click)="toggleSort(column)"
      >{{column.header}}
        <ng-container *ngIf="getSortIcon(column) as icon">
          <nb-icon [icon]="icon" pack="eva"></nb-icon>
        </ng-container>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of rows; index as index" [ngClass]="getRowClasses(row)" (click)="navigateRow(row)">
      <td *ngFor="let column of columns"
          [ngClass]="getCellClasses(column)">
        <ng-container *ngTemplateOutlet="column.template; context: { $implicit: row, index: index }"></ng-container>
      </td>
    </tr>
    <tr *ngIf="rows.length === 0 && columns">
      <td [colSpan]="columns.length">
        <h5 class="empty-message">Keine Einträge</h5>
      </td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="pagination && pagination.totalRows > 0" class="pagination">
    <wr-page-size-selector [ngModel]="pagination.pageSize"
                           (ngModelChange)="pagination.pageSizeChange.emit($event)"></wr-page-size-selector>
    <wr-pagination-button-group [entityCount]="pagination.totalRows"
                                [size]="pagination.pageSize"
                                [ngModel]="pagination.page"
                                (ngModelChange)="pagination.pageChange.emit($event)" />
    <div></div>
  </div>
</div>
