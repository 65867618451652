<div class="cockpit-detail-view">
  <div class="header">
    <div class="group">{{getGroupName(entry)}}
      <nb-icon icon="chevron-right-outline" pack="eva"></nb-icon>
    </div>
    <div class="checkpoint">{{getCheckpointString(entry)}}</div>
    <ng-container *ngIf="addressResolver$|async as addressLoader">
      <ng-container *ngIf="addressLoader(buildingSection?.addressId) as address">
        <div class="address">
          <nb-icon icon="pin-outline" pack="eva"></nb-icon>
          <span>{{address.street}} {{address.number}}</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <wr-collapsible-area id="entry-details" title="Details">
    <div class="entry-details">
      <div class="info">
        <label>Feststellung</label>
        <div class="value">{{entry.controls.predefinedAssessment.value?.text ?? 'Keine Feststellung'}}</div>
      </div>
      <div class="info" *ngIf="entry.controls.action.value as action">
        <label>Maßnahme</label>
        <div class="value">{{action | actionTranslation}}</div>
      </div>
      <div class="info" *ngIf="entry.controls.deadline.value as deadline">
        <label>Frist</label>
        <div class="value">{{deadline | deadlineTranslation}}</div>
      </div>
      <div class="info" *ngIf="entry.controls.note.value as note">
        <label>Beschreibung</label>
        <div class="value">{{note}}</div>
      </div>
      <ng-container *ngIf="entry.controls.attachments.value as attachments">
        <div class="info" *ngIf="attachments.length">
          <label>Bilder & Dokumentenliste</label>
          <div class="value">
            <wr-attachments-carousel [attachments]="attachments"></wr-attachments-carousel>
          </div>
        </div>
      </ng-container>
    </div>
  </wr-collapsible-area>
  <hau-form *ngIf="formWrapper.form$|async as form" [formGroup]="form">
    <hau-form-field label="TGAs" [validation]="entry.validation.buildingEquipments">
      <wr-building-equipments-editor [formControl]="entry.controls.buildingEquipments"
                                     [building]="building"
                                     [buildingSection]="buildingSection"></wr-building-equipments-editor>
    </hau-form-field>
  </hau-form>
</div>
