import { Directive, Input, TemplateRef } from '@angular/core';
import { MaybeBoolean } from '@/utils/inputs';

@Directive({
  selector: '[column]',
})
export class ColumnDirective<T> {

  @Input() of!: T[];

  @Input() header: string | undefined;

  @Input() shrink?: MaybeBoolean;

  @Input() sort?: string;

  @Input() maxWidth?: string;

  get sortable() {
    return this.sort && this.sort.trim().length > 0;
  }

  constructor(
    public readonly template: TemplateRef<any>,
  ) {
  }

  static ngTemplateContextGuard<T>(
    dir: ColumnDirective<T>,
    ctx: unknown,
  ): ctx is { $implicit: T; index: number } {
    return true;
  }

}
