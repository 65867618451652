import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailValidationService {

  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }
}
