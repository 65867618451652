import { Component, Input, OnInit } from '@angular/core';
import { BuildingItemDto, InspectionItemDto, InspectionService } from '@artemis-software/wr-api';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { tryWithLoading } from '@/utils/async-utils';

@Component({
  templateUrl: './map-overview-popup-panel.component.html',
  styleUrls: ['./map-overview-popup-panel.component.scss'],
})

export class MapOverviewPopupPanelComponent implements OnInit {

  @Input()
  building: BuildingItemDto | undefined;

  lastInspection$ = new BehaviorSubject<InspectionItemDto | undefined>(undefined);

  loading$ = new BehaviorSubject<boolean>(false);

  get buildingIdentifier(): string {
    const name = this.building?.name?.length ? this.building.name : 'Gebäude';
    return `${name} (${this.building?.internalNumber})`;
  }

  constructor(
    private readonly inspectionService: InspectionService,
  ) {
  }

  ngOnInit() {
    this.getLastInspection();
  }

  private async getLastInspection() {
    await tryWithLoading(this.loading$, async () => {
      const lastInspections = await firstValueFrom(this.inspectionService.findLastInspectionByBuilding({
        buildingId: this.building?.id,
      }));
      if (!lastInspections.length) {
        this.lastInspection$.next(undefined);
      } else {
        this.lastInspection$.next(lastInspections[0]);
      }
    });
  }
}
