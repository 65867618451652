<div class="search-row">
  <wr-search-bar class="grow"
                 [search]="searchText"
                 (searchChange)="searchTextChange.emit($event)">
  </wr-search-bar>
  <button *ngIf="addItemClicked.observed"
          nbButton
          status="primary"
          nbTooltip="Neu hinzufügen"
          nbTooltipStatus="primary"
          (click)="addItemClicked.emit()">
    <nb-icon icon="plus-outline" pack="eva"></nb-icon>
  </button>
</div>

<div *ngIf="items?.length; else noItems" class="list-view noselect" [nbSpinner]="!items">
  <div class="list-item" *ngFor="let item of items"
       (click)="selectedChange.emit(item)"
       [class.selected]="selected === item">
    <ng-container *ngIf="listItemTemplate?.templateRef as template; else noTemplate"
                  [ngTemplateOutlet]="template"
                  [ngTemplateOutletContext]="{$implicit: item}">
    </ng-container>
    <ng-template #noTemplate>
      <nb-alert status="warning">
        No template provided
      </nb-alert>
    </ng-template>
  </div>
</div>

<ng-template #noItems>
  <span class="text-hint no-items">
    <nb-icon icon="slash-outline" pack="eva"></nb-icon>
    <span>Keine Einträge gefunden</span>
  </span>
</ng-template>
