import { ContractorFilterDto, ContractorItemDto, ContractorService } from '@artemis-software/wr-api';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {
  ChangeContractorFilter,
  CountContractors,
  FilterContractors,
  LoadAllContractors,
} from '@/store/contractor/contractor.action';

type ContractorStateModel = {
  contractors: ContractorItemDto[],
  filteredContractors: ContractorItemDto[],
  count: number,
  loading: boolean,
  filter: ContractorFilterDto,
}

@State<ContractorStateModel>({
  name: 'contracts',
  defaults: {
    contractors: [],
    filteredContractors: [],
    count: 0,
    loading: false,
    filter: {} as ContractorFilterDto,
  },
})
@Injectable()
export class ContractorState implements NgxsOnInit {
  constructor(
    private readonly store: Store,
    private readonly contractorService: ContractorService,
    private readonly authService: AuthService,
  ) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllContractors());
    });
  }

  @Selector()
  static contractors(state: ContractorStateModel): ContractorItemDto[] {
    return state.contractors;
  }

  @Selector()
  static filteredContractors(state: ContractorStateModel): ContractorItemDto[] {
    return state.filteredContractors;
  }

  @Selector()
  static contractsLoading(state: ContractorStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: ContractorStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: ContractorStateModel): ContractorFilterDto {
    return state.filter;
  }

  @Action(LoadAllContractors)
  loadAllContractors(ctx: StateContext<ContractorStateModel>, action: LoadAllContractors): void {
    ctx.patchState({
      loading: true,
    });

    this.contractorService.findAll({}).subscribe((contractors: ContractorItemDto[]) => {
      ctx.patchState({
        contractors,
        loading: false,
      });
    });
  }

  @Action(FilterContractors)
  filterContractors(ctx: StateContext<ContractorStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.contractorService.findAll(ctx.getState().filter).subscribe((contractors: ContractorItemDto[]) => {
      ctx.patchState({
        filteredContractors: contractors,
        loading: false,
      });
    });
  }

  @Action(CountContractors)
  countContractors(ctx: StateContext<ContractorStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.contractorService.getCount(ctx.getState().filter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeContractorFilter)
  changeContractorFilter(ctx: StateContext<ContractorStateModel>, action: ChangeContractorFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountContractors());
    this.store.dispatch(new FilterContractors());
  }
}
