import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-pagination-button-group',
  templateUrl: './pagination-button-group.component.html',
  styleUrls: ['./pagination-button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PaginationButtonGroupComponent,
    },
  ],
})
export class PaginationButtonGroupComponent implements ControlValueAccessor {

  @Input() entityCount = 0;
  @Input() size = 1;
  page = 0;
  onChange = (_: number) => {
  };
  onTouched = () => {
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  resetPage(): void {
    this.page = 0;
    this.onChange(this.page);
  }

  nextPage(): void {
    this.page++;
    this.onChange(this.page);
  }

  previousPage(): void {
    this.page--;
    this.onChange(this.page);
  }

  endPage(): void {
    this.page = this.maxPage;
    this.onChange(this.page);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(page: number): void {
    this.page = page;
  }

  get maxPage(): number {
    return Math.ceil(this.entityCount / this.size) - 1;
  }
}
