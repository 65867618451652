import { Pipe } from '@angular/core';
import { InspectionEntryDetailDto } from '@artemis-software/wr-api';
import { EnumTranslationPipe } from './enum-translation.pipe';

@Pipe({
  name: 'actionTranslation',
})
export class ActionTranslationPipe implements EnumTranslationPipe<InspectionEntryDetailDto.ActionEnum> {

  transform(value: InspectionEntryDetailDto.ActionEnum): string {
    switch (value) {
    case InspectionEntryDetailDto.ActionEnum.ImminentDanger:
      return 'Gefahr im Verzug';
    case InspectionEntryDetailDto.ActionEnum.ActionNecessary:
      return 'Aktion notwendig';
    case InspectionEntryDetailDto.ActionEnum.ActionRecommended:
      return 'Aktion empfohlen';
    case InspectionEntryDetailDto.ActionEnum.RenovationNecessary:
      return 'Renovierung notwendig';
    case InspectionEntryDetailDto.ActionEnum.RiskAnalysisRecommended:
      return 'Risikoanalyse empfohlen';
    case InspectionEntryDetailDto.ActionEnum.NoActionNecessary:
      return 'Keine Aktion notwendig';
    default:
      throw new Error(`Unknown action: ${value}`);
    }
  }
}
