import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';


@Injectable()
export class SessionService {

  constructor(
    private authService: AuthService,
  ) {
  }

  private initialized = false;

  private ws?: WebSocket;

  init() {
    if (this.initialized) return;
    this.initialized = true;

    this.authService.authCredentials$.subscribe(cred => {
      if (!cred) {
        if (this.ws) {
          this.ws.close();
          this.ws = undefined;
        }
        return;
      }

      const url = new URL(environment.apiUrl);
      url.protocol = url.protocol.replace('http', 'ws');
      url.pathname = 'session';
      url.searchParams.set('Authorization', `Bearer ${cred.token}`);

      this.ws = new WebSocket(url.toString());
      this.ws.onmessage = (event) => {
        console.log(event.data);
      };
    });
  }

}
