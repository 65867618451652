import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { InspectionFormType } from '@pages/inspection-detail-page-v2/inspection-util';

export class InspectionFormWrapper {
  enabled$ = new BehaviorSubject<boolean>(false);
  form$ = new BehaviorSubject<InspectionFormType | undefined>(undefined);

  set form(value: InspectionFormType | undefined) {
    this.form$.next(value);
    this.enabled$.next(value?.enabled ?? false);
  }

  get controls() {
    return this.form?.controls;
  }

  get form(): InspectionFormType | undefined {
    return this.form$.value;
  }

  get enabled(): boolean {
    return this.enabled$.value;
  }

  get disabled(): boolean {
    return !this.enabled;
  }

  get valid(): boolean {
    return this.form?.valid ?? false;
  }

  get dirty(): boolean {
    return this.form?.dirty ?? false;
  }

  get(control: string): AbstractControl {
    return this.form?.get(control) as AbstractControl;
  }

  updateValueAndValidity(opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }): void {
    this.form?.updateValueAndValidity(opts);
  }

  enable(): void {
    this.form?.enable({ emitEvent: false });
    this.enabled$.next(true);

    const id = this.form?.get('id')?.value;
    if (id) {
      this.form?.get('building')?.disable();
    }
  }

  disable(): void {
    this.form?.disable({ emitEvent: false });
    this.enabled$.next(false);
  }

  markAllAsTouched(): void {
    this.form?.markAllAsTouched();
  }

  async getMappedValue() {
    if (!this.form) throw new Error('Form is not defined');
    return this.form!.getMappedValue();
  }

  markAsPristine(): void {
    this.form?.markAsPristine();
  }
}
