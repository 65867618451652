import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PendingChanges } from '@guards/pending-changes.guard';
import { BehaviorSubject, combineLatest, debounceTime, firstValueFrom, takeUntil } from 'rxjs';
import {
  CustomFieldDetailDto,
  CustomFieldItemDto,
  CustomFieldMergeDto,
  CustomFieldService,
} from '@artemis-software/wr-api';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { CustomFieldDialogComponent } from './custom-field-dialog/custom-field-dialog.component';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import {
  ListDetailSplitViewComponent,
} from '@components/split-view/list-detail-split-view/list-detail-split-view.component';

@Component({
  selector: 'wr-custom-field-split-view',
  templateUrl: './custom-field-split-view.component.html',
  styleUrls: ['./custom-field-split-view.component.scss'],
})
export class CustomFieldSplitViewComponent implements PendingChanges, OnInit, OnDestroy {

  @ViewChild(ListDetailSplitViewComponent)
  splitView?: ListDetailSplitViewComponent<CustomFieldItemDto, CustomFieldDetailDto>;

  readonly loadDetail = (item: CustomFieldItemDto) => this.customFieldService.findById(item.id);
  readonly saveAction = (val: CustomFieldMergeDto) => this.save(val);
  readonly deleteAction = (val: CustomFieldMergeDto) => this.delete(val.id!);
  readonly deleteDialogAction = () => this.deleteDialog();

  loading$ = new BehaviorSubject<boolean>(false);
  searchText$ = new BehaviorSubject<string>('');
  reload$ = new BehaviorSubject<void>(undefined);
  fields$ = new BehaviorSubject<CustomFieldItemDto[] | undefined>(undefined);
  destroy$ = new BehaviorSubject<void>(undefined);

  constructor(
    private readonly customFieldService: CustomFieldService,
    private readonly nbDialogService: NbDialogService,
    private readonly nbToastrService: NbToastrService,
  ) {
    combineLatest([
      this.searchText$,
      this.reload$,
    ]).pipe(
      debounceTime(500),
    ).subscribe(async ([searchText]) => {
      this.fields$.next(undefined);
      const fields = await firstValueFrom(this.customFieldService.findAll({
        text: searchText.trim(),
        sort: { sort: 'name', order: 'ASC' },
      }));
      this.fields$.next(fields);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.reload$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.splitView?.refresh();
    });
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return false;
  }

  async showAddDialog(): Promise<void> {
    const result = await firstValueFrom(this.nbDialogService.open(CustomFieldDialogComponent).onClose);
    if (result) {
      await this.save(result);
    }
  }

  async save(formValue: CustomFieldMergeDto): Promise<void> {
    await firstValueFrom(this.customFieldService.merge(formValue));
    this.nbToastrService.success('Benutzerdefiniertes Feld gespeichert', 'Erfolg');
    this.reload$.next(undefined);
  }

  async deleteDialog(): Promise<boolean> {
    return await firstValueFrom(this.nbDialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Benutzerdefiniertes Feld löschen?',
        message: 'Möchten Sie das benutzerdefinierte Feld wirklich löschen?',
      },
    }).onClose);
  }

  async delete(id: string): Promise<void> {
    await firstValueFrom(this.customFieldService.deleteById(id));
    this.nbToastrService.success('Benutzerdefiniertes Feld gelöscht', 'Erfolg');
    this.reload$.next(undefined);
    this.splitView?.unselect();
  }
}
