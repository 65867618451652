import { Component, forwardRef } from '@angular/core';
import { RawEditorOptions } from 'tinymce';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true,
    },
  ],
})
export class RichTextEditorComponent extends AbstractFormControl<string>{
  readonly initOptions: RawEditorOptions = {
    base_url: '/tinymce',
    suffix: '.min',
    language: 'de',
    language_url: '/assets/tinymce/langs/de.js',
    promotion: false,
    menubar: false,
    statusbar: false,
    inline_boundaries: false,
    height: 300,
    resize: 'both',
  };

  readonly plugins = 'lists';
  readonly toolbar = 'undo redo | styles | forecolor | bold italic underline | bullist numlist';
}
