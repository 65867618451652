<nb-card>
  <nb-card-header>Reparaturaufträge</nb-card-header>
  <nb-card-body>
    <wr-table [rows]="repairTasks()"
              [loading]="loading()"
              [rowLink]="getInspectionLink">
      <ng-template column let-repairTask [of]="repairTasks()" header="Name">
        {{ repairTask.title }}
      </ng-template>
      <ng-template column let-repairTask [of]="repairTasks()" header="Prüfpunktzeile">
        <ng-container *ngIf="repairTask.inspectionEntry">
          {{repairTask.inspectionEntry.note || "-"}}
        </ng-container>
      </ng-template>
      <ng-template column let-repairTask [of]="repairTasks()" header="Frist">
        <ng-container *ngIf="repairTask.deadline">
          {{ repairTask.deadline | date: 'dd.MM.yyyy' }}
        </ng-container>
      </ng-template>
      <ng-template column let-repairTask [of]="repairTasks()" header="Status">
        <wr-repair-task-status-text [status]="repairTask.status"></wr-repair-task-status-text>
      </ng-template>
      <ng-template column let-repairTask [of]="repairTasks()" header="Firma">
        <div *ngIf="repairTask.contractor">
          {{ repairTask.contractor.name }}
        </div>
      </ng-template>
      <wr-table-pagination [totalRows]="count()"
                           [pageSize]="pageSize()"
                           (pageSizeChange)="pageSize.set($event)"
                           [page]="page()"
                           (pageChange)="page.set($event)"></wr-table-pagination>
    </wr-table>
  </nb-card-body>
</nb-card>
