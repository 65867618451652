import { Component, computed, inject, signal } from '@angular/core';
import { select } from '@/utils/signals/select';
import { BuildingState } from '@/store/building/building.state';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { loader } from '@/utils/signals/loader';
import { InspectionFilterDto, InspectionItemDto, InspectionService } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-building-dashboard-inspections',
  templateUrl: './building-dashboard-inspections.component.html',
  styleUrls: ['./building-dashboard-inspections.component.scss'],
})
export class BuildingDashboardInspectionsComponent {
  readonly inspectionService = inject(InspectionService);

  readonly building = select(BuildingState.selectedDashboardBuilding);

  readonly loading = loader();

  readonly page = signal(0);
  readonly pageSize = signal(10);

  readonly filter = computed(() => {
    const building = this.building();
    if (!building) {
      return undefined;
    }
    return {
      buildingId: building.id,
      page: this.page(),
      size: this.pageSize(),
    } as InspectionFilterDto;
  });

  readonly inspections = asyncComputed(() => {
    const filter = this.filter();
    return filter ? this.inspectionService.findAll(filter) : [];
  }, [], this.loading);

  readonly count = asyncComputed(() => {
    const filter = this.filter();
    return filter ? this.inspectionService.getCount(filter) : 0;
  }, 0, this.loading);

  readonly getInspectionLink = (inspection: InspectionItemDto) => (['inspection', inspection.id, 'general']);
}
