import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Configuration } from '@artemis-software/wr-api';
import { Observable } from 'rxjs';

type UploadSuccess = { key: string; publicUrl: string };

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {

  constructor(private readonly http: HttpClient,
    private readonly apiConfig: Configuration) {
  }

  uploadSingleFile(file: File, onProgress?: (value: number) => void, onError?: (value: HttpResponse<any>) => void, onDone?: (value: HttpResponse<any>) => void): void {
    const formData: FormData = new FormData();
    formData.append('file', file);

    this.http.post(this.apiConfig.basePath + '/api/file/upload',
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }).pipe(tap((event: any) => {
      if (event && event.total && event.type === HttpEventType.UploadProgress) {
        const progress: number = Math.round(100 * event.loaded / event.total);
        if (onProgress) {
          onProgress(progress);
        }
      }
    })).subscribe(event => {
      if (event instanceof HttpResponse) {
        if (onDone) {
          onDone(event);
        }
      }
    }, error => {
      if (onError) {
        onError(error);
      }
    });
  }

  uploadSingleFileNoProgress(file: File): Observable<UploadSuccess> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<UploadSuccess>(this.apiConfig.basePath + '/api/file/upload', formData);
  }
}
