import { EmailFilterDto, EmailItemDto, EmailService } from '@artemis-software/wr-api';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {
  ChangeEmailFilter,
  CountEmails,
  FilterEmails,
  LoadAllEmails,
} from './email.action';

type EmailStateModel = {
  emails: EmailItemDto[],
  filteredEmails: EmailItemDto[],
  count: number,
  loading: boolean,
  filter: EmailFilterDto,
}

@State<EmailStateModel>({
  name: 'emails',
  defaults: {
    emails: [],
    filteredEmails: [],
    count: 0,
    loading: false,
    filter: {} as EmailFilterDto,
  },
})

@Injectable()
export class EmailState implements NgxsOnInit {
  constructor(
    private readonly store: Store,
    private readonly emailService: EmailService,
    private readonly authService: AuthService,
  ) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllEmails());
    });
  }

  @Selector()
  static emails(state: EmailStateModel): EmailItemDto[] {
    return state.emails;
  }

  @Selector()
  static filteredEmails(state: EmailStateModel): EmailItemDto[] {
    return state.filteredEmails;
  }

  @Selector()
  static emailsLoading(state: EmailStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: EmailStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: EmailStateModel): EmailFilterDto {
    return state.filter;
  }

  @Action(LoadAllEmails)
  loadAllEmails(ctx: StateContext<EmailStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.emailService.findAll({}).subscribe((emails: EmailItemDto[]) => {
      ctx.patchState({
        emails,
        loading: false,
      });
    });
  }

  @Action(FilterEmails)
  filterEmails(ctx: StateContext<EmailStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.emailService.findAll(ctx.getState().filter).subscribe((emails: EmailItemDto[]) => {
      ctx.patchState({
        filteredEmails: emails,
        loading: false,
      });
    });
  }

  @Action(CountEmails)
  countEmails(ctx: StateContext<EmailStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.emailService.getCount(ctx.getState().filter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeEmailFilter)
  changeEmailFilter(ctx: StateContext<EmailStateModel>, action: ChangeEmailFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountEmails());
    this.store.dispatch(new FilterEmails());
  }
}
