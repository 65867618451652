import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NbComponentStatus, NbMenuItem, NbMenuService } from '@nebular/theme';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, Observable } from 'rxjs';
import { randomId } from '@/utils/random-utils';
import { OrganisationDetailDto } from '@artemis-software/wr-api';
import OrganisationTypeEnum = OrganisationDetailDto.OrganisationTypeEnum;

export class WrMenuItem extends NbMenuItem {
  override children?: WrMenuItem[];
  notificationCount$?: Observable<number>;
  organisationTypes?: OrganisationTypeEnum[];
}

const badgeColor: NbComponentStatus = 'danger';

@Component({
  selector: 'wr-menu',
  template: '<nb-menu *ngIf="itemsMapped$|async as items" [items]="items" [tag]="tag"></nb-menu>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  itemsMapped$ = new BehaviorSubject<NbMenuItem[]>([]);

  readonly tag = randomId();

  private _items$ = new BehaviorSubject<WrMenuItem[]>([]);
  private _reload$ = new BehaviorSubject(undefined);
  private _openedMenuItems = new Set<string>();

  @Input()
  set items(value: WrMenuItem[]) {
    this._items$.next(value);
  }

  constructor(
    private readonly menuService: NbMenuService,
  ) {
    menuService.onSubmenuToggle().pipe(
      filter((event) => event.tag === this.tag),
    ).subscribe(({ item }) => {
      if (item.children) {
        if (item.expanded) {
          this._openedMenuItems.add(item.title);
        } else {
          this._openedMenuItems.delete(item.title);
        }
      }
    });

    combineLatest([
      this._items$,
      this._reload$,
    ]).subscribe(async ([items]) => {
      const menu = await this.buildNbMenuItems(items);
      this.itemsMapped$.next(menu);
    });
  }

  private async buildNbMenuItems(menuItems: WrMenuItem[]): Promise<NbMenuItem[]> {
    const filtered: NbMenuItem[] = [];
    for (const menuItem of menuItems) {
      const copy = { ...menuItem };
      if (copy.notificationCount$ && !copy.badge) {
        try {
          const notificationCount = await firstValueFrom(copy.notificationCount$);
          if (notificationCount > 0) {
            copy.badge = {
              status: badgeColor,
              text: notificationCount.toString(),
            };
          }
        } catch (e) {
          console.error(e);
        }
      }
      if (copy.children) {
        copy.children = await this.buildNbMenuItems(copy.children);
        if (copy.children.some((child) => child.badge)) {
          // Add dot to parent if any child has a badge
          copy.badge = {
            status: badgeColor,
            dotMode: true,
          };
        }
        copy.expanded = this._openedMenuItems.has(copy.title);
      }
      if (!copy.children || copy.children.length > 0) {
        filtered.push(copy);
      }
    }
    return filtered as NbMenuItem[];
  }

  reload() {
    this._reload$.next(undefined);
  }
}

