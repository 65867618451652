import { Component } from '@angular/core';
import { ChangeContractorFilter, CountContractors, FilterContractors } from '@/store/contractor/contractor.action';
import { defineStoreUsage } from '@/utils/storeUsage';
import { ContractorState } from '@/store/contractor/contractor.state';
import { ContractorItemDto, ContractorService } from '@artemis-software/wr-api';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'wr-contractor-overview-page',
  templateUrl: './contractor-overview-page.component.html',
  styleUrls: ['./contractor-overview-page.component.scss']
})
export class ContractorOverviewPageComponent {

  contractorStoreUsage = defineStoreUsage({
    filterAction: ChangeContractorFilter,
    countSelector: ContractorState.count,
    loadingSelector: ContractorState.contractsLoading
  });

  @Select(ContractorState.filteredContractors)
  contractors$!: Observable<ContractorItemDto[]>;
  @Select(ContractorState.count)
  count$!: Observable<number>;
  @Select(ContractorState.contractsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(private readonly formBuilder: FormBuilder,
              private readonly store: Store,
              private readonly router: Router,
  ) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('contractorFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {
    this.form = new FormGroup({
      text: this.formBuilder.control(text),
    });
  }

  navigateToCreateContractor(contractor: ContractorItemDto) {
    this.router.navigate(['contractor', contractor.id]);
  }

  triggerFilter() {
    this.store.dispatch(new ChangeContractorFilter(this.form.value));
    localStorage.setItem('contractorFilter', JSON.stringify(this.form.value));
  }

  add() {
    this.router.navigate(['contractor', 'new']);
  }
}
