import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { createForm } from '@sonofabit/ng-core';
import { ManagerDetailsDto, UserItemDto, UserService } from '@artemis-software/wr-api';
import { firstValueFrom } from 'rxjs';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'wr-property-manager-dialog',
  templateUrl: './property-manager-dialog.component.html',
  styleUrls: ['./property-manager-dialog.component.scss'],
})
export class PropertyManagerDialogComponent implements OnInit {

  readonly dialogRef = inject(NbDialogRef<PropertyManagerDialogComponent>);
  readonly userService = inject(UserService);
  protected readonly FormControl = FormControl;

  form?: ReturnType<typeof this.initForm>;
  selectedOption: 'user' | 'manual' = 'user';

  @Input()
  propertyManager?: UserItemDto | ManagerDetailsDto;

  ngOnInit(): void {
    this.form = this.initForm(this.propertyManager);
    if (this.propertyManager && 'id' in this.propertyManager) {
      this.selectedOption = 'user';
    } else {
      this.selectedOption = 'manual';
    }
  }

  initForm(initialValue: UserItemDto | ManagerDetailsDto | undefined) {
    const userId = initialValue && 'id' in initialValue ? initialValue.id : undefined;
    return createForm(({ field }) => {
      return {
        user: field({
          value: { value: userId },
        }),
        firstName: field({
          value: initialValue?.firstName ?? '',
        }),
        lastName: field({
          value: initialValue?.lastName ?? '',
        }),
        email: field({
          value: initialValue?.email ?? '',
        }),
      };
    });
  }

  close() {
    this.dialogRef.close();
  }

  async submit() {
    if (this.selectedOption === 'user') {
      const userId = this.form?.get('user')?.value.value;
      if (userId) {
        const user = await firstValueFrom(this.userService.findById(userId));
        this.dialogRef.close({ propertyManager: user });
      } else {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close({
        embeddedPropertyManager: {
          firstName: this.form?.get('firstName')?.value,
          lastName: this.form?.get('lastName')?.value,
          email: this.form?.get('email')?.value,
        },
      });
    }
  }

}
