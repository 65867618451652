import {
  AfterViewInit,
  Component, computed, effect,
  ElementRef,
  EmbeddedViewRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MapOverviewPopupPanelComponent } from '@pages/map-overview-popup-panel/map-overview-popup-panel.component';
import * as L from 'leaflet';
import { IconOptions, Marker } from 'leaflet';
import {
  ConditionDto,
  BuildingFilterDto,
  BuildingItemDto,
  BuildingService,
  OrganisationDetailDto, OrganisationService,
} from '@artemis-software/wr-api';
import { BehaviorSubject, firstValueFrom, startWith } from 'rxjs';
import { tryWithLoading } from '@/utils/async-utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { currentUser } from '@/utils/admin-utils';
import OrganisationTypeEnum = OrganisationDetailDto.OrganisationTypeEnum;
import { asyncComputed } from '@/utils/signals/asyncComputed';

export const mapUrls = [
  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
];

const iconOptions: Partial<IconOptions> = {
  iconSize: [50, 50],
  iconAnchor: [25, 45],
  popupAnchor: [0, -30],
};

export const primaryIcon = L.icon({
  ...iconOptions,
  iconUrl: 'assets/icons/map-pin-primary.png',
});

export const successIcon = L.icon({
  ...iconOptions,
  iconUrl: 'assets/icons/map-pin-success.png',
});

export const warningIcon = L.icon({
  ...iconOptions,
  iconUrl: 'assets/icons/map-pin-warning.png',
});

export const dangerIcon = L.icon({
  ...iconOptions,
  iconUrl: 'assets/icons/map-pin-danger.png',
});

const getIconForCondition = (condition: ConditionDto.ConditionEnum) => {
  switch (condition) {
  case ConditionDto.ConditionEnum.MinorDefects:
    return warningIcon;
  case ConditionDto.ConditionEnum.MajorDefects:
    return dangerIcon;
  default:
    return successIcon;
  }
};

@Component({
  selector: 'wr-map-overview',
  templateUrl: './map-overview.component.html',
  styleUrls: ['./map-overview.component.scss'],
})

export class MapOverviewComponent implements AfterViewInit {

  currentUser = currentUser();
  isOrganisation = computed(() => this.currentUser()?.organisationType === OrganisationTypeEnum.Cooperative);
  organisationId = computed(() => this.currentUser()?.organisation.id);

  showAll = computed(() => {
    const currentUser = this.currentUser();
    if (!currentUser) {
      return false;
    }
    return currentUser.organisationType === OrganisationTypeEnum.Admin;
  });

  myOrganisations = asyncComputed(() => this.organisationService.findMyOrganisations());
  myOrganisationIds = computed(() => this.myOrganisations()?.map(org => org.id));

  @ViewChild('hidden')
  hiddenContainer?: ElementRef;

  private map!: L.Map;
  markers: L.Marker[] = [];
  layerGroup = L.layerGroup();

  loading$ = new BehaviorSubject<boolean>(false);
  form!: FormGroup;

  constructor(
    private readonly buildingService: BuildingService,
    private readonly organisationService: OrganisationService,
    private viewContainerRef: ViewContainerRef,
    private readonly formBuilder: FormBuilder,
  ) {
    effect(() => {
      if (this.isOrganisation()) {
        this.initFilterForm(this.organisationId() ?? '');
      } else {
        this.initFilterForm(this.myOrganisationIds()?.[0] ?? '');
      }
    });
  }


  ngAfterViewInit() {
    this.initializeMap();
  }

  initFilterForm(organisationId: string): void {
    this.form = this.formBuilder.group({
      organisationId: this.formBuilder.control(organisationId),
    });

    this.form.valueChanges.pipe(startWith(this.form.value)).subscribe((formValue) => {
      this.fetchBuildings(formValue);
    });
  }

  private async fetchBuildings(formValue: BuildingFilterDto) {
    this.markers.length = 0;
    this.layerGroup.clearLayers();
    await tryWithLoading(this.loading$, async () => {
      const buildings = await firstValueFrom(this.buildingService.findAll(formValue));
      buildings.forEach(building => {
        const marker = this.createMarkerForBuilding(building);
        if (marker) {
          this.markers.push(marker);
        }

      });
      this.addMarkersToMap();
      this.centerMapOnMarkers();
    });
  }

  private createMarkerForBuilding(building: BuildingItemDto): L.Marker | null {
    const lat = building.coordinates?.latitude;
    const long = building.coordinates?.longitude;

    if (lat && long) {
      const marker = L.marker([lat, long], {
        icon: getIconForCondition(building.buildingCondition.condition),
      });
      marker.options.title = building.name;
      this.bindMarkerPopupContent(marker, building);
      return marker;
    }
    return null;
  }

  private initializeMap() {
    this.map = L.map('map');
    L.tileLayer(mapUrls[0]).addTo(this.map);
  }

  private addMarkersToMap() {
    this.markers.forEach(marker => this.layerGroup.addLayer(marker));
    this.layerGroup.addTo(this.map);
  }

  private centerMapOnMarkers() {
    if (this.markers.length === 0) {
      return;
    }
    const bounds = L.latLngBounds(this.markers.map(marker => marker.getLatLng()));
    this.map.fitBounds(bounds);
  }

  private bindMarkerPopupContent(marker: Marker, building: BuildingItemDto) {
    marker.on('click', () => {
      if (!marker.getPopup()) {
        const popupPanelComponent = this.viewContainerRef.createComponent(MapOverviewPopupPanelComponent);
        popupPanelComponent.instance.building = building;
        const domElem: HTMLElement = (popupPanelComponent.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
        this.hiddenContainer?.nativeElement.append(domElem);
        const popupContent = L.popup().setContent(domElem);
        marker.bindPopup(popupContent).openPopup();
      }
    });
  }
}
