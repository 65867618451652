import { BehaviorSubject } from 'rxjs';

const prefix = 'dok-';

export class LocalStorageUtils {
  static readonly storage = localStorage;

  static set<T>(key: string, value: T): void {
    this.setString(key, JSON.stringify(value));
  }

  static get<T>(key: string): T {
    return JSON.parse(this.getString(key));
  }

  private static setString(key: string, value: string): void {
    this.storage.setItem(prefix + key, value);
  }

  private static getString(key: string): string {
    return this.storage.getItem(prefix + key) ?? '""';
  }
}

export class LocalStorageSubject<T> extends BehaviorSubject<T> {
  private readonly key: string;

  constructor(localStorageKey: string, startValue: T) {
    const storedValue = LocalStorageUtils.get<T>(localStorageKey);
    super(!storedValue ? startValue : storedValue);
    this.key = localStorageKey;
  }

  override next(value: T): void {
    LocalStorageUtils.set(this.key, value);
    super.next(value);
  }
}
