import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import {
  AddressMergeDto, AddressService,
  ContractorDetailDto,
  ContractorMergeDto,
  ContractorService
} from '@artemis-software/wr-api';
import { Store } from '@ngxs/store';
import { LoadAllContractors } from '@/store/contractor/contractor.action';
import { PendingChanges } from '@guards/pending-changes.guard';

@Component({
  selector: 'wr-contractor-detail-page',
  templateUrl: './contractor-detail-page.component.html',
  styleUrls: ['./contractor-detail-page.component.scss']
})
export class ContractorDetailPageComponent implements PendingChanges{

  saving$ = new BehaviorSubject(false);
  isEditing = false;
  form?: FormGroup;
  firstSubmit = false;

  constructor(private readonly router: Router,
              private readonly dialogService: NbDialogService,
              private readonly contractorService: ContractorService,
              private readonly store: Store,
              private readonly toastrService: NbToastrService,
              private readonly route: ActivatedRoute,
              private readonly addressService: AddressService) {
    route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        if (id === 'new') {
          this.isEditing = true;
          this.initForm();
        } else {
          this.contractorService.findById(id).subscribe((contractor) => {
            this.initForm(contractor);
            this.form?.disable();
          });
        }
      } else {
        this.router.navigate(['contractors']);
      }
    });
  }

  initForm(dto?: ContractorDetailDto): void {
    this.form = new FormGroup({
      id: new FormControl(dto?.id),
      name: new FormControl(dto?.name, [Validators.required]),
      email: new FormControl(dto?.email, [Validators.required, Validators.email]),
      description: new FormControl(dto?.description),
      telephoneNumber: new FormControl(dto?.telephoneNumber),
      addressId: new FormControl(dto?.address.id),
      street: new FormControl(dto?.address.street, [Validators.required]),
      state: new FormControl(dto?.address.country),
      zipCode: new FormControl(dto?.address.zipCode, [Validators.required]),
      city: new FormControl(dto?.address.city, [Validators.required]),
      country: new FormControl({ value: 'AT', disabled: true }),
      pin: new FormControl(dto?.pin),
      houseNumber: new FormControl(dto?.address.number, [Validators.required]),
    });
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['contractors']);
  }

  onEdit(): void {
    this.isEditing = true;
    this.form?.enable();
  }

  onDelete(): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Externe Firma löschen',
        message: 'Bist du sicher, dass du diese externe Firma löschen willst?',
      },
    }).onClose.subscribe((result) => {
      if (result) {
        this.contractorService.deleteById(this.form?.value.id).subscribe(() => {
          this.store.dispatch(new LoadAllContractors());
          this.router.navigate(['contractors']);
          this.toastrService.success('Externe Firma wurde gelöscht', 'Externe Firma');
        });
      }
    }, (error) => {
      this.toastrService.danger('Externe Firma konnte nicht gelöscht werden.', 'Externe Firma');
    });
  }

  hasError(controlName: string, errorName = 'required'): boolean {
    const control = this.form?.get(controlName);
    return this.isEditing && this.firstSubmit && (control?.hasError(errorName) ?? false) && ((control?.touched ?? false) || (control?.dirty ?? false));
  }

  async submit(): Promise<void> {
    this.firstSubmit = true;
    this.form?.markAsTouched();

    if (!this.form?.valid) {
      this.toastrService.danger('Externe Firma kann nicht gespeichert werden, es gibt noch Fehler.', 'Externe Firma');
      return;
    }

    try {
      this.saving$.next(true);
      const addressDto: AddressMergeDto = {
        id: this.form.get('addressId')?.value,
        street: this.form.get('street')?.value,
        zipCode: this.form.get('zipCode')?.value,
        city: this.form.get('city')?.value,
        state: this.form.get('state')?.value,
        country: this.form.get('country')?.value,
        number: this.form.get('houseNumber')?.value,
      };
      const address = await firstValueFrom(this.addressService.merge(addressDto));

      this.form?.patchValue({ addressId: address.id });
      const contractorDto = this.form?.value as ContractorMergeDto;
      const contractor = await firstValueFrom(this.contractorService.merge(contractorDto));
      this.isEditing = false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['contractor', contractor.id]).then(() => {
          this.store.dispatch(new LoadAllContractors());
          this.toastrService.success('Externe Firma wurde erfolgreich gespeichert', 'Externe Firma');
        });
      });
    } catch (e) {
      console.error(e);
      this.toastrService.danger('Externe Firma konnte nicht gespeichert werden', 'Fehler');
    } finally {
      this.saving$.next(false);
    }
  }

  async cancel(): Promise<void> {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['contractor', this.form?.value.id]);
    });
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.isEditing;
  }
}
