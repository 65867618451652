import { OrganisationFilterDto } from '@artemis-software/wr-api';

export class LoadAllOrganisations {
  static readonly type = '[Organisation] Load all Organisations';
}

export class FilterOrganisations {
  static readonly type = '[Organisation] Filter Organisations';
}

export class CountOrganisations {
  static readonly type = '[Organisation] Count organisations';
}

export class ChangeOrganisationFilter {
  static readonly type = '[Organisation] Change Organisation Filter';

  constructor(public filter: OrganisationFilterDto) {
  }
}
