import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionTranslationPipe } from './actionTranslation.pipe';
import { DeadlineTranslationPipe } from './deadlineTranslation.pipe';
import { ConditionTranslationPipe } from './condition-translation.pipe';
import { TruncatePipe } from './truncate.pipe';
import { ElementTypeTranslationPipe } from './element-type-translation.pipe';
import { ValueTypeTranslationPipe } from './value-type-translation.pipe';
import { RepairTaskStatusTranslationPipe } from '@shared/pipes/repairTaskStatusTranslation.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { RichTextPipe } from '@shared/pipes/richText.pipe';
import { MaintenanceTimerTypePipe } from './maintenance-timer-type.pipe';
import { MaintenanceIntervalTranslationPipe } from '@shared/pipes/maintenance-interval-translation.pipe';
import { EquipmentTypeTranslationPipe } from '@shared/pipes/equipment-type-translation.pipe';
import { OrganisationTypeTranslationPipe } from '@shared/pipes/organisation-type-translation.pipe';
import { EmailTypeTranslationPipe } from '@shared/pipes/email-type-translation.pipe';
import { BuildingTypeTranslationPipe } from '@shared/pipes/building-type-translation.pipe';
import { BuildingClassTranslationPipe } from '@shared/pipes/building-class-translation.pipe';


@NgModule({
  declarations: [
    ActionTranslationPipe,
    DeadlineTranslationPipe,
    ConditionTranslationPipe,
    EmailTypeTranslationPipe,
    TruncatePipe,
    ElementTypeTranslationPipe,
    ValueTypeTranslationPipe,
    RepairTaskStatusTranslationPipe,
    PluralizePipe,
    RichTextPipe,
    MaintenanceTimerTypePipe,
    OrganisationTypeTranslationPipe,
    BuildingTypeTranslationPipe,
    BuildingClassTranslationPipe,
    EquipmentTypeTranslationPipe,
    MaintenanceIntervalTranslationPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ActionTranslationPipe,
    DeadlineTranslationPipe,
    ConditionTranslationPipe,
    EmailTypeTranslationPipe,
    TruncatePipe,
    ElementTypeTranslationPipe,
    ValueTypeTranslationPipe,
    RepairTaskStatusTranslationPipe,
    PluralizePipe,
    RichTextPipe,
    MaintenanceTimerTypePipe,
    OrganisationTypeTranslationPipe,
    BuildingTypeTranslationPipe,
    BuildingClassTranslationPipe,
    EquipmentTypeTranslationPipe,
    MaintenanceIntervalTranslationPipe,
  ],
  providers: [
    ActionTranslationPipe,
    DeadlineTranslationPipe,
    RepairTaskStatusTranslationPipe,
  ],
})
export class PipesModule {
}
