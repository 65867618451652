<nb-card [nbSpinner]="loading()">
  <nb-card-body>
    <div class="info-container">
      <div class="info-section">
        <div class="info-section-header">
          <nb-icon icon="building" pack="fas"></nb-icon>
          Gebäude
        </div>
        <div class="info-section-body">
          <div class="info-item">
            <nb-icon icon="building" pack="fas"></nb-icon>
            {{ buildingCount() }} Gebäude
          </div>
          <div class="info-item">
            <nb-icon icon="ruler" pack="fas"></nb-icon>
            {{ totalArea() }} m²
          </div>
        </div>
      </div>
      <div class="info-section">
        <div class="info-section-header">
          <nb-icon icon="tools" pack="fas"></nb-icon>
          Reparaturaufträge
        </div>
        <wr-repair-task-chart></wr-repair-task-chart>
      </div>
      <div class="info-section">
        <div class="info-section-header">
          <nb-icon icon="clipboard-check" pack="fas"></nb-icon>
          Rundgänge
        </div>
        <wr-inspection-chart></wr-inspection-chart>
      </div>
    </div>
  </nb-card-body>
</nb-card>
