import { Component, effect } from '@angular/core';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UserState } from '@/store/user/user.state';
import { Router } from '@angular/router';
import {
  OrganisationItemDto,
  UserItemDto,
} from '@artemis-software/wr-api';
import { ChangeUserFilter } from '@/store/user/user.action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationState } from '@/store/organisation/organisation.state';
import { defineStoreUsage } from '@/utils/storeUsage';
import { currentUser } from '@/utils/admin-utils';

@Component({
  selector: 'wr-cooperative-user-overview-page',
  templateUrl: './cooperative-user-overview-page.component.html',
  styleUrls: ['./cooperative-user-overview-page.component.scss'],
})
export class CooperativeUserOverviewPageComponent {
  currentUser = currentUser();

  userStoreUsage = defineStoreUsage({
    filterAction: ChangeUserFilter,
    countSelector: UserState.count,
    loadingSelector: UserState.usersLoading
  });

  @Select(UserState.filteredUsers)
  users$!: Observable<UserItemDto[]>;
  @Select(OrganisationState.organisations)
  organisations$!: Observable<OrganisationItemDto[]>;
  @Select(UserState.count)
  count$!: Observable<number>;
  @Select(UserState.usersLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(private readonly store: Store,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder) {
    effect(() => {
      const currentUser = this.currentUser();
      if (currentUser) {
        this.initFilter(currentUser.organisation.id);
        this.triggerFilter();
      }
    });
  }

  initFilter(organisationId: string) {
    const filter = localStorage.getItem('cooperativeUserFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text, organisationId);
    } else {
      this.initFilterForm('', organisationId);
    }
  }

  initFilterForm(text: string, organisationId: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      organisationId: this.formBuilder.control(organisationId),
    });
  }

  navigateToUser(user: UserItemDto): void {
    this.router.navigate(['user', user.id]);
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeUserFilter(this.form.value));
    localStorage.setItem('cooperativeUserFilter', JSON.stringify(this.form.value));
  }
}
