<hau-form [formGroup]="form">
  <hau-form-field label="Name" cols="6" [validation]="form.validation.title">
    <input nbInput fullWidth formControlName="title">
  </hau-form-field>
  <hau-form-field label="Beschreibung" [validation]="form.validation.description">
    <textarea nbInput fullWidth formControlName="description"></textarea>
  </hau-form-field>
  <hau-form-field label="Tags" [validation]="form.validation.tags">
    <wr-multi-tag-select formControlName="tags"></wr-multi-tag-select>
  </hau-form-field>
  <hau-form-field label="Felder" [validation]="form.validation.fields">
    <wr-custom-fields-select formControlName="fields"></wr-custom-fields-select>
  </hau-form-field>
</hau-form>
