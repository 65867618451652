<input #fileInput
       class="file-input"
       type="file"
       [accept]="getAcceptForFileType(type)"
       (change)="onFilesSelected($event)"
       [multiple]="multiple"/>
<label class="file-input-container" [class.label-dragging]="dragDepth>0" (click)="fileInput.click()">
  <span class="file-input-label-subtext" [ngSwitch]="type">
    <ng-container *ngSwitchCase="'IMAGE'">Bild<ng-container *ngIf="multiple">er</ng-container> hochladen</ng-container>
    <ng-container *ngSwitchCase="'FILE'">Datei<ng-container *ngIf="multiple">en</ng-container> hochladen</ng-container>
  </span>
  <nb-icon pack="eva" icon="plus-outline"></nb-icon>
</label>
