import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { Injectable } from '@angular/core';
import { formatDate } from 'date-fns';
import { deAT } from 'date-fns/locale/de-AT';

const defaultParams = (params: DateFormatterParams): DateFormatterParams => ({
  ...params,
  locale: 'de',
  weekStartsOn: 1,
});

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {

  public override dayViewHour({ date }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', {
      locale: deAT,
    });
  }

  public override weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }

  override monthViewColumnHeader(params: DateFormatterParams): string {
    return super.monthViewColumnHeader(defaultParams(params));
  }
}
