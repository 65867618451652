<nb-select placeholder="Gebäude auswählen"
           multiple
           [(selected)]="value"
           [disabled]="isDisabled"
           [compareWith]="equalFn">
  <nb-option *ngFor="let building of buildings$ | async"
             [value]="{value: building.id}">
    {{building.sectionText}}
  </nb-option>
  <nb-option *ngIf="showNoneOption" [value]="[]">Alle</nb-option>
</nb-select>
