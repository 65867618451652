import { Component, forwardRef } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { ConditionDto } from '@artemis-software/wr-api';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-condition-select',
  templateUrl: './condition-select.component.html',
  styleUrls: ['./condition-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConditionSelectComponent),
      multi: true,
    },
  ],
})
export class ConditionSelectComponent extends AbstractFormControl<ConditionDto.ConditionEnum> {
  readonly Condition = ConditionDto.ConditionEnum;
  Conditions = [
    this.Condition.None,
    this.Condition.NoDefects,
    this.Condition.MinorDefects,
    this.Condition.MajorDefects,
  ];
}
