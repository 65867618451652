<div class="detail-container" [nbSpinner]="!!(loading$|async) || !form">
  <ng-container *ngIf="showCard; else noCard">
    <nb-card>
      <nb-card-header><ng-container [ngTemplateOutlet]="header"></ng-container></nb-card-header>
      <nb-card-body><ng-container [ngTemplateOutlet]="body"></ng-container></nb-card-body>
      <nb-card-footer><ng-container [ngTemplateOutlet]="footer"></ng-container></nb-card-footer>
    </nb-card>
  </ng-container>

  <ng-template #noCard>
    <div class="header"><ng-container [ngTemplateOutlet]="header"></ng-container></div>
    <div class="body"><ng-container [ngTemplateOutlet]="body"></ng-container></div>
    <div class="footer"><ng-container [ngTemplateOutlet]="footer"></ng-container></div>
  </ng-template>

</div>

<ng-template #header>
  <h5 *ngIf="showTitle">TGA</h5>
  <wr-edit-delete-button-group *ngIf="!isNew && !disableEdit && isAdmin()"
                               (edit)="enable()"
                               (delete)="onDelete()"
                               [close]="close"></wr-edit-delete-button-group>
</ng-template>

<ng-template #body>
  <hau-form *ngIf="form" [formGroup]="form">
    <hau-form-field label="TGA Typ" [validation]="form.validation.equipment" cols="6">
      <wr-equipment-select formControlName="equipment"></wr-equipment-select>
    </hau-form-field>
    <hau-form-field label="Anzahl" [validation]="form.validation.quantity" cols="6">
      <input nbInput fullWidth type="number" formControlName="quantity">
    </hau-form-field>
    <hau-form-field label="Gebäude" [validation]="form.validation.building" cols="6">
      <wr-building-select formControlName="building"></wr-building-select>
    </hau-form-field>
    <hau-form-field label="Gebäude Teil" [validation]="form.validation.buildingSectionId" cols="6">
      <nb-select fullWidth formControlName="buildingSectionId">
        <nb-option *ngFor="let buildingSection of buildingSections$ | async"
                   [value]="buildingSection.id">{{buildingSection.address.street + ' ' + buildingSection.address.number}}</nb-option>
      </nb-select>
    </hau-form-field>
    <hau-form-field label="Bilder & Dokumentenliste" [validation]="form.validation.attachments">
      <wr-multi-attachment-control formControlName="attachments"></wr-multi-attachment-control>
    </hau-form-field>
  </hau-form>

  <div class="spacer"></div>

  <div class="custom-field-collection" *ngFor="let collectionValues of customFieldCollectionsAndValues$|async">
    <b>{{collectionValues.collection.title}}</b>
    <p class="description">{{collectionValues.collection.description}}</p>
    <wr-custom-form [collection]="collectionValues.collection"
                    [values]="collectionValues.values"
                    [readonly]="!form?.enabled">
    </wr-custom-form>
  </div>
</ng-template>

<ng-template #footer>
  <ng-container *ngIf="isAdmin()">
    <button size="medium" [disabled]="!form?.enabled" nbButton status="primary" (click)="submit()">Speichern
    </button>
    <button size="medium" [disabled]="!form?.enabled" nbButton status="basic" (click)="cancel.emit()">Zurück</button>
  </ng-container>
</ng-template>
