import { NgModule } from '@angular/core';
import { DataColumnDirective } from '@shared/generic/data-table/data-column.directive';
import { PaginationComponent } from '@shared/generic/pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NebularModule } from '@shared/nebular/nebular.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { CustomFormModule } from '@shared/custom-form/custom-form.module';
import { ComponentsModule } from '@shared/components/components.module';
import { DataTableComponent } from '@shared/generic/data-table/data-table.component';
import { DataTableFilterDirective } from '@shared/generic/data-table/data-table-filter.directive';
import { CypressIdDirective } from '@shared/directives/cypress-id.directive';

@NgModule({
  declarations: [
    DataColumnDirective,
    DataTableComponent,
    PaginationComponent,
    DataTableFilterDirective,
  ],
  imports: [
    FormsModule,
    CommonModule,
    NebularModule,
    ReactiveFormsModule,
    PipesModule,
    DirectivesModule,
    CustomFormModule,
    ComponentsModule,
    CypressIdDirective,
  ],
  exports: [
    DataColumnDirective,
    DataTableComponent,
    PaginationComponent,
    DataTableFilterDirective,
  ],
})
export class GenericModule {}
