<hau-form *ngIf="formWrapper.form$|async as form" [formGroup]="form">
  <ng-container *ngIf="getBuildingSectionFormGroup(form, this.selectedSection.id) as formGroup">
    <hau-form-field label="Gebäudeteilbilder">
      <wr-multi-attachment-control [formControl]="formGroup.controls.buildingSectionImages"></wr-multi-attachment-control>
    </hau-form-field>
    <hau-form-field label="Eingang">
      <wr-multi-attachment-control [formControl]="formGroup.controls.entranceImages"></wr-multi-attachment-control>
    </hau-form-field>
    <hau-form-field label="Türklingel">
      <wr-multi-attachment-control [formControl]="formGroup.controls.doorBellImages"></wr-multi-attachment-control>
    </hau-form-field>
    <hau-form-field label="Hausnummer">
      <wr-multi-attachment-control [formControl]="formGroup.controls.houseNumberImages"></wr-multi-attachment-control>
    </hau-form-field>
  </ng-container>
</hau-form>
