import { ContractorFilterDto } from '@artemis-software/wr-api';

export class LoadAllContractors {
  static readonly type = '[Contractor] Load all Contractors';
}

export class FilterContractors {
  static readonly type = '[Contractor] Filter Contractors';
}

export class CountContractors {
  static readonly type = '[Contractor] Count contractors';
}

export class ChangeContractorFilter {
  static readonly type = '[Contractor] Change Contractor Filter';

  constructor(public filter: ContractorFilterDto) {
  }
}
