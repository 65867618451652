import {
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { ColumnDirective } from '@components/table/column.directive';
import { evalMaybeBoolean, MaybeBoolean } from '@/utils/inputs';
import { PaginationDirective } from '@components/table/pagination.directive';
import { SortDto } from '@artemis-software/wr-api';
import { Router } from '@angular/router';

@Component({
  selector: 'wr-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> {
  @Input() rows!: T[];

  @Input() loading?: MaybeBoolean;

  @Input() totalRows?: number;

  @ContentChildren(ColumnDirective)
  columns?: QueryList<ColumnDirective<T>>;

  @ContentChild(PaginationDirective)
  pagination?: PaginationDirective;

  @Input() sort?: SortDto;

  @Input() rowLink?: (row: T) => (string | Array<string | number>);

  @Output() sortChange = new EventEmitter<SortDto>();

  readonly router = inject(Router);

  get isLoading() {
    return evalMaybeBoolean(this.loading);
  }

  getCellClasses(column: ColumnDirective<T>) {
    const classes = [];
    if (evalMaybeBoolean(column.shrink)) {
      classes.push('shrink');
    }
    return classes;
  }

  getHeaderClasses(column: ColumnDirective<T>) {
    const classes = [];
    if (evalMaybeBoolean(column.shrink)) {
      classes.push('shrink');
    }
    if (column.sortable) {
      classes.push('sortable');
    }
    return classes;
  }

  getSortIcon(column: ColumnDirective<T>) {
    if (column.sortable && this.sort?.sort === column.sort) {
      return this.sort?.order === 'ASC' ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline';
    }
    return undefined;
  }

  toggleSort(column: ColumnDirective<T>) {
    if (column.sortable) {
      if (this.sort?.sort === column.sort) {
        this.sortChange.emit({
          sort: column.sort!,
          order: this.sort?.order === 'ASC' ? 'DESC' : 'ASC',
        });
      } else {
        this.sortChange.emit({
          sort: column.sort!,
          order: 'ASC',
        });
      }
    }
  }

  getRowClasses(row: T) {
    const classes = [];
    if (this.rowLink) {
      classes.push('clickable');
    }
    return classes;
  }

  navigateRow(row: T) {
    const route = this.rowLink?.(row);
    if (route) {
      if (route instanceof Array) {
        this.router.navigate(route);
      } else {
        this.router.navigate([route]);
      }
    }
  }
}
