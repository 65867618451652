import { computed, inject, isSignal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ParamMap } from '@angular/router';

export function routeParams(): Signal<ParamMap> {
  const route = inject(ActivatedRoute);

  return toSignal(route.paramMap, { initialValue: route.snapshot.paramMap });
}

export function routeParam(name: string, required: true): Signal<string>;

export function routeParam(name: string, required?: boolean): Signal<string | null>;

export function routeParam(name: string, defaultValue: string): Signal<string>;

export function routeParam(name: string, defaultValue: () => string): Signal<string>;

export function routeParam(name: string, required: unknown = false): Signal<string | null> {
  const params = routeParams();

  return computed(() => {
    const value = params().get(name) ?? null;

    if (required === true && value === null) {
      throw new Error(`Route parameter ${name} is required but not set.`);
    }
    if (value === null && typeof required === 'function') {
      return required();
    }
    if (value === null && typeof required === 'string') {
      return required;
    }

    return value;
  });
}

export function routeParamNumber(name: string, required: true): Signal<number>;

export function routeParamNumber(
  name: string,
  defaultValue: number | Signal<number>,
): Signal<number>;

export function routeParamNumber(name: string, required?: boolean): Signal<number | null>;

export function routeParamNumber(
  name: string,
  requiredOrDefault?: boolean | number | Signal<number>,
): Signal<number | null> {
  const param = routeParam(name, requiredOrDefault === true);

  return computed(() => {
    const value = param();

    if (value === null) {
      if (isSignal(requiredOrDefault)) return requiredOrDefault();
      if (typeof requiredOrDefault === 'number') return requiredOrDefault;
      return null;
    }

    const number = Number(value);
    if (Number.isNaN(number)) throw new Error(`Route parameter ${name} is not a number.`);
    return number;
  });
}

