import { effect, EffectRef, untracked } from '@angular/core';

export type WatchSource = SimpleWatchSource | ArrayWatchSource;

export type SimpleWatchSource = () => unknown;

export type ArrayWatchSource = Readonly<SimpleWatchSource[]>;

export interface WatchOptions {
  immediate?: boolean;
}

export type UnwrapSimpleWatchSource<T extends SimpleWatchSource> = T extends () => infer U
  ? U
  : never;

export type MapSources<T> = T extends SimpleWatchSource
  ? UnwrapSimpleWatchSource<T>
  : T extends ArrayWatchSource
    ? {
      [K in keyof T]: T[K] extends SimpleWatchSource ? UnwrapSimpleWatchSource<T[K]> : never;
    }
    : never;

/**
 * Watches a given source for changes and calls the provided handler function when a change is detected.
 * @param watchSource The source to watch for changes.
 * @param handler The function to call when a change is detected. Receives the new and old values as arguments.
 * @param options Options for the watch.
 *    - immediate: If true, the handler will be called immediately with the current value of the watch source.
 * @returns An EffectRef object that can be used to stop the watch.
 */
export function watch<T extends Readonly<WatchSource>>(
  watchSource: T,
  handler: (newValue: MapSources<T>, oldValue: MapSources<T>) => void,
  options?: WatchOptions,
): EffectRef {
  let oldValue: MapSources<T> | undefined = undefined;
  let firstRun = true;
  return effect(() => {
    let value: MapSources<T>;
    if (typeof watchSource === 'function') value = watchSource() as MapSources<T>;
    else if (Array.isArray(watchSource))
      value = watchSource.map((watchSource) => watchSource()) as MapSources<T>;
    else throw new Error('Invalid watch source');

    if (!firstRun || options?.immediate !== false) {
      untracked(() => {
        handler(value as MapSources<T>, oldValue as MapSources<T>);
      });
    } else {
      firstRun = false;
    }

    oldValue = value;
  });
}
