<wr-base-dialog [submitResponse]="submitCoordinates.bind(this)" [header]="title">
  <form *ngIf="form" [formGroup]="form">
    <div class="grid">
      <div class="col-6">
        <label class="label" for="latitude">Breitengrad</label>
        <input id="latitude" type="number" nbInput formControlName="latitude" fullWidth>
        <div class="error" *ngIf="hasError('latitude')">Breitengrad ist erforderlich!</div>
      </div>
      <div class="col-6">
        <label class="label" for="longitude">Längengrad</label>
        <input id="longitude" type="number" nbInput formControlName="longitude" fullWidth>
        <div class="error" *ngIf="hasError('longitude')">Längengrad ist erforderlich!</div>
      </div>
    </div>
  </form>
</wr-base-dialog>
