import { Component, computed, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { Tracked } from '@/utils/signals/tracked';
import { BuildingEquipmentService, BuildingItemDto } from '@artemis-software/wr-api';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'wr-maintenance-timer-dialog',
  templateUrl: './maintenance-timer-dialog.component.html',
  styleUrls: ['./maintenance-timer-dialog.component.scss'],
})
export class MaintenanceTimerDialogComponent {

  readonly buildingEquipmentService = inject(BuildingEquipmentService);
  readonly dialogRef = inject(NbDialogRef);

  @Input()
  @Tracked()
  buildingId?: string;

  @Output()
  save = new EventEmitter<void>();

  readonly selectedBuildingEquipmentId = signal<string | undefined>(undefined);

  readonly hasBuilding = computed(() => !!this.buildingId);

  readonly buildingEquipments = asyncComputed(() => {
    if (!this.buildingId) {
      return [];
    }
    return this.buildingEquipmentService.findAll({
      buildingId: this.buildingId,
    });
  });

  buildingSelected(building?: BuildingItemDto) {
    this.buildingId = building?.id;
  }

  close() {
    this.dialogRef.close();
    this.save.emit();
  }

  clearSelection(){
    this.buildingId = undefined;
    this.selectedBuildingEquipmentId.set(undefined);
  }
}
