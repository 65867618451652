import { Component, ContentChild, Input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import {
  GenericMultiControlTemplateDirective,
} from '@shared/custom-form/generic-multi-control/generic-multi-control-template.directive';

@Component({
  selector: 'wr-generic-multi-control[formArray]',
  templateUrl: './generic-multi-control.component.html',
  styleUrls: ['./generic-multi-control.component.scss'],
})
export class GenericMultiControlComponent {

  @Input()
  formArray!: FormArray;

  @ContentChild(GenericMultiControlTemplateDirective)
  template?: GenericMultiControlTemplateDirective;

  add() {
    this.formArray.push(new FormControl());
  }

  removeAt(i: number): void {
    this.formArray.removeAt(i);
  }

}
