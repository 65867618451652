import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ChangeUserFilter, CountUsers, FilterUsers, LoadAllUsers, LoadCurrentUser } from './user.action';
import { UserDetailDto, UserFilterDto, UserItemDto, UserService } from '@artemis-software/wr-api';
import { AuthService } from '@services/auth.service';

type UserStateModel = {
  users: UserItemDto[],
  filteredUsers: UserItemDto[],
  currentUser?: UserDetailDto,
  count: number,
  loading: boolean,
  filter: UserFilterDto,
}

@State<UserStateModel>({
  name: 'users',
  defaults: {
    users: [],
    filteredUsers: [],
    count: 0,
    loading: false,
    filter: {} as UserFilterDto,
  },
})

@Injectable()
export class UserState implements NgxsOnInit {

  constructor(private readonly store: Store,
    private readonly userService: UserService,
    private readonly authService: AuthService) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllUsers());
      this.store.dispatch(new LoadCurrentUser());
    });
  }

  @Selector()
  static users(state: UserStateModel): UserItemDto[] {
    return state.users;
  }

  @Selector()
  static filteredUsers(state: UserStateModel): UserItemDto[] {
    return state.filteredUsers;
  }

  @Selector()
  static usersLoading(state: UserStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: UserStateModel): number {
    return state.count;
  }

  @Selector()
  static currentUser(state: UserStateModel): UserDetailDto | undefined {
    return state.currentUser;
  }

  @Selector()
  static filter(state: UserStateModel): UserFilterDto {
    return state.filter;
  }

  @Action(LoadAllUsers)
  loadAllUsers(ctx: StateContext<UserStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.userService.findAll({}).subscribe((users: UserItemDto[]) => {
      ctx.patchState({
        users,
        loading: false,
      });
    });
  }

  @Action(FilterUsers)
  filterUsers(ctx: StateContext<UserStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.userService.findAll(ctx.getState().filter).subscribe((users: UserItemDto[]) => {
      ctx.patchState({
        filteredUsers: users,
        loading: false,
      });
    });
  }

  @Action(CountUsers)
  countUsers(ctx: StateContext<UserStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.userService.getCount(ctx.getState().filter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(LoadCurrentUser)
  currentUser(ctx: StateContext<UserStateModel>, _: LoadCurrentUser): void {
    ctx.patchState({
      loading: true,
    });

    this.userService.findCurrentUser().subscribe((user: UserDetailDto) => {
      ctx.patchState({
        currentUser: user,
        loading: false,
      });
    });
  }

  @Action(ChangeUserFilter)
  changeUserFilter(ctx: StateContext<UserStateModel>, action: ChangeUserFilter) {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    ctx.dispatch(new CountUsers());
    ctx.dispatch(new FilterUsers());
  }
}
