import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';
import { BuildingDetailDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-building-attic-select',
  templateUrl: './building-attic-select.component.html',
  styleUrls: ['./building-attic-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingAtticSelectComponent),
      multi: true,
    },
  ],
})
export class BuildingAtticSelectComponent extends AbstractFormControl<BuildingDetailDto.ConvertedAtticEnum> {
  readonly BuildingAttic = BuildingDetailDto.ConvertedAtticEnum;
}
