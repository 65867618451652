import { Component, computed, inject } from '@angular/core';
import { BuildingItemDto, BuildingService, SortDto } from '@artemis-software/wr-api';
import { loader } from '@/utils/signals/loader';
import { Tracked } from '@/utils/signals/tracked';
import { asyncComputed } from '@/utils/signals/asyncComputed';

@Component({
  selector: 'wr-user-dashboard-building-overview',
  templateUrl: './user-dashboard-building-overview.component.html',
})
export class UserDashboardBuildingOverviewComponent {

  buildingService = inject(BuildingService);

  isLoading = loader();

  @Tracked() page = 0;
  @Tracked() pageSize = 10;
  @Tracked() sort?: SortDto;

  results = asyncComputed(() => {
    return this.buildingService.findBuildingByUser({
      page: this.page,
      size: this.pageSize,
      sort: this.sort ? [`${this.sort?.sort},${this.sort.order.toLowerCase()}`] : undefined,
    });
  }, undefined, this.isLoading);

  readonly getBuildingLink = (building: BuildingItemDto) => (['building', building.id]);

  buildings = computed(() => this.results()?.content ?? []);

  count = computed(() => this.results()?.totalElements ?? 0);


}
