import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ChangeTagFilter, CountTags, FilterTags, LoadAllTags } from './tag.action';
import { TagFilterDto, TagItemDto, TagService } from '@artemis-software/wr-api';
import { AuthService } from '@services/auth.service';

type TagStateModel = {
  tags: TagItemDto[],
  filteredTags: TagItemDto[],
  count: number,
  loading: boolean,
  filter: TagFilterDto,
}


@State<TagStateModel>({
  name: 'tags',
  defaults: {
    tags: [],
    filteredTags: [],
    count: 0,
    loading: false,
    filter: {} as TagFilterDto,
  },
})
@Injectable()
export class TagState implements NgxsOnInit {
  constructor(
    private readonly store: Store,
    private readonly tagService: TagService,
    private readonly authService: AuthService,
  ) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllTags());
    });
  }

  @Selector()
  static tags(state: TagStateModel): TagItemDto[] {
    return state.tags;
  }

  @Selector()
  static filteredTags(state: TagStateModel): TagItemDto[] {
    return state.filteredTags;
  }

  @Selector()
  static tagsLoading(state: TagStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: TagStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: TagStateModel): TagFilterDto {
    return state.filter;
  }

  @Action(LoadAllTags)
  loadAllTags(ctx: StateContext<TagStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.tagService.findAll({}).subscribe((tags: TagItemDto[]) => {
      ctx.patchState({
        tags,
        loading: false,
      });
    });
  }

  @Action(FilterTags)
  filterTags(ctx: StateContext<TagStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.tagService.findAll(ctx.getState().filter).subscribe((tags: TagItemDto[]) => {
      ctx.patchState({
        filteredTags: tags,
        loading: false,
      });
    });
  }

  @Action(CountTags)
  countTags(ctx: StateContext<TagStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.tagService.getCount(ctx.getState().filter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeTagFilter)
  changeTagFilter(ctx: StateContext<TagStateModel>, action: ChangeTagFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountTags());
    this.store.dispatch(new FilterTags());
  }
}
