import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-page-size-selector',
  templateUrl: './page-size-selector.component.html',
  styleUrls: ['./page-size-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PageSizeSelectorComponent,
    },
  ],
})
export class PageSizeSelectorComponent implements ControlValueAccessor {

  size = 10;
  onChange = (_: number) => {
  };
  onTouched = () => {
  };

  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(size: number): void {
    this.size = size;
  }

  sizeChanged($event: number) {
    if ($event == null) {
      return;
    }
    if ($event < 1) {
      this.size = 1;
    } else {
      this.size = $event;
    }
    this.onChange(this.size);
  }
}
