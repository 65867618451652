<nb-card>
  <nb-card-header>
    <span>Rundgänge</span>
    <div class="spacer"></div>
    <wr-status-select [showEmptyOption]="true" [(ngModel)]="status"></wr-status-select>
  </nb-card-header>
  <wr-table [rows]="inspections()"
            [loading]="isLoading()"
            [(sort)]="sort"
            [rowLink]="getInspectionLink"
  >
    <ng-template column
                 let-inspection
                 [of]="inspections()"
                 header="FolgeNr."
                 shrink
                 sort="continuationNumber">
      <ng-template *ngIf="inspection.continuationNumber > 0">{{inspection.continuationNumber}}</ng-template>
    </ng-template>
    <ng-template column
                 let-inspection
                 [of]="inspections()"
                 header="Status"
                 shrink
                 sort="status"><wr-status-text [status]="inspection.status" /></ng-template>
    <ng-template column
                 let-inspection
                 [of]="inspections()"
                 header="Datum"
                 shrink
                 sort="fromTimestamp">{{inspection.fromTimestamp | date: 'dd.MM.yyyy'}}</ng-template>
    <ng-template column
                 let-inspection
                 [of]="inspections()"
                 header="Adresse">{{inspection.building.sectionText}}</ng-template>
    <wr-table-pagination [totalRows]="inspectionCount()"
                         [(pageSize)]="pageSize"
                         [(page)]="page"></wr-table-pagination>
  </wr-table>
</nb-card>
