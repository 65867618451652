import { Pipe, PipeTransform } from '@angular/core';
import { getMaintenanceInterval } from '@/utils/equipment-utils';
import { EquipmentFilterDto } from '@artemis-software/wr-api';
import MaintenanceIntervalEnum = EquipmentFilterDto.MaintenanceIntervalEnum;

@Pipe({
  name: 'maintenanceIntervalTranslation',
})
export class MaintenanceIntervalTranslationPipe implements PipeTransform {
  transform(value: MaintenanceIntervalEnum): string {
    return getMaintenanceInterval(value);
  }
}
