import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BuildingEquipmentState } from '@/store/buildingEquipment/buildingEquipment.state';
import { ActivatedRoute, Router } from '@angular/router';
import { BuildingEquipmentItemDto, BuildingService } from '@artemis-software/wr-api';
import { ChangeBuildingEquipmentFilter } from '@/store/buildingEquipment/buildingEquipment.action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BuildingDetailDto } from '@artemis-software/wr-api/model/buildingDetailDto';
import { defineStoreUsage } from '@/utils/storeUsage';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-building-equipment-overview-page',
  templateUrl: './building-equipment-overview-page.component.html',
  styleUrls: ['./building-equipment-overview-page.component.scss'],
})
export class BuildingEquipmentOverviewPageComponent {
  isAdmin = isAdmin();

  buildingEquipmentStoreUsage = defineStoreUsage({
    filterAction: ChangeBuildingEquipmentFilter,
    countSelector: BuildingEquipmentState.count,
    loadingSelector: BuildingEquipmentState.buildingEquipmentsLoading,
  });

  @Select(BuildingEquipmentState.filteredBuildingEquipments)
  buildingEquipments$!: Observable<BuildingEquipmentItemDto[]>;
  @Select(BuildingEquipmentState.count)
  count$!: Observable<number>;
  @Select(BuildingEquipmentState.buildingEquipmentsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(private readonly store: Store,
              private readonly router: Router,
              private readonly activeRoute: ActivatedRoute,
              private readonly formBuilder: FormBuilder,
              private readonly buildingService: BuildingService) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('buildingEquipmentFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
      this.addSpecialFields(filterValue.organisationId, filterValue.building);
    } else {
      this.initFilterForm('');
    }
    this.triggerFilter();
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      organisationId: this.formBuilder.control(''),
      buildingId: this.formBuilder.control(''),
      isPropertyManager: this.formBuilder.control(false),
      building: this.formBuilder.control(undefined),
    });

    this.form.get('organisationId')?.valueChanges.subscribe(() => {
      this.form.get('building')?.setValue(undefined);
    });

    this.form.get('building')?.valueChanges.subscribe((building: BuildingDetailDto) => {
      this.form.get('buildingId')?.setValue(building?.id);
    });

    this.activeRoute.queryParams.subscribe((params) => {
      if (params['buildingId']) {
        this.buildingService.findById(params['buildingId']).subscribe((building) => {
          this.form.get('building')?.setValue(building);
        });
      }
    });
  }

  async addSpecialFields(organisationId: string, building: BuildingDetailDto): Promise<void> {
    this.form.patchValue({
      'organisationId': organisationId,
      'building': building,
    });
  }

  navigateToBuildingEquipment(buildingEquipment: BuildingEquipmentItemDto): void {
    this.router.navigate(['buildingEquipment', buildingEquipment.id]);
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeBuildingEquipmentFilter(this.form.value));
    localStorage.setItem('buildingEquipmentFilter', JSON.stringify(this.form.value));
  }

  add(): void {
    this.router.navigate(['buildingEquipment', 'new']);
  }
}
