import { Component, Input, Output } from '@angular/core';
import {
  InspectionDetailDto,
  InspectionPdfDetailDto,
  InspectionPdfItemDto,
  InspectionPdfService,
} from '@artemis-software/wr-api';
import { BehaviorSubject, firstValueFrom, Observable, throwError, timeout } from 'rxjs';
import { InspectionPdfDownloadService } from '@services/inspection-pdf-download.service';
import { FilterInspectionPdfs } from '@/store/inspectionPdf/inspectionPdf.action';
import { Store } from '@ngxs/store';
import { tryWithLoading } from '@/utils/async-utils';
import { catchError } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import InspectionPdfTypeEnum = InspectionPdfDetailDto.InspectionPdfTypeEnum;
import { NbDialogService } from '@nebular/theme';
import { ShareLinkDialogComponent } from '@components/dialog/share-link-dialog/share-link-dialog.component';
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-inspection-pdf-entry',
  templateUrl: './inspection-pdf-entry.component.html',
  styleUrls: ['./inspection-pdf-entry.component.scss'],
})
export class InspectionPdfEntryComponent {

  isAdmin = isAdmin();

  @Input()
  label!: string;
  @Input()
  inspection!: InspectionDetailDto;
  @Input()
  inspectionPdfType!: InspectionPdfTypeEnum;

  @Output()
  loading$ = new BehaviorSubject<boolean>(false);

  @Input()
  inspectionPdf$!: Observable<InspectionPdfItemDto | undefined>;

  filter = new FormGroup({
    contractors: new FormControl([]),
    buildingSections: new FormControl([]),
  });

  constructor(
    private readonly inspectionPdfService: InspectionPdfService,
    private readonly inspectionPdfDownloadService: InspectionPdfDownloadService,
    private readonly store: Store,
    private readonly dialogService: NbDialogService,) {
  }

  async generatePdf() {
    await tryWithLoading(this.loading$, async () => {
      await firstValueFrom(
        this.inspectionPdfService.generatePdf({
          inspectionId: this.inspection.id,
          inspectionPdfType: this.inspectionPdfType,
          contractors: this.filter.value.contractors ?? [],
          buildingSections: this.filter.value.buildingSections ?? [],
        }).pipe(
          timeout(30000),
          catchError(error => {
            if (error.name === 'TimeoutError') {
              console.error('Request timed out. Please try again.');
            } else {
              console.error('An error occurred:', error.message);
            }
            return throwError(error);
          }),
        ),
      );
      this.store.dispatch(new FilterInspectionPdfs({ inspectionId: this.inspection.id }));
    });
  }


  openPdfLink() {
    firstValueFrom(this.inspectionPdf$).then(inspectionPdf => {
      if (inspectionPdf && inspectionPdf.presignedUrl) {
        window.open(inspectionPdf.presignedUrl, '_blank');
      } else {
        console.error('presignedUrl is not available');
      }
    }).catch(error => {
      console.error('Error opening PDF link:', error);
    });
  }

  async downloadPdf() {
    await tryWithLoading(this.loading$, async () => {
      try {
        const inspectionPdf = await firstValueFrom(this.inspectionPdf$);
        if (inspectionPdf) {
          await firstValueFrom(this.inspectionPdfDownloadService.downloadPdf(this.getFileName(), inspectionPdf.id));
        } else {
          console.error('Inspection PDF data is not available');
        }
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    });
  }

  getFileName(): string {
    let sectionText = this.inspection?.building.sectionText ?? '';
    sectionText = sectionText.replace('/', '').replace(',', '_').replace(' ', '_');
    return `${this.getTypeText()}_Rundgang_${this.inspection?.continuationNumber}_${sectionText}`;
  }

  getTypeText(): string {
    switch (this.inspectionPdfType) {
    case InspectionPdfTypeEnum.Shortlist:
      return 'Shortlist';
    case InspectionPdfTypeEnum.Longlist:
      return 'Longlist';
    case InspectionPdfTypeEnum.Coversheet:
      return 'Deckblatt';
    case InspectionPdfTypeEnum.BuildingEquipments:
      return 'TGAs';
    case InspectionPdfTypeEnum.CertificateSheet:
      return 'Zertifikat';
    default:
      return '';
    }
  }

  protected readonly InspectionPdfTypeEnum = InspectionPdfTypeEnum;

  async openPdfShareLink() {
    (await firstValueFrom(this.dialogService.open(ShareLinkDialogComponent,
      {
        context: {
          inspectionPdf$: this.inspectionPdf$,
        },
      }).onClose));
  }

  showBuildingSectionFilter(inspectionPdfType: InspectionPdfTypeEnum): boolean {
    return inspectionPdfType === InspectionPdfTypeEnum.Shortlist || inspectionPdfType === InspectionPdfTypeEnum.Longlist;
  }
}
