import { NbComponentStatus } from '@nebular/theme';
import {
  AssessmentPresetItemDto,
  AssessmentPresetMergeDto,
  CheckpointItemDto,
  InspectionDetailDto,
  InspectionEntryDetailDto,
  InspectionEntryMergeDto,
} from '@artemis-software/wr-api';
import { Validators } from '@angular/forms';
import * as uuid from 'uuid';
import { filter } from 'rxjs';
import { createForm, group } from '@sonofabit/ng-core';
import { TimeUtils } from '@shared/form-controls/time-picker/time-picker.component';
import { EntityReferenceRequiredValidator } from '@/utils/validators';
import {
  attachmentDetailToAttachmentReferenceDto,
  attachmentFromDetail,
  attachmentsFromDetails,
  attachmentToMerge,
} from '@shared/form-controls/attachment-upload/attachment-utils';
import CheckIntervalEnum = AssessmentPresetMergeDto.CheckIntervalEnum;
import ActionEnum = InspectionEntryMergeDto.ActionEnum;

export type InspectionFormType = Awaited<ReturnType<typeof createInspectionForm>>;
export type InspectionEntryFormType = Awaited<ReturnType<typeof createInspectionEntryForm>>;

interface InspectionEntryOptions {
  targetSectionId?: string;
  inspectionEntry?: InspectionEntryDetailDto;
  standardAssessment?: AssessmentPresetItemDto;
}

export const getInspectionEntryStatusFromFormGroup = (entry: InspectionEntryFormType): NbComponentStatus => {
  const deadline = entry.controls.deadline?.value ?? undefined;
  const action = entry.controls.action?.value ?? undefined;
  const predefinedAssessmentId = entry.controls.predefinedAssessment?.value?.id;

  return getInspectionEntryStatus(deadline, action, predefinedAssessmentId);
};

export const getInspectionEntryStatus = (deadline?: CheckIntervalEnum, action?: ActionEnum, predefinedAssessmentId?: string): NbComponentStatus => {
  if (deadline && deadline !== CheckIntervalEnum.None || action && action === ActionEnum.ImminentDanger) {
    return 'danger';
  }
  if (action && (action === ActionEnum.ActionRecommended || action === ActionEnum.RiskAnalysisRecommended)) {
    return 'info';
  }
  if (action && action === ActionEnum.NoActionNecessary && predefinedAssessmentId) {
    return 'success';
  }
  return 'basic';
};

export const getInspectionEntryStatusText = (status: NbComponentStatus): string | undefined => {
  switch (status) {
  case 'danger':
    return 'Prüfzeile hat eine Frist oder ist als Gefahr eingestuft';
  case 'info':
    return 'Prüfzeile hat eine Empfehlung';
  case 'success':
    return 'Prüfzeile hat keine Maßnahme und eine Feststellung';
  default:
    return undefined;
  }
};

export const createInspectionForm = (inspection?: InspectionDetailDto) => {
  return createForm(({ field, array, mapToId }) => {
    return {
      id: field({ value: inspection?.id }),
      description: field({ value: inspection?.description }),
      continuationNumber: field({ value: inspection?.continuationNumber, validators: [Validators.required] }),
      normType: field({ value: inspection?.normType, validators: [Validators.required] }),
      date: field({ value: inspection?.fromTimestamp ? new Date(inspection.fromTimestamp) : new Date() }),
      fromTime: field({ value: TimeUtils.fromDateString(inspection?.fromTimestamp) }),
      toTime: field({ value: TimeUtils.fromDateString(inspection?.toTimestamp) }),
      sendingDate: field({
        value: TimeUtils.normalizeDateString(inspection?.sendingTimestamp),
      }),
      building: field({
        value: inspection?.building,
        validators: [Validators.required],
        toDto: value => mapToId(value) ?? undefined,
        alias: 'buildingId',
      }),
      technicianReference: field({
        value: { value: inspection?.technician?.id },
        validators: [EntityReferenceRequiredValidator.createValidator()],
      }),
      clerkReference: field({ value: { value: inspection?.clerk?.id } }),
      status: field({ value: inspection?.status ?? InspectionDetailDto.StatusEnum.Todo }),
      checkpointFilterText: field({ value: '' }),
      selectedCheckpointGroup: field({ value: { value: '', disabled: true } }),
      coverImageReference: field({
        value: attachmentFromDetail(inspection?.coverImage),
        toDto: (value) => attachmentDetailToAttachmentReferenceDto(value),
      }),
      locationReplacement: field({
        value: attachmentFromDetail(inspection?.locationReplacement),
        toDto: (value) => attachmentDetailToAttachmentReferenceDto(value),
      }),
      attachments: field({
        value: attachmentsFromDetails(inspection?.attachments ?? []),
        toDto: (value) => value?.map(dto => attachmentToMerge(dto)),
      }),
      version: field({ value: inspection?.version }),
      usedTemplateId: field({ value: inspection?.usedTemplate?.id }),
      inspectionEntries: array([] as InspectionEntryFormType[]),
      buildingSectionImages: array((inspection?.buildingSectionImagesDto ?? []).map(section =>
        group({
          buildingSectionId: field({ value: section.buildingSectionId }),
          buildingSectionImages: field({
            value: attachmentsFromDetails(section.buildingSectionImages),
            toDto: (value) => value.map(dto => attachmentToMerge(dto)),
          }),
          entranceImages: field({
            value: attachmentsFromDetails(section.entranceImages),
            toDto: (value) => value.map(dto => attachmentToMerge(dto)),
          }),
          doorBellImages: field({
            value: attachmentsFromDetails(section.doorBellImages),
            toDto: (value) => value.map(dto => attachmentToMerge(dto)),
          }),
          houseNumberImages: field({
            value: attachmentsFromDetails(section.houseNumberImages),
            toDto: (value) => value.map(dto => attachmentToMerge(dto)),
          }),
        }),
      )),
      applyPreviousInspection: field<string | undefined>({ value: undefined }),
    };
  });
};

export const createInspectionEntryForm = (checkpoint: CheckpointItemDto, options: InspectionEntryOptions) => {
  const cid = uuid.v4();

  const form = createForm(({ field, mapToId, mapToIds, array }) => {
    return {
      cid: field({ value: cid }),
      id: field({ value: options.inspectionEntry?.id }),
      note: field({ value: options.inspectionEntry?.note }),
      action: field({
        value: options.standardAssessment ?
          options.standardAssessment.action : options.inspectionEntry?.action,
      }),
      deadline: field({
        value: options.standardAssessment?.checkInterval ?
          options.standardAssessment.checkInterval : options.inspectionEntry?.deadline,
      }),
      checkpointId: field({ value: checkpoint.id }),
      checkpointName: field({ value: checkpoint.name }),
      checkpointNumber: field({ value: checkpoint.number }),
      checkpointGroupNumber: field({ value: checkpoint.checkpointGroupNumber }),
      checkpointGroupName: field({ value: checkpoint.checkpointGroupName }),
      checkpointGroupId: field({ value: checkpoint.checkpointGroupId }),
      buildingEquipmentRelated: field({ value: checkpoint.buildingEquipmentRelated }),
      predefinedAssessment: field({
        value: options.standardAssessment ? options.standardAssessment : options.inspectionEntry?.predefinedAssessment,
        toDto: (value) => mapToId(value),
        alias: 'predefinedAssessmentId',
      }),
      buildingSectionId: field({ value: options.targetSectionId ?? options.inspectionEntry?.buildingSection?.id }),
      attachments: field({
        value: attachmentsFromDetails(options.inspectionEntry?.attachments ?? []),
        toDto: (value) => value?.map(dto => attachmentToMerge(dto)),
      }),
      buildingEquipments: field({
        value: options.inspectionEntry?.buildingEquipments?.length ? options.inspectionEntry?.buildingEquipments : undefined,
        toDto: value => value ? mapToIds(value) : undefined,
        alias: 'buildingEquipmentIds',
      }),
      repairTaskId: field({ value: options.inspectionEntry?.repairTaskId }),
      comments: array((options.inspectionEntry?.comments ?? []).map(comment =>
        group({
          id: field({ value: comment.id }),
          text: field({ value: comment.text }),
          isChecked: field({ value: comment.isChecked }),
          createdBy: field({ value: comment.createdBy }),
          checkedBy: field({ value: comment.checkedBy }),
          created: field({ value: comment.created }),
          lastUpdated: field({ value: comment.lastUpdated }),
        }),
      )),
    };
  });
  form.controls.comments?.controls.forEach((comment, index) => {
    if (!comment.controls.text?.value) {
      form.controls.comments?.removeAt(index);
    }
  });
  form.controls.predefinedAssessment?.valueChanges.subscribe((value) => {
    form.controls.action?.setValue(value?.action);
    form.controls.deadline?.setValue(value?.checkInterval);
  });
  form.controls.action?.valueChanges
    .pipe(filter((value) => value === ActionEnum.ImminentDanger))
    .subscribe(() => {
      form.controls.deadline?.setValue(CheckIntervalEnum.OneDay);
    });
  form.updateValueAndValidity({ emitEvent: false });
  form.markAsDirty();
  return form;
};

export const getCheckpointString = (form: InspectionEntryFormType): string => {
  const checkpointGroupNumber = form.controls.checkpointGroupNumber?.value ?? 0;
  const checkpointName = form.controls.checkpointName?.value ?? '';
  const checkpointNumber = form.controls.checkpointNumber?.value ?? 0;
  return `${checkpointGroupNumber}.${checkpointNumber} ${checkpointName}`;
};
