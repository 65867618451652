import { ServerSentEventsService } from '@/services/event.service';
import { Component, inject, NgZone } from '@angular/core';
import {
  BehaviorSubject,
  concat,
  concatMap,
  exhaustMap,
  firstValueFrom,
  merge,
  of,
  Subject,
  switchMap,
  throttleTime,
} from 'rxjs';
import { NotificationDto, NotificationService } from "@artemis-software/wr-api";

@Component({
  selector: 'wr-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss'],
})
export class NotificationsDropdownComponent {
  private readonly eventsService = inject(ServerSentEventsService);
  private readonly notificationsService = inject(NotificationService);

  private readonly notificationEvents = this.eventsService.event('NotificationCreated');

  notifications = new BehaviorSubject<(NotificationDto & { animated?: boolean })[]>([]);

  reloadNotificationCount = new Subject<void>();

  unreadCount = 0;

  get hasUnreadNotifications(): boolean {
    return this.unreadCount > 0;
  }

  get formattedUnreadCount(): string {
    return this.unreadCount > 99 ? '99+' : this.unreadCount.toString();
  }

  constructor(zone: NgZone) {
    merge(of(undefined), this.notificationEvents, this.reloadNotificationCount)
      .pipe(switchMap(() => this.notificationsService.getUnreadNotificationCount()))
      .subscribe((count) => {
        zone.run(() => {
          this.unreadCount = count;
        });
      });

    this.notificationEvents
      .pipe(concatMap((event) => this.notificationsService.getById(event.notificationId)))
      .subscribe((notification) => {
        zone.run(() => {
          this.notifications.next([
            { ...notification, animated: true },
            ...this.notifications.value,
          ]);
        });
      });

    concat(of(undefined), this.loadNotifications)
      .pipe(
        throttleTime(300),
        exhaustMap(() => {
          return this.notificationsService.getAll(
            this.notifications.value[this.notifications.value.length - 1]?.date,
          );
        }),
      )
      .subscribe((notifications) => {
        zone.run(() => {
          this.notifications.next([...this.notifications.value, ...notifications]);
        });
      });
  }

  readonly loadNotifications = new Subject<void>();

  async markAsRead(notification: NotificationDto): Promise<void> {
    if (notification.readAt) return;

    notification = await firstValueFrom(this.notificationsService.markAsRead(notification.id));

    const index = this.notifications.value.findIndex((n) => n.id === notification.id);
    if (index === -1) return;

    const notifications = [...this.notifications.value];
    notifications[index] = notification;

    this.notifications.next(notifications);
    this.reloadNotificationCount.next();
  }

  async markAllAsRead(): Promise<void> {
    await firstValueFrom(this.notificationsService.markAllAsRead());
    this.notifications.next(
      this.notifications.value.map((notification) => ({
        ...notification,
        readAt: notification.date ?? new Date().toISOString(),
      })),
    );
    this.reloadNotificationCount.next();
  }
}
