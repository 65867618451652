/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConditionDto { 
    conditionValue: number;
    condition: ConditionDto.ConditionEnum;
}
export namespace ConditionDto {
    export type ConditionEnum = 'NO_DEFECTS' | 'MINOR_DEFECTS' | 'MAJOR_DEFECTS' | 'NONE';
    export const ConditionEnum = {
        NoDefects: 'NO_DEFECTS' as ConditionEnum,
        MinorDefects: 'MINOR_DEFECTS' as ConditionEnum,
        MajorDefects: 'MAJOR_DEFECTS' as ConditionEnum,
        None: 'NONE' as ConditionEnum
    };
}


