import { Component } from '@angular/core';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { InspectionItemDto } from '@artemis-software/wr-api';
import { InspectionState } from '@/store/inspection/inspection.state';
import { select } from '@/utils/signals/select';

@Component({
  selector: 'wr-inspection-chart',
  templateUrl: './inspection-chart.component.html',
})
export class InspectionChartComponent {

  inspections = select(InspectionState.filteredInspections);

  readonly inspectionStatusCount = asyncComputed(() => {
    const inspections = this.inspections();
    const inspectionStatusCount = new Map<InspectionItemDto.StatusEnum, number>(
      Object.values(InspectionItemDto.StatusEnum).map(status => [status, 0]),
    );
    inspections.forEach(inspection => {
      const count = inspectionStatusCount.get(inspection.status) || 0;
      inspectionStatusCount.set(inspection.status, count + 1);
    });
    return inspectionStatusCount;
  }, new Map<InspectionItemDto.StatusEnum, number>(
    Object.values(InspectionItemDto.StatusEnum).map(status => [status, 0]),
  ));

  readonly inspectionLabels = asyncComputed(() => {
    return Array.from(this.inspectionStatusCount().keys()).map(status => this.getLabelForInspectionStatus(status));
  });

  readonly inspectionData = asyncComputed(() => {
    const statusEnums = Object.values(InspectionItemDto.StatusEnum);
    const initialDataArray = new Array(statusEnums.length).fill(0);
    const statusArrays = new Map<InspectionItemDto.StatusEnum, number[]>(
      statusEnums.map(status => [status, [...initialDataArray]]),
    );

    this.inspectionStatusCount().forEach((count, status) => {
      const statusIndex = statusEnums.indexOf(status);
      if (statusIndex !== -1) {
        const dataArray = statusArrays.get(status);
        if (dataArray) {
          dataArray[statusIndex] = count;
        }
      }
    });

    return Array.from(statusArrays.values()).map((data, index) => {
      const status = statusEnums[index];
      const color = this.getColorForInspectionStatus(status);
      return {
        label: this.getLabelForInspectionStatus(status),
        data,
        borderColor: color,
        backgroundColor: color,
      };
    });
  }, []);

  private getLabelForInspectionStatus(status: InspectionItemDto.StatusEnum): string {
    const labels = {
      'DRAFT': 'Vorlage',
      'TODO': 'Offen',
      'IN_PROGRESS': 'In Arbeit',
      'IN_REVIEW': 'In Prüfung',
      'DONE': 'Abgeschlossen',
      'SENT': 'Gesendet',
      'NONE': 'Kein Status',
    };
    return labels[status];
  }

  private getColorForInspectionStatus(status: InspectionItemDto.StatusEnum): string {
    const colors = {
      'DRAFT': '#ffc94d',
      'TODO': '#0095ff',
      'IN_PROGRESS': '#3366ff',
      'IN_REVIEW': '#db8b00',
      'DONE': '#00d68f',
      'SENT': '#00b887',
      'NONE': 'gray',
    };
    return colors[status];
  }
}
