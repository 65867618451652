import { Component } from '@angular/core';

@Component({
  selector: 'wr-split-view',
  templateUrl: './split-view.component.html',
  styleUrls: ['./split-view.component.scss'],
})
export class SplitViewComponent {


}
