import { Component, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'wr-logout-page',
  template: '',
})
export class LogoutPageComponent implements OnInit {

  constructor(private readonly auth: AuthService) {
  }

  ngOnInit(): void {
    this.auth.logout();
  }
}
