<nb-card>
  <nb-card-header>Rundgänge</nb-card-header>
  <nb-card-body>
    <wr-table [rows]="inspections()"
              [loading]="loading()"
              [rowLink]="getInspectionLink">
      <ng-template column
                   let-inspection
                   [of]="inspections()"
                   header="FolgeNr."
                   shrink
                   sort="continuationNumber">
        <ng-template *ngIf="inspection.continuationNumber > 0">{{ inspection.continuationNumber }}</ng-template>
      </ng-template>
      <ng-template column
                   let-inspection
                   [of]="inspections()"
                   header="Datum"
                   shrink
                   sort="fromTimestamp">{{ inspection.fromTimestamp | date: 'dd.MM.yyyy' }}
      </ng-template>
      <ng-template column
                   let-inspection
                   [of]="inspections()"
                   header="Techniker"
                   shrink
                   sort="technician">
        <div *ngIf="inspection.technician.firstName && inspection.technician.lastName">
          {{ inspection.technician.firstName }} {{ inspection.technician.lastName }}
        </div>
      </ng-template>
      <ng-template column
                   let-inspection
                   [of]="inspections()"
                   header="Sachbearbeiter"
                   shrink
                   sort="clerk">
        <div *ngIf="inspection.clerk ; else noClerk">
          {{ inspection.clerk.firstName }} {{ inspection.clerk.lastName }}
        </div>
        <ng-template #noClerk>
          <div>Nicht zugewiesen</div>
        </ng-template>
      </ng-template>
      <ng-template column
                   let-inspection
                   [of]="inspections()"
                   header="Status"
                   shrink
                   sort="status">
        <wr-status-text [status]="inspection.status" />
      </ng-template>
      <ng-template
        column
        let-inspection
        [of]="inspections()"
        header="Zustand"
        shrink
        sort="condition">
        <div *ngIf="inspection.condition.conditionValue">
          <wr-building-condition
            [condition]="inspection.condition.conditionValue"></wr-building-condition>
        </div>
      </ng-template>
      <wr-table-pagination [totalRows]="count()"
                           [pageSize]="pageSize()"
                           (pageSizeChange)="pageSize.set($event)"
                           [page]="page()"
                           (pageChange)="page.set($event)"></wr-table-pagination>
    </wr-table>
  </nb-card-body>
</nb-card>
