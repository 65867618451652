<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="user"></nb-icon>
          Mitarbeiterstatistik
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="technician">Techniker*in</label>
                <wr-user-multi-select id="technician" formControlName="technicianReferences"
                                      [showNoneOption]="true"></wr-user-multi-select>
              </div>
              <div class="filter-control-group">
                <label class="label" for="datePicker">Zeitraum</label>
                <wr-date-range-picker id="datePicker" formControlName="selectedRange"
                                      [showTodayButton]="true"></wr-date-range-picker>
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
</div>
<div class="statistics-container">
  <nb-card [nbSpinner]="isLoading()">
    <nb-card-body>
      <wr-chart
        *ngIf="statistics() !== undefined"
        [labels]="labels()"
        title="Einträge pro Woche">
        <wr-dataset
          type="bar"
          [data]="datasets().entriesDataset"
          label="Einträge pro Woche"
          backgroundColor="#4c84ff"
          yAxisID="y"
          stack="entries">
        </wr-dataset>
      </wr-chart>
    </nb-card-body>
  </nb-card>

  <nb-card [nbSpinner]="isLoading()">
    <nb-card-body>
      <wr-chart
        *ngIf="statistics() !== undefined"
        [labels]="labels()"
        title="Gebäude pro Woche">
        <wr-dataset
          type="bar"
          [data]="datasets().inspectionsDataset"
          label="Gebäude pro Woche"
          backgroundColor="#3dc8ab"
          yAxisID="y"
          stack="inspections">
        </wr-dataset>
      </wr-chart>
    </nb-card-body>
  </nb-card>

  <nb-card [nbSpinner]="isLoading()">
    <nb-card-body>
      <wr-chart
        *ngIf="statistics() !== undefined"
        [labels]="labels()"
        title="Fläche pro Woche">
        <wr-dataset
          type="bar"
          [data]="datasets().squareMetersDataset"
          label="Fläche pro Woche - m²"
          backgroundColor="#ff6361"
          yAxisID="y"
          stack="squareMeters">
        </wr-dataset>
      </wr-chart>
    </nb-card-body>
  </nb-card>
</div>
