<div class="login-container">

  <nb-card class="login-card">
    <nb-card-body class="login-card-body">
      <img class="corporate-logo" src="assets/pictures/gema-m.svg" alt="GEMA">

      <div class="login-body">
        <form [formGroup]="loginForm" class="login-form">
          <div class="username-control">
            <input type="text"
                   formControlName="username"
                   nbInput
                   fullWidth
                   placeholder="Benutzername"
                   fieldSize="large"
                   data-cy="username"
                   (click)="usernameChange()"
                   (keydown)="usernameChange()">
            <ng-container *ngIf="usernameError">
          <span class="caption status-danger">
            Benutzername benötigt!
          </span>
            </ng-container>
          </div>

          <nb-form-field class="password-control">
            <input [type]="getInputType()"
                   formControlName="password"
                   nbInput
                   fullWidth
                   placeholder="Passwort"
                   fieldSize="large"
                   data-cy="password"
                   (click)="passwordChange()"
                   (keydown)="passwordChange()">
            <button nbSuffix nbButton type="button" ghost (click)="toggleShowPassword()">
              <nb-icon pack="eva" [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"
                       [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
              </nb-icon>
            </button>
            <ng-container *ngIf="passwordError">
          <span class="caption status-danger">
            Passwort wird benötigt!
          </span>
            </ng-container>
          </nb-form-field>
        </form>
      </div>

      <div class="login-footer">
        <button nbButton
                fullWidth
                class="submit"
                status="primary"
                (click)="onSubmit()"
                size="large"
                data-cy="login-button">
          Anmelden
        </button>
      </div>
    </nb-card-body>
  </nb-card>
</div>
