/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RepairTaskItemDto } from './repairTaskItemDto';
import { EmailAttachment } from './emailAttachment';
import { InspectionItemDto } from './inspectionItemDto';
import { UserItemDto } from './userItemDto';


export interface EmailItemDto { 
    id: string;
    user?: UserItemDto;
    subject: string;
    htmlBody: string;
    emailType: EmailItemDto.EmailTypeEnum;
    inspection?: InspectionItemDto;
    repairTasks: Array<RepairTaskItemDto>;
    attachments: Array<EmailAttachment>;
    userRecipients: Array<UserItemDto>;
    recipients: Array<string>;
    isSent: boolean;
}
export namespace EmailItemDto {
    export type EmailTypeEnum = 'INSPECTION' | 'REPAIR_TASK' | 'MAINTENANCE_TIMER';
    export const EmailTypeEnum = {
        Inspection: 'INSPECTION' as EmailTypeEnum,
        RepairTask: 'REPAIR_TASK' as EmailTypeEnum,
        MaintenanceTimer: 'MAINTENANCE_TIMER' as EmailTypeEnum
    };
}


