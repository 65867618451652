import { Component, Input, OnInit } from '@angular/core';
import { EmailMergeDto, EmailMessageDto, UserEmailDto } from '@artemis-software/wr-api';
import { createForm } from '@sonofabit/ng-core';
import { EmailValidationService } from '@services/email-validation.service';

@Component({
  selector: 'wr-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class  SendEmailComponent implements OnInit{
  @Input()
  title = '';
  @Input()
  message = '';
  @Input()
  emailMessage: EmailMessageDto = {
    subject: '',
    attachments: [],
    htmlBody: ''
  };
  @Input()
  multipleReceivers: boolean = false;
  @Input()
  receiverUsers: UserEmailDto[] = [];
  @Input()
  receiver= '';

  items: string[] = [];
  filteredUsers: UserEmailDto[] = [];

  form?: ReturnType<typeof this.initForm>;

  constructor(private readonly emailValidationService: EmailValidationService) {}

  ngOnInit() {
    this.form = this.initForm();
  }

  initForm() {
    return createForm(({ field  }) => {
      return {
        subject: field({
          value: this.emailMessage.subject,
        }),
        receiver: field({
          value: this.receiver,
        }),
        attachments: field({
          value: this.emailMessage.attachments,
        }),
        body: field({
          value: this.emailMessage.htmlBody,
        }),
      };
    });
  }

  submitResponse(): EmailMergeDto {
    if (this.form) {
      if (!this.multipleReceivers) {
        this.items = [this.form.controls.receiver.value];
      }

      return <EmailMergeDto>{
        subject: this.form.controls.subject.value || '',
        htmlBody: this.form.controls.body.value || '',
        attachments: this.form.controls.attachments ? this.form.controls.attachments.value : [],
        recipients: this.items,
      };
    }
    throw new Error('Email subject and/or body is empty');
  }

  onTagAdd(event: any, tagInput: HTMLInputElement) {
    const value = event.value.trim();
    if (this.emailValidationService.isValidEmail(value) || this.receiverUsers.some(user => user.email === value)) {
      if (value && !this.items.includes(value)) {
        this.items.push(value);
        tagInput.value = '';
        this.updateFilteredUsers(tagInput.value);
      }
    }
  }

  onTagRemove(event: any) {
    const value = event.text;
    this.items = this.items.filter(item => item !== value);
    this.updateFilteredUsers('');
  }

  onInput(event: any) {
    const inputValue = event.target.value;
    this.updateFilteredUsers(inputValue);
  }

  onSelect(selected: UserEmailDto, tagInput: HTMLInputElement) {
    const event = { value: selected.email.trim() };
    this.onTagAdd(event, tagInput);
  }

  updateFilteredUsers(inputValue: string) {
    this.filteredUsers = this.receiverUsers.filter(user =>
      user.userName.toLowerCase().includes(inputValue.toLowerCase()) && !this.items.includes(user.email)
    );
  }

  isFormValid() {
    if (this.form) {
      const value = this.form.controls.receiver.value;
      if (this.multipleReceivers) {
        return this.items.length > 0;
      } else {
        return this.emailValidationService.isValidEmail(value);
      }
    }
    return false;
  }
}
