import { MaintenanceTimerFilterDto } from '@artemis-software/wr-api';

export class LoadAllMaintenanceTimers {
  static readonly type = '[MaintenanceTimer] Load all maintenanceTimers';
}

export class FilterMaintenanceTimers {
  static readonly type = '[MaintenanceTimer] Filter maintenanceTimers';
}

export class CountMaintenanceTimers {
  static readonly type = '[MaintenanceTimer] Count maintenanceTimers';
}

export class ChangeMaintenanceTimerFilter {
  static readonly type = '[MaintenanceTimer] Change maintenanceTimer filter';

  constructor(public filter: MaintenanceTimerFilterDto) {
  }
}
