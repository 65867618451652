<nb-card>
  <nb-card-header>
    <h5>
      <nb-icon icon="wrench" pack="fas"></nb-icon>
      Reparaturaufträge
    </h5>
  </nb-card-header>
  <wr-data-table *ngIf="count$ | async as count; else noRepairTasks"
                 [data$]="repairTasks$"
                 [sortKey]="'repairTaskStatisticsSort'"
                 [storeUsage]="repairTaskStoreUsage"
                 [defaultSort]="'title'"
                 [defaultPageSize]="5">
    <ng-template dataColumn let-repairTask key="title" display="Name">
      {{ repairTask.title }}
    </ng-template>
    <ng-template dataColumn let-repairTask key="inspectionEntry.note" display="Prüfpunktzeile">
      {{ repairTask.inspectionEntry.note || "-" }}
    </ng-template>
    <ng-template dataColumn let-repairTask key="deadline" display="Frist">
      {{ repairTask.deadline | date: 'dd.MM.yyyy' }}
    </ng-template>
    <ng-template dataColumn let-repairTask key="status" display="Status">
      <wr-repair-task-status-text [status]="repairTask.status"></wr-repair-task-status-text>
    </ng-template>
    <ng-template dataColumn let-repairTask key="contractor" display="Firma">
      <div *ngIf="repairTask.contractor">
        {{ repairTask.contractor.name }}
      </div>
    </ng-template>
  </wr-data-table>
</nb-card>

<ng-template #noRepairTasks>
  <nb-card-body>
    <h5 class="empty-message">Keine Einträge</h5>
  </nb-card-body>
</ng-template>
