import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable, of, shareReplay, switchMap } from 'rxjs';
import { ListItemDirective } from '@shared/components/list-view/list-item.directive';
import { DetailViewDirective } from './detail-view.directive';

interface Id {
  id: string;
}

@Component({
  selector: 'wr-list-detail-split-view',
  templateUrl: './list-detail-split-view.component.html',
  styleUrls: ['./list-detail-split-view.component.scss'],
})
export class ListDetailSplitViewComponent<ItemDto extends Id, DetailDto extends Id> {

  @Input()
  data$?: Observable<ItemDto[] | undefined>;
  @Input()
  loadDetailFunc?: (item: ItemDto) => Observable<DetailDto>;
  @Input()
  searchText?: string;

  @Output()
  searchTextChange = new EventEmitter<string>();
  @Output()
  addItemClicked = new EventEmitter<void>();

  @ContentChild(ListItemDirective)
  listItemTemplate?: ListItemDirective;

  @ContentChild(DetailViewDirective)
  detailViewTemplate?: DetailViewDirective;

  selectedItem$ = new BehaviorSubject<ItemDto | undefined>(undefined);

  detailItem$: Observable<DetailDto | ItemDto | undefined>;

  constructor() {
    this.detailItem$ = this.selectedItem$.pipe(
      switchMap((item) => {
        if (!item) {
          return of(undefined);
        }
        if (this.loadDetailFunc) {
          return this.loadDetailFunc(item);
        }
        return of(item);
      }),
      shareReplay(1),
    );
  }

  unselect(): void {
    this.selectedItem$.next(undefined);
  }

  refresh(): void {
    const current = this.selectedItem$.value;
    this.selectedItem$.next(undefined);
    this.selectedItem$.next(current);
  }
}
