import { Component, computed, inject, signal } from '@angular/core';
import { BuildingEquipmentItemDto, BuildingEquipmentService } from '@artemis-software/wr-api';
import { select } from '@/utils/signals/select';
import { BuildingState } from '@/store/building/building.state';
import { loader } from '@/utils/signals/loader';
import { asyncComputed } from '@/utils/signals/asyncComputed';

@Component({
  selector: 'wr-building-dashboard-building-equipments',
  templateUrl: './building-dashboard-building-equipments.component.html',
  styleUrls: ['./building-dashboard-building-equipments.component.scss'],
})
export class BuildingDashboardBuildingEquipmentsComponent {
  readonly buildingEquipmentService = inject(BuildingEquipmentService);

  readonly building = select(BuildingState.selectedDashboardBuilding);

  readonly loading = loader();

  readonly page = signal(0);
  readonly pageSize = signal(10);

  readonly filter = computed(() => {
    const building = this.building();
    if (!building) {
      return undefined;
    }
    return {
      buildingId: building.id,
      page: this.page(),
      size: this.pageSize(),
    };
  });

  readonly buildingEquipments = asyncComputed(() => {
    const filter = this.filter();
    return filter ? this.buildingEquipmentService.findAll(filter) : [];
  }, [], this.loading);

  readonly count = asyncComputed(() => {
    const filter = this.filter();
    return filter ? this.buildingEquipmentService.getCount(filter) : 0;
  }, 0, this.loading);

  readonly getBuildingEquipmentLink = (buildingEquipment: BuildingEquipmentItemDto) => (['buildingEquipment', buildingEquipment.id]);
}
