<div class="maintenance-timers" *ngIf="buildingEquipment">
  <wr-maintenance-timer *ngFor="let entity of entities(); trackBy: trackByFn"
                        [maintenanceTimer]="entity"
                        [buildingEquipmentId]="buildingEquipment.id"
                        [initialMaintenanceDays]="initialMaintenanceDays"
                        (update)="onUpdate($event)"
                        (delete)="onDelete(entity)"
  ></wr-maintenance-timer>
  <wr-maintenance-timer *ngIf="creatingEntity()"
                        [buildingEquipmentId]="buildingEquipment.id"
                        [initialMaintenanceDays]="initialMaintenanceDays"
                        (update)="onCreate($event)"
                        (editCancel)="creatingEntity.set(false)"></wr-maintenance-timer>
  <div class="new-timer-button"  *ngIf="!creatingEntity() && isAdmin()" (click)="creatingEntity.set(true)">
    <nb-icon icon="plus-outline" pack="eva"></nb-icon>
  </div>
</div>
