import { AttachmentFilterDto } from '@artemis-software/wr-api';

export class FilterAttachments {
  static readonly type = '[Attachment] Filter Attachments';
}

export class CountAttachments {
  static readonly type = '[Attachment] Count attachments';
}

export class LoadCurrentAttachment {
  static readonly type = '[Attachment] Load current attachment';
}

export class ChangeAttachmentFilter {
  static readonly type = '[Attachment] Change attachment filter';

  constructor(public filter: AttachmentFilterDto) {

  }
}
