import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  ChangeCheckpointGroupFilter,
  CountCheckpointGroups,
  FilterCheckpointGroups,
  LoadAllCheckpointGroups,
} from './checkpointGroup.actions';
import { CheckpointGroupFilterDto, CheckpointGroupItemDto, CheckpointGroupService } from '@artemis-software/wr-api';
import { AuthService } from '@services/auth.service';

type CheckpointGroupStateModel = {
  checkpointGroups: CheckpointGroupItemDto[],
  filteredCheckpointGroups: CheckpointGroupItemDto[],
  count: number,
  loading: boolean,
  filter: CheckpointGroupFilterDto,
};

@State<CheckpointGroupStateModel>({
  name: 'checkpointGroups',
  defaults: {
    checkpointGroups: [],
    filteredCheckpointGroups: [],
    count: 0,
    loading: false,
    filter: {} as CheckpointGroupFilterDto,
  },
})

@Injectable()
export class CheckpointGroupState implements NgxsOnInit {

  constructor(private readonly store: Store,
    private readonly checkpointGroupService: CheckpointGroupService,
    private readonly authService: AuthService) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllCheckpointGroups());
    });
  }

  @Selector()
  static checkpointGroups(state: CheckpointGroupStateModel): CheckpointGroupItemDto[] {
    return state.checkpointGroups;
  }

  @Selector()
  static filteredCheckpointGroups(state: CheckpointGroupStateModel): CheckpointGroupItemDto[] {
    return state.filteredCheckpointGroups;
  }

  @Selector()
  static checkpointGroupsLoading(state: CheckpointGroupStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: CheckpointGroupStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: CheckpointGroupStateModel): CheckpointGroupFilterDto {
    return state.filter;
  }

  @Action(LoadAllCheckpointGroups)
  loadAllCheckpointGroups(ctx: StateContext<CheckpointGroupStateModel>, action: LoadAllCheckpointGroups): void {
    ctx.patchState({
      loading: true,
    });

    this.checkpointGroupService.findAll({}).subscribe((checkpointGroups: CheckpointGroupItemDto[]) => {
      ctx.patchState({
        checkpointGroups,
        loading: false,
      });
    });
  }

  @Action(FilterCheckpointGroups)
  filterCheckpointGroups(ctx: StateContext<CheckpointGroupStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.checkpointGroupService.findAll(ctx.getState().filter).subscribe((checkpointGroups: CheckpointGroupItemDto[]) => {
      ctx.patchState({
        filteredCheckpointGroups: checkpointGroups,
        loading: false,
      });
    });
  }

  @Action(CountCheckpointGroups)
  countCheckpointGroups(ctx: StateContext<CheckpointGroupStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.checkpointGroupService.getCount(ctx.getState().filter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeCheckpointGroupFilter)
  changeCheckpointGroupFilter(ctx: StateContext<CheckpointGroupStateModel>, action: ChangeCheckpointGroupFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountCheckpointGroups());
    this.store.dispatch(new FilterCheckpointGroups());
  }
}
