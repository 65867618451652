import { Component, forwardRef, Input } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { EquipmentDetailDto } from '@artemis-software/wr-api';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-equipment-type-select',
  templateUrl: './equipment-type-select.component.html',
  styleUrls: ['./equipment-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EquipmentTypeSelectComponent),
      multi: true,
    },
  ],
})
export class EquipmentTypeSelectComponent extends AbstractFormControl<EquipmentDetailDto.EquipmentTypeEnum> {

  readonly EquipmentTypeValues = Object.values(EquipmentDetailDto.EquipmentTypeEnum);

  @Input()
  showEmptyOption = false;

  @Input()
  placeholder = 'TGA Typ auswählen';
}
