import { Directive, Input } from '@angular/core';
import { DatasetDirective } from '.';
import { ChartDataset } from 'chart.js/auto';

@Directive({
  selector: 'wr-dataset[type=bar][data]',
  providers: [{ provide: DatasetDirective, useExisting: BarDatasetDirective }],
})
export class BarDatasetDirective extends DatasetDirective {
  @Input() backgroundColor?: string;

  @Input() borderColor?: string;

  @Input() yAxisID?: string;

  @Input() xAxisID?: string;

  @Input() indexAxis?: 'x' | 'y';

  @Input() stack?: string;

  override getDataset(): ChartDataset<'bar'> {
    const { data, label, backgroundColor, borderColor, xAxisID, yAxisID, indexAxis, stack } = this;

    return {
      type: 'bar',
      label,
      data,
      backgroundColor,
      borderColor,
      xAxisID,
      yAxisID,
      indexAxis,
      stack,
    };
  }
}
