import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MappedFormGroup } from '@sonofabit/ng-core';
import { UnwrapFormGroupValue } from '@sonofabit/ng-core/lib/forms/createForm';
import { BehaviorSubject } from 'rxjs';
import { tryWithLoading } from '@/utils/async-utils';

@Component({
  selector: 'wr-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
})
export class FormActionsComponent<TControl extends {
  [K in keyof TControl]: AbstractControl<any>;
}> {

  private _form!: MappedFormGroup<TControl, UnwrapFormGroupValue<TControl>>;

  @Input()
  set form(value: typeof this._form) {
    this._form = value;
    this._form.disable();
  }

  get form() {
    return this._form;
  }

  @Input()
  saveAction?: (val: UnwrapFormGroupValue<TControl>) => Promise<unknown>;
  @Input()
  deleteAction?: (val: UnwrapFormGroupValue<TControl>) => Promise<unknown>;
  @Input()
  deleteDialog?: () => Promise<boolean>;

  loading$ = new BehaviorSubject<boolean>(false);

  get isEditing() {
    return this.form.enabled;
  }

  cancelEdit() {
    this.form.disable();
  }

  startEdit() {
    this.form.enable();
  }

  get saveDisabled() {
    return !this.form.valid || !this.form.dirty;
  }

  async saveClick() {
    await this.executeAction(this.saveAction);
    this.cancelEdit();
  }

  async deleteClick() {
    let confirmed = true;
    if (this.deleteDialog) {
      confirmed = await this.deleteDialog();
    }
    if (confirmed) {
      await this.executeAction(this.deleteAction);
    }
  }

  private async executeAction(action?: (val: UnwrapFormGroupValue<TControl>) => Promise<unknown>) {
    await tryWithLoading(this.loading$, async () => {
      const mappedValue = await this.form.getMappedValue();
      if (action) {
        await action(mappedValue);
      }
    });
  }
}
