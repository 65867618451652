import { Component, Input } from '@angular/core';
import { RepairTaskDetailDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-repair-task-status-text',
  templateUrl: './repair-task-status-text.component.html',
  styleUrls: ['./repair-task-status-text.component.scss'],
})
export class RepairTaskStatusTextComponent {
  readonly StatusEnum = RepairTaskDetailDto.StatusEnum;

  @Input()
  status?: RepairTaskDetailDto.StatusEnum;
}
