import { Component, forwardRef, Input } from '@angular/core';
import { ContractorItemDto, IdReferenceDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { Observable } from 'rxjs';
import { ContractorState } from '@/store/contractor/contractor.state';
import { Select } from '@ngxs/store';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-contractor-multi-select',
  templateUrl: './contractor-multi-select.component.html',
  styleUrls: ['./contractor-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractorMultiSelectComponent),
      multi: true,
    },
  ],
})
export class ContractorMultiSelectComponent extends AbstractFormControl<IdReferenceDto[]> {
  readonly equalFn = (c1: IdReferenceDto, c2: IdReferenceDto) => c1.value === c2.value;

  @Select(ContractorState.contractors)
  contractors$!: Observable<ContractorItemDto[]>;

  @Input()
  showNoneOption = false;
}
