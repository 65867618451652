import { Component, inject } from '@angular/core';
import { MaintenanceTimerDetailDto } from '@artemis-software/wr-api';
import { defineStoreUsage } from '@/utils/storeUsage';
import {
  ChangeMaintenanceTimerFilter,
  CountMaintenanceTimers,
} from '@/store/maintenanceTimer/maintenanceTimer.action';
import { MaintenanceTimerState } from '@/store/maintenanceTimer/maintenanceTimer.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { differenceInDays } from 'date-fns';

@Component({
  selector: 'wr-maintenance-timer-overview-page',
  styleUrls: ['./maintenance-timer-overview-page.component.scss'],
  templateUrl: './maintenance-timer-overview-page.component.html',
})
export class MaintenanceTimerOverviewPageComponent {

  storeUsage = defineStoreUsage({
    filterAction: ChangeMaintenanceTimerFilter,
    countSelector: MaintenanceTimerState.count,
    loadingSelector: MaintenanceTimerState.maintenanceTimersLoading,
  });

  store = inject(Store);

  router = inject(Router);

  @Select(MaintenanceTimerState.filteredMaintenanceTimers)
  maintenanceTimers$!: Observable<MaintenanceTimerDetailDto[]>;

  constructor() {
    this.store.dispatch(new ChangeMaintenanceTimerFilter({
      organisationId: undefined,
    }));
    this.store.dispatch(new CountMaintenanceTimers());
  }

  navigateToBuildingEquipment(entity: MaintenanceTimerDetailDto) {
    this.router.navigate(['buildingEquipment', entity.buildingEquipment.id]);
  }

  dueDays(date: Date): number {
    const current = new Date();
    return differenceInDays(date, current);
  }
}
