<div class="pagination" *ngIf="maxPage > 0">
  <button nbButton status="primary" ghost (click)="startPage()" [disabled]="page$.value === 0" cypressId="startPage">
    <nb-icon pack="eva" icon="arrowhead-left-outline"></nb-icon>
  </button>
  <button nbButton status="primary" ghost (click)="previousPage()" [disabled]="page$.value - 1 < 0" cypressId="previousPage">
    <nb-icon pack="eva" icon="chevron-left-outline"></nb-icon>
  </button>
  <span class="current-page">{{page$.value + 1}} / {{maxPage + 1}}</span>
  <button nbButton status="primary" ghost (click)="nextPage()" [disabled]="page$.value + 1 > maxPage" cypressId="nextPage">
    <nb-icon pack="eva" icon="chevron-right-outline"></nb-icon>
  </button>
  <button nbButton status="primary" ghost (click)="endPage()" [disabled]="page$.value === maxPage" cypressId="endPage">
    <nb-icon pack="eva" icon="arrowhead-right-outline"></nb-icon>
  </button>
</div>
