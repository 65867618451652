import { Component, computed, EventEmitter, inject, Input, Output } from '@angular/core';
import { MaintenanceTimerDetailDto, MaintenanceTimerItemDto, MaintenanceTimerMergeDto } from '@artemis-software/wr-api';
import { createForm } from '@sonofabit/ng-core';
import { Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'wr-maintenance-timer-edit-dialog',
  templateUrl: './maintenance-timer-edit-dialog.component.html',
  styleUrls: ['./maintenance-timer-edit-dialog.component.scss']
})
export class MaintenanceTimerEditDialogComponent {

  readonly dialogRef = inject(NbDialogRef);

  @Input()
  maintenanceTimer!: MaintenanceTimerItemDto;

  @Output()
  saveMaintenanceTimer = new EventEmitter<MaintenanceTimerMergeDto>();

  readonly saveFunction = () => this.save();

  form = computed(() => {
    return createForm(({ field }) => {
      return {
        id: field({
          value: this.maintenanceTimer?.id,
        }),
        buildingEquipmentId: field({
          value: this.maintenanceTimer.buildingEquipmentId,
        }),
        type: field({
          value: this.maintenanceTimer?.type ?? MaintenanceTimerDetailDto.TypeEnum.Maintenance,
        }),
        startDate: field({
          value: this.maintenanceTimer ? new Date(this.maintenanceTimer.startDate) : new Date(),
          validators: [Validators.required],
          toDto: (value: Date) => value.toISOString(),
        }),
        durationDays: field({
          value: this.maintenanceTimer.durationDays,
          validators: [Validators.min(1)],
        }),
        reminderDurationDays: field({
          value: this.maintenanceTimer?.reminderDurationDays ?? 0,
        }),
      };
    });
  });

  get nextDate() {
    const startDate = this.form().value.startDate;
    const durationDays = this.form().value.durationDays;

    if (startDate == undefined || durationDays === undefined) return;

    const nextDate = new Date(startDate.getTime());
    nextDate.setDate(nextDate.getDate() + durationDays);

    return nextDate;
  }

  get reminderDate() {
    const nextDate = this.nextDate;
    const reminderDurationDays = this.form().value.reminderDurationDays;

    if (nextDate == undefined || reminderDurationDays === undefined) return;

    const nextReminderDate = new Date(nextDate.getTime());
    nextReminderDate.setDate(nextReminderDate.getDate() - reminderDurationDays);

    return nextReminderDate;
  }

  async save(){
    this.saveMaintenanceTimer.emit(await this.form().getMappedValue());
    this.dialogRef.close();
  }
}
