import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EquipmentFilterDto, EquipmentItemDto, EquipmentService } from '@artemis-software/wr-api';
import { ChangeEquipmentFilter, CountEquipments, FilterEquipments, LoadAllEquipments } from './equipment.action';
import { AuthService } from '@services/auth.service';

type EquipmentStateModel = {
  equipments: EquipmentItemDto[],
  filteredEquipments: EquipmentItemDto[],
  count: number,
  loading: boolean,
  filter: EquipmentFilterDto,
}

@State<EquipmentStateModel>({
  name: 'equipments',
  defaults: {
    equipments: [],
    filteredEquipments: [],
    count: 0,
    loading: false,
    filter: {} as EquipmentFilterDto,
  },
})

@Injectable()
export class EquipmentState implements NgxsOnInit {

  constructor(private readonly store: Store,
    private readonly equipmentService: EquipmentService,
    private readonly authService: AuthService) {
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllEquipments());
    });
  }

  @Selector()
  static equipments(state: EquipmentStateModel): EquipmentItemDto[] {
    return state.equipments;
  }

  @Selector()
  static filteredEquipments(state: EquipmentStateModel): EquipmentItemDto[] {
    return state.filteredEquipments;
  }

  @Selector()
  static equipmentsLoading(state: EquipmentStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: EquipmentStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: EquipmentStateModel): EquipmentFilterDto {
    return state.filter;
  }

  @Action(LoadAllEquipments)
  loadAllEquipments(ctx: StateContext<EquipmentStateModel>, action: LoadAllEquipments): void {
    ctx.setState({
      ...ctx.getState(),
      loading: true,
    });

    this.equipmentService.findAll({}).subscribe((equipments: EquipmentItemDto[]) => {
      ctx.setState({
        ...ctx.getState(),
        equipments,
        loading: false,
      });
    });
  }

  @Action(FilterEquipments)
  filterEquipments(ctx: StateContext<EquipmentStateModel>): void {
    ctx.setState({
      ...ctx.getState(),
      loading: true,
    });

    const newFilter = this.createFilter(ctx.getState().filter);
    this.equipmentService.findAll(newFilter).subscribe((equipments: EquipmentItemDto[]) => {
      ctx.setState({
        ...ctx.getState(),
        filteredEquipments: equipments,
        loading: false,
      });
    });
  }

  @Action(CountEquipments)
  countEquipments(ctx: StateContext<EquipmentStateModel>): void {
    ctx.setState({
      ...ctx.getState(),
      loading: true,
    });

    const newFilter = this.createFilter(ctx.getState().filter);
    this.equipmentService.getCount(newFilter).subscribe((count: number) => {
      ctx.setState({
        ...ctx.getState(),
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeEquipmentFilter)
  changeEquipmentFilter(ctx: StateContext<EquipmentStateModel>, action: ChangeEquipmentFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    this.store.dispatch(new CountEquipments());
    this.store.dispatch(new FilterEquipments());
  }

  private createFilter(filter: EquipmentFilterDto) {
    return {
      text: filter.text,
      page: filter.page,
      size: filter.size,
      sort: filter.sort,
      equipmentType: filter.equipmentType && filter.equipmentType.length > 0 ? filter.equipmentType : undefined,
      maintenanceInterval: filter.maintenanceInterval && filter.maintenanceInterval.length > 0 ? filter.maintenanceInterval : undefined,
    };
  }
}
