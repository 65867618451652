import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CheckVersion } from './version.actions';
import { StateReset } from 'ngxs-reset-plugin';
import { environment } from '../../../environments/environment';

type VersionStateModel = {
  version?: string
};

@State<VersionStateModel>({
  name: 'version',
  defaults: {
    version: undefined,
  },
})
@Injectable()
export class VersionState implements NgxsOnInit {

  constructor(private readonly store: Store) {
  }

  ngxsOnInit(ctx: StateContext<VersionStateModel>): void {
    this.store.dispatch(new CheckVersion());
  }

  @Action(CheckVersion)
  async checkVersion(ctx: StateContext<VersionStateModel>): Promise<void> {
    const storeVersion = ctx.getState().version;
    const currentVersion = environment.storeVersion;

    if (storeVersion !== currentVersion) {
      console.log('Resetting store', storeVersion, currentVersion);
      await this.store.dispatch(new StateReset());
      window.localStorage.clear();
      window.sessionStorage.clear();
      ctx.patchState({
        version: currentVersion,
      });
    }
  }
}
