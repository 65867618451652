import { Component, computed, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExternalContractorService } from '@artemis-software/wr-api';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { loader } from '@/utils/signals/loader';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'wr-contractor-external-dashboard-archive',
  templateUrl: './contractor-external-dashboard-archive.component.html',
  styleUrls: ['./contractor-external-dashboard-archive.component.scss']
})
export class ContractorExternalDashboardArchiveComponent {

  readonly loading = loader();
  filterValues = signal({ text: '', tag: undefined });

  form!: FormGroup;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly externalContractorService: ExternalContractorService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.initFilter();
  }

  buildingId = computed(() => {
    return this.route.snapshot.paramMap.get('id');
  });

  attachments = asyncComputed(() => {
    const id = this.buildingId();
    const filter = this.filterValues();
    if (id) {
      return this.externalContractorService.findAllAttachmentsByBuilding(id, filter.text, filter.tag);
    }
    return [];
  }, [], this.loading);

  tags = asyncComputed(() => {
    const id = this.buildingId();
    if (id) {
      return this.externalContractorService.findTagsByInspectionBuilding(id);
    }
    return [];
  }, [], this.loading);

  initFilter() {
    const filter = localStorage.getItem('attachmentContractorFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
      this.form.patchValue({
        'tag': filterValue.tag,
      });
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      tag: this.formBuilder.control(''),
    });
  }

  triggerFilter(): void {
    const filterValues = this.form.value;
    this.filterValues.set({
      text: filterValues.text,
      tag: filterValues.tag.id
    });
  }

}
