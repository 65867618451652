<nb-tag-list *ngIf="value as value" (tagRemove)="onTagRemove($event)" [class.has-tags]="value.length > 0">
  <nb-tag *ngFor="let tag of value"
          [text]="tag.name"
          [removable]="!isDisabled"
          [style.background-color]="tag.color"
  [style.color]="getColor(tag.color)">
  </nb-tag>
</nb-tag-list>

<span class="text-hint" *ngIf="!value?.length && isDisabled">
  Keine Tags ausgewählt
</span>

<ng-container *ngIf="contextMenu$|async as contextMenu">
  <button *ngIf="!isDisabled"
          nbButton
          size="small"
          shape="round"
          type="button"
          [nbContextMenu]="contextMenu"
          [nbContextMenuTag]="contextMenuTag">
    <nb-icon pack="eva" icon="plus-outline"></nb-icon>
  </button>
</ng-container>
