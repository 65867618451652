<wr-page pageTitle="Wartungstimer" icon="hourglass-half" iconPack="fas" backLink="/buildingEquipments">
  <wr-data-table
    [data$]="maintenanceTimers$"
    [sortKey]="'cooperativeMaintenanceTimerSort'"
    [defaultSort]="'nextDate'"
    [storeUsage]="storeUsage"
    (lineClick)="navigateToBuildingEquipment($event)"
  >
    <ng-template dataColumn
                 let-entity
                 key="equipment"
                 display="TGA"
                 [sortable]="false"
    >
      {{ entity.buildingEquipment.equipmentName }}
    </ng-template>
    <ng-template dataColumn
                 let-entity
                 key="type"
                 display="Typ">
      {{entity.type | maintenanceTimerType}}
    </ng-template>
    <ng-template dataColumn
                 let-entity
                 key="durationDays"
                 display="Dauer">
      {{entity.durationDays}} {{ entity.durationDays | pluralize: 'Tag': 'Tage' }}
    </ng-template>
    <ng-template dataColumn
                 let-entity
                 key="nextDate"
                 display="Fälligkeitsdatum">
      {{ entity.nextDate | date: 'dd.MM.yyyy' }}
    </ng-template>
  </wr-data-table>

</wr-page>
