<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="image"></nb-icon>
          Archiv
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <div class="filter-control-group">
                <label class="label" for="datePicker">Zeitraum</label>
                <wr-date-range-picker id="datePicker" formControlName="selectedRange"
                                      [showTodayButton]="true"></wr-date-range-picker>
              </div>
              <div class="filter-control-group">
                <label class="label" for="isArchive">Im Archiv?</label>
                <nb-toggle id="isArchive" formControlName="isArchive" class="is-archive"></nb-toggle>
              </div>
              <div class="filter-control-group">
                <label class="label" for="building">Gebäude</label>
                <wr-building-select id="building" formControlName="building"></wr-building-select>
              </div>
              <div class="filter-control-group">
                <label class="label" for="organisation">Genossenschaften</label>
                <wr-organisation-select id="organisation"
                                        formControlName="organisationId"
                                        [showEmptyOption]="true"></wr-organisation-select>
              </div>
              <div class="filter-control-group">
                <label class="label" for="tag">Tags</label>
                <wr-tag-select id="tag" formControlName="tag"></wr-tag-select>
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
    [data$]="attachments$"
    [sortKey]="'attachmentSort'"
    [storeUsage]="attachmentStorageUse"
    [defaultSort]="'created'"
  >
    <ng-template
      dataColumn
      let-attachment
      key="preview"
      display="Datei"
      [sortable]="false">
      <wr-attachment-preview
                             [isDisabled]="true"
                             [attachment]="attachment">
      </wr-attachment-preview>
    </ng-template>
    <ng-template
      dataColumn
      let-attachment
      key="name"
      display="Dateiname"
      [sortable]="false">
      <div *ngIf="attachment.fileName && attachment.fileEnding">
        {{attachment.fileName}}.{{attachment.fileEnding}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-attachment
      key="comment"
      display="Kommentar"
      [sortable]="false">
      <div *ngIf="attachment.comment">
        {{attachment.comment}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-attachment
      key="tags"
      display="Tags"
      [sortable]="false">
      <div *ngIf="attachment.tags">
        <wr-tag-list [tags]="attachment.tags"></wr-tag-list>
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-attachment
      key="created"
      display="Erstellungsdatum"
      [sortable]="false">
      <div *ngIf="attachment.created">
        {{displayDate(attachment.created)}}
      </div>
    </ng-template>
  </wr-data-table>
</div>
