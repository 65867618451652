<div class="detail-container">
  <nb-card [nbSpinner]="!!(saving$|async)">
    <nb-card-header>
      <button nbButton ghost status="primary" (click)="navigateBack()">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>Benutzer</h5>
      <wr-edit-delete-button-group *ngIf="isAdmin()" (edit)="onEdit()" (delete)="onDelete()"></wr-edit-delete-button-group>
    </nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="form">
        <form [formGroup]="form">
          <div class="sub-card-body">
            <input hidden formControlName="id">
            <div class="grid">
              <div class="col-6">
                <label class="label" for="userName">Benutzername</label>
                <input fullWidth id="userName" type="text" nbInput formControlName="userName">
                <div class="error" *ngIf="hasError('userName')">Benutzername ist erforderlich!</div>
              </div>
              <div class="col-6">
                <label class="label" for="email">Email</label>
                <input fullWidth id="email" type="text" nbInput formControlName="email">
                <div class="error" *ngIf="hasError('email')">Email ist erforderlich!</div>
              </div>
            </div>
            <div class="grid">
              <div class="col-6">
                <label class="label" for="firstName">Vorname</label>
                <input fullWidth id="firstName" type="text" nbInput formControlName="firstName">
                <div class="error" *ngIf="hasError('firstName')">Vorname ist erforderlich!</div>
              </div>
              <div class="col-6">
                <label class="label" for="lastName">Nachname</label>
                <input fullWidth id="lastName" type="text" nbInput formControlName="lastName">
                <div class="error" *ngIf="hasError('lastName')">Nachname ist erforderlich!</div>
              </div>
            </div>
            <div class="grid">
              <div class="col-4">
                <label class="label" for="abbreviation">Kürzel</label>
                <input fullWidth id="abbreviation" type="text" nbInput formControlName="abbreviation">
                <div class="error" *ngIf="hasError('abbreviation')">Kürzel ist erforderlich!</div>
              </div>
              <div class="col-4">
                <label class="label" for="organisationSelect">Genossenschaften</label>
                <wr-organisation-select id="organisationSelect"
                                        formControlName="organisationId"></wr-organisation-select>
                <div class="error" *ngIf="hasError('organisationId')">Genossenschaft ist erforderlich!</div>
              </div>
            </div>
            <div class="grid">
              <div class="col-6">
                <label class="label" for="password">Neues Passwort</label>
                <input fullWidth id="password" type="password" nbInput formControlName="password">
              </div>
              <div class="col-6">
                <label class="label" for="repeatPassword">Passwort bestätigen</label>
                <input fullWidth id="repeatPassword" type="password" nbInput formControlName="repeatPassword">
              </div>
            </div>
            <div class="grid">
              <div class="col-12">
                <label class="label">Bilder & Dokumentenliste</label>
                <wr-multi-attachment-control formControlName="attachments"></wr-multi-attachment-control>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </nb-card-body>
    <nb-card-footer *ngIf="isAdmin()">
      <button size="medium" [disabled]="!isEditing" nbButton status="primary" (click)="submit()">Speichern</button>
      <button size="medium" [disabled]="!isEditing" nbButton status="basic" (click)="cancel()">Zurück</button>
    </nb-card-footer>
  </nb-card>
</div>
