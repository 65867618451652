import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ChangeInspectionFilter, CountInspections, FilterInspections } from './inspection.action';
import { InspectionFilterDto, InspectionItemDto, InspectionService } from '@artemis-software/wr-api';
import { firstValueFrom } from 'rxjs';

type InspectionStateModel = {
  inspections: InspectionItemDto[],
  filteredInspections: InspectionItemDto[],
  count: number,
  loading: boolean
  filter: InspectionFilterDto,
}

@State<InspectionStateModel>({
  name: 'inspections',
  defaults: {
    inspections: [],
    filteredInspections: [],
    count: 0,
    loading: false,
    filter: {} as InspectionFilterDto,
  },
})

@Injectable()
export class InspectionState {

  constructor(private readonly store: Store,
              private readonly inspectionService: InspectionService) {
  }

  @Selector()
  static inspections(state: InspectionStateModel): InspectionItemDto[] {
    return state.inspections;
  }

  @Selector()
  static filteredInspections(state: InspectionStateModel): InspectionItemDto[] {
    return state.filteredInspections;
  }

  @Selector()
  static inspectionsLoading(state: InspectionStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: InspectionStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: InspectionStateModel): InspectionFilterDto {
    return state.filter;
  }

  @Action(FilterInspections)
  async filterInspections(ctx: StateContext<InspectionStateModel>): Promise<void> {
    try {
      const inspections = await firstValueFrom(this.inspectionService.findAll(ctx.getState().filter));
      ctx.patchState({
        filteredInspections: inspections,
      });
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(CountInspections)
  async countInspections(ctx: StateContext<InspectionStateModel>): Promise<void> {
    const count = await firstValueFrom(this.inspectionService.getCount(ctx.getState().filter));
    ctx.patchState({
      count: count,
    });
  }

  @Action(ChangeInspectionFilter)
  changeInspectionFilter(ctx: StateContext<InspectionStateModel>, action: ChangeInspectionFilter) {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
      loading: true,
    });
    this.store.dispatch(new CountInspections());
    this.store.dispatch(new FilterInspections());
  }
}
