<nb-form-field *ngIf="visible; else placeholderSelect">
  <nb-icon *ngIf="hasSelectedEquipment()"
           nbPrefix
           nbTooltip="hat TGA"
           nbTooltipStatus="info"
           status="info"
           icon="cube"
           pack="fas"></nb-icon>
  <nb-select fullWidth
             [disabled]="isDisabled"
             placeholder="Feststellung auswählen"
             [compareWith]="equals"
             [(selected)]="value">
    <nb-option *ngFor="let assessmentPreset of checkpoint?.assessmentPresets"
               [value]="assessmentPreset">
      <div class="icon-text-container">
        <nb-icon class="fixed-icon"
                 *ngIf="hasEquipment(assessmentPreset)"
                 nbTooltip="hat TGA"
                 nbTooltipStatus="info"
                 status="info"
                 icon="cube"
                 pack="fas"></nb-icon>
        <div class="text-content">{{assessmentPreset.text}}</div>
      </div>
    </nb-option>
  </nb-select>
</nb-form-field>

<ng-template #placeholderSelect>
  <nb-select fullWidth></nb-select>
</ng-template>
