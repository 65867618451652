import { EquipmentFilterDto } from '@artemis-software/wr-api';
import { getEquipmentType } from '@/utils/equipment-utils';
import { Pipe, PipeTransform } from '@angular/core';
import EquipmentTypeEnum = EquipmentFilterDto.EquipmentTypeEnum;

@Pipe({
  name: 'equipmentTypeTranslation',
})
export class EquipmentTypeTranslationPipe implements PipeTransform {
  transform(value: EquipmentTypeEnum): string {
    return getEquipmentType(value);
  }
}
