import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsDropdownComponent } from './notifications-dropdown/notifications-dropdown.component';
import { SharedModule } from '@/shared/shared.module';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { RouterModule } from '@angular/router';
import { NbBadgeModule } from "@nebular/theme";
import { NotificationItemCompactComponent } from './notification-item-compact/notification-item-compact.component';

@NgModule({
  declarations: [NotificationsDropdownComponent, NotificationItemComponent, NotificationItemCompactComponent],
  imports: [CommonModule, SharedModule, RouterModule, NbBadgeModule],
  exports: [NotificationsDropdownComponent, NotificationItemComponent, NotificationItemCompactComponent],
})
export class NotificationsModule {
}
