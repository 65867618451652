<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="bell"></nb-icon>
          Benachrichtigungen
        </div>
        <ng-container *ngIf="filterForm">
          <form [formGroup]="filterForm">
            <div class="list-filter-control">
              <div class="filter-control-group">
                  <label class="label" for="read">Gelesen</label>
                  <nb-checkbox id="read" formControlName="read"></nb-checkbox>
              </div>
              <div class="filter-control-group">
                <label class="label" for="variant">Variante</label>
                  <wr-notification-variant-select
                    [showEmptyOption]="true"
                    id="variant"
                    formControlName="variant">
                  </wr-notification-variant-select>
              </div>
              <div class="filter-control-group">
                <label class="label" for="type">Typ</label>
                  <wr-notification-type-select
                    id="type"
                    formControlName="type"
                    [showEmptyOption]="true">
                  </wr-notification-type-select>
              </div>
              <div class="filter-control-group">
                <label class="label">Vor diesem Datum</label>
                  <wr-date-picker formControlName="before" />
              </div>
              <button nbButton ghost status="primary" (click)="loadNotifications()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
</div>

<ng-container *ngIf="notifications()">
  <wr-list-view [items]="notifications()"
                [searchText]="searchText()"
                (searchTextChange)="onSearchTextChange($event)">
    <ng-template wrListItem let-item>
      <wr-notification-item-compact
        [notification]="item"
        (markAsRead)="markAsRead($event)">
      </wr-notification-item-compact>
    </ng-template>
  </wr-list-view>
</ng-container>
