import { Pipe, PipeTransform } from '@angular/core';
import { EmailItemDto } from '@artemis-software/wr-api';

@Pipe({
  name: 'emailTypeTranslation',
})
export class EmailTypeTranslationPipe implements PipeTransform {

  transform(value: EmailItemDto.EmailTypeEnum | null | undefined): string | undefined {
    switch (value) {
    case EmailItemDto.EmailTypeEnum.Inspection:
      return 'Rundgang';
    case EmailItemDto.EmailTypeEnum.RepairTask:
      return 'Reparaturaufträge';
    }
    return undefined;
  }
}
