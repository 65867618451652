import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { CustomFieldCollectionMemberMergeDto, CustomFieldItemDto, CustomFieldService } from '@artemis-software/wr-api';
import { combineLatest, debounceTime, filter, map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import Sortable from 'sortablejs';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { randomId } from '@/utils/random-utils';

type CustomFieldWithRequiredFlag = CustomFieldItemDto & { required: boolean };

@Component({
  selector: 'wr-custom-fields-select',
  templateUrl: './custom-fields-select.component.html',
  styleUrls: ['./custom-fields-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldsSelectComponent),
      multi: true,
    },
  ],
})
export class CustomFieldsSelectComponent extends AbstractFormControl<CustomFieldCollectionMemberMergeDto[]> implements OnInit, OnDestroy {

  customFields$?: Observable<CustomFieldItemDto[]>;
  selectedCustomFields$?: Observable<CustomFieldWithRequiredFlag[]>;
  notSelectedCustomFields$?: Observable<CustomFieldItemDto[]>;

  destroy$ = new Subject<void>();
  contextMenu: NbMenuItem[] = [];
  contextMenuTag = randomId();

  get sortableOptions(): Sortable.Options {
    return {
      disabled: this.isDisabled,
    };
  }

  constructor(
    private readonly customFieldService: CustomFieldService,
    private readonly nbMenuService: NbMenuService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.customFields$ = this.customFieldService.findAll({}).pipe(
      takeUntil(this.destroy$),
      shareReplay(1),
    );

    this.selectedCustomFields$ = combineLatest([
      this.customFields$,
      this.value$,
    ]).pipe(
      debounceTime(100),
      map(([customFields, selectedCustomFields]) => {
        return selectedCustomFields?.map(memberDto => {
          const field = customFields.find(field => field.id === memberDto.fieldId);
          const required = memberDto.required;
          return { ...field!, required: required ?? false };
        }) ?? [];
      }),
    );

    this.notSelectedCustomFields$ = combineLatest([
      this.customFields$,
      this.selectedCustomFields$,
    ]).pipe(
      map(([customFields, selectedCustomFields]) => {
        return customFields.filter(field => !selectedCustomFields.find(selectedField => selectedField.id === field.id));
      }),
    );

    this.notSelectedCustomFields$.subscribe(fields => {
      this.contextMenu = fields.map(field => {
        return {
          title: field.name,
          data: field,
        };
      });
    });

    this.nbMenuService.onItemClick().pipe(
      takeUntil(this.destroy$),
      filter(menu => menu.tag === this.contextMenuTag),
    ).subscribe(menu => {
      this.select(menu.item.data);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  select($event: CustomFieldItemDto) {
    this.value = [
      ...this.value ?? [],
      {
        fieldId: $event.id,
        required: false,
      },
    ];
  }

  remove(field: CustomFieldWithRequiredFlag) {
    this.value = (this.value ?? []).filter(member => member.fieldId !== field.id);
  }

  onSort() {
    this.value = [...this.value ?? []];
  }

  toggleRequired(field: CustomFieldWithRequiredFlag, required: boolean) {
    this.value = (this.value ?? []).map(member => {
      if (member.fieldId === field.id) {
        return {
          ...member,
          required,
        };
      }
      return member;
    });
  }
}
