import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlsModule } from './form-controls/form-controls.module';
import { NebularModule } from './nebular/nebular.module';
import { PipesModule } from './pipes/pipes.module';
import { HauFormsModule } from '@sonofabit/ng-core';
import { DirectivesModule } from '@shared/directives/directives.module';
import { CustomFormModule } from '@shared/custom-form/custom-form.module';
import { ComponentsModule } from '@shared/components/components.module';
import { GenericModule } from '@shared/generic/generic.module';
import { CalendarModule } from 'angular-calendar';

@NgModule({
  declarations: [],
  imports: [
    HauFormsModule,
    CommonModule,
    FormControlsModule,
    NebularModule,
    PipesModule,
    DirectivesModule,
    CustomFormModule,
    ComponentsModule,
    GenericModule,
  ], exports: [
    FormControlsModule,
    NebularModule,
    PipesModule,
    DirectivesModule,
    CustomFormModule,
    ComponentsModule,
    GenericModule,
    CalendarModule,
  ],
})
export class SharedModule {
}
