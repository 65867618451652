import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CheckpointDetailDto,
  CheckpointService, EmailMergeDto,
  InspectionDetailDto,
  InspectionEntryItemDto,
  InspectionEntryService,
  RepairTaskDetailDto,
  RepairTaskMergeDto,
  RepairTaskService,
} from '@artemis-software/wr-api';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter, firstValueFrom,
  map,
  Observable,
  shareReplay,
  switchMap,
} from 'rxjs';
import { NbDialogService, NbMenuItem, NbMenuService, NbToastrService } from '@nebular/theme';
import {
  RepairTaskEditDialogComponent,
} from '@pages/inspection-detail-page-v2/repair-tasks/repair-task-edit-dialog/repair-task-edit-dialog.component';
import {
  RepairTaskGenerateComponent,
} from '@pages/inspection-detail-page-v2/repair-tasks/repair-task-generate/repair-task-generate.component';
import { tryWithLoading } from '@/utils/async-utils';
import { ListViewGroup } from '@shared/components/grouped-list-view/grouped-list-view.component';
import { SendEmailComponent } from '@components/dialog/send-email/send-email.component';
import { randomId } from '@/utils/random-utils';
import { RepairTaskStatusTranslationPipe } from '@shared/pipes/repairTaskStatusTranslation.pipe';
import { joinUrl } from '@/utils/url-utils';
import { isAdmin } from '@/utils/admin-utils';
import EmailTypeEnum = EmailMergeDto.EmailTypeEnum;

export type InspectionEntryWithCheckpoint = InspectionEntryItemDto & {
  checkpoint: CheckpointDetailDto,
}

@Component({
  selector: 'wr-repair-tasks',
  templateUrl: './repair-tasks.component.html',
  styleUrls: ['./repair-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepairTasksComponent {

  isAdmin = isAdmin();

  readonly contextMenuTag = randomId();

  textFilter$ = new BehaviorSubject<string>('');
  repairTasks$!: Observable<RepairTaskDetailDto[]>;
  repairTasksGrouped$!: Observable<ListViewGroup<RepairTaskDetailDto>[]>;

  loading$ = new BehaviorSubject<boolean>(false);
  reload$ = new BehaviorSubject(undefined);
  destroy$ = new BehaviorSubject(undefined);

  private _inspection$ = new BehaviorSubject<InspectionDetailDto | null>(null);

  @Input()
  set inspection(value: InspectionDetailDto) {
    this._inspection$.next(value);
  }

  constructor(
    private readonly repairTaskService: RepairTaskService,
    private readonly dialogService: NbDialogService,
    private readonly nbToastrService: NbToastrService,
    private readonly inspectionEntryService: InspectionEntryService,
    private readonly checkpointService: CheckpointService,
    private readonly nbDialogService: NbDialogService,
    private readonly nbMenuService: NbMenuService,
    private readonly repairTaskStatusTranslationPipe: RepairTaskStatusTranslationPipe,
  ) {
    this.repairTasks$ = combineLatest([this._inspection$, this.textFilter$, this.reload$]).pipe(
      debounceTime(100),
      filter(([inspection]) => !!inspection),
      map(([inspection, filter]) => ({
        inspection: inspection as InspectionDetailDto,
        filter: filter,
      })),
      switchMap(({ inspection, filter }) =>
        this.repairTaskService.findAll({
          inspectionId: inspection.id,
          text: filter,
        }),
      ),
      shareReplay(1),
    );

    this.repairTasksGrouped$ = this.repairTasks$.pipe(
      map((repairTasks) => {
        const map = new Map<string, ListViewGroup<RepairTaskDetailDto>>();
        for (const repairTask of repairTasks) {
          const contractor = repairTask.contractor;
          const key = contractor?.id ?? 'no-contractor';
          if (!map.has(key)) {
            map.set(key, {
              id: key,
              displayName: contractor?.name ?? 'Nicht zugeordnet',
              items: [],
            });
          }
          map.get(key)!.items.push(repairTask);
        }
        return Array.from(map.values());
      }),
    );

    this.nbMenuService.onItemClick().pipe(
      filter(menu => menu.tag === this.contextMenuTag),
    ).subscribe(menu => {
      const { status, items } = menu.item.data;
      this.updateStatusForAll(status, items);
    });
  }

  getStatusContextMenu(items: RepairTaskDetailDto[]): NbMenuItem[] {
    return Object.values(RepairTaskDetailDto.StatusEnum).map((status) => ({
      title: this.repairTaskStatusTranslationPipe.transform(status),
      data: {
        status: status,
        items: items,
      },
    } as NbMenuItem));
  }

  async editRepairTask(repairTask: RepairTaskDetailDto) {
    const checkpointId = repairTask.inspectionEntry?.checkpointId;

    if (!checkpointId) {
      throw new Error('CheckpointId is missing');
    }

    const checkpoint = await firstValueFrom(this.checkpointService.findById(checkpointId));
    const ref = this.dialogService.open(RepairTaskEditDialogComponent, {
      autoFocus: false,
    });
    ref.componentRef.instance.initDialog(repairTask, checkpoint.contractors);
    ref.onClose.subscribe(async (repairTaskMergeDto: RepairTaskMergeDto) => {
      if (repairTaskMergeDto) {
        await tryWithLoading(this.loading$, async () => await firstValueFrom(this.repairTaskService.merge(repairTaskMergeDto)));
        this.nbToastrService.success('Reparaturauftrag wurde erfolgreich bearbeitet', 'Bearbeitet');
        this.reload();
      }
    });
  }

  async addRepairTasks(): Promise<void> {
    try {
      const inspection = this._inspection$.value!;
      const inspectionEntries = await firstValueFrom(this.inspectionEntryService.getInspectionEntriesWithActionNecessary(inspection.id));
      const result = await firstValueFrom(this.dialogService.open(RepairTaskGenerateComponent, {
        context: {
          entries: inspectionEntries,
          building: inspection.building,
        },
      }).onClose) as string[];

      const mergePromises: Promise<RepairTaskDetailDto>[] = result.map(async (entryId) =>
        await firstValueFrom(this.repairTaskService.merge({ inspectionEntryId: entryId })),
      );

      await Promise.all(mergePromises);
      this.nbToastrService.success('Reparaturaufträge wurden erfolgreich erstellt', 'Erstellt');
      this.reload();
    } catch (error) {
      console.error(error);
      this.nbToastrService.danger('Reparaturaufträge konnten nicht erstellt werden', 'Fehler');
    }
  }

  reload(): void {
    this.reload$.next(undefined);
  }

  async showEmailDialog(event: MouseEvent, items: RepairTaskDetailDto[]) {
    event.stopPropagation();
    try {
      const contractor = items[0].contractor!;
      const repairTasksIds = items.map((item) => item.id);
      const url = joinUrl(window.origin, '/#/contractor-login/');
      const emailContent = await tryWithLoading(this.loading$, async () =>
        await firstValueFrom(this.repairTaskService.generateEmailContent(repairTasksIds, url)),
      );

      const dialogRef = this.nbDialogService.open(SendEmailComponent, {
        context: {
          title: 'E-Mail senden',
          message: 'Bist du sicher, dass du eine E-Mail an den Auftragnehmer senden willst?',
          emailMessage: emailContent,
          receiver: contractor.email,
        },
      });

      const message = await firstValueFrom(dialogRef.onClose);
      if (!message) {
        this.nbToastrService.info('Es wurde keine E-Mail versendet', 'E-Mail abgebrochen');
        return;
      }
      message.emailType = EmailTypeEnum.RepairTask;
      message.repairTasksId = repairTasksIds;
      if (message) {
        this.repairTaskService.sendRepairTaskEmail(contractor.id, message).subscribe(() => {
          this.nbToastrService.success('E-Mail wurde erfolgreich versendet', 'E-Mail versendet');
        });
      }

    } catch (error) {
      console.error('Failed to generate email content:', error);
    }
  }

  private async updateStatusForAll(status: RepairTaskDetailDto.StatusEnum, items: RepairTaskDetailDto[]) {
    const ids = items.map(item => item.id);
    await tryWithLoading(this.loading$, async () => {
      await firstValueFrom(this.repairTaskService.updateStatusForAll(status, ids));
      this.nbToastrService.success('Status wurde erfolgreich angewendet', 'Aktualisiert');
    });
    this.reload();
  }
}
