import { CheckpointFilterDto } from '@artemis-software/wr-api';

export class LoadAllCheckpoints {
  static readonly type = '[Checkpoint] Load all checkpoints';
}

export class FilterCheckpoints {
  static readonly type = '[Checkpoint] Filter checkpoints';
}

export class CountCheckpoints {
  static readonly type = '[Checkpoint] Count checkpoints';
}

export class ChangeCheckpointFilter {
  static readonly type = '[Checkpoint] Change filter';

  constructor(public filter: CheckpointFilterDto) {
  }
}
