import { EnumTranslationPipe } from './enum-translation.pipe';
import { CustomFieldItemDto } from '@artemis-software/wr-api';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'elementTypeTranslation',
})
export class ElementTypeTranslationPipe implements EnumTranslationPipe<CustomFieldItemDto.ElementTypeEnum> {
  transform(value: CustomFieldItemDto.ElementTypeEnum): string {
    switch (value) {
    case CustomFieldItemDto.ElementTypeEnum.Single:
      return 'Einfach';
    case CustomFieldItemDto.ElementTypeEnum.Multiple:
      return 'Mehrfach';
    default:
      throw new Error(`Unknown element type: ${value}`);
    }
  }

}
