import { Component, Input, OnInit } from '@angular/core';
import { InspectionDetailDto, InspectionPdfItemDto } from '@artemis-software/wr-api';
import { Select, Store } from '@ngxs/store';
import { InspectionPdfState } from '@/store/inspectionPdf/inspectionPdf.state';
import { map, Observable } from 'rxjs';
import { FilterInspectionPdfs } from '@/store/inspectionPdf/inspectionPdf.action';
import InspectionPdfTypeEnum = InspectionPdfItemDto.InspectionPdfTypeEnum;

type PdfOption = {
  type: InspectionPdfTypeEnum;
  label: string;
}

@Component({
  selector: 'wr-inspection-pdfs',
  templateUrl: './inspection-pdfs.component.html',
  styleUrls: ['./inspection-pdfs.component.scss'],
})
export class InspectionPdfsComponent implements OnInit {

  @Input()
  inspection!: InspectionDetailDto;

  @Select(InspectionPdfState.filteredInspectionPdfs)
  inspectionPdfs$!: Observable<InspectionPdfItemDto[]>;

  readonly pdfOptions: PdfOption[] = [
    { type: InspectionPdfTypeEnum.Shortlist, label: 'Shortlist' },
    { type: InspectionPdfTypeEnum.Longlist, label: 'Longlist' },
    { type: InspectionPdfTypeEnum.Coversheet, label: 'Deckblatt' },
    { type: InspectionPdfTypeEnum.BuildingEquipments, label: 'TGAs' },
    { type: InspectionPdfTypeEnum.CertificateSheet, label: 'Zertifikat' },
  ];

  constructor(private readonly store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new FilterInspectionPdfs({ inspectionId: this.inspection.id }));
  }

  protected readonly InspectionPdfTypeEnum = InspectionPdfTypeEnum;

  getInspectionPdf(inspectionPdfType: InspectionPdfItemDto.InspectionPdfTypeEnum): Observable<InspectionPdfItemDto | undefined> {
    return this.inspectionPdfs$.pipe(map(inspectionPdfs =>
      inspectionPdfs.find(element => element.inspectionPdfType === inspectionPdfType
      )));
  }
}
