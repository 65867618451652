import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationDto } from '@artemis-software/wr-api';
import { NbComponentStatus } from '@nebular/theme';
import { dateToTimeAgoString } from '@/utils/date-utils';

@Component({
  selector: 'wr-notification-item-compact',
  templateUrl: './notification-item-compact.component.html',
  styleUrls: ['./notification-item-compact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemCompactComponent {
  @Input()
  notification!: NotificationDto;

  @Output()
  markAsRead = new EventEmitter<NotificationDto>();

  statusMap: Record<NotificationDto.VariantEnum, NbComponentStatus> = {
    Error: 'danger',
    Normal: 'info',
    Success: 'success',
    Warning: 'warning',
  };

  get icon(): string {
    switch (this.notification.variant) {
    case NotificationDto.VariantEnum.Error:
      return 'close-circle';
    case NotificationDto.VariantEnum.Normal:
      return 'info';
    case NotificationDto.VariantEnum.Success:
      return 'checkmark-circle-2';
    case NotificationDto.VariantEnum.Warning:
      return 'alert-circle';
    }
    return '';
  }

  get timePassed(): string {
    return dateToTimeAgoString(this.notification.date);
  }

  getUrl(): { href: string; fragment?: string } | undefined {
    const url = this.notification.url;
    if (!url) return undefined;

    const [href, fragment] = url.split('#');
    return {
      href,
      fragment,
    };
  }

  get notificationTypeText(): string {
    switch (this.notification.type) {
    case 'InspectionComment':
      return 'Kommentar';
    case 'Maintenance':
      return 'Wartung';
    }
    return '';
  }
}
