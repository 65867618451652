import { Component, Input } from '@angular/core';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { InspectionFormWrapper } from '@pages/inspection-detail-page-v2/inspection-form';
import { InspectionFormType } from '@pages/inspection-detail-page-v2/inspection-util';

@Component({
  selector: 'wr-cover-page-customization',
  templateUrl: './cover-page-customization.component.html',
})
export class CoverPageCustomizationComponent {
    @Input()
    selectedSection!: BuildingSectionDetailDto;

    @Input()
    formWrapper!: InspectionFormWrapper;

    getBuildingSectionFormGroup(form: InspectionFormType, buildingSectionId: string) {
      const buildingSectionImagesArray = form.controls.buildingSectionImages;
      if (!buildingSectionImagesArray) {
        throw new Error('BuildingSectionImages FormArray not found in the form.');
      }

      const index = buildingSectionImagesArray.controls.findIndex(control => {
        return control.controls.buildingSectionId.value === buildingSectionId;
      });

      if (index === -1) {
        throw new Error(`No FormGroup found for buildingSectionId: ${buildingSectionId}`);
      }

      return buildingSectionImagesArray.at(index);
    }
}
