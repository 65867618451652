import { Component, computed, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { MaintenanceTimerFilterDto, MaintenanceTimerItemDto, MaintenanceTimerService } from '@artemis-software/wr-api';
import { Tracked } from '@/utils/signals/tracked';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { BuildingResolverService } from '@services/building-resolver.service';
import { firstValueFrom, forkJoin } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import {
  MaintenanceTimerEditDialogComponent
} from '@pages/maintenance-timer-dashboard-page/maintenance-timer-edit-dialog/maintenance-timer-edit-dialog.component';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';

@Component({
  selector: 'wr-maintenance-timer-dashboard-list',
  templateUrl: './maintenance-timer-dashboard-list.component.html',
  styleUrls: ['./maintenance-timer-dashboard-list.component.scss'],
})
export class MaintenanceTimerDashboardListComponent {
  readonly maintenanceTimerService = inject(MaintenanceTimerService);
  readonly buildingResolverService = inject(BuildingResolverService);
  readonly dialogService = inject(NbDialogService);

  @Input()
  @Tracked()
  filter?: MaintenanceTimerFilterDto;

  @Output()
  addMaintenanceTimer = new EventEmitter<{ buildingId: string }>();

  readonly refreshSignal = signal(0);

  readonly maintenanceTimers = asyncComputed(() => {
    this.refreshSignal();
    if (!this.filter) return [];
    return this.maintenanceTimerService.findAll({
      ...this.filter,
      sort: {
        sort: 'nextDate',
        order: 'ASC',
      },
    });
  });

  readonly maintenanceTimersGrouped = computed(() => {
    const maintenanceTimers = this.maintenanceTimers();
    if (!maintenanceTimers) return {} as Record<string, MaintenanceTimerItemDto[]>;
    return maintenanceTimers.reduce((acc, maintenanceTimer) => {
      const key = maintenanceTimer.buildingId;
      if (!acc[key]) {
        acc[key] = [] as MaintenanceTimerItemDto[];
      }
      acc[key].push(maintenanceTimer);
      return acc;
    }, {} as Record<string, MaintenanceTimerItemDto[]>);
  });

  readonly buildingIds = computed(() => {
    return Object.keys(this.maintenanceTimersGrouped());
  });

  readonly buildings = asyncComputed(() => {
    const ids = this.buildingIds();
    if (!ids.length) return [];
    return forkJoin(ids.map((buildingId) => this.buildingResolverService.getBuilding(buildingId)));
  });

  refresh() {
    this.refreshSignal.set(this.refreshSignal() + 1);
  }

  editMaintenanceTimer(maintenanceTimer: MaintenanceTimerItemDto) {
    const ref = this.dialogService.open(MaintenanceTimerEditDialogComponent, {
      context: {
        maintenanceTimer: maintenanceTimer
      }
    });
    ref.componentRef.instance.saveMaintenanceTimer.subscribe((maintenanceTimer: MaintenanceTimerItemDto) => {
      if(maintenanceTimer) {
        this.maintenanceTimerService.merge(maintenanceTimer).subscribe(() => {
          this.refresh();
        });
      }
    });
  }

  async deleteMaintenanceTimer(maintenanceTimer: MaintenanceTimerItemDto) {
    const result = await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Wartungstimer löschen',
        message: 'Bist du sicher, dass du diesen Wartungstimer löschen willst?',
      },
    }).onClose);
    if(result) {
      this.maintenanceTimerService.deleteById(maintenanceTimer.id!).subscribe(() => {
        this.refresh();
      });
    }
  }
}
