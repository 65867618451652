<div class="maintenance-timer-calendar-cell" *ngIf="day as day">
  <div class="day">{{ day.date | date:'dd' }}</div>
  <div class="count" *ngIf="count()">{{ count() }}</div>
  <div class="events">
    <div class="event" *ngFor="let timer of timers()" (click)="goToBuildingEquipment(timer.meta!.buildingEquipmentId)">
      <span class="timer-character"
            [nbTooltip]="timer.meta!.type | maintenanceTimerType"
            [nbTooltipStatus]="'primary'">
        {{ typeCharacter(timer.meta!.type) }}
      </span>
      <span class="timer-title">{{ timer.title }}</span>
      <div class="flex-grow"></div>
      <nb-icon class="external-link-icon" icon="external-link-outline" pack="eva"></nb-icon>
    </div>
  </div>
</div>
