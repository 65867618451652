import { Pipe, PipeTransform } from '@angular/core';
import { OrganisationDetailDto } from '@artemis-software/wr-api';
import OrganisationTypeEnum = OrganisationDetailDto.OrganisationTypeEnum;

@Pipe({
  name: 'organisationTypeTranslation',
})
export class OrganisationTypeTranslationPipe implements PipeTransform {

  transform(value: OrganisationTypeEnum | null | undefined): string | undefined {
    switch (value) {
    case OrganisationTypeEnum.Admin:
      return 'Admin';
    case OrganisationTypeEnum.Cooperative:
      return 'Genossenschaft';
    case OrganisationTypeEnum.PropertyManager:
      return 'Hausverwalter';
    }
    return 'Unbekannt';
  }
}
