/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TagItemDto } from './tagItemDto';


export interface AttachmentDetailDto { 
    type: AttachmentDetailDto.TypeEnum;
    s3key: string;
    presignedUrl: string;
    fileName?: string;
    fileEnding?: string;
    comment: string;
    rotationDegrees: number;
    created: string;
    tags: Array<TagItemDto>;
}
export namespace AttachmentDetailDto {
    export type TypeEnum = 'IMAGE' | 'VIDEO' | 'AUDIO' | 'DOCUMENT' | 'FILE';
    export const TypeEnum = {
        Image: 'IMAGE' as TypeEnum,
        Video: 'VIDEO' as TypeEnum,
        Audio: 'AUDIO' as TypeEnum,
        Document: 'DOCUMENT' as TypeEnum,
        File: 'FILE' as TypeEnum
    };
}


