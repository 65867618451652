import { Component, Input } from '@angular/core';

type Defect = 'none' | 'minor' | 'major';

@Component({
  selector: 'wr-checkpoint-weight',
  templateUrl: './checkpoint-weight.component.html',
  styleUrls: ['./checkpoint-weight.component.scss']
})
export class CheckpointWeightComponent {
  @Input()
  weight?: number;

  getWeight(): Defect {
    if (this.weight === undefined) {
      return 'none';
    }
    if (this.weight >= 0 && this.weight <= 3) {
      return 'none';
    }
    if (this.weight >= 4 && this.weight <= 7) {
      return 'minor';
    }
    if (this.weight >= 8 && this.weight <= 10) {
      return 'major';
    }
    return 'none';
  }

}
