<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="file-alt"></nb-icon>
          Templates
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
              <button nbButton ghost status="primary" (click)="add()">
                <nb-icon pack="eva" icon="plus-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
    [data$]="templates$"
    (lineClick)="navigateToTemplate($event)"
    [sortKey]="'templateSort'"
    [storeUsage]="templateStoreUsage"
    [defaultSort]="'name'">
    <ng-template
      dataColumn
      let-template
      key="name"
      display="Name">
      <div *ngIf="template.name">
        {{template.name}}
      </div>
    </ng-template>
  </wr-data-table>
</div>
