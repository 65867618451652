import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentDetailDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-tag-filter-list',
  templateUrl: './tag-filter-list.component.html',
  styleUrls: ['./tag-filter-list.component.scss']
})
export class TagFilterListComponent {

  @Input() set attachments(value: AttachmentDetailDto[]) {
    this._attachments = value;
    this.tagAttachmentsMap = this.extractTagsAndAttachments();
  }

  showSelect = true;

  _attachments: AttachmentDetailDto[] = [];
  tagAttachmentsMap: { [name: string]: AttachmentDetailDto[] } = {};

  @Output()
  hoveredTagChange = new EventEmitter<string>();
  hoveredTag = '';

  @Output()
  selectedTagsChange = new EventEmitter<string[]>();
  selectedTags: string[] = [];

  constructor() { }

  onMouseEnter(key: string) {
    if(this.hoveredTag === '')
      this.hoveredTagChange.emit(key);
  }

  onMouseLeave() {
    if(this.hoveredTag === '')
      this.hoveredTagChange.emit('');
  }

  extractTagsAndAttachments() {
    const tagAttachmentsMap: { [name: string]: AttachmentDetailDto[] } = {};
    this._attachments.forEach(dto => {
      dto.tags.forEach(tag => {
        if (!tagAttachmentsMap[tag.name]) {
          tagAttachmentsMap[tag.name] = [];
        }
        tagAttachmentsMap[tag.name].push(dto);
      });
    });
    if(this.selectedTags && this.selectedTags.length) {
      this.selectedTags.forEach((value) => {
        if(!(value in tagAttachmentsMap)) {
          this.selectedTags!.splice(this.selectedTags!.indexOf(value, 0), 1);
        }
      });
    }
    this.showSelect = false;
    setTimeout(() => {
      this.showSelect = true;
    });
    return tagAttachmentsMap;
  }
}
