import { Subject } from 'rxjs/internal/Subject';

export const tryWithLoading = async <T>(loading$: Subject<boolean>, load: () => Promise<T>): Promise<T> => {
  try {
    loading$.next(true);
    return await load();
  } finally {
    loading$.next(false);
  }
};

export const waitForCondition = async (conditionFn: () => boolean, checkInterval = 100) => {
  while (!conditionFn()) {
    await new Promise((resolve) => setTimeout(resolve, checkInterval));
  }
};
