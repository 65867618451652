import { Injectable } from '@angular/core';
import { filter, firstValueFrom, Observable, Subject } from 'rxjs';
import { waitForCondition } from '@/utils/async-utils';
import { MercureService } from "@artemis-software/wr-api";
import { AuthService } from "@services/auth.service";

export interface BaseEvent<TType> {
  type: TType;
  date: string;
}

export type MercureEvent = NotificationCreatedEvent;

export type MercureEventType = MercureEvent['type'];

export type UnwrapEventType<T> = T extends MercureEventType
  ? Extract<MercureEvent, { type: T }>
  : never;

export interface NotificationCreatedEvent extends BaseEvent<'NotificationCreated'> {
  notificationId: string;
}

@Injectable()
export class ServerSentEventsService {
  eventSource?: EventSource;

  constructor(
    private readonly mercureService: MercureService,
    private readonly authService: AuthService,
  ) {
    this.initEventSource();
  }

  private readonly events$ = new Subject<MercureEvent>();

  async initEventSource(): Promise<void> {
    await waitForCondition(() => this.authService.authCredentials$ !== undefined);
    const { url } = await firstValueFrom(this.mercureService.getMercureUrl());

    this.eventSource = new EventSource(url);
    this.eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        this.events$.next(data);
      } catch (e) {
        console.error(e);
        return;
      }
    };
  }

  get all(): Observable<MercureEvent> {
    return this.events$.asObservable();
  }

  event<TType extends MercureEventType>(type: TType): Observable<UnwrapEventType<TType>> {
    return this.events$.pipe(filter((e) => e.type === type)) as Observable<UnwrapEventType<TType>>;
  }

  events<TType extends MercureEventType>(...types: TType[]): Observable<UnwrapEventType<TType>> {
    return this.events$.pipe(
      filter((e) => types.includes(e.type as unknown as TType)),
    ) as Observable<UnwrapEventType<TType>>;
  }
}
