<hau-form-field *ngIf="formElement"
                [label]="formElement.field.name"
                [validation]="formElement.formControl.errors"
                [required]="formElement.required">

  <ng-container hauPrefix *ngIf="isMultiControl">
    <nb-icon icon="copy-outline" pack="eva" status="basic" nbTooltip="Mehrfacheingabe"
             nbTooltipStatus="basic"></nb-icon>
  </ng-container>

  <ng-container *ngIf="formElement.field.elementType === 'Single'; then single; else multi"></ng-container>

  <ng-template #single>
    <ng-container [ngTemplateOutlet]="controlTemplate"
                  [ngTemplateOutletContext]="{control: formElement.formControl, valueType: formElement.field.valueType}"></ng-container>
  </ng-template>

  <ng-template #multi>
    <wr-generic-multi-control *ngIf="formArray as formArray"
                              [formArray]="formArray">
      <ng-template wrControlTemplate let-control="control">
        <ng-container [ngTemplateOutlet]="controlTemplate"
                      [ngTemplateOutletContext]="{control: control, valueType: formElement.field.valueType}"></ng-container>
      </ng-template>
    </wr-generic-multi-control>
  </ng-template>

  <ng-template #controlTemplate let-control="control" let-valueType="valueType">
    <ng-container [ngSwitch]="valueType">

      <ng-container *ngSwitchCase="ValueTypeEnum.String">
        <input nbInput fullWidth type="text" [formControl]="control" placeholder="Kurzer Text" />
      </ng-container>

      <ng-container *ngSwitchCase="ValueTypeEnum.Integer">
        <input nbInput fullWidth type="number" step="1" [formControl]="control" placeholder="Ganze Zahl" />
      </ng-container>

      <ng-container *ngSwitchCase="ValueTypeEnum.Double">
        <input nbInput fullWidth type="number" step="0.01" [formControl]="control" placeholder="Dezimalzahl" />
      </ng-container>

      <ng-container *ngSwitchCase="ValueTypeEnum.Boolean">
        <nb-toggle [formControl]="control"></nb-toggle>
      </ng-container>

      <ng-container *ngSwitchCase="ValueTypeEnum.Longtext">
        <textarea nbInput fullWidth [formControl]="control" placeholder="Langer Text"></textarea>
      </ng-container>

    </ng-container>
  </ng-template>
</hau-form-field>
