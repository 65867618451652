<div class="base-dialog" [ngClass]="dialogWidth">
  <nb-card [accent]="status" [size]="size">
    <nb-card-header>{{header}}</nb-card-header>
    <nb-card-body>
      <ng-content></ng-content>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton
              *ngIf="showCancelButton"
              status="basic"
              (click)="cancel()">
        {{cancelText}}
      </button>
      <button nbButton
              *ngIf="showSubmitButton"
              [disabled]="disableSubmit"
              [status]="status"
              (click)="submit()">
        {{submitText}}
      </button>
    </nb-card-footer>
  </nb-card>

</div>
