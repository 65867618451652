import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { NotificationDto } from "@artemis-software/wr-api";
import { AbstractFormControl } from "@shared/form-controls/abstract-form-control";

@Component({
  selector: 'wr-notification-type-select',
  templateUrl: './notification-type-select.component.html',
  styleUrls: ['./notification-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NotificationTypeSelectComponent),
      multi: true,
    },
  ]
})
export class NotificationTypeSelectComponent extends AbstractFormControl<NotificationDto.TypeEnum>{
  readonly NotificationType = NotificationDto.TypeEnum;

  @Input()
  showEmptyOption = false;
}
