import { Component, signal, WritableSignal } from '@angular/core';
import { IdReferenceDto, UserStatisticsFilterDto } from '@artemis-software/wr-api';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { StatisticsService } from '@services/statistics.service';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NbDateService } from "@nebular/theme";
import { eachWeekOfInterval, format } from 'date-fns';
import { loader } from "@/utils/signals/loader";

interface UserStatistics {
  squareMetersDataset: number[];
  inspectionsDataset: number[];
  entriesDataset: number[];
}

@Component({
  selector: 'wr-employee-statistics-page',
  templateUrl: './employee-statistics-page.component.html',
  styleUrls: ['./employee-statistics-page.component.scss']
})
export class EmployeeStatisticsPageComponent {

  isLoading = loader();

  readonly filter: WritableSignal<UserStatisticsFilterDto> = signal({
    userIds: [],
  });

  readonly statistics = asyncComputed(
    () => {
      const filter = this.filter();
      return this.statisticsService.getUserStatistics({
        ...filter,
        userIds: filter.userIds ?? [],
      });
    },
    undefined,
    this.isLoading
  );

  readonly datasets: WritableSignal<UserStatistics> = asyncComputed(
    () => {
      const statistics = this.statistics();
      const labels = this.labels();
      if (!statistics || !labels) {
        return {
          squareMetersDataset: [],
          inspectionsDataset: [],
          entriesDataset: [],
        };
      }

      const squareMetersDataset: number[] = new Array(labels.length).fill(0);
      const inspectionsDataset: number[] = new Array(labels.length).fill(0);
      const entriesDataset: number[] = new Array(labels.length).fill(0);

      statistics.squareMetersPerWeek.forEach(stat => {
        const weekIndex = labels.findIndex(label => {
          return label === `${stat.week.week}/${stat.week.year}`;
        });

        if (weekIndex !== -1) {
          squareMetersDataset[weekIndex] = stat.info;
        }
      });

      statistics.inspectionsPerWeek.forEach(stat => {
        const weekIndex = labels.findIndex(label => {
          return label === `${stat.week.week}/${stat.week.year}`;
        });

        if (weekIndex !== -1) {
          inspectionsDataset[weekIndex] = stat.info;
        }
      });

      statistics.entriesPerWeek.forEach(stat => {
        const weekIndex = labels.findIndex(label => {
          return label === `${stat.week.week}/${stat.week.year}`;
        });

        if (weekIndex !== -1) {
          entriesDataset[weekIndex] = stat.info;
        }
      });

      return {
        squareMetersDataset,
        inspectionsDataset,
        entriesDataset,
      };
    },
    {
      squareMetersDataset: [],
      inspectionsDataset: [],
      entriesDataset: [],
    }
  );

  readonly labels = asyncComputed(
    () => {
      const filter = this.filter();
      if (!filter.from || !filter.to) {
        return [];
      }

      const startDate = filter.from;
      const endDate = filter.to;

      const weeks = eachWeekOfInterval({ start: startDate, end: endDate });
      return weeks.map(week => `${format(week, 'w')}/${format(week, 'yyyy')}`);
    },
    []
  );

  form!: FormGroup;

  readonly FORMAT_STRING = 'yyyy-MM-ddTHH:mm:ss.SSSZ';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dateService: NbDateService<Date>,
    private readonly statisticsService: StatisticsService
  ) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('userStatisticsFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm();

      let selectedRange = undefined;

      if (filterValue.selectedRange?.start) {
        let startDateTime = this.dateService.parse(filterValue.selectedRange.start, this.FORMAT_STRING);
        let endDateTime = this.dateService.parse(filterValue.selectedRange.end ?? filterValue.selectedRange.start, this.FORMAT_STRING);

        startDateTime = this.dateService.addHours(startDateTime, 1);
        endDateTime = this.dateService.addHours(endDateTime, 1);

        selectedRange = {
          start: startDateTime,
          end: endDateTime,
        };
      }

      this.filter.set({
        ...this.filter(),
        from: selectedRange?.start.toISOString(),
        to: selectedRange?.end.toISOString(),
      });
      this.addSpecialFields(selectedRange, filterValue.technicianId);
    } else {
      this.initFilterForm();
    }
  }

  initFilterForm(): void {
    const startOfMonth = this.dateService.getMonthStart(this.dateService.today());
    const endOfMonth = this.dateService.addMonth(startOfMonth, 2);

    this.form = this.formBuilder.group({
      technicianReferences: this.formBuilder.control(null),
      selectedRange: new FormControl({
        start: startOfMonth,
        end: endOfMonth,
      }),
    });
  }

  addSpecialFields(selectedRange: {
    start: Date;
    end: Date
  } | undefined, technicianIds: string[]): void {
    this.form.patchValue({
      'selectedRange': selectedRange,
      'technicianReferences': technicianIds,
    });
  }

  triggerFilter(): void {
    const formValue = this.form.value;
    if (formValue.selectedRange && !formValue.selectedRange?.end) {
      formValue.selectedRange.end = formValue.selectedRange.start;
    }

    this.filter.set({
      ...this.filter(),
      userIds: formValue.technicianReferences ? [...formValue.technicianReferences.map(
        (technicianReference: IdReferenceDto) => technicianReference.value
      )] : [],
      from: formValue.selectedRange?.start,
      to: formValue.selectedRange?.end,
    });

    localStorage.setItem('userStatisticsFilter', JSON.stringify(this.form.value));
  }
}
