<wr-base-dialog status="primary" header="Bild bearbeiten" [submitResponse]="onSubmit">
  <div class="flex-container">
    <div class="file-image">
      <div class="image"
           [style.transform]="getTransform()"
           [style.background-image]="getImage(attachment)"></div>
    </div>

    <hau-form [formGroup]="form">
      <hau-form-field label="Kommentar">
        <textarea nbInput fullWidth formControlName="comment"></textarea>
      </hau-form-field>
      <hau-form-field label="Tags">
        <wr-multi-tag-select formControlName="tags"></wr-multi-tag-select>
      </hau-form-field>
    </hau-form>
  </div>
</wr-base-dialog>
