import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { ListItemDirective } from './list-item.directive';

@Component({
  selector: 'wr-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent<Item> {

  @Input()
  items: Item[] | null | undefined;

  @ContentChild(ListItemDirective)
  listItemTemplate?: ListItemDirective;

  @Input()
  selected: Item | null | undefined;
  @Input()
  searchText?: string;

  @Output()
  selectedChange = new EventEmitter<Item>();
  @Output()
  searchTextChange = new EventEmitter<string>();
  @Output()
  addItemClicked = new EventEmitter<void>();
}
