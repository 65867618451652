<div class="upload-container" *ngIf="formControlContext?.pendingJobs$?.value as pendingJobs">
  <div class="upload-list" *ngIf="pendingJobs.length > 0">
    <wr-attachment-upload-entry *ngFor="let job of pendingJobs"
                                [attachment]="job.attachment"
                                (delete)="cancelJob(job)">
    </wr-attachment-upload-entry>
  </div>
  <wr-attachment-drop-zone [type]="type"
                           (fileSelected)="upload($event)">
  </wr-attachment-drop-zone>
</div>
