import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, firstValueFrom, from, lastValueFrom, Observable, throwError } from 'rxjs';
import { AuthService } from '@services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly auth: AuthService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handleAuth(request, next));
  }

  async handleAuth(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const authCredentials = await firstValueFrom(this.auth.authCredentials$);
    let authReq = request;
    if (authCredentials != null) {
      authReq = request.clone({
        headers: new HttpHeaders({
          Authorization: `Bearer ${authCredentials.token}`,
          'is-dashboard': 'true',
        }),
      });
    }

    return await lastValueFrom(next.handle(authReq).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401 || error.status === 403) {
          this.auth.logout();
        }
      }
      return throwError(() => error);
    })));
  }
}
