import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wr-edit-delete-button-group',
  templateUrl: './edit-delete-button-group.component.html',
  styleUrls: ['./edit-delete-button-group.component.scss'],
})
export class EditDeleteButtonGroupComponent {
  @Output()
  edit = new EventEmitter<void>();
  @Output()
  delete = new EventEmitter<void>();
  @Input() @Output()
  close = new EventEmitter<void>();
}
