import { Component, computed, inject, Input } from '@angular/core';
import { Tracked } from '@/utils/signals/tracked';
import { MonthViewDay } from 'calendar-utils';
import { CalendarEvent } from 'angular-calendar';
import { MaintenanceTimerItemDto } from '@artemis-software/wr-api';
import { Router } from '@angular/router';

@Component({
  selector: 'wr-maintenance-timer-calendar-cell',
  templateUrl: './maintenance-timer-calendar-cell.component.html',
  styleUrls: ['./maintenance-timer-calendar-cell.component.scss'],
})
export class MaintenanceTimerCalendarCellComponent {

  readonly router = inject(Router);

  @Input()
  @Tracked()
  day?: MonthViewDay;

  readonly timers = computed(() => {
    return (this.day?.events ?? []) as CalendarEvent<MaintenanceTimerItemDto>[];
  });

  readonly count = computed(() => {
    return this.timers().length;
  });

  readonly typeCharacter = (type: MaintenanceTimerItemDto.TypeEnum) => {
    switch (type) {
    case MaintenanceTimerItemDto.TypeEnum.Inspection:
      return 'I';
    case MaintenanceTimerItemDto.TypeEnum.Maintenance:
      return 'W';
    }
    return undefined;
  };

  goToBuildingEquipment(id: string) {
    this.router.navigate(['buildingEquipment', id]);
  }
}
