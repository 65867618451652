import { Component, computed, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { MaintenanceTimerFilterDto, OrganisationDetailDto } from '@artemis-software/wr-api';
import { currentUser, isAdmin } from '@/utils/admin-utils';
import OrganisationTypeEnum = OrganisationDetailDto.OrganisationTypeEnum;

@Component({
  selector: 'wr-maintenance-timer-dashboard-filter',
  templateUrl: './maintenance-timer-dashboard-filter.component.html',
  styleUrls: ['./maintenance-timer-dashboard-filter.component.scss'],
})
export class MaintenanceTimerDashboardFilterComponent {

  currentUser = currentUser();
  isAdmin = isAdmin();
  isPropertyManager = computed(() => this.currentUser()?.organisationType == OrganisationTypeEnum.PropertyManager);
  isOrganisationUser = computed(() => {
    if (this.currentUser()?.organisationType == OrganisationTypeEnum.Cooperative) {
      return this.currentUser()?.organisation.id;
    }
    return undefined;
  });

  @Output()
  filterChange = new EventEmitter<MaintenanceTimerFilterDto>();

  @Output()
  addMaintenanceTimer = new EventEmitter<void>();

  readonly form = new FormGroup({
    organisationId: new FormControl(),
    building: new FormControl(),
    equipmentType: new FormControl(),
    dateRange: new FormControl(),
    expired: new FormControl(true),
    notExpired: new FormControl(true),
    maxDueDays: new FormControl(),
  });

  constructor() {
    this.form.valueChanges.pipe(debounceTime(100)).subscribe((value) => {
      const expired = !!value.expired;
      const notExpired = !!value.notExpired;

      let expiredFlag = undefined;

      if (!expired && notExpired) {
        expiredFlag = false;
      } else if (expired && !notExpired) {
        expiredFlag = true;
      }

      this.filterChange.next({
        organisationId: value.organisationId,
        buildingId: value.building?.id,
        equipmentType: value.equipmentType,
        fromDate: value.dateRange?.start,
        toDate: value.dateRange?.end,
        maxDueDays: value.maxDueDays,
        expired: expiredFlag,
      });
    });
  }
}
