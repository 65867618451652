<nb-select placeholder="Techniker auswählen"
           multiple
           [selected]="value"
           [disabled]="isDisabled"
           [compareWith]="equalFn"
           (selectedChange)="onSelectionChange($event)">
  <nb-option *ngFor="let user of users | async"
             [value]="{value: user.id}">
    {{ user.firstName }} {{ user.lastName }}
  </nb-option>
<nb-option *ngIf="showNoneOption" [value]="{value: 'all'}">Alle</nb-option>
</nb-select>
