<wr-base-dialog header="Prüfpunktzeilen duplizieren?"
                size="large"
                status="primary"
                [submitResponse]="onSubmit"
                [disableSubmit]="!selectedSection">
  <label class="label">Ziel-Gebäudeteil auswählen</label>
  <wr-building-section-select [building]="building"
                              [(selectedSection)]="selectedSection">
  </wr-building-section-select>
  <ng-container *ngIf="selectedSection">
    <label class="label">Prüfpunkte auswählen</label>
    <wr-grouped-checkbox-list [(groups)]="groups"></wr-grouped-checkbox-list>
  </ng-container>
</wr-base-dialog>
