import { signal } from '@angular/core';

/**
 * Decorator that makes signals react to changes in the property.
 * Will store the value in a signal under the hood.
 * Useful for using e.g. @Input() with signals.
 *
 * @example
 * ```ts
 * class MyComponent {
 *   @Input()
 *   @Tracked()
 *   myProperty!: string;
 *
 *   constructor() {
 *     watch(() => this.myProperty, (value) => {
 *       console.log('myProperty changed to', value)
 *     })
 *   }
 * }
 * ```
 */
export function Tracked(): PropertyDecorator {
  return function(target: unknown, propertyKey: string | symbol): void {
    const key = Symbol(propertyKey.toString());

    Object.defineProperty(target, propertyKey, {
      get(): unknown {
        if (!this[key]) {
          this[key] = signal<unknown>(undefined);
        }

        return this[key]();
      },
      set(value: unknown): void {

        if (!this[key]) {
          this[key] = signal<unknown>(value);
        } else {
          this[key].set(value);
        }
      },
      enumerable: true,
      configurable: true,
    });
  };
}
