import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationDto, NotificationFilterDto, NotificationService } from '@artemis-software/wr-api';
import { signal, effect } from '@angular/core';
import { firstValueFrom } from "rxjs";
import { createForm } from "@sonofabit/ng-core";
import VariantEnum = NotificationFilterDto.VariantEnum;

@Component({
  selector: 'wr-notification-overview-page',
  templateUrl: './notification-overview-page.component.html',
  styleUrls: ['./notification-overview-page.component.scss'],
})
export class NotificationOverviewPageComponent implements OnInit {
  private service = inject(NotificationService);

  notifications = signal<any[]>([]);
  searchText = signal<string>('');

  filterForm = createForm(({ field }) => {
    return {
      read: field({
        value: false,
      }),
      variant: field({
        value: null,
      }),
      before: field({
        value: null,
        toDto: (value: Date | null) => value?.toISOString(),
      }),
      type: field({
        value: null,
      }),
    };
  });

  async ngOnInit(): Promise<void> {
    await this.loadNotifications();
  }

  async loadNotifications(): Promise<void> {
    const notificationFilter = await this.filterForm.getMappedValue();
    const response = await firstValueFrom(this.service.findAllPaginated({
      text: this.searchText(),
      read: notificationFilter.read,
      variant: notificationFilter.variant ?? undefined,
      before: notificationFilter.before,
      type: notificationFilter.type ?? undefined,
    }));
    this.notifications.set(response.items);
  }

  onSearchTextChange(newText: string): void {
    this.searchText.set(newText);
  }

  constructor() {
    effect(() => {
      const filterValues = this.filterForm.getMappedValue();
      const currentSearchText = this.searchText();
      this.loadNotifications();
    });
  }

  async markAsRead(notification: NotificationDto): Promise<void> {
    if (notification.readAt) return;

    await firstValueFrom(this.service.markAsRead(notification.id));
    await this.loadNotifications();
  }

}
