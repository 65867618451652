import { Pipe } from '@angular/core';
import { InspectionEntryDetailDto } from '@artemis-software/wr-api';
import { EnumTranslationPipe } from './enum-translation.pipe';

@Pipe({
  name: 'deadlineTranslation',
})
export class DeadlineTranslationPipe implements EnumTranslationPipe<InspectionEntryDetailDto.DeadlineEnum> {

  transform(value: InspectionEntryDetailDto.DeadlineEnum): string {
    switch (value) {
    case InspectionEntryDetailDto.DeadlineEnum.None:
      return 'Keine Frist';
    case InspectionEntryDetailDto.DeadlineEnum.OneDay:
      return '1 Tag';
    case InspectionEntryDetailDto.DeadlineEnum.OneMonth:
      return '1 Monat';
    case InspectionEntryDetailDto.DeadlineEnum.HalfYear:
      return '6 Monate';
    case InspectionEntryDetailDto.DeadlineEnum.Year:
      return '1 Jahr';
    default:
      throw new Error(`Unknown deadline: ${value}`);
    }
  }
}
