<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="envelope"></nb-icon>
          Emails
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <div class="filter-control-group">
                <label class="label" for="datePicker">Zeitraum</label>
                <wr-date-range-picker id="datePicker" formControlName="selectedRange"
                                      [showTodayButton]="true"></wr-date-range-picker>
              </div>
              <div class="filter-control-group">
                <label class="label" for="emailType">Typ</label>
                <wr-email-type-select id="emailType" formControlName="emailType">
                </wr-email-type-select>
              </div>
              <div class="filter-control-group">
                <label class="label" for="emailFilter">Empfänger</label>
                <input type="text" nbInput id="emailFilter" formControlName="emailFilter" placeholder="Empfänger...">
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
    [data$]="emails$"
    (lineClick)="navigateToEmail($event)"
    [sortKey]="'emailSort'"
    [storeUsage]="emailStoreUsage"
    [defaultSort]="'subject'"
  >
    <ng-template
      dataColumn
      let-email
      key="subject"
      display="Betreff">
      <div *ngIf="email.subject">
        {{ email.subject }}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-email
      key="emailType"
      display="Email Typ">
      <div *ngIf="email.emailType" [ngSwitch]="email.emailType">
        <ng-container *ngSwitchCase="EmailTypeEnum.Inspection">
          Rundgang
        </ng-container>
        <ng-container *ngSwitchCase="EmailTypeEnum.RepairTask">
          Reparaturaufträge
        </ng-container>
      </div>
    </ng-template>
  </wr-data-table>
</div>
