import { Component } from '@angular/core';
import { combineLatest, debounceTime, Observable, startWith } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { OrganisationState } from '../../store/organisation/organisation.state';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import {
  ChangeOrganisationFilter,
  CountOrganisations,
  FilterOrganisations,
} from '../../store/organisation/organisation.action';
import {
  AddressService,
  OrganisationDetailDto,
  OrganisationItemDto,
  OrganisationService,
} from '@artemis-software/wr-api';
import { FormBuilder, FormGroup } from '@angular/forms';
import {defineStoreUsage} from "../../utils/storeUsage";

@Component({
  selector: 'wr-organisation-overview-page',
  templateUrl: './organisation-overview-page.component.html',
  styleUrls: ['./organisation-overview-page.component.scss'],
})
export class OrganisationOverviewPageComponent {

  organisationStoreUsage = defineStoreUsage({
    filterAction: ChangeOrganisationFilter,
    countSelector: OrganisationState.count,
    loadingSelector: OrganisationState.organisationsLoading
  });

  @Select(OrganisationState.filteredOrganisations)
  organisations$!: Observable<OrganisationDetailDto[]>;
  @Select(OrganisationState.count)
  count$!: Observable<number>;
  @Select(OrganisationState.organisationsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(private readonly store: Store,
    private readonly router: Router,
    private readonly dialogService: NbDialogService,
    private readonly organisationService: OrganisationService,
    private readonly addressService: AddressService,
    private readonly formBuilder: FormBuilder,
    private readonly nbToastrService: NbToastrService) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('organisationFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
    });
  }

  navigateToUser(organisation: OrganisationItemDto): void {
    this.router.navigate(['organisation', organisation.id]);
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeOrganisationFilter(this.form.value));
    localStorage.setItem('organisationFilter', JSON.stringify(this.form.value));
  }

  add(): void {
    this.router.navigate(['organisation', 'new']);
  }
}
