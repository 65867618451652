import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressMergeDto } from '@artemis-software/wr-api';
import { NbToastrService } from '@nebular/theme';

export type houseNumber = {
  number: string;
  sectionId: string;
  addressId: string;
}

type Address = {
  zipCode: string;
  city: string;
  province: string;
}

@Component({
  selector: 'wr-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialogComponent {

  constructor(
    private readonly toastrService: NbToastrService,
  ) {
  }

  @Input()
  title = '';
  @Input()
  street = '';
  @Input()
  address: Address = { zipCode: '', city: '', province: '' };
  @Input()
  houseNumbers: houseNumber[] = [];
  @Output()
  submit = new EventEmitter<AddressMergeDto>();

  firstSubmit = false;

  form?: ReturnType<typeof this.initForm>;

  ngOnInit() {
    this.form = this.initForm();
  }

  initForm() {
    const houseNumber = this.houseNumbers.reduce((acc, cur) => {
      if (!acc.min || parseInt(cur.number) < parseInt(acc.min)) {
        acc.min = cur.number;
      }
      if (!acc.max || parseInt(cur.number) > parseInt(acc.max)) {
        acc.max = cur.number;
      }
      return acc;
    }, { min: '', max: '' });

    const houseNumbers = new FormGroup({
      sectionId: new FormControl(this.houseNumbers[0].sectionId),
      addressId: new FormControl(this.houseNumbers[0].addressId),
      number: new FormControl(houseNumber.min + '-' + houseNumber.max, [Validators.required]),
    });
    return new FormGroup({
      street: new FormControl(this.street, Validators.required),
      houseNumbers: houseNumbers,
      automaticAddress: new FormGroup({
        zipCode: new FormControl(this.address?.zipCode, [Validators.required]),
        city: new FormControl(this.address?.city, [Validators.required]),
        province: new FormControl(this.address?.province, [Validators.required]),
      }),
    });
  }

  submitAddress() {
    this.firstSubmit = true;

    if (this.form?.get('street')?.invalid) {
      this.toastrService.danger('Straße darf nicht leer sein.', 'Gebäude zusammenfügen');
    }

    if (this.form?.get('houseNumbers.number')?.invalid) {
      this.toastrService.danger('Hausnummer darf nicht leer sein.', 'Gebäude zusammenfügen');
    }

    if (this.form?.get('automaticAddress.zipCode')?.invalid) {
      this.toastrService.danger('Postleitzahl darf nicht leer sein.', 'Gebäude zusammenfügen');
    }

    if (this.form?.get('automaticAddress.city')?.invalid) {
      this.toastrService.danger('Stadt darf nicht leer sein.', 'Gebäude zusammenfügen');
    }

    if (this.form?.get('automaticAddress.province')?.invalid) {
      this.toastrService.danger('Bundesland darf nicht leer sein.', 'Gebäude zusammenfügen');
    }

    if (this.form && this.form.valid) {
      return {
        ...this.form.value,
      };
    }
    throw new Error('Form is not valid');
  }

  hasError(controlName: string): boolean {
    const control = this.form?.get(controlName);
    return !!control?.errors;
  }
}
