<div class="search-row">
  <wr-search-bar class="grow"
                 [search]="searchText"
                 (searchChange)="searchTextChange.emit($event)">
  </wr-search-bar>
  <button *ngIf="addItemClicked.observed && !disableAddButton"
          nbButton
          status="primary"
          nbTooltip="Neu hinzufügen"
          nbTooltipStatus="primary"
          (click)="addItemClicked.emit()">
    <nb-icon icon="plus-outline" pack="eva"></nb-icon>
  </button>
</div>

<div *ngIf="groups?.length; else noItems" class="list-view noselect" [nbSpinner]="!groups">
  <div class="list-item-group" *ngFor="let group of groups">
    <ng-container [ngTemplateOutlet]="groupHeaderTemplate?.templateRef || defaultHeaderTemplate"
                  [ngTemplateOutletContext]="{
                    id: group.id,
                    click: toggleFunc,
                    displayName: group.displayName,
                    expanded: !hiddenGroups.get(group.id),
                    items: group.items
                  }">
    </ng-container>
    <ng-container *ngIf="!hiddenGroups.get(group.id)">
      <div class="list-item" *ngFor="let item of group.items"
           (click)="selectedChange.emit(item)"
           [class.selected]="selected === item">
        <ng-container *ngIf="listItemTemplate?.templateRef as template; else noTemplate"
                      [ngTemplateOutlet]="template"
                      [ngTemplateOutletContext]="{$implicit: item}">
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noTemplate>
      <nb-alert status="warning">
        No template provided
      </nb-alert>
    </ng-template>
  </div>
</div>

<ng-template #noItems>
  <span class="text-hint no-items">
    <nb-icon icon="slash-outline" pack="eva"></nb-icon>
    <span>Keine Einträge gefunden</span>
  </span>
</ng-template>

<ng-template #defaultHeaderTemplate
             let-id="id"
             let-displayName="displayName"
             let-expanded="expanded"
             let-click="click">
  <div class="group-header" (click)="click(id)">
    <span>{{displayName}}</span>
    <nb-icon pack="eva" [icon]="expanded ? 'chevron-down-outline' : 'chevron-up-outline'"></nb-icon>
  </div>
</ng-template>
