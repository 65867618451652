import { Component } from '@angular/core';

@Component({
  selector: 'wr-contractor-external-dashboard-page',
  templateUrl: './contractor-external-dashboard-page.component.html',
  styleUrls: ['./contractor-external-dashboard-page.component.scss']
})
export class ContractorExternalDashboardPageComponent {


}
