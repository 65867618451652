<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="cubes"></nb-icon>
          TGAs
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <div class="filter-control-group">
                <label class="label" for="buildingSelect">Gebäude</label>
                <wr-building-select id="buildingSelect"
                                    formControlName="building"
                                    [fixedOrganisationId]="currentOrganisationId()">
                </wr-building-select>
              </div>
              <div class="list-filter-control">
                <button nbButton ghost status="primary" (click)="triggerFilter()">
                  <nb-icon pack="eva" icon="search-outline"></nb-icon>
                </button>
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
    [data$]="buildingEquipments$"
    (lineClick)="navigateToBuildingEquipment($event)"
    [defaultSort]="'equipmentName'"
    [storeUsage]="buildingEquipmentStoreUsage"
    [sortKey]="'cooperativeBuildingEquipmentSort'"
  >
    <ng-template
      dataColumn
      let-buildingEquipment
      key="equipmentName"
      display="TGA">
      <div *ngIf="buildingEquipment.equipmentName">
        {{buildingEquipment.equipmentName}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-buildingEquipment
      key="equipmentType"
      display="Typ">
      <div *ngIf="buildingEquipment.equipmentType">
        {{buildingEquipment.equipmentType | equipmentTypeTranslation}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-buildingEquipment
      key="quantity"
      display="Anzahl">
      <div *ngIf="buildingEquipment.quantity">
        {{buildingEquipment.quantity}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-buildingEquipment
      key="building"
      display="Gebäudeteil">
      <div *ngIf="buildingEquipment.sectionText">
        {{buildingEquipment.sectionText}}
      </div>
    </ng-template>
  </wr-data-table>
</div>
