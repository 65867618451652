<ng-container *ngIf="value?.length; else noItems">

  <div [nbSpinner]="!!(loading$|async)"
       class="building-equipments"
       [sortableArray]="value"
       [sortableOptions]="sortableOptions">
    <div class="building-equipment" *ngFor="let item of value">
      <div class="name-and-description">
        <div class="name">
          <nb-icon pack="fas" icon="cube"></nb-icon>
          <span>{{item.equipmentName}}</span>
        </div>
        <div class="description">
          <div>
            <b>Anzahl:</b>
            <span>{{item.quantity}}</span>
          </div>
          <div *ngIf="isBuildingSectionMismatch(item)">
            <b>Gebäudeteil:</b>
            <span>{{item.sectionText}}</span>
            <nb-icon class="mismatch"
                     icon="alert-triangle-outline"
                     pack="eva"
                     status="warning"
                     nbTooltip="TGA ist nicht im selben Gebäudeteil wie die Prüfpunktzeile"
                     nbTooltipStatus="warning"
                     *ngIf="isBuildingSectionMismatch(item)"></nb-icon>
          </div>
        </div>
      </div>
      <div class="flex-grow"></div>
      <button nbButton
              status="info"
              (click)="showDetailWindow(item)"
              nbTooltip="TGA bearbeiten"
              nbTooltipStatus="info">
        <nb-icon pack="eva" icon="external-link-outline"></nb-icon>
      </button>
      <button *ngIf="!isDisabled"
              nbButton
              ghost
              (click)="removeBuildingEquipment(item)">
        <nb-icon pack="eva" icon="close"></nb-icon>
      </button>
    </div>
  </div>
</ng-container>

<ng-template #noItems>
  <div class="text-hint">Keine TGAs ausgewählt</div>
</ng-template>

<div class="center-button">
  <button *ngIf="!isDisabled"
          nbButton
          type="button"
          size="giant"
          [nbPopover]="popoverMenu"
          [nbPopoverPlacement]="NbPosition.TOP">
    <nb-icon icon="plus-outline" pack="eva"></nb-icon>
  </button>
</div>

<ng-template #popoverMenu>
  <div class="building-equipment-add">
    <wr-list-view [items]="notSelectedBuildingEquipments$|async"
                  [searchText]="text$.value"
                  (searchTextChange)="text$.next($event)"
                  (selectedChange)="addBuildingEquipment($event)"
                  (addItemClicked)="createNewBuildingEquipment()">
      <ng-template wrListItem let-item>
        <div class="building-equipment-add-item">
          <nb-icon pack="fas" icon="cube"></nb-icon>
          <div class="building-equipment-list-item-name">
            <span>{{item.equipmentName}}</span>
            <span class="address">{{item.sectionText}}</span>
          </div>
        </div>
      </ng-template>
    </wr-list-view>
  </div>
</ng-template>
