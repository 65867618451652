import { Component, EventEmitter, forwardRef, Injector, Input } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { AbstractFormControl } from '../../abstract-form-control';
import { AttachmentDetailDto, TagItemDto } from '@artemis-software/wr-api';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Attachment, AttachmentUploadJob, FormControlContext } from '../attachment-utils';
import { AttachmentDialogComponent } from '@components/dialog/attachment-dialog/attachment-dialog.component';
import Sortable from 'sortablejs';

const formControlCache = new Map<FormControl<unknown>, FormControlContext>();

@Component({
  selector: 'wr-multi-attachment-control',
  templateUrl: './multi-attachment-control.component.html',
  styleUrls: ['./multi-attachment-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiAttachmentControlComponent),
      multi: true,
    },
  ],
})
export class MultiAttachmentControlComponent extends AbstractFormControl<Attachment[]> {

  @Input()
  type: AttachmentDetailDto.TypeEnum = AttachmentDetailDto.TypeEnum.File;

  selectedFiles: number[] = [];

  hoveredTag = '';

  currentControlContext!: FormControlContext;

  selectedTags: string[] = [];

  get hasAnyPendingAttachments(): boolean {
    for (const context of formControlCache.values()) {
      if (context.pendingJobs$?.value.length) {
        return true;
      }
    }
    return false;
  }

  get sortableOptions(): Sortable.Options {
    return {
      disabled: this.isDisabled,
    };
  }

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly injector: Injector,
    private readonly nbToastrService: NbToastrService,
  ) {
    super();
  }

  deleteFiles(): void {
    if (this.selectedFiles.length === 0) {
      return;
    }
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Ausgewählte Dateien löschen?',
        message: 'Bist du sicher, dass du alle ausgewählte Dateien und Bilder löschen willst?',
      },
    }).onClose.subscribe((result: boolean) => {
      if (result) {
        this.value = [...this.value!.map((attachment, index) => {
          return {
            ...attachment,
            deleted: this.selectedFiles.includes(index),
          } as Attachment;
        })];
        this.deselectAll();
      }
    });
  }

  isSelected(i: number): boolean {
    return this.selectedFiles.includes(i) && !this.isDisabled;
  }

  toggleSelect(i: number): void {
    if (this.isDisabled) {
      return;
    }
    if (this.selectedFiles.includes(i)) {
      this.selectedFiles = this.selectedFiles.filter(index => index !== i);
    } else {
      this.selectedFiles.push(i);
    }
  }

  selectAll(): void {
    if (this.value) {
      this.selectedFiles = [...this.value.keys()];
    }
  }

  deselectAll(): void {
    this.selectedFiles = [];
  }

  override writeValue(attachments?: Attachment[]): void {
    const formControl = this.getFormControl();

    if (!formControlCache.has(formControl)) {
      const context = {
        attachmentChangeEventListener: new EventEmitter<Attachment[]>(),
        pendingJobs$: new BehaviorSubject<AttachmentUploadJob[]>([]),
      };
      context.attachmentChangeEventListener.subscribe((attachments: Attachment[]) => {
        formControl.setValue([...attachments]);
      });
      formControlCache.set(formControl, context);
    }
    this.currentControlContext = formControlCache.get(formControl)!;

    this.deselectAll();
    super.writeValue(attachments);
  }

  private getFormControl(): FormControl {
    const ngControl = this.injector.get(NgControl, null);
    const formControl = ngControl?.control as FormControl | undefined;
    if (formControl) {
      return formControl;
    }
    const formControlName = ngControl?.name;
    if (formControlName) {
      const formGroup = this.injector.get(ControlContainer).control;
      return formGroup?.get(formControlName + '') as FormControl;
    }
    throw new Error('No form control found');
  }

  rotateSelection(delta: number): void {
    const copy = [...this.value ?? []];
    for (const index of this.selectedFiles) {
      copy[index].rotationDegrees += delta;
    }
    this.value = copy;
  }

  checkHoveredTag(tags: Array<TagItemDto>) {
    if (this.hoveredTag == '')
      return true;
    return tags.some(tag => {
      return tag.name === this.hoveredTag;
    });
  }

  hoveredTagChange(tag: string) {
    this.hoveredTag = tag;
  }

  async showAttachmentDialog(attachment: Attachment) {
    try {
      const form = (await firstValueFrom(this.dialogService.open(AttachmentDialogComponent,
        {
          context: {
            attachment: attachment,
          },
        }).onClose));
      if (form) {
        if (form.tags && this.value) {
          this.value = this.value.map(a => {
            if (a.s3key === attachment.s3key) {
              return {
                ...a,
                tags: form.tags,
                comment: form.comment,
              };
            }
            return a;
          });
          this.nbToastrService.success('Bild Metadaten bearbeitet', 'Erfolg');
        }
      }
    } catch (e) {
      console.error(e);
      this.nbToastrService.danger('Fehler beim bearbeiten der Meta-Daten', 'Fehler');
    }
  }

  checkSelectedTags(tags: Array<TagItemDto>) {
    if (this.selectedTags.length == 0) {
      return true;
    }

    const tagNamesSet = new Set(tags.map(tag => tag.name));
    for (const selectedTag of this.selectedTags) {
      if (tagNamesSet.has(selectedTag)) {
        return true;
      }
    }

    return false;
  }

  onSelectedTagsChange(tags: string[]) {
    this.selectedTags = tags;
  }
}
