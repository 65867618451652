import { Component, OnInit } from '@angular/core';
import { ContractorService, ExternalContractorService } from '@artemis-software/wr-api';
import { createForm } from '@sonofabit/ng-core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'wr-contractor-login-page',
  templateUrl: './contractor-login-page.component.html',
  styleUrls: ['./contractor-login-page.component.scss']
})
export class ContractorLoginPageComponent implements OnInit{

  form?: ReturnType<typeof this.initForm>;
  buildingId?: string;

  constructor(private readonly externalContractorService: ExternalContractorService,
              private readonly router: Router,
              private readonly nbToastrService: NbToastrService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.form = this.initForm();
    this.buildingId = this.route.snapshot.paramMap.get('id')!;
  }

  initForm() {
    return createForm(({ field  }) => {
      return {
        pin: field({
          value: '',
          validators: [Validators.required],
        }),
      };
    });
  }

  async onSubmit() {
    const pin = this.form?.get('pin')?.value;
    if(pin){
      this.externalContractorService.login(pin)
        .subscribe((contractor) => {
          if(contractor) {
            this.nbToastrService.success('Anmeldung erfolgreich', 'Success');
            this.router.navigate(['contractor-view', this.buildingId],
              { queryParams: { contractorId: contractor.id } });
          }
          else{
            this.nbToastrService.danger('Anmeldung fehlgeschlagen', 'Error');
          }
        });
    }
  }
}
