export class LoadAddress {
  static readonly type = '[Address] Load address';

  constructor(public addressId: string) {
  }
}

export class LoadAddresses {
  static readonly type = '[Address] Load addresses';

  constructor(public addressIds: string[]) {
  }
}
