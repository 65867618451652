import { ChangeDetectionStrategy, Component, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { Select, Store } from '@ngxs/store';
import { BuildingState } from '@/store/building/building.state';
import { Observable } from 'rxjs';
import { ChangeRepairTaskFilter } from '@/store/repairTask/repairTask.action';
import { ChangeInspectionFilter } from '@/store/inspection/inspection.action';
import { ChangeBuildingFilter } from '@/store/building/building.action';

export interface CooperativeStatisticsFilter {
  cooperativeId?: string;
  selectedRange?: {
    start: Date;
    end: Date;
  };
}

@Component({
  selector: 'wr-cooperative-statistics-page',
  templateUrl: './cooperative-statistics-page.component.html',
  styleUrls: ['./cooperative-statistics-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CooperativeStatisticsPageComponent {

  @Select(BuildingState.buildingsLoading)
  loading$!: Observable<boolean>;

  isLoading = asyncComputed(() => this.loading$, false);

  readonly filter: WritableSignal<CooperativeStatisticsFilter> = signal({
    cooperativeId: undefined,
    selectedRange: {
      start: new Date(),
      end: new Date(),
    }
  });

  form!: FormGroup;

  readonly FORMAT_STRING = 'yyyy-MM-ddTHH:mm:ss.SSSZ';
  readonly localStorageKey = 'cooperativeStatisticsFilter';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dateService: NbDateService<Date>,
    private readonly store: Store,
  ) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem(this.localStorageKey);
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm();

      let selectedRange = {
        start: new Date(),
        end: new Date(),
      };

      if (filterValue.selectedRange?.start) {
        let startDateTime = this.dateService.parse(filterValue.selectedRange.start, this.FORMAT_STRING);
        let endDateTime = this.dateService.parse(filterValue.selectedRange.end ?? filterValue.selectedRange.start, this.FORMAT_STRING);

        startDateTime = this.dateService.addHours(startDateTime, 1);
        endDateTime = this.dateService.addHours(endDateTime, 1);

        selectedRange = {
          start: startDateTime,
          end: endDateTime,
        };
      }

      this.filter.set({
        ...this.filter(),
        cooperativeId: filterValue.cooperativeId,
        selectedRange: selectedRange
      });
      this.addSpecialFields(selectedRange, filterValue.technicianId);
    } else {
      this.initFilterForm();
    }
  }

  initFilterForm(): void {
    const startOfMonth = this.dateService.getMonthStart(this.dateService.today());
    const endOfMonth = this.dateService.addMonth(startOfMonth, 2);

    this.form = this.formBuilder.group({
      cooperativeId: this.formBuilder.control(null),
      selectedRange: new FormControl({
        start: startOfMonth,
        end: endOfMonth,
      }),
    });
  }

  addSpecialFields(selectedRange: {
    start: Date;
    end: Date
  } | undefined, cooperativeId: string): void {
    this.form.patchValue({
      'selectedRange': selectedRange,
      'cooperativeId': cooperativeId,
    });
  }

  triggerFilter(): void {
    const formValue = this.form.value;
    if (formValue.selectedRange && !formValue.selectedRange?.end) {
      formValue.selectedRange.end = formValue.selectedRange.start;
    }

    this.filter.set({
      ...this.filter(),
      cooperativeId: formValue.cooperativeId,
      selectedRange: formValue.selectedRange,
    });

    this.store.dispatch(new ChangeRepairTaskFilter({
      organisationId: formValue.cooperativeId,
      selectedRange: {
        start: formValue.selectedRange.start.toISOString(),
        end: formValue.selectedRange.end.toISOString(),
      }
    }));

    this.store.dispatch(new ChangeInspectionFilter({
      organisationId: formValue.cooperativeId,
      selectedRange: {
        start: formValue.selectedRange.start.toISOString(),
        end: formValue.selectedRange.end.toISOString(),
      }
    }));

    this.store.dispatch(new ChangeBuildingFilter({ organisationId: formValue.cooperativeId, }));

    localStorage.setItem(this.localStorageKey, JSON.stringify(this.form.value));
  }
}
