import { Component, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormGroup } from '@angular/forms';
import { BuildingItemDto, BuildingSectionService } from '@artemis-software/wr-api';
import { BehaviorSubject } from 'rxjs';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { InspectionEntryFormType } from '@pages/inspection-detail-page-v2/inspection-util';

@Component({
  selector: 'wr-apply-from-other-section-dialog',
  templateUrl: './apply-from-other-section-dialog.component.html',
  styleUrls: ['./apply-from-other-section-dialog.component.scss'],
})
export class ApplyFromOtherSectionDialogComponent {

  @ViewChild(BaseDialogComponent)
  baseDialogComponent!: BaseDialogComponent<InspectionEntryFormType, undefined>;

  @Input()
  entries: InspectionEntryFormType[] = [];
  @Input()
  checkpointString?: string;

  buildingSections$ = new BehaviorSubject<BuildingSectionDetailDto[]>([]);

  private _buildingId?: string;

  @Input()
  set building(value: BuildingItemDto) {
    const buildingId = value.id;
    if (this._buildingId !== buildingId) {
      this.buildingSectionService.findAllByBuildingId(buildingId).subscribe(sections => this.buildingSections$.next(sections));
      this._buildingId = buildingId;
    }
  }

  constructor(
    private readonly dialogRef: NbDialogRef<void>,
    private readonly buildingSectionService: BuildingSectionService,
  ) {
  }

  select(entry: InspectionEntryFormType): void {
    this.baseDialogComponent.submitResponse = () => entry;
    this.baseDialogComponent.submit();
  }

  getSectionTitle(entry: InspectionEntryFormType): string {
    const sectionId = entry.get('buildingSectionId')?.value;
    const section = this.buildingSections$.value.find(s => s.id === sectionId);
    return `${section?.address.street} ${section?.address.number}`;
  }

  getEntryDescription(entry: InspectionEntryFormType): string {
    const assessment = entry.get('predefinedAssessment')?.value;
    return assessment?.text ?? '';
  }
}
