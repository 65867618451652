import { Pipe, PipeTransform } from '@angular/core';
import { BuildingDetailDto } from '@artemis-software/wr-api';
import BuildingTypeEnum = BuildingDetailDto.BuildingTypeEnum;

export const buildingTypeTranslations: Record<BuildingTypeEnum, string> = {
  OTHER: 'Andere',
  RESIDENTIAL_BUILDING: 'Wohngebäude',
  RESIDENTIAL_BUILDING_WITH_BUSINESS: 'Wohngebäude mit Lokal',
  SCHOOL: 'Schule',
};

@Pipe({
  name: 'buildingTypeTranslation',
})
export class BuildingTypeTranslationPipe implements PipeTransform {
  transform(value?: BuildingTypeEnum) {
    return value ? buildingTypeTranslations[value] : undefined;
  }
}
