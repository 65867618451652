import { Component, ElementRef, forwardRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BuildingItemDto, BuildingService } from '@artemis-software/wr-api';
import { BehaviorSubject, combineLatest, debounceTime, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';

@Component({
  selector: 'wr-building-select',
  templateUrl: './building-select.component.html',
  styleUrls: ['./building-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingSelectComponent),
      multi: true,
    },
  ],
})
export class BuildingSelectComponent extends AbstractFormControl<BuildingItemDto | undefined> implements OnInit {

  readonly buildingService = inject(BuildingService);
  readonly router = inject(Router);

  @ViewChild('searchInput')
  input?: ElementRef;

  @Input()
  organisationId$?: Observable<string>;

  @Input()
  fixedOrganisationId: string | undefined;
  @Input()
  isPropertyManager = false;

  filterText$ = new BehaviorSubject<string>('');
  filteredBuildings$?: Observable<BuildingItemDto[]>;

  focusCache?: BuildingItemDto;

  ngOnInit(): void {
    this.filteredBuildings$ = combineLatest([
      this.filterText$,
      this.organisationId$
        ? this.organisationId$.pipe(startWith(undefined))
        : of(undefined),
    ]).pipe(
      debounceTime(100),
      switchMap(([filterText, organisationId]) => {
        const filterOrganisationId = this.fixedOrganisationId || organisationId;
        return this.buildingService.findAll({
          text: filterText,
          organisationId: filterOrganisationId,
          page: 0,
          size: 10,
        });
      }),
      shareReplay(1),
    );
  }

  override writeValue(building: BuildingItemDto): void {
    super.writeValue(building);
    this.filterText$.next(this.viewHandle(building));
  }

  onSelectionChange(building?: BuildingItemDto): void {
    if (building?.id) {
      this.filterText$.next(this.viewHandle(building));
      this.value = building;
    }
  }

  viewHandle(value?: BuildingItemDto | string): string {
    if (typeof value === 'string') {
      return value;
    }
    if (value) {
      return value.sectionText;
    }
    return '';
  }

  clear(): void {
    this.value = undefined;
    this.filterText$.next('');
  }

  goToBuilding(): void {
    this.router.navigate(['building', this.value?.id]);
  }

  focusIn(): void {
    this.focusCache = this.value;
    this.clear();
  }

  focusOut(): void {
    setTimeout(() => {
      if (!this.value) {
        this.value = this.focusCache;
      }
    }, 100);
  }
}
