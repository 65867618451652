import { Component, forwardRef } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent extends AbstractFormControl<Date> {

  override writeValue(value: Date | string | undefined) {
    if (typeof value === 'string') {
      value = new Date(value);
    }
    super.writeValue(value);
  }
}
