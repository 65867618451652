import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { LoadAllCheckpointGroups } from '@/store/checkpointGroup/checkpointGroup.actions';
import { CheckpointGroupDetailDto, CheckpointGroupService, CheckpointMergeDto } from '@artemis-software/wr-api';
import { PendingChanges } from '@guards/pending-changes.guard';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'wr-checkpoint-group-detail-page',
  templateUrl: './checkpoint-group-detail-page.component.html',
  styleUrls: ['./checkpoint-group-detail-page.component.scss'],
})
export class CheckpointGroupDetailPageComponent implements PendingChanges {
  isEditing = false;
  firstSubmit = false;
  form?: FormGroup;
  saving$ = new BehaviorSubject(false);

  constructor(private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router,
    private readonly dialogService: NbDialogService,
    private readonly checkpointGroupService: CheckpointGroupService,
    private readonly toastrService: NbToastrService) {
    route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        if (id === 'new') {
          this.isEditing = true;
          this.initForm();
        } else {
          this.checkpointGroupService.findById(id).subscribe((checkpointGroup) => {
            this.initForm(checkpointGroup);
            this.form?.disable();
          });
        }
      } else {
        this.router.navigate(['checkpointGroups']);
      }
    });
  }

  initForm(dto?: CheckpointGroupDetailDto): void {
    this.form = new FormGroup({
      id: new FormControl(dto?.id),
      name: new FormControl(dto?.name, [Validators.required]),
      number: new FormControl(dto?.number, [Validators.required]),
      description: new FormControl(dto?.description),
    });
  }

  hasError(controlName: string, errorName = 'required'): boolean {
    const control = this.form?.get(controlName);
    return this.isEditing && this.firstSubmit && (control?.hasError(errorName) ?? false) && ((control?.touched ?? false) || (control?.dirty ?? false));
  }

  onEdit(): void {
    this.isEditing = true;
    this.form?.enable();
  }

  async submit(): Promise<void> {
    this.firstSubmit = true;
    this.form?.markAsTouched();

    if (!this.form?.valid) {
      this.toastrService.danger('Benutzer kann nicht gespeichert werden, es gibt noch Fehler.', 'Benutzer');
      return;
    }

    try {
      this.saving$.next(true);
      const checkpointGroupDto = this.form?.value as CheckpointMergeDto;
      const checkpointGroup = await firstValueFrom(this.checkpointGroupService.merge(checkpointGroupDto));

      this.isEditing = false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['checkpointGroup', checkpointGroup.id]).then(() => {
          this.store.dispatch(new LoadAllCheckpointGroups());
          this.toastrService.success('Prüftpunktgruppe wurde erfolgreich gespeichert', 'Prüftpunktgruppe');
        });
      });
    } catch (e) {
      console.error(e);
      this.toastrService.danger('Beim Speichern der Prüfpunktgruppe ist ein Fehler aufgetreten', 'Fehler');
    } finally {
      this.saving$.next(false);
    }
  }

  onDelete(): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Prüfpunkt Gruppe löschen',
        message: 'Bist du sicher, dass du diese Prüfpunkt Gruppe löschen willst?',
      },
    }).onClose.subscribe((result) => {
      if (result) {
        this.checkpointGroupService.deleteById(this.form?.value.id).subscribe(() => {
          this.store.dispatch(new LoadAllCheckpointGroups());
          this.router.navigate(['checkpointGroups']);
          this.toastrService.success('Prüfpunkt Gruppe wurde erfolgreich gelöscht', 'Prüfpunktgruppe');
        });
      }
    }, () => {
      this.toastrService.danger('Beim Löschen der Prüfpunkt Gruppe ist ein Fehler aufgetreten', 'Prüfpunktgruppe');
    });
  }

  async cancel(): Promise<void> {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['checkpointGroup', this.form?.value.id]);
    });
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['checkpointGroups']);
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.isEditing;
  }
}
