import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InspectionFormWrapper } from '@pages/inspection-detail-page-v2/inspection-form';
import { getCheckpointString, InspectionEntryFormType } from '@pages/inspection-detail-page-v2/inspection-util';
import { debounceTime, Observable, Subject, takeUntil } from 'rxjs';
import { AddressDetailDto, BuildingItemDto, BuildingSectionItemDto } from '@artemis-software/wr-api';
import { Select } from '@ngxs/store';
import { AddressState } from '@/store/address/address.state';

@Component({
  selector: 'wr-cockpit-entry-detail-view',
  templateUrl: './cockpit-entry-detail-view.component.html',
  styleUrls: ['./cockpit-entry-detail-view.component.scss'],
})
export class CockpitEntryDetailViewComponent implements OnInit, OnDestroy {

  readonly getCheckpointString = getCheckpointString;
  readonly getGroupName = (entry: InspectionEntryFormType) => entry.controls.checkpointGroupName.value;

  @Select(AddressState.addressResolver)
  addressResolver$!: Observable<(id?: string) => AddressDetailDto>;

  @Input()
  entry!: InspectionEntryFormType;
  @Input()
  formWrapper!: InspectionFormWrapper;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.formWrapper.enabled$.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
    ).subscribe((res) => {
      if (res) {
        this.entry.enable({ emitEvent: false });
      } else {
        this.entry.disable({ emitEvent: false });
      }
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get building(): BuildingItemDto | undefined {
    return this.formWrapper.form?.controls.building.value;
  }

  get buildingSection(): BuildingSectionItemDto | undefined {
    const id = this.entry.controls.buildingSectionId.value;
    return this.building?.sections?.find(s => s.id === id);
  }
}
