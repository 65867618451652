<wr-base-dialog status="info"
                [header]="title"
                submitText="Senden"
                [disableSubmit]="!isFormValid()"
                [submitResponse]="submitResponse.bind(this)"
                dialogWidth="giant">
  <form *ngIf="form" [formGroup]="form">
    <hau-form-field label="Betreff" [validation]="form.validation.subject">
      <input type="text" formControlName="subject" nbInput fullWidth>
    </hau-form-field>
    <hau-form-field label="Empfänger" *ngIf="!multipleReceivers">
      <input type="text" formControlName="receiver" nbInput fullWidth readonly>
    </hau-form-field>

    <div class="email-user-input" *ngIf="multipleReceivers">
      <nb-tag-list (tagRemove)="onTagRemove($event)">
        <nb-tag *ngFor="let item of items" [text]="item" removable></nb-tag>
        <input
          #tagInput
          type="text"
          nbTagInput
          [nbAutocomplete]="auto"
          (input)="onInput($event)"
          (tagAdd)="onTagAdd($event, tagInput)"
          placeholder="Email oder Benutzer hinzufügen"
          fullWidth>
        <nb-autocomplete #auto (selectedChange)="onSelect($event, tagInput)">
          <nb-option *ngFor="let user of filteredUsers" [value]="user">{{ user.userName }}</nb-option>
        </nb-autocomplete>
      </nb-tag-list>
    </div>

    <hau-form-field label="Text" [validation]="form.validation.body">
      <wr-rich-text-editor formControlName="body"></wr-rich-text-editor>
    </hau-form-field>
  </form>
  {{message}}
</wr-base-dialog>
