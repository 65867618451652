<nb-layout>
  <nb-layout-header *ngIf="loggedIn">
    <button class="collapse-btn" (click)="toggleSidebar()" nbButton ghost>
      <nb-icon pack="fas" icon="bars"></nb-icon>
    </button>
    <div class="nav-bar-container" *ngIf="loggedIn">
      <div class="corporate-logo">
        <img src="assets/pictures/gema-m.svg" alt="GEMA">
        <h4>MyGemaPro</h4>
      </div>
      <div class="user">
        <div>{{organisationName()}}</div>
        <nb-user *ngIf="(currentUser$ | async) as currentUser" size="medium"
                 name="{{currentUser.firstName}} {{currentUser.lastName}}"
                 [picture]="getUserProfile(currentUser)">
        </nb-user>
        <wr-notifications-dropdown></wr-notifications-dropdown>
      </div>
    </div>
  </nb-layout-header>
  <nb-sidebar state="compacted" *ngIf="loggedIn">
    <div class="scrollable-content">
      <wr-menu [items]="menuItems()"></wr-menu>
    </div>
  </nb-sidebar>
  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>

