import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[dataColumn][key][display]',
})
export class DataColumnDirective {
  @Input()
  key!: string;
  @Input()
  display!: string;

  @Input()
  sortable = true;

  constructor(public readonly template: TemplateRef<any>) {}
}
