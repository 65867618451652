/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AssessmentPresetMergeDto { 
    id?: string;
    text?: string;
    action?: AssessmentPresetMergeDto.ActionEnum;
    checkpointId?: string;
    checkInterval?: AssessmentPresetMergeDto.CheckIntervalEnum;
    buildingEquipmentRelated?: boolean;
    standardAssessment?: boolean;
}
export namespace AssessmentPresetMergeDto {
    export type ActionEnum = 'IMMINENT_DANGER' | 'ACTION_NECESSARY' | 'RENOVATION_NECESSARY' | 'ACTION_RECOMMENDED' | 'RISK_ANALYSIS_RECOMMENDED' | 'NO_ACTION_NECESSARY';
    export const ActionEnum = {
        ImminentDanger: 'IMMINENT_DANGER' as ActionEnum,
        ActionNecessary: 'ACTION_NECESSARY' as ActionEnum,
        RenovationNecessary: 'RENOVATION_NECESSARY' as ActionEnum,
        ActionRecommended: 'ACTION_RECOMMENDED' as ActionEnum,
        RiskAnalysisRecommended: 'RISK_ANALYSIS_RECOMMENDED' as ActionEnum,
        NoActionNecessary: 'NO_ACTION_NECESSARY' as ActionEnum
    };
    export type CheckIntervalEnum = 'YEAR' | 'HALF_YEAR' | 'ONE_MONTH' | 'NONE' | 'ONE_DAY';
    export const CheckIntervalEnum = {
        Year: 'YEAR' as CheckIntervalEnum,
        HalfYear: 'HALF_YEAR' as CheckIntervalEnum,
        OneMonth: 'ONE_MONTH' as CheckIntervalEnum,
        None: 'NONE' as CheckIntervalEnum,
        OneDay: 'ONE_DAY' as CheckIntervalEnum
    };
}


