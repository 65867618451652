import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ContractorItemDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-contractor-select',
  templateUrl: './contractor-select.component.html',
  styleUrls: ['./contractor-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractorSelectComponent),
      multi: true,
    },
  ],
})
export class ContractorSelectComponent extends AbstractFormControl<ContractorItemDto> {
  @Input()
  contractors = [] as ContractorItemDto[];

  compareContractors(contractor1: ContractorItemDto, contractor2: ContractorItemDto): boolean {
    return contractor1.id === contractor2.id;
  }
}
