import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[wrListItem]',
})
export class ListItemDirective<T = any> {
  constructor(
    public readonly templateRef: TemplateRef<T>,
  ) {
  }
}
