import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InspectionFormWrapper } from '@pages/inspection-detail-page-v2/inspection-form';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  firstValueFrom,
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  getCheckpointString,
  InspectionEntryFormType,
  InspectionFormType,
} from '@pages/inspection-detail-page-v2/inspection-util';
import { ListViewGroup } from '@shared/components/grouped-list-view/grouped-list-view.component';
import { BuildingSectionResolverService } from '@services/building-section-resolver.service';
import { AddressItemDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-building-equipments-cockpit',
  templateUrl: './building-equipments-cockpit.component.html',
  styleUrls: ['./building-equipments-cockpit.component.scss'],
})
export class BuildingEquipmentsCockpitComponent implements OnInit, OnDestroy {

  readonly getCheckpointString = getCheckpointString;
  readonly getBuildingEquipmentCount = (entry: InspectionEntryFormType): number => {
    return entry.controls.buildingEquipments.value?.length ?? 0;
  };

  @Input()
  formWrapper!: InspectionFormWrapper;

  relevantInspectionEntries$ = new BehaviorSubject<InspectionEntryFormType[]>([]);
  textFilter$ = new BehaviorSubject<string>('');
  filteredInspectionEntries$: Observable<InspectionEntryFormType[]>;
  filteredInspectionEntriesGrouped$: Observable<ListViewGroup<InspectionEntryFormType>[]>;
  selectedItem$ = new BehaviorSubject<InspectionEntryFormType | null>(null);

  private readonly destroy$ = new Subject<void>();

  constructor(
    buildingSectionResolverService: BuildingSectionResolverService,
  ) {
    this.filteredInspectionEntries$ = combineLatest([
      this.relevantInspectionEntries$,
      this.textFilter$,
    ]).pipe(
      debounceTime(100),
      map(([relevantEntries, textFilter]) => {
        return relevantEntries.filter((entry) => {
          const entryString = getCheckpointString(entry);
          return entryString.toLowerCase().includes(textFilter.toLowerCase());
        });
      }),
    );

    this.filteredInspectionEntriesGrouped$ = this.filteredInspectionEntries$.pipe(
      switchMap(async (filteredEntries) => {
        const map = new Map<string, { group: ListViewGroup<InspectionEntryFormType>, address: AddressItemDto }>();

        for (const entry of filteredEntries) {
          const sectionId = entry.controls.buildingSectionId.value!;
          const section = await firstValueFrom(buildingSectionResolverService.getBuildingSection(sectionId));
          const address = section.address;
          if (!map.has(sectionId)) {
            const group: ListViewGroup<InspectionEntryFormType> = {
              id: sectionId,
              displayName: `${address.street} ${address.number}`,
              items: [],
            };
            map.set(sectionId, { group, address });
          }
          const entries = map.get(sectionId) !;
          entries.group.items.push(entry);
        }
        return Array.from(map.values());
      }),
      switchMap((promises) => Promise.all(promises)),
      map((entries) => entries
        .sort((a, b) =>
          a.address.street.localeCompare(b.address.street) || a.address.number.localeCompare(b.address.number),
        )
        .map(entry => entry.group),
      ),
    );
  }

  ngOnInit(): void {
    this.formWrapper.form$.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
      filter((form) => !!form),
      map((form) => form!),
    ).subscribe((form) => {
      form.valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(100),
        startWith(form.value),
      ).subscribe(() => {
        this.filterRelevantInspectionEntries(form);
      });
    });

    this.filteredInspectionEntries$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filteredEntries) => {
        const currentlySelected = this.selectedItem$.value;
        if (currentlySelected) {
          const selected = filteredEntries.find((entry) => entry.controls.id.value === currentlySelected.controls.id.value);
          this.selectedItem$.next(selected ?? null);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private filterRelevantInspectionEntries(form: InspectionFormType): void {
    const allEntries = form.controls.inspectionEntries.controls;
    const relevantEntries = allEntries.filter((entry) => {
      return entry.controls.buildingEquipmentRelated.value
        || entry.controls.predefinedAssessment.value?.buildingEquipmentRelated;
    });
    this.relevantInspectionEntries$.next(relevantEntries);
  }
}
