<div class="detail-container">
  <nb-card [nbSpinner]="!!(saving$|async)">
    <nb-card-header>
      <button nbButton ghost status="primary" (click)="navigateBack()">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>Genossenschaft</h5>
      <wr-edit-delete-button-group (edit)="onEdit()" (delete)="onDelete()"></wr-edit-delete-button-group>
    </nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="form">
        <form [formGroup]="form">
          <div class="sub-card-body">
            <input hidden formControlName="id">
            <div class="grid">
              <div class="col-4">
                <label class="label" for="name">Name</label>
                <input fullWidth id="name" type="text" nbInput formControlName="name" [readonly]="!isEditing" required>
                <div class="error" *ngIf="hasError('name')">Name ist erforderlich!</div>
              </div>
              <div class="col-4">
                <label class="label" for="date">Vertragsdatum</label>
                <input nbInput id="date" formControlName="contractDateText" fullWidth placeholder="Datum"
                       [nbDatepicker]="datepicker">
                <nb-datepicker #datepicker></nb-datepicker>
              </div>
              <div class="col-4">
                <label class="label" for="organisationType">Typ</label>
                <wr-organisation-type-select id="organisationType" formControlName="organisationType">
                </wr-organisation-type-select>
              </div>
            </div>
            <div class="grid">
              <div class="col-12">
                <label class="label" for="street">Straße</label>
                <input fullWidth id="street" type="text" nbInput formControlName="street" [readonly]="!isEditing"
                       required>
                <div class="error" *ngIf="hasError('street')">Straße ist erforderlich!</div>
              </div>
            </div>
            <div class="grid">
              <div class="col-6">
                <label class="label" for="zipCode">Postleitzahl</label>
                <input fullWidth id="zipCode" type="text" min="0" max="10000" step="1" nbInput formControlName="zipCode"
                       [readonly]="!isEditing" required>
                <div class="error" *ngIf="hasError('zipCode')">Postleitzahl ist erforderlich!</div>
              </div>
              <div class="col-6">
                <label class="label" for="city">Stadt</label>
                <input fullWidth id="city" type="text" nbInput formControlName="city" [readonly]="!isEditing" required>
                <div class="error" *ngIf="hasError('city')">Stadt ist erforderlich!</div>
              </div>
            </div>
            <div class="grid">
              <div class="col-6">
                <label class="label" for="state">Bundesland</label>
                <input fullWidth id="state" type="text" nbInput formControlName="state" [readonly]="!isEditing"
                       required>
                <div class="error" *ngIf="hasError('state')">Bundesland ist erforderlich!</div>
              </div>
              <div class="col-6">
                <label class="label" for="country">Land</label>
                <input fullWidth id="country" type="text" nbInput formControlName="country" [readonly]="true">
              </div>
            </div>
            <div class="grid" formGroupName="license">
              <div class="col-6">
                <label class="label" for="maxUsers">Maximale Anzahl an Benutzern</label>
                <input fullWidth id="maxUsers" type="number" nbInput formControlName="maxUsers" [readonly]="!isEditing"
                       required min="0" step="1">
                <div class="error" *ngIf="hasError('license.maxUsers')">Benutzeranzahl ist erforderlich!</div>
                <div class="error" *ngIf="hasError('license.maxUsers', 'min')">Benutzeranzahl kann nicht negativ sein!
                </div>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </nb-card-body>
    <nb-card-footer>
      <button size="medium" [disabled]="!isEditing" nbButton status="primary" (click)="submit()">Speichern</button>
      <button size="medium" [disabled]="!isEditing" nbButton status="basic" (click)="cancel()">Zurück</button>
    </nb-card-footer>
  </nb-card>
</div>
