<nb-card>
  <nb-card-header>
    <h5>
      <nb-icon icon="building" pack="fas"></nb-icon>
      Gebäude
    </h5>
  </nb-card-header>
  <wr-data-table *ngIf="count$ | async as count; else noBuildings"
                 [data$]="buildings$"
                 (lineClick)="navigateToBuilding($event)"
                 [sortKey]="'buildingStatisticsSort'"
                 [storeUsage]="buildingStoreUsage"
                 [defaultSort]="'address'"
                 [defaultPageSize]="5">
    <ng-template dataColumn let-building key="address" display="Adresse">
      {{ building.sectionText }}
    </ng-template>
    <ng-template dataColumn let-building key="name" display="Name">
      {{ building.name }}
    </ng-template>
    <ng-template dataColumn let-building key="organisationName" display="Genossenschaft">
      {{ building.organisationName }}
    </ng-template>
    <ng-template dataColumn let-building key="internalNumber" display="Interne Nummer">
      {{ building.organisationName }}
    </ng-template>
    <ng-template dataColumn let-building key="buildingCondition.conditionValue" display="Zustand">
      <wr-building-condition *ngIf="building.buildingCondition.conditionValue > 0"
                             [condition]="building.buildingCondition.conditionValue"/>
    </ng-template>
  </wr-data-table>
</nb-card>

<ng-template #noBuildings>
  <nb-card-body>
    <h5 class="empty-message">Keine Einträge</h5>
  </nb-card-body>
</ng-template>
