import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadAllCheckpoints } from '@/store/checkpoint/checkpoint.actions';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  AssessmentPresetItemDto,
  AssessmentPresetService,
  CheckpointDetailDto,
  CheckpointMergeDto,
  CheckpointService,
  ContractorItemDto, IdReferenceDto,
} from '@artemis-software/wr-api';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { PendingChanges } from '@guards/pending-changes.guard';

@Component({
  selector: 'wr-checkpoint-detail-page',
  templateUrl: './checkpoint-detail-page.component.html',
  styleUrls: ['./checkpoint-detail-page.component.scss'],
})
export class CheckpointDetailPageComponent implements PendingChanges {
  toDeleteAssessmentPreset: string[] = [];
  isEditing = false;
  firstSubmit = false;
  form?: FormGroup;
  saving$ = new BehaviorSubject(false);

  readonly Interval = AssessmentPresetItemDto.CheckIntervalEnum;

  constructor(private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly router: Router,
    private readonly checkpointService: CheckpointService,
    private readonly assessmentPresetService: AssessmentPresetService,
    private readonly dialogService: NbDialogService,
    private readonly formBuilder: FormBuilder,
    private readonly toastrService: NbToastrService) {
    route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        if (id === 'new') {
          this.isEditing = true;
          this.initForm();
        } else {
          this.checkpointService.findById(id).subscribe((checkpoint) => {
            this.initForm(checkpoint);
            this.form?.disable();
          });
        }
      } else {
        this.router.navigate(['checkpoints']);
      }
    });
  }

  initForm(dto?: CheckpointDetailDto): void {
    this.form = new FormGroup({
      id: new FormControl(dto?.id),
      name: new FormControl(dto?.name, [Validators.required]),
      number: new FormControl(dto?.number, [Validators.required]),
      description: new FormControl(dto?.description, [Validators.required]),
      checkpointGroupId: new FormControl(dto?.checkpointGroup?.id, [Validators.required]),
      deprecated: new FormControl(dto?.deprecated),
      buildingEquipmentRelated: new FormControl(dto?.buildingEquipmentRelated),
      assessmentPresets: this.formBuilder.array([]),
      weight: new FormControl(dto?.weight, [Validators.required, Validators.min(0), Validators.max(10)]),
      contractors: new FormControl(dto?.contractors?.map((contractor: ContractorItemDto) => ({ value: contractor.id }))),
    });
    if (dto) {
      dto.assessmentPresets?.forEach((assessmentPreset) => {
        const assessmentPresetFormGroup = new FormGroup({
          id: new FormControl(assessmentPreset.id),
          text: new FormControl({ value: assessmentPreset.text, disabled: true }),
          action: new FormControl({ value: assessmentPreset.action, disabled: true }, [Validators.required]),
          checkpointId: new FormControl({ value: assessmentPreset.checkpointId, disabled: true }),
          checkInterval: new FormControl({ value: assessmentPreset.checkInterval, disabled: true }),
          buildingEquipmentRelated: new FormControl({
            value: assessmentPreset.buildingEquipmentRelated,
            disabled: true,
          }),
          standardAssessment: new FormControl({ value: assessmentPreset.standardAssessment, disabled: true }),
        });
        this.actionChanged(assessmentPresetFormGroup);
        this.assessmentPresets.push(assessmentPresetFormGroup);
        this.subscribeToStandardAssessment(assessmentPresetFormGroup);
      });
    } else {
      const assessmentPreset = new FormGroup({
        id: new FormControl(undefined),
        text: new FormControl('Neuer Text...'),
        action: new FormControl(AssessmentPresetItemDto.ActionEnum.NoActionNecessary),
        checkpointId: new FormControl(undefined),
        checkInterval: new FormControl(this.Interval.None),
        standardAssessment: new FormControl(false),
      });
      this.actionChanged(assessmentPreset);
      this.assessmentPresets.push(assessmentPreset);
      this.subscribeToStandardAssessment(assessmentPreset);
    }
  }

  actionChanged(assessmentPreset: FormGroup): void {
    assessmentPreset.get('action')?.valueChanges.subscribe((action) => {
      if (action === AssessmentPresetItemDto.ActionEnum.ImminentDanger) {
        assessmentPreset.get('checkInterval')?.setValue(this.Interval.OneDay);
        assessmentPreset.get('checkInterval')?.disable();
      } else {
        assessmentPreset.get('checkInterval')?.enable();
      }
    });
  }

  get assessmentPresets(): FormArray {
    return this.form?.controls['assessmentPresets'] as FormArray;
  }

  deleteAssessment(index: number): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Vordefinierte Bewertung löschen',
        message: 'Bist du sicher, dass du diese vordefinierte Bewertung löschen willst?',
      },
    }).onClose.subscribe((result) => {
      if (result) {
        const id = this.assessmentPresets.at(index).value.id;
        if (id) {
          this.toDeleteAssessmentPreset.push(id);
        }
        this.assessmentPresets.removeAt(index);
        this.toastrService.success('Vordefinierte Bewertung wurde gelöscht.', 'Bewertung');
      }
    }, (error) => {
      this.toastrService.danger('Vordefinierte Bewertung konnte nicht gelöscht werden.', 'Bewertung');
    });
  }

  addAssessment(): void {
    const newAssessmentPreset = this.formBuilder.group({
      id: new FormControl(''),
      text: new FormControl('Neuer Text...'),
      action: new FormControl(AssessmentPresetItemDto.ActionEnum.NoActionNecessary),
      checkpointId: new FormControl(this.form?.controls['id'].value),
      checkInterval: new FormControl(this.Interval.None),
      buildingEquipmentRelated: new FormControl(false),
      standardAssessment: new FormControl(false),
    });
    this.assessmentPresets.insert(0, newAssessmentPreset);
    this.toastrService.success('Vordefinierte Bewertung wurde hinzugefügt.', 'Prüfpunkt');
  }

  hasError(controlName: string, errorName = 'required'): boolean {
    const control = this.form?.get(controlName);
    return this.isEditing && this.firstSubmit && (control?.hasError(errorName) ?? false) && ((control?.touched ?? false) || (control?.dirty ?? false));
  }

  onEdit(): void {
    this.isEditing = true;
    this.form?.enable();
  }

  async cancel(): Promise<void> {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['checkpoint', this.form?.value.id]);
    });
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['checkpoints']);
  }

  async submit(): Promise<void> {
    this.firstSubmit = true;
    this.form?.markAsTouched();

    if (!this.form?.valid) {
      this.toastrService.danger('Prüfpunkt kann nicht gespeichert werden, es gibt noch Fehler.', 'Prüfpunkt');
      return;
    }

    try {
      this.saving$.next(true);
      const checkpointDto = this.form?.value as CheckpointMergeDto;
      const contractors = this.form?.value.contractors;
      checkpointDto.contractorIds = contractors?.map((contractor: IdReferenceDto) => contractor.value) ?? [];
      const checkpoint = await firstValueFrom(this.checkpointService.merge(checkpointDto));

      const deleteAssessmentPresetPromises = this.toDeleteAssessmentPreset.map((id) => {
        firstValueFrom(this.assessmentPresetService.deleteById(id));
      });

      await Promise.all(deleteAssessmentPresetPromises);

      const mergeAssessmentPresetPromises = this.form?.value.assessmentPresets.map((assessmentPreset: any) => {
        assessmentPreset.checkpointId = checkpoint.id;
        firstValueFrom(this.assessmentPresetService.merge(assessmentPreset));
      });

      await Promise.all(mergeAssessmentPresetPromises);

      this.isEditing = false;
      this.store.dispatch(new LoadAllCheckpoints());
      await this.router.navigateByUrl('/', { skipLocationChange: true });
      await this.router.navigate(['checkpoint', checkpoint.id]);
      this.toastrService.success('Prüftpunkt wurde erfolgreich gespeichert', 'Prüftpunkt');
    } catch (e) {
      console.error(e);
      this.toastrService.danger('Prüfpunkt konnte nicht gespeichert werden', 'Fehler');
    } finally {
      this.saving$.next(false);
    }
  }

  onDelete(): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Prüfpunkt löschen',
        message: 'Bist du sicher, dass du diesen Prüfpunkt löschen willst?',
      },
    }).onClose.subscribe((result) => {
      if (result) {
        this.checkpointService.deleteById(this.form?.value.id).subscribe(() => {
          this.store.dispatch(new LoadAllCheckpoints());
          this.router.navigate(['checkpoints']);
          this.toastrService.success('Prüfpunkt wurde gelöscht', 'Prüfpunkt');
        });
      }
    }, (error) => {
      this.toastrService.danger('Prüfpunkt konnte nicht gelöscht werden.', 'Prüfpunkt');
    });
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.isEditing;
  }

  subscribeToStandardAssessment(assessmentPreset: FormGroup): void {
    assessmentPreset.get('standardAssessment')?.valueChanges.subscribe((isStandard) => {
      if (isStandard) {
        this.assessmentPresets.controls.forEach((preset) => {
          if (preset !== assessmentPreset) {
            preset.get('standardAssessment')?.setValue(false);
          }
        });
      }
    });
  }
}
