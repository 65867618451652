<div class="building-list">
  <div class="building" *ngFor="let building of buildings()">
    <div class="building-name">{{ building.sectionText }}</div>
    <div class="maintenance-timer-list">
      <wr-maintenance-timer-item-card *ngFor="let maintenanceTimer of maintenanceTimersGrouped()[building.id]"
                                      [maintenanceTimer]="maintenanceTimer"
                                      (edit)="editMaintenanceTimer($event.maintenanceTimer)"
                                      (delete)="deleteMaintenanceTimer($event.maintenanceTimer)"
      ></wr-maintenance-timer-item-card>

      <div class="new-timer-button" (click)="addMaintenanceTimer.next({buildingId: building.id})">
        <nb-icon icon="plus-outline" pack="eva"></nb-icon>
      </div>
    </div>
  </div>
</div>
