import { Component, forwardRef } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { OrganisationDetailDto } from '@artemis-software/wr-api';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import OrganisationTypeEnum = OrganisationDetailDto.OrganisationTypeEnum;

@Component({
  selector: 'wr-organisation-type-select',
  templateUrl: './organisation-type-select.component.html',
  styleUrls: ['./organisation-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganisationTypeSelectComponent),
      multi: true,
    },
  ],
})
export class OrganisationTypeSelectComponent extends AbstractFormControl<OrganisationTypeEnum> {
  readonly OrganisationType = OrganisationTypeEnum;
  OrganisationTypes = [
    this.OrganisationType.Admin,
    this.OrganisationType.Cooperative,
    this.OrganisationType.PropertyManager,
  ];
}
