import { Component, Input } from '@angular/core';
import { NbComponentSize, NbComponentStatus, NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'wr-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
})
export class BaseDialogComponent<Yes, No> {
  @Input()
  status: NbComponentStatus = 'basic';
  @Input()
  header = '';
  @Input()
  size: NbComponentSize | '' = '';
  @Input()
  dialogWidth: NbComponentSize = 'medium';

  @Input()
  showCancelButton = true;
  @Input()
  cancelText = 'Zurück';
  @Input()
  cancelResponse: () => No | boolean = () => false;
  @Input()
  showSubmitButton = true;
  @Input()
  submitText = 'Bestätigen';
  @Input()
  submitResponse: () => Yes | boolean = () => true;

  @Input()
  disableSubmit = false;

  constructor(
    private readonly nbDialogRef: NbDialogRef<unknown>,
  ) {
  }

  async submit(): Promise<Yes | boolean> {
    const response = await this.submitResponse();
    this.nbDialogRef.close(response);
    return response;
  }

  cancel(): No | boolean {
    const response = this.cancelResponse();
    this.nbDialogRef.close(response);
    return response;
  }
}
