import { Component } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { TagItemDto, TagService } from '@artemis-software/wr-api';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { NbMenuItem, NbMenuService, NbTagComponent } from '@nebular/theme';
import { Select } from '@ngxs/store';
import { TagState } from '@/store/tag/tag.state';
import {getColor} from '@/utils/tag-utils';

@Component({
  selector: 'wr-multi-tag-select',
  templateUrl: './tag-multi-select.component.html',
  styleUrls: ['./tag-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TagMultiSelectComponent,
      multi: true,
    },
  ],
})
export class TagMultiSelectComponent extends AbstractFormControl<TagItemDto[]> {

  readonly contextMenuTag = 'tagButtonAdd';

  @Select(TagState.tags)
  tags$!: Observable<TagItemDto[]>;
  contextMenu$: Observable<NbMenuItem[] | undefined>;

  constructor(private readonly tagService: TagService,
    private readonly nbMenuService: NbMenuService) {
    super();
    this.contextMenu$ = combineLatest([this.tags$, this.value$])
      .pipe(
        map(([allTags, selectedTags]) => allTags
          .filter(tag => !selectedTags || !selectedTags?.find(t => t.name === tag.name))
          .map(tag => {
            return {
              title: tag.name,
              data: tag,
            };
          })),
        map(menuItems => menuItems.length > 0 ? menuItems : undefined),
      );
    this.nbMenuService.onItemClick().pipe(filter(menu => menu.tag === this.contextMenuTag)).subscribe(menu => {
      const copy = [...this.value ?? []];
      copy.push(menu.item.data);
      this.value = copy;
    });
  }

  onTagRemove(tagToRemove: NbTagComponent): void {
    this.tags$.subscribe(tags => {
      const tag = tags.find(t => t.name === tagToRemove.text);
      if (tag && this.value) {
        const copy = [...this.value];
        copy.splice(copy.findIndex(t => t.name === tagToRemove.text), 1);
        this.value = copy;
      }
    });
  }

  protected readonly getColor = getColor;
}
