/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuildingEquipmentItemDto } from './buildingEquipmentItemDto';
import { InspectionEntryItemDto } from './inspectionEntryItemDto';
import { ContractorItemDto } from './contractorItemDto';


export interface RepairTaskItemDto { 
    id: string;
    title: string;
    description: string;
    status: RepairTaskItemDto.StatusEnum;
    deadline?: string;
    buildingEquipment?: BuildingEquipmentItemDto;
    inspectionEntry?: InspectionEntryItemDto;
    contractor?: ContractorItemDto;
}
export namespace RepairTaskItemDto {
    export type StatusEnum = 'DRAFT' | 'TODO' | 'IN_PROGRESS' | 'FINISHED' | 'DECLINED';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Todo: 'TODO' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Finished: 'FINISHED' as StatusEnum,
        Declined: 'DECLINED' as StatusEnum
    };
}


