import { Component, computed, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { BuildingState } from '@/store/building/building.state';
import { BuildingDetailDto, BuildingItemDto, BuildingService, OrganisationDetailDto } from '@artemis-software/wr-api';
import { of } from 'rxjs';
import { SelectDashboardBuilding } from '@/store/building/building.action';
import { select } from '@/utils/signals/select';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { Router } from '@angular/router';
import { currentUser } from '@/utils/admin-utils';
import OrganisationTypeEnum = OrganisationDetailDto.OrganisationTypeEnum;

@Component({
  selector: 'wr-building-dashboard-header',
  templateUrl: './building-dashboard-header.component.html',
  styleUrls: ['./building-dashboard-header.component.scss'],
})
export class BuildingDashboardHeaderComponent {

  readonly store = inject(Store);
  readonly buildingService = inject(BuildingService);
  readonly router = inject(Router);
  readonly currentUser = currentUser();
  readonly isPropertyManager = computed(() => this.currentUser()?.organisationType === OrganisationTypeEnum.PropertyManager);
  currentOrganisationId = computed(() => {
    if (this.currentUser()?.organisationType == OrganisationTypeEnum.Cooperative) {
      return this.currentUser()?.organisation.id;
    }
    return undefined;
  });

  readonly selectedDashboardBuilding = select(BuildingState.selectedDashboardBuilding);

  readonly buildingDetail = asyncComputed(() => {
    const building = this.selectedDashboardBuilding();
    if (!building) {
      return of(undefined);
    }
    return this.buildingService.findById(building.id);
  });

  selectDashboardBuilding(building: BuildingItemDto) {
    this.store.dispatch(new SelectDashboardBuilding(building));
  }

  getPropertyManager(building: BuildingDetailDto): string {
    const firstName = building.propertyManager?.firstName || building.embeddedPropertyManager?.firstName;
    const lastName = building.propertyManager?.lastName || building.embeddedPropertyManager?.lastName;
    return `${firstName} ${lastName}`;
  }

  editBuilding(building: BuildingDetailDto) {
    this.router.navigate(['building', building.id]);
  }
}
