/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TemplateDetailDto } from './templateDetailDto';
import { InspectionEntryDetailDto } from './inspectionEntryDetailDto';
import { BuildingItemDto } from './buildingItemDto';
import { BuildingSectionImagesDto } from './buildingSectionImagesDto';
import { AttachmentDetailDto } from './attachmentDetailDto';
import { UserItemDto } from './userItemDto';


export interface InspectionDetailDto { 
    id: string;
    name: string;
    description: string;
    building: BuildingItemDto;
    technician: UserItemDto;
    clerk?: UserItemDto;
    fromTimestamp: string;
    toTimestamp: string;
    sendingTimestamp?: string;
    continuationNumber: number;
    version: number;
    normType: InspectionDetailDto.NormTypeEnum;
    status: InspectionDetailDto.StatusEnum;
    inspectionEntries: Array<InspectionEntryDetailDto>;
    attachments: Array<AttachmentDetailDto>;
    coverImage?: AttachmentDetailDto;
    locationReplacement?: AttachmentDetailDto;
    lastSavedTimeStamp: string;
    usedTemplate?: TemplateDetailDto;
    buildingSectionImagesDto?: Array<BuildingSectionImagesDto>;
}
export namespace InspectionDetailDto {
    export type NormTypeEnum = 'NORM_1300' | 'NORM_1301';
    export const NormTypeEnum = {
        _1300: 'NORM_1300' as NormTypeEnum,
        _1301: 'NORM_1301' as NormTypeEnum
    };
    export type StatusEnum = 'DRAFT' | 'TODO' | 'IN_PROGRESS' | 'IN_REVIEW' | 'DONE' | 'SENT' | 'NONE';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Todo: 'TODO' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        InReview: 'IN_REVIEW' as StatusEnum,
        Done: 'DONE' as StatusEnum,
        Sent: 'SENT' as StatusEnum,
        None: 'NONE' as StatusEnum
    };
}


