<div class="detail-container">
  <nb-card [nbSpinner]="!!(saving$|async)">
    <nb-card-header>
      <button nbButton ghost status="primary" (click)="navigateBack()">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>Vorlage</h5>
      <wr-edit-delete-button-group (edit)="onEdit()" (delete)="onDelete()"></wr-edit-delete-button-group>
    </nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="form">
        <nb-tabset>
          <nb-tab tabTitle="Grunddaten">
            <form [formGroup]="form">
              <div>
                <ng-container>
                  <div class="grid">
                    <div class="col-6 md:col-4">
                      <label class="label" for="templateName">Name der Vorlage</label>
                      <input fullWidth id="templateName" nbInput formControlName="name">
                      <div class="error" *ngIf="hasError('name')">Name der Vorlage ist erforderlich!</div>
                    </div>
                  </div>
                </ng-container>

                <ng-container>
                  <nb-card size="large">
                    <nb-card-header class="header-flex">
                      <h6>Prüfpunkte</h6>
                      <button nbButton
                              ghost
                              status="primary"
                              nbTooltip="Prüfpunkt hinzufügen"
                              [disabled]="!isEditing"
                              (click)="showAddCheckpointDialog()">
                        <nb-icon icon="plus-outline" pack="eva"></nb-icon>
                      </button>
                    </nb-card-header>

                    <nb-card-body class="no-padding">
                      <ng-container *ngFor="let group of (checkpointGroups$ | async)">
                        <ng-container *ngIf="getFilteredCheckpoints(group.number).length > 0">
                          <div class="group-header" (click)="toggleGroupVisibility(group.number)">
                            <span>{{ group.number }}. {{ group.name }}</span>
                            <button nbButton ghost status="basic" class="toggle-button">
                              <nb-icon
                                [icon]="isGroupVisible(group.number) ? 'chevron-up-outline' : 'chevron-down-outline'"
                                pack="eva"></nb-icon>
                            </button>
                          </div>
                          <ng-container *ngIf="isGroupVisible(group.number)">
                            <ng-container
                              *ngFor="let checkpoint of getFilteredCheckpoints(group.number); let i = index">
                              <div [formGroup]="checkpoint" class="checkpoint-item-wrapper">
                                <div class="grid">
                                  <div class="col-12 md:col-12">
                                    <div class="checkpoint-item">
                                      <span>{{ checkpoint.value.group }}
                                        .{{ checkpoint.value.number }} {{ checkpoint.value.name }}</span>
                                      <button nbButton ghost status="basic"
                                              (click)="deleteCheckpoint(checkpoint.value.id)" [disabled]="!isEditing">
                                        <nb-icon icon="close-outline" pack="eva"></nb-icon>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </nb-card-body>
                  </nb-card>

                </ng-container>
              </div>
            </form>
          </nb-tab>
        </nb-tabset>
      </ng-container>
    </nb-card-body>
    <nb-card-footer>
      <button size="medium" [disabled]="!isEditing" nbButton status="primary" (click)="submit()">Speichern</button>
      <button size="medium" [disabled]="!isEditing" nbButton status="basic" (click)="cancel()">Zurück</button>
    </nb-card-footer>
  </nb-card>
</div>
