import { UserFilterDto } from '@artemis-software/wr-api';

export class LoadAllUsers {
  static readonly type = '[User] Load all Users';
}

export class FilterUsers {
  static readonly type = '[User] Filter Users';
}

export class CountUsers {
  static readonly type = '[User] Count users';
}

export class LoadCurrentUser {
  static readonly type = '[User] Load current user';
}

export class ChangeUserFilter {
  static readonly type = '[User] Change User Filter';

  constructor(public filter: UserFilterDto) {
  }
}
