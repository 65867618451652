import { Component, Input } from '@angular/core';
import { InspectionDetailDto } from '@artemis-software/wr-api';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { BehaviorSubject } from 'rxjs';
import { InspectionFormWrapper } from '@pages/inspection-detail-page-v2/inspection-form';

@Component({
  selector: 'wr-meta-data',
  templateUrl: './meta-data.component.html',
})
export class MetaDataComponent {

  @Input()
  inspection!: InspectionDetailDto;

  @Input()
  formWrapper!: InspectionFormWrapper;

  selectedSection$ = new BehaviorSubject<BuildingSectionDetailDto | undefined>(undefined);
}
