import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NbComponentStatus } from "@nebular/theme";

interface Checkable {
  name: string;
  checked?: boolean;
  icon?: {
    name: string;
    pack: string;
    status: NbComponentStatus;
    tooltip: string;
  };
}

export interface CheckboxGroup<T> extends Checkable {
  items: CheckboxItem<T>[];
  collapsed?: boolean;
  indeterminate?: boolean;
}

export interface CheckboxItem<T> extends Checkable {
  item: T;
}

@Component({
  selector: 'wr-grouped-checkbox-list',
  templateUrl: './grouped-checkbox-list.component.html',
  styleUrls: ['./grouped-checkbox-list.component.scss'],
})
export class GroupedCheckboxListComponent<T> {

  @Input() groups: CheckboxGroup<T>[] = [];

  @Output() groupsChange = new EventEmitter<CheckboxGroup<T>[]>();

  groupChecked(group: CheckboxGroup<T>, checked: boolean) {
    group.checked = checked;
    group.indeterminate = false;
    group.items.forEach(item => item.checked = checked);
    this.groupsChange.emit(this.groups);
  }

  itemChecked(group: CheckboxGroup<T>, item: CheckboxItem<T>, checked: boolean) {
    item.checked = checked;
    const allChecked = group.items.every(i => i.checked);
    const allUnchecked = group.items.every(i => !i.checked);
    if (allChecked) {
      group.checked = true;
      group.indeterminate = false;
    } else if (allUnchecked) {
      group.checked = false;
      group.indeterminate = false;
    } else {
      group.indeterminate = true;
    }
    this.groupsChange.emit(this.groups);
  }

  toggleCollapse(group: CheckboxGroup<T>): void {
    group.collapsed = !group.collapsed;
  }
}
