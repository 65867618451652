<div class="debug-page">
  <nb-card>
    <nb-card-header>Environment</nb-card-header>
    <nb-card-body>
      <p><b>Tag: </b>{{ currentEnvironment.tag }}</p>
      <p><b>Api-Url: </b>{{ currentEnvironment.apiUrl }}</p>
      <p><b>Store-Version: </b>{{ currentEnvironment.storeVersion }}</p>
    </nb-card-body>
  </nb-card>

  <nb-card>
    <nb-card-header>Sentry</nb-card-header>
    <nb-card-body>
      <button nbButton (click)="throwTestError()">Test Sentry Error</button>
    </nb-card-body>
  </nb-card>
</div>
