import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ChangeCheckpointFilter, CountCheckpoints, FilterCheckpoints, LoadAllCheckpoints } from './checkpoint.actions';
import { CheckpointFilterDto, CheckpointItemDto, CheckpointService } from '@artemis-software/wr-api';
import { AuthService } from '@services/auth.service';

type CheckpointStateModel = {
  checkpoints: CheckpointItemDto[],
  filteredCheckpoints: CheckpointItemDto[],
  count: number,
  loading: boolean,
  filter: CheckpointFilterDto,
};

@State<CheckpointStateModel>({
  name: 'checkpoints',
  defaults: {
    checkpoints: [],
    filteredCheckpoints: [],
    count: 0,
    loading: false,
    filter: {} as CheckpointFilterDto,
  },
})

@Injectable()
export class CheckpointState implements NgxsOnInit {

  constructor(private readonly store: Store,
    private readonly checkpointService: CheckpointService,
    private readonly authService: AuthService) {
  }

  ngxsOnInit(): void {
    this.authService.loggedIn$.subscribe(() => {
      this.store.dispatch(new LoadAllCheckpoints());
    });
  }

  @Selector()
  static checkpoints(state: CheckpointStateModel): CheckpointItemDto[] {
    return state.checkpoints;
  }

  @Selector()
  static filteredCheckpoints(state: CheckpointStateModel): CheckpointItemDto[] {
    return state.filteredCheckpoints;
  }

  @Selector()
  static checkpointsLoading(state: CheckpointStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static count(state: CheckpointStateModel): number {
    return state.count;
  }

  @Selector()
  static filter(state: CheckpointStateModel): CheckpointFilterDto {
    return state.filter;
  }

  @Action(LoadAllCheckpoints)
  loadAllCheckpoints(ctx: StateContext<CheckpointStateModel>, action: LoadAllCheckpoints): void {
    ctx.patchState({
      loading: true,
    });

    this.checkpointService.findAll({}).subscribe((checkpoints: CheckpointItemDto[]) => {
      ctx.patchState({
        checkpoints,
        loading: false,
      });
    });
  }

  @Action(FilterCheckpoints)
  filterCheckpoints(ctx: StateContext<CheckpointStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.checkpointService.findAll(ctx.getState().filter).subscribe((checkpoints: CheckpointItemDto[]) => {
      ctx.patchState({
        filteredCheckpoints: checkpoints,
        loading: false,
      });
    });
  }

  @Action(CountCheckpoints)
  countCheckpoints(ctx: StateContext<CheckpointStateModel>): void {
    ctx.patchState({
      loading: true,
    });

    this.checkpointService.getCount(ctx.getState().filter).subscribe((count: number) => {
      ctx.patchState({
        count: count,
        loading: false,
      });
    });
  }

  @Action(ChangeCheckpointFilter)
  changeCheckpointFilter(ctx: StateContext<CheckpointStateModel>, action: ChangeCheckpointFilter): void {
    const filter = { ...ctx.getState().filter, ...action.filter };
    ctx.patchState({
      filter: filter,
    });
    ctx.dispatch(new CountCheckpoints());
    ctx.dispatch(new FilterCheckpoints());
  }
}
