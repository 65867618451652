import { Component, Input } from '@angular/core';
import { FileUploadService } from '@services/file-upload.service';
import { AttachmentDetailDto } from '@artemis-software/wr-api';
import { NbToastrService } from '@nebular/theme';
import { Attachment, AttachmentUploadJob, FormControlContext, PendingAttachment } from '../attachment-utils';

@Component({
  selector: 'wr-attachment-upload-section[formControlContext]',
  templateUrl: './attachment-upload-section.component.html',
  styleUrls: ['./attachment-upload-section.component.scss'],
})
export class AttachmentUploadSectionComponent {

  @Input()
  type: AttachmentDetailDto.TypeEnum = AttachmentDetailDto.TypeEnum.File;
  @Input()
  formControlContext!: FormControlContext;

  private _attachments: Attachment[] = [];

  @Input()
  set attachments(attachments: Attachment[] | undefined) {
    this._attachments = attachments ? [...attachments] : [];
  }

  get attachments(): Attachment[] {
    return this._attachments;
  }

  constructor(
    private fileUploadService: FileUploadService,
    private nbToastrService: NbToastrService,
  ) {
  }

  async upload(file: File): Promise<void> {
    const split = file.name.split('.');
    const pendingAttachment: PendingAttachment = {
      type: this.getType(file.type),
      fileName: split[0],
      fileEnding: split[1] ?? '',
      rotationDegrees: 0,
      tags: [],
      created: '',
      comment: '',
      deleted: false,
    };

    const currentAttachments = this.attachments;
    const currentEventLister = this.formControlContext.attachmentChangeEventListener;
    const currentPendingJobs = this.formControlContext.pendingJobs$;

    const subscription = this.fileUploadService.uploadSingleFileNoProgress(file).subscribe(response => {
      // this block is asynchronous, so we need to make sure the correct context is used
      const uploadedAttachment: Attachment = {
        ...pendingAttachment,
        s3key: response.key,
        presignedUrl: response.publicUrl,
      };
      currentAttachments.push(uploadedAttachment);
      currentEventLister?.emit([...currentAttachments]);
      currentPendingJobs.next(currentPendingJobs.value.filter(j => j.subscription !== subscription));
    });
    const job: AttachmentUploadJob = { subscription, attachment: pendingAttachment };
    currentPendingJobs.next([...currentPendingJobs.value, job]);
  }

  cancelJob(job: AttachmentUploadJob): void {
    job.subscription.unsubscribe();
    this.nbToastrService.warning('Upload abgebrochen', 'Upload abgebrochen');
    const pendingJobs = this.formControlContext?.pendingJobs$;
    pendingJobs?.next(pendingJobs?.value.filter(j => j !== job));
  }

  private getType(type: string): AttachmentDetailDto.TypeEnum {
    if (type.startsWith('image')) {
      return AttachmentDetailDto.TypeEnum.Image;
    }
    return AttachmentDetailDto.TypeEnum.File;
  }
}
