import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BuildingEquipmentState } from '@/store/buildingEquipment/buildingEquipment.state';
import { Router } from '@angular/router';
import { BuildingEquipmentItemDto } from '@artemis-software/wr-api';
import { ChangeBuildingEquipmentFilter } from '@/store/buildingEquipment/buildingEquipment.action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BuildingDetailDto } from '@artemis-software/wr-api/model/buildingDetailDto';
import { defineStoreUsage } from '@/utils/storeUsage';

@Component({
  selector: 'wr-property-manager-building-equipment-overview-page',
  templateUrl: './property-manager-building-equipment-overview-page.component.html',
  styleUrls: ['./property-manager-building-equipment-overview-page.component.scss'],
})
export class PropertyManagerBuildingEquipmentOverviewPageComponent {

  buildingEquipmentStoreUsage = defineStoreUsage({
    filterAction: ChangeBuildingEquipmentFilter,
    countSelector: BuildingEquipmentState.count,
    loadingSelector: BuildingEquipmentState.buildingEquipmentsLoading
  });

  @Select(BuildingEquipmentState.filteredBuildingEquipments)
  buildingEquipments$!: Observable<BuildingEquipmentItemDto[]>;
  @Select(BuildingEquipmentState.count)
  count$!: Observable<number>;
  @Select(BuildingEquipmentState.buildingEquipmentsLoading)
  loading$!: Observable<boolean>;
  form!: FormGroup;

  constructor(private readonly store: Store,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem('propertyManagerBuildingEquipmentFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
      this.addSpecialFields(filterValue.building);
    } else {
      this.initFilterForm('');
    }
    this.triggerFilter();
  }

  initFilterForm(text: string): void {
    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      organisationId: this.formBuilder.control(undefined),
      buildingId: this.formBuilder.control(''),
      building: this.formBuilder.control(undefined),
      isPropertyManager: this.formBuilder.control(true)
    });
    this.form.get('building')?.valueChanges.subscribe((building: BuildingDetailDto) => {
      this.form.get('buildingId')?.setValue(building?.id);
    });
  }

  async addSpecialFields(building: BuildingDetailDto): Promise<void> {
    this.form.patchValue({
      'building': building,
    });
  }

  navigateToBuildingEquipment(buildingEquipment: BuildingEquipmentItemDto): void {
    this.router.navigate(['buildingEquipment', buildingEquipment.id]);
  }

  triggerFilter(): void {
    this.store.dispatch(new ChangeBuildingEquipmentFilter(this.form.value));
    localStorage.setItem('propertyManagerBuildingEquipmentFilter', JSON.stringify(this.form.value));
  }
}
