import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@/app.module';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://544b57686b144a959c9cd6d203376b6f@o4504548216733696.ingest.sentry.io/4504548784472064',
  environment: environment.tag,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (environment.tag === 'production' || environment.tag === 'staging') {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));
