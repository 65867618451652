/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InspectionEntryCommentUpdateDto } from './inspectionEntryCommentUpdateDto';
import { AttachmentMergeDto } from './attachmentMergeDto';


export interface InspectionEntryMergeDto { 
    id?: string;
    note?: string;
    deadline?: InspectionEntryMergeDto.DeadlineEnum;
    action?: InspectionEntryMergeDto.ActionEnum;
    predefinedAssessmentId?: string;
    inspectionId?: string;
    checkpointId?: string;
    attachments?: Array<AttachmentMergeDto>;
    buildingSectionId?: string;
    buildingEquipmentIds?: Array<string>;
    comments?: Array<InspectionEntryCommentUpdateDto>;
}
export namespace InspectionEntryMergeDto {
    export type DeadlineEnum = 'YEAR' | 'HALF_YEAR' | 'ONE_MONTH' | 'NONE' | 'ONE_DAY';
    export const DeadlineEnum = {
        Year: 'YEAR' as DeadlineEnum,
        HalfYear: 'HALF_YEAR' as DeadlineEnum,
        OneMonth: 'ONE_MONTH' as DeadlineEnum,
        None: 'NONE' as DeadlineEnum,
        OneDay: 'ONE_DAY' as DeadlineEnum
    };
    export type ActionEnum = 'IMMINENT_DANGER' | 'ACTION_NECESSARY' | 'RENOVATION_NECESSARY' | 'ACTION_RECOMMENDED' | 'RISK_ANALYSIS_RECOMMENDED' | 'NO_ACTION_NECESSARY';
    export const ActionEnum = {
        ImminentDanger: 'IMMINENT_DANGER' as ActionEnum,
        ActionNecessary: 'ACTION_NECESSARY' as ActionEnum,
        RenovationNecessary: 'RENOVATION_NECESSARY' as ActionEnum,
        ActionRecommended: 'ACTION_RECOMMENDED' as ActionEnum,
        RiskAnalysisRecommended: 'RISK_ANALYSIS_RECOMMENDED' as ActionEnum,
        NoActionNecessary: 'NO_ACTION_NECESSARY' as ActionEnum
    };
}


