<ng-container *ngIf="inspectionPdfs$|async as inspectionPdfs">
  <nb-alert>
    <div class="info-alert">
      <nb-icon icon="info-outline" pack="eva"></nb-icon>
      <span>Dokumente werden ca. 30 Tage nach der letzten Generierung gelöscht!</span>
    </div>
  </nb-alert>

  <div class="pdf-options">
    <wr-inspection-pdf-entry *ngFor="let option of pdfOptions"
                             [label]="option.label"
                             [inspectionPdfType]="option.type"
                             [inspection]="inspection"
                             [inspectionPdf$]="getInspectionPdf(option.type)">
    </wr-inspection-pdf-entry>
  </div>
</ng-container>
