import { Component, forwardRef, Input } from '@angular/core';
import { RepairTaskDetailDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '@shared/form-controls/abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-repair-task-status-select',
  templateUrl: './repair-task-status-select.component.html',
  styleUrls: ['./repair-task-status-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RepairTaskStatusSelectComponent),
      multi: true,
    },
  ],
})
export class RepairTaskStatusSelectComponent extends AbstractFormControl<RepairTaskDetailDto.StatusEnum> {
  enumValues = Object.values(RepairTaskDetailDto.StatusEnum);
  @Input()
  readonly = false;

  @Input()
  showAll = false;

}
