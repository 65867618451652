<div class="maintenance-timer" [formGroup]="form()" [nbSpinner]="isLoading()">
  <div *ngIf="isEditing(); else display">
    <div>
      <nb-select formControlName="type" fullWidth>
        <nb-option [value]="TypeEnum.Maintenance">{{TypeEnum.Maintenance | maintenanceTimerType}}</nb-option>
        <nb-option [value]="TypeEnum.Inspection">{{TypeEnum.Inspection | maintenanceTimerType}}</nb-option>
      </nb-select>
    </div>
    <div>
      <hau-form-field label="Startdatum" [validation]="form().validation.startDate">
        <wr-date-picker formControlName="startDate" />
      </hau-form-field>
      <hau-form-field label="Dauer (Tage)" [validation]="form().validation.durationDays">
        <input nbInput type="number" fullWidth formControlName="durationDays" [valueAsNumber]="true" />
      </hau-form-field>
      <hau-form-field label="Erinnerung (Tage)" [validation]="form().validation.reminderDurationDays">
        <input nbInput type="number" fullWidth formControlName="reminderDurationDays" [valueAsNumber]="true">
      </hau-form-field>
      <hau-form-field label="Nächste {{form().value.type! | maintenanceTimerType}}">
        {{ nextDate | date: 'dd.MM.yyyy' }}
      </hau-form-field>
      <hau-form-field label="Erinnerung">
        {{ reminderDate | date: 'dd.MM.yyyy' }}
      </hau-form-field>
    </div>
    <div class="footer">
      <button nbButton size="small" *ngIf="showCancelButton" (click)="cancelEditing()">Abbrechen</button>
      <button nbButton status="primary" size="small" (click)="save()">Speichern</button>
    </div>
  </div>
  <ng-template #display>
    <div *ngIf="maintenanceTimer">
      <div class="duration-days">
        {{ daysLeft() }} {{ daysLeft() | pluralize: 'Tag': 'Tage' }}
      </div>
      <div class="type">{{ maintenanceTimer.type | maintenanceTimerType }}</div>
      <div>
        <div class="label-and-text">
          <label class="label">Dauer</label>
          <span>{{ maintenanceTimer.durationDays }} {{ maintenanceTimer.durationDays | pluralize: 'Tag': 'Tage' }}</span>
        </div>
        <div class="label-and-text">
          <label class="label">Nächste {{ maintenanceTimer.type | maintenanceTimerType }}</label>
          <span>{{ maintenanceTimer.nextDate | date: 'dd.MM.yyyy' }}</span>
        </div>
        <div class="label-and-text">
          <label class="label">Erinnerung</label>
          <span>{{ maintenanceTimer.reminderDate | date: 'dd.MM.yyyy' }}</span>
        </div>
        <div class="label-and-text">
          <label class="label">Wiederholungen</label>
          <span>{{maintenanceTimer.iterations}}</span>
        </div>
        <div class="label-and-text">
          <label class="label">Letzte Verspätung</label>
          <span>{{maintenanceTimer.overdueDays}} {{maintenanceTimer.overdueDays | pluralize: 'Tag': 'Tage'}}</span>
        </div>
      </div>
      <div class="footer" *ngIf="isAdmin()">
        <button nbButton size="medium" status="basic" (click)="finishMaintenanceTimer()">
          <nb-icon icon="checkmark-circle-2-outline" pack="eva"></nb-icon>
        </button>
        <button nbButton size="medium" status="basic" (click)="startEdit()">
          <nb-icon icon="edit-outline" pack="eva"></nb-icon>
        </button>
        <button nbButton size="medium" status="basic" (click)="showCheckDates()" nbTooltip="Wiederholungen">
          <nb-icon icon="calendar-outline" pack="eva"></nb-icon>
        </button>
        <button nbButton size="medium" status="basic" (click)="deleteEntity()">
          <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
        </button>
      </div>
    </div>
  </ng-template>
</div>
