import { Component, EventEmitter, Input, Output } from '@angular/core';
import { createForm } from '@sonofabit/ng-core';
import { Validators } from '@angular/forms';
import { CoordinatesDto } from '@artemis-software/wr-api';

export const zeroCoordinates = () => ({ latitude: 0, longitude: 0 });

@Component({
  selector: 'wr-coordinate-dialog',
  templateUrl: './coordinate-dialog.component.html',
  styleUrls: ['./coordinate-dialog.component.scss'],
})
export class CoordinateDialogComponent {
  @Input()
  title = '';
  @Input()
  coordinates: CoordinatesDto = zeroCoordinates();
  @Output()
  submit = new EventEmitter<{ latitude: number, longitude: number }>();

  firstSubmit = false;

  form?: ReturnType<typeof this.initForm>;

  ngOnInit() {
    this.form = this.initForm();
  }

  initForm() {
    return createForm(({ field }) => {
      return {
        latitude: field({
          value: this.coordinates.latitude,
          validators: [Validators.required],
        }),
        longitude: field({
          value: this.coordinates.longitude,
          validators: [Validators.required],
        }),
      };
    });
  }

  submitCoordinates() {
    this.firstSubmit = true;
    if (this.form && this.form.valid) {
      return {
        latitude: this.form.value.latitude,
        longitude: this.form.value.longitude,
      };
    }
    throw new Error('Latitude and/or longitude is empty');
  }

  hasError(controlName: string): boolean {
    const control = this.form?.get(controlName);
    return !!control?.errors;
  }
}
