import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InspectionDetailDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '../abstract-form-control';

@Component({
  selector: 'wr-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusSelectComponent),
      multi: true,
    },
  ],
})
export class StatusSelectComponent extends AbstractFormControl<InspectionDetailDto.StatusEnum> {
  readonly Status = InspectionDetailDto.StatusEnum;

  @Input()
  showEmptyOption = false;
  @Input()
  showNotAssignedOption = false;
}
