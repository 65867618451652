<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="check-to-slot"></nb-icon>
          Prüfpunkt Gruppen
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
              <button nbButton ghost status="primary" (click)="add()">
                <nb-icon pack="eva" icon="plus-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
  [data$]="checkpointGroups$"
  (lineClick)="navigateToUser($event)"
  [sortKey]="'checkpointGroup'"
  [storeUsage]="checkpointGroupStoreUsage"
  [defaultSort]="'number'"
  >
    <ng-template
      dataColumn
      let-checkpointGroup
      key="number"
      display="Nummer">
      <div *ngIf="checkpointGroup.number">
        {{checkpointGroup.number}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-checkpointGroup
      key="name"
      display="Gruppenname">
      <div *ngIf="checkpointGroup.name">
        {{cleanName(checkpointGroup.name)}}
      </div>
    </ng-template>
  </wr-data-table>
</div>

