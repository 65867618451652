import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { BuildingState } from '@/store/building/building.state';
import { Observable } from 'rxjs';
import { BuildingItemDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-building-dashboard-page',
  templateUrl: './building-dashboard-page.component.html',
  styleUrls: ['./building-dashboard-page.component.scss'],
})
export class BuildingDashboardPageComponent {
  @Select(BuildingState.selectedDashboardBuilding)
  selectedDashboardBuilding$!: Observable<BuildingItemDto | undefined>;
}
