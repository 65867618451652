import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { CheckpointState } from './checkpoint/checkpoint.state';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { UserState } from './user/user.state';
import { CheckpointGroupState } from './checkpointGroup/checkpointGroup.state';
import { AddressState } from './address/address.state';
import { OrganisationState } from './organisation/organisation.state';
import { BuildingState } from './building/building.state';
import { EquipmentState } from './equipment/equipment.state';
import { BuildingEquipmentState } from './buildingEquipment/buildingEquipment.state';
import { InspectionState } from './inspection/inspection.state';
import { TemplateState } from './template/template.state';
import { BuildingSectionState } from './buildingSection/buildingSection.state';
import { VersionState } from './version/version.state';
import { TagState } from './tag/tag.state';
import { InspectionPdfState } from './inspectionPdf/inspectionPdf.state';
import { ContractorState } from '@/store/contractor/contractor.state';
import { AttachmentState } from '@/store/attachment/attachment.state';
import { RepairTaskState } from '@/store/repairTask/repairTask.state';
import { MaintenanceTimerState } from '@/store/maintenanceTimer/maintenanceTimer.state';
import { EmailState } from '@/store/email/email.state';

const states: any[] = [
  CheckpointGroupState,
  OrganisationState,
  AttachmentState,
  CheckpointState,
  EquipmentState,
  AddressState,
  UserState,
  BuildingState,
  BuildingEquipmentState,
  InspectionState,
  TemplateState,
  BuildingSectionState,
  VersionState,
  InspectionPdfState,
  TagState,
  ContractorState,
  RepairTaskState,
  MaintenanceTimerState,
  EmailState
];

@NgModule({
  imports: [
    NgxsModule.forRoot(states, {
      developmentMode: environment.tag == 'local',
      selectorOptions: {},
    }),
    NgxsStoragePluginModule.forRoot({ storage: StorageOption.SessionStorage }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
  ],
  declarations: [],
  exports: [],
})
export class StoreModule {

}
