<div class="attachment-single-select">
  <ng-container *ngIf="value && !value.deleted; else noAttachment">
    <wr-attachment-preview [attachment]="value"
                           [nbSpinner]="!!(loading$|async)"
                           nbSpinnerStatus="primary"
                           [isDisabled]="isDisabled"
                           (imageEditClick)="fileInput.click()">
    </wr-attachment-preview>

    <input #fileInput
           hidden
           class="file-input"
           type="file"
           [accept]="getAcceptForFileType(type)"
           (change)="onFilesSelected($event)"/>
  </ng-container>

  <ng-template #noAttachment>
    <ng-container *ngIf="isDisabled; then disabled; else editing"></ng-container>

    <ng-template #disabled>
      <span class="text-hint" [ngSwitch]="type">
        <ng-container *ngSwitchCase="'IMAGE'">Kein Bild ausgewählt</ng-container>
        <ng-container *ngSwitchCase="'FILE'">Keine Datei ausgewählt</ng-container>
      </span>
    </ng-template>

    <ng-template #editing>
      <wr-attachment-drop-zone [nbSpinner]="!!(loading$|async)"
                               nbSpinnerStatus="primary"
                               (fileSelected)="upload($event)"
                               [type]="type"
                               [multiple]="false">
      </wr-attachment-drop-zone>
    </ng-template>
  </ng-template>
</div>
<div class="delete-link" *ngIf="!isDisabled">
  <a (click)="deleteFile()">Deckblattbild (optional) löschen</a>
</div>
