<div class="attachments-carousel">
  <div class="attachment"
       [ngClass]="size"
       [class.disabled]="disabledPreview"
       *ngFor="let attachment of attachments; let i = index"
       (click)="select(i)"
       [style.background-image]="'url('+attachment.presignedUrl+')'">
  </div>
</div>

<div class="attachment-modal-wrapper"
     [class.visible]="!!selected"
     (click)="outsideClick()">
  <div class="attachment-modal"
       (click)="insideClick($event)"
       [style.background-image]="selected?.presignedUrl ? 'url('+selected?.presignedUrl+')' : null">
    <nb-icon pack="eva" icon="close-outline" (click)="selectedIndex=undefined"></nb-icon>
    <nb-icon pack="eva" icon="arrow-ios-back-outline" (click)="previous()"></nb-icon>
    <nb-icon pack="eva" icon="arrow-ios-forward-outline" (click)="next()"></nb-icon>
  </div>
</div>
