import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import { ConfirmDialogComponent } from '@components/dialog/confirm-dialog/confirm-dialog.component';

export interface PendingChanges {
  hasPendingChanges(): boolean | Promise<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<PendingChanges> {
  constructor(private readonly nbDialogService: NbDialogService) {
  }

  async confirmDialog(): Promise<boolean> {
    const dialogRef = this.nbDialogService.open(ConfirmDialogComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });

    return firstValueFrom(dialogRef.onClose);
  }

  async canDeactivate(component: PendingChanges): Promise<boolean> {
    if (!component.hasPendingChanges) {
      console.warn('Component does not implement PendingChanges interface');
      return true;
    }
    const hasPendingChanges = await component.hasPendingChanges();
    return !hasPendingChanges || await this.confirmDialog();
  }
}
