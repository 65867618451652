<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="user"></nb-icon>
          Benutzer
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
  [data$]="users$"
  (lineClick)="navigateToUser($event)"
  [sortKey]="'cooperativeUserSort'"
  [storeUsage]="userStoreUsage"
  [defaultSort]="'abbreviation'"
  >
    <ng-template
      dataColumn
      let-user
      key="abbreviation"
      display="Kürzel">
      <div *ngIf="user.abbreviation">
        {{user.abbreviation}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-user
      key="userName"
      display="Benutzer">
      <div *ngIf="user.userName">
        {{user.userName}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-user
      key="firstName"
      display="Vorname">
      <div *ngIf="user.firstName">
        {{user.firstName}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-user
      key="lastName"
      display="Nachname">
      <div *ngIf="user.lastName">
        {{user.lastName}}
      </div>
    </ng-template>
  </wr-data-table>
</div>
