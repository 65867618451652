import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: 'wr-table-pagination',
})
export class PaginationDirective {

  @Input() totalRows!: number;

  @Input() pageSize!: number;

  @Input() page!: number;

  @Output() pageChange = new EventEmitter<number>();

  @Output() pageSizeChange = new EventEmitter<number>();


}
