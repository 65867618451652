import { Component, computed, inject } from '@angular/core';
import { BuildingService, SettingsService } from '@artemis-software/wr-api';
import { select } from '@/utils/signals/select';
import { BuildingState } from '@/store/building/building.state';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { loader } from '@/utils/signals/loader';
import { GradientColors } from '@shared/form-controls/threshold-slider/gradient-colors';

@Component({
  selector: 'wr-building-dashboard-stats',
  templateUrl: './building-dashboard-stats.component.html',
  styleUrls: ['./building-dashboard-stats.component.scss'],
})
export class BuildingDashboardStatsComponent {

  private readonly buildingService = inject(BuildingService);
  private readonly settingsService = inject(SettingsService);

  readonly loading = loader();

  readonly building = select(BuildingState.selectedDashboardBuilding);

  readonly settings = asyncComputed(() => {
    return this.settingsService.getSettings();
  }, null, this.loading);

  readonly conditionValues = asyncComputed(() => {
    const buildingId = this.building()?.id;
    if (buildingId !== undefined) {
      return this.buildingService.getConditionValuesByCheckpointGroup(buildingId);
    }
    return [];
  }, [], this.loading);

  readonly conditionValueLabels = computed(() => {
    const values = this.conditionValues();
    return values ? values.map(value => value.checkpointGroup) : [];
  });

  readonly conditionValueData = computed(() => {
    const values = this.conditionValues();
    const labels = this.conditionValueLabels() || [];

    if (!values || !labels.length) return [];

    const conditionDataMap = new Map<string, number>();
    values.forEach(value => {
      conditionDataMap.set(value.checkpointGroup, Number((value.conditionPercent * 100).toFixed(2))); // Convert to percentage
    });

    return labels.map(label => {
      const dataArray = labels.map(l => (l === label ? conditionDataMap.get(l) || 0 : 0));
      const dataValue = conditionDataMap.get(label) || 0;
      return {
        label,
        data: dataArray,
        borderColor: this.getColorForConditionValue(dataValue),
        backgroundColor: this.getColorForConditionValue(dataValue),
      };
    });
  });


  private getColorForConditionValue(conditionPercent: number): string {
    const thresholds = this.settings()?.conditionThresholds;
    if (!thresholds) {
      return 'gray';
    }

    if (conditionPercent >= thresholds.majorThreshold * 100) return GradientColors.SUCCESS_COLOR;
    if (conditionPercent >= thresholds.minorThreshold * 100) return GradientColors.WARNING_COLOR;
    return GradientColors.DANGER_COLOR;
  }
}
