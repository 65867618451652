import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'wr-debug-page',
  templateUrl: './debug-page.component.html',
  styleUrls: ['./debug-page.component.scss'],
})
export class DebugPageComponent {
  currentEnvironment = environment;

  constructor(
  ) {
  }

  throwTestError(): void {
    throw new Error('Sentry Test Error');
  }

}
