import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { TagDetailDto, TagMergeDto, TagService } from '@artemis-software/wr-api';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadAllTags } from '@/store/tag/tag.action';
import { PendingChanges } from '@guards/pending-changes.guard';

@Component({
  selector: 'wr-tag-detail-page',
  templateUrl: './tag-detail-page.component.html',
  styleUrls: ['./tag-detail-page.component.scss'],
})
export class TagDetailPageComponent implements PendingChanges {
  isEditing = false;
  firstSubmit = false;
  form?: FormGroup;

  saving$ = new BehaviorSubject(false);

  constructor(private readonly dialogService: NbDialogService,
    private readonly tagService: TagService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly toastrService: NbToastrService) {
    route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        if (id === 'new') {
          this.isEditing = true;
          this.initForm();
        } else {
          this.tagService.findById(id).subscribe((tag) => {
            this.initForm(tag);
            this.form?.disable();
          });
        }
      } else {
        this.router.navigate(['tags']);
      }
    });
  }

  initForm(dto?: TagDetailDto): void {
    this.form = new FormGroup({
      id: new FormControl(dto?.id),
      name: new FormControl(dto?.name, [Validators.required]),
      description: new FormControl(dto?.description, [Validators.required]),
      color: new FormControl(dto?.color, [Validators.required]),
    });
    if (!this.form.value.color) {
      this.form.patchValue({
        color: '#000000',
      });
    }
  }

  onEdit(): void {
    this.isEditing = true;
    this.form?.enable();
  }

  onDelete(): void {
    this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Tag löschen',
        message: 'Bist du sicher, dass du den Tag löschen willst?',
      },
    }).onClose.subscribe({
      next: (result) => {
        if (result) {
          this.tagService.deleteById(this.form?.value.id).subscribe(() => {
            this.store.dispatch(new LoadAllTags());
            this.router.navigate(['tags']);
            this.toastrService.success('Tag wurde erfolgreich gelöscht', 'Tag');
          });
        }
      }, error: () => {
        this.toastrService.danger('Beim Löschen des Tags ist ein Fehler aufgetreten', 'Tag');
      },
    });
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['tags']);
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.isEditing;
  }

  hasError(controlName: string, errorName = 'required'): boolean {
    const control = this.form?.get(controlName);
    return this.isEditing && this.firstSubmit && (control?.hasError(errorName) ?? false) && ((control?.touched ?? false) || (control?.dirty ?? false));
  }

  async cancel(): Promise<void> {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['tag', this.form?.value.id]);
    });
  }

  async submit(): Promise<void> {
    this.firstSubmit = true;
    this.form?.markAsTouched();

    if (!this.form?.valid) {
      this.toastrService.danger('Tag kann nicht gespeichert werden, es fehlen noch Daten.', 'Tag');
      return;
    }
    try {
      this.saving$.next(true);
      const tagDto: TagMergeDto = {
        ...this.form?.value,
      };
      const tag = await firstValueFrom(this.tagService.merge(tagDto));
      this.isEditing = false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.store.dispatch(new LoadAllTags());
        this.router.navigate(['tag', tag.id]).then(() => {
          this.toastrService.success('Tag wurde erfolgreich gespeichert', 'Tag');
        });
      });
    } catch (e: unknown) {
      console.error(e);
      this.toastrService.danger('Tag konnte nicht gespeichert werden', 'Fehler');
    } finally {
      this.saving$.next(false);
    }
  }

}
