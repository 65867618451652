import { Component } from '@angular/core';
import { TemplateState } from '@/store/template/template.state';
import { TemplateItemDto } from '@artemis-software/wr-api';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'wr-template-selection-dialog',
  templateUrl: './template-selection-dialog.component.html',
})
export class TemplateSelectionDialogComponent {

  @Select(TemplateState.templates)
  templates$!: Observable<TemplateItemDto[]>;
  selectedTemplateId = '';
  onSubmit = () => this.selectedTemplateId;
}
