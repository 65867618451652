import { inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';

// selects a value from the ngxs store and returns a signal that updates when the value changes
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function select<T>(selector: (state: any, ...states: any[]) => T): Signal<T> {
  const store = inject(Store);
  const current = store.selectSnapshot(selector);
  const observable = store.select<T>(selector);
  return toSignal(observable, { initialValue: current });
}
