import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { BuildingEquipmentDetailDto } from '@artemis-software/wr-api/model/buildingEquipmentDetailDto';
import { Tracked } from '@/utils/signals/tracked';
import { MaintenanceTimerDetailDto, MaintenanceTimerService } from '@artemis-software/wr-api';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { firstValueFrom } from "rxjs";
import { isAdmin } from '@/utils/admin-utils';

@Component({
  selector: 'wr-maintenance-timer-overview',
  templateUrl: './maintenance-timer-overview.component.html',
  styleUrls: ['./maintenance-timer-overview.component.scss'],
})
export class MaintenanceTimerOverviewComponent implements OnInit{
  isAdmin = isAdmin();
  @Input()
  @Tracked()
  buildingEquipment?: BuildingEquipmentDetailDto;

  initialMaintenanceDays: number = 0;

  service = inject(MaintenanceTimerService);

  entities = asyncComputed(() => {
    if (!this.buildingEquipment) return [];
    return this.service.findAllDetailDtos({
      buildingEquipmentId: this.buildingEquipment.id, sort: {
        sort: 'nextDate',
        order: 'ASC',
      },
    });
  }, []);

  creatingEntity = signal(false);

  async ngOnInit(): Promise<void> {
    this.initialMaintenanceDays = await this.getInitialMaintenanceDays()
  }

  onUpdate(entity: MaintenanceTimerDetailDto): void {
    const entities = [...this.entities()];
    const index = entities.findIndex((e) => e.id === entity.id);
    if (index >= 0) {
      entities[index] = entity;
      this.entities.set(entities);
    }
  }

  onCreate(entity: MaintenanceTimerDetailDto) {
    this.entities.set([...this.entities(), entity]);
    this.creatingEntity.set(false);
  }

  onDelete(entity: MaintenanceTimerDetailDto) {
    const entities = [...this.entities()];
    const index = entities.findIndex((e) => e.id === entity.id);
    if (index >= 0) {
      entities.splice(index, 1);
      this.entities.set(entities);
    }
  }

  trackByFn(index: number, item: MaintenanceTimerDetailDto): string {
    return item.id;
  }

  async getInitialMaintenanceDays(): Promise<number> {
    const maintenanceInterval = this.buildingEquipment?.equipment.maintenanceInterval;
    let days = 0;
    if(maintenanceInterval) {
      days = await firstValueFrom(this.service.getIntervalDays(maintenanceInterval))
    }
    return days;
  }

}
