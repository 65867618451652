/**
 * MyGemaPro APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificationDto { 
    id: string;
    date: string;
    title: string;
    message: string;
    readAt?: string;
    variant: NotificationDto.VariantEnum;
    url?: string;
    type: NotificationDto.TypeEnum;
}
export namespace NotificationDto {
    export type VariantEnum = 'Normal' | 'Success' | 'Warning' | 'Error';
    export const VariantEnum = {
        Normal: 'Normal' as VariantEnum,
        Success: 'Success' as VariantEnum,
        Warning: 'Warning' as VariantEnum,
        Error: 'Error' as VariantEnum
    };
    export type TypeEnum = 'InspectionComment' | 'Maintenance';
    export const TypeEnum = {
        InspectionComment: 'InspectionComment' as TypeEnum,
        Maintenance: 'Maintenance' as TypeEnum
    };
}


