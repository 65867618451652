<div class="detail-container">
  <nb-card>
    <nb-card-body>
      <nb-tabset>
        <nb-tab tabTitle="Reperaturaufträge">
          <wr-building-repair-task-overview-page>
          </wr-building-repair-task-overview-page>
        </nb-tab>
        <nb-tab tabTitle="Archiv">
          <wr-contractor-external-dashboard-archive>
          </wr-contractor-external-dashboard-archive>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
  </nb-card>
</div>
