<nb-card>
  <nb-card-header>
    <span>Gebäude</span>
  </nb-card-header>
  <wr-table [rows]="buildings()" [loading]="isLoading()" [rowLink]="getBuildingLink">
    <wr-table-pagination [totalRows]="buildingCount()"
                         [(pageSize)]="pageSize"
                         [(page)]="page"></wr-table-pagination>
    <ng-template column let-building [of]="buildings()" header="Adresse">
      {{ building.sectionText }}
    </ng-template>
    <ng-template column let-building [of]="buildings()" header="Name">
      {{ building.name }}
    </ng-template>
    <ng-template column let-building [of]="buildings()" header="Genossenschaft">
      {{ building.organisationName }}
    </ng-template>
    <ng-template column let-building [of]="buildings()" header="Interne Nummer">
      {{ building.organisationName }}
    </ng-template>
    <ng-template column let-building [of]="buildings()" header="Zustand">
      <wr-building-condition *ngIf="building.buildingCondition.conditionValue > 0"
                             [condition]="building.buildingCondition.conditionValue" />
    </ng-template>
  </wr-table>
</nb-card>
