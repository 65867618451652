import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {

  transform(count: number, singular: string, plural: string): unknown {
    return count === 1 ? singular : plural;
  }
}
