<div class="list-container">
  <nb-card class="list-filter-card">
    <nb-card-body>
      <div class="list-filter">
        <div class="list-filter-title">
          <nb-icon pack="fas" icon="truck"></nb-icon>
          Externe Firmen
        </div>
        <ng-container *ngIf="form">
          <form [formGroup]="form">
            <div class="list-filter-control">
              <div class="filter-control-group">
                <label class="label" for="textFilter">Textfilter</label>
                <input type="text" nbInput id="textFilter" formControlName="text" placeholder="Filter Text...">
              </div>
              <button nbButton ghost status="primary" (click)="triggerFilter()">
                <nb-icon pack="eva" icon="search-outline"></nb-icon>
              </button>
              <button nbButton ghost status="primary" (click)="add()">
                <nb-icon pack="eva" icon="plus-outline"></nb-icon>
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </nb-card-body>
  </nb-card>
  <wr-data-table
    [data$]="contractors$"
    (lineClick)="navigateToCreateContractor($event)"
    [sortKey]="'contractorSort'"
    [storeUsage]="contractorStoreUsage"
    [defaultSort]="'name'"
  >
    <ng-template
      dataColumn
      let-contractor
      key="name"
      display="Name">
      <div *ngIf="contractor.name">
        {{contractor.name}}
      </div>
    </ng-template>
    <ng-template
      dataColumn
      let-contractor
      key="description"
      display="Beschreibung">
      <div *ngIf="contractor.description">
        {{contractor.description}}
      </div>
    </ng-template>
  </wr-data-table>
</div>

