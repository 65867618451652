import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { InspectionState } from '@/store/inspection/inspection.state';
import { map, Observable } from 'rxjs';
import {
  ConditionDto,
  BuildingDetailDto,
  InspectionDetailDto,
  InspectionFilterDto,
  InspectionItemDto,
  OrganisationItemDto,
} from '@artemis-software/wr-api';
import { OrganisationState } from '@/store/organisation/organisation.state';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDateService } from '@nebular/theme';
import { ChangeInspectionFilter } from '@/store/inspection/inspection.action';
import ConditionEnum = ConditionDto.ConditionEnum;
import { defineStoreUsage } from '@/utils/storeUsage';
import { createSelectedRange } from '@/utils/filter-range-utils';

@Component({
  selector: 'wr-property-manager-inspection-overview-page',
  templateUrl: './property-manager-inspection-overview-page.component.html',
  styleUrls: ['./property-manager-inspection-overview-page.component.scss'],
})
export class PropertyManagerInspectionOverviewPageComponent {
  inspectionStoreUsage = defineStoreUsage({
    filterAction: ChangeInspectionFilter,
    countSelector: InspectionState.count,
    loadingSelector: InspectionState.inspectionsLoading,
  });

  @Select(InspectionState.filteredInspections)
  inspections$!: Observable<InspectionItemDto[]>;
  @Select(OrganisationState.organisations)
  organisations$!: Observable<OrganisationItemDto[]>;
  @Select(InspectionState.count)
  count$!: Observable<number>;
  @Select(InspectionState.inspectionsLoading)
  loading$!: Observable<boolean>;

  form!: FormGroup;

  constructor(private readonly store: Store,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly dateService: NbDateService<Date>) {
    this.initFilter();
  }

  initFilter(): void {
    const filter = localStorage.getItem('propertyManagerInspectionFilter');
    if (filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
      this.addSpecialFields(
        filterValue.building,
        createSelectedRange(filterValue, this.dateService),
        filterValue.condition);
    } else {
      this.initFilterForm('');
    }
    this.triggerFilter();
  }

  initFilterForm(text: string): void {
    const startOfMonth = this.dateService.getMonthStart(this.dateService.today());
    const endOfMonth = this.dateService.addMonth(startOfMonth, 2);

    this.form = this.formBuilder.group({
      text: this.formBuilder.control(text),
      organisationId: this.formBuilder.control(undefined),
      continuationNumber: this.formBuilder.control(undefined),
      building: this.formBuilder.control(undefined),
      buildingId: this.formBuilder.control(null),
      status: this.formBuilder.control(InspectionDetailDto.StatusEnum.None),
      selectedRange: new FormControl({
        start: startOfMonth,
        end: endOfMonth,
      }),
      condition: this.formBuilder.control(undefined),
      isPropertyManager: this.formBuilder.control(true),
    });
  }

  async addSpecialFields(building: BuildingDetailDto, selectedRange: { start: Date; end: Date } | undefined, condition: ConditionEnum): Promise<void> {
    this.form.patchValue({
      'building': building,
      'selectedRange': selectedRange,
      'condition': condition,
    });
  }

  getOrganisationName(id: string | undefined): Observable<string | undefined> {
    return this.organisations$.pipe(
      map((organisations: OrganisationItemDto[]) => {
        const dto = organisations.find((organisation: OrganisationItemDto) => organisation.id === id);
        return dto?.name ?? '-';
      }),
    );
  }

  navigateToInspection(entry: InspectionItemDto): void {
    this.router.navigate(['inspection', entry.id]);
  }

  triggerFilter(): void {
    this.form.controls['buildingId'].setValue(this.form.controls['building'].value?.id);
    const filter: InspectionFilterDto = this.form.value;
    if (filter.selectedRange && !filter.selectedRange?.end) {
      filter.selectedRange.end = filter.selectedRange.start;
    }
    this.store.dispatch(new ChangeInspectionFilter(filter));
    localStorage.setItem('propertyManagerInspectionFilter', JSON.stringify(this.form.value));
  }
}
