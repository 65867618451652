<wr-base-dialog status="primary"
                [showCancelButton]="true"
                [showSubmitButton]="true"
                [submitResponse]="saveFunction"
                header="Wartungstimer bearbeiten">
  <div [formGroup]="form()" class="maintenance-timer">
    <hau-form-field label="Startdatum" [validation]="form().validation.startDate">
      <wr-date-picker formControlName="startDate" />
    </hau-form-field>
    <hau-form-field label="Dauer (Tage)" [validation]="form().validation.durationDays">
      <input nbInput type="number" fullWidth formControlName="durationDays" [valueAsNumber]="true" />
    </hau-form-field>
    <hau-form-field label="Erinnerung (Tage)" [validation]="form().validation.reminderDurationDays">
      <input nbInput type="number" fullWidth formControlName="reminderDurationDays" [valueAsNumber]="true">
    </hau-form-field>
    <hau-form-field label="Nächste {{form().value.type! | maintenanceTimerType}}">
      {{ nextDate | date: 'dd.MM.yyyy' }}
    </hau-form-field>
    <hau-form-field label="Erinnerung">
      {{ reminderDate | date: 'dd.MM.yyyy' }}
    </hau-form-field>
  </div>
</wr-base-dialog>
