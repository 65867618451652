<hau-form [formGroup]="form">
  <hau-form-field label="Name" cols="6" [validation]="form.validation.name">
    <input nbInput fullWidth formControlName="name">
  </hau-form-field>
  <hau-form-field label="Beschreibung" [validation]="form.validation.description">
    <textarea nbInput fullWidth formControlName="description"></textarea>
  </hau-form-field>
  <hau-form-field label="Elementtyp" cols="6" [validation]="form.validation.elementType">
    <dok-element-type-select formControlName="elementType"></dok-element-type-select>
  </hau-form-field>
  <hau-form-field label="Wertetyp" cols="6" [validation]="form.validation.valueType">
    <dok-value-type-select formControlName="valueType"></dok-value-type-select>
  </hau-form-field>
</hau-form>
