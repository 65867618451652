<nb-select fullWidth [(selected)]="value" [disabled]="isDisabled">
  <nb-option *ngIf="showEmptyOption" [value]="Status.None">Alle</nb-option>
  <nb-option [value]="Status.Draft">
    <nb-icon icon="edit-outline" pack="eva"></nb-icon>
    Vorlage
  </nb-option>
  <nb-option [value]="Status.Todo">
    <nb-icon icon="radio-button-on-outline" pack="eva"></nb-icon>
    Offen
  </nb-option>
  <nb-option [value]="Status.InProgress">
    <nb-icon icon="play-circle-outline" pack="eva"></nb-icon>
    In Arbeit
  </nb-option>
  <nb-option [value]="Status.InReview">
    <nb-icon icon="refresh-outline" pack="eva"></nb-icon>
    In Prüfung
  </nb-option>
  <nb-option [value]="Status.Done">
    <nb-icon icon="checkmark-outline" pack="eva"></nb-icon>
    Abgeschlossen
  </nb-option>
  <nb-option [value]="Status.Sent">
    <nb-icon icon="done-all-outline" pack="eva"></nb-icon>
    Gesendet
  </nb-option>
</nb-select>
