import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export class AbstractFormControl<T> implements ControlValueAccessor {
  isDisabled = false;

  protected _value?: T;

  onChange?: (value: T | undefined) => void;
  onTouch?: () => void;

  value$ = new BehaviorSubject<T | undefined>(undefined);

  set value(value: T | undefined) {
    this._value = value;
    this.onChange?.(this._value);
    this.onTouch?.();
    this.value$.next(this._value);
  }

  get value(): T | undefined {
    return this._value;
  }

  writeValue(value: T | undefined): void {
    this._value = value;
    this.value$.next(this._value);
  }

  registerOnChange(fn: (value: T | undefined) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
