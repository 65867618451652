<nb-card>
  <nb-card-header>Aktueller Gebäudezustand</nb-card-header>
  <nb-card-body [nbSpinner]="loading()">
    <ng-container *ngIf="conditionValueData().length">
      <wr-large-chart
        [dynamicSize]="true"
        [labels]="conditionValueLabels()"
        [showLegend]="false">
        <ng-container *ngFor="let dataset of conditionValueData(); let i = index">
          <wr-dataset
            type="bar"
            [data]="dataset.data"
            [label]="dataset.label"
            [backgroundColor]="dataset.backgroundColor"
            [borderColor]="dataset.borderColor"
            indexAxis="y"
            stack="conditionValues">
          </wr-dataset>
        </ng-container>
      </wr-large-chart>
    </ng-container>
  </nb-card-body>
</nb-card>
