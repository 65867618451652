import { Component, forwardRef } from '@angular/core';
import { AbstractFormControl } from '../abstract-form-control';
import { TagItemDto, TagService } from '@artemis-software/wr-api';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { NbMenuItem, NbMenuService, NbTagComponent } from '@nebular/theme';
import { Select } from '@ngxs/store';
import { TagState } from '@/store/tag/tag.state';

@Component({
  selector: 'wr-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagSelectComponent),
      multi: true,
    },
  ],
})
export class TagSelectComponent extends AbstractFormControl<TagItemDto[]> {
  @Select(TagState.tags)
  tags$!: Observable<TagItemDto[]>;

  constructor() {
    super();
  }
}
