<div class="form-actions-wrapper" [nbSpinner]="!!(loading$|async)">
  <div class="button-row">
    <ng-container *ngIf="form.enabled">
      <button nbButton status="danger" nbTooltip="Löschen" nbTooltipStatus="danger" (click)="deleteClick()">
        <nb-icon icon="trash-2-outline" pack="eva"></nb-icon>
      </button>
      <button nbButton nbTooltip="Abbrechen" nbTooltipStatus="basic" (click)="cancelEdit()">
        <nb-icon icon="close-outline" pack="eva"></nb-icon>
      </button>
      <button nbButton
              *ngIf="form.enabled"
              (click)="saveClick()"
              [disabled]="saveDisabled"
              status="success"
              nbTooltip="Speichern"
              nbTooltipStatus="success">
        <nb-icon icon="save-outline" pack="eva"></nb-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="!form?.enabled">
      <button nbButton nbTooltip="Bearbeiten" nbTooltipStatus="basic" (click)="startEdit()">
        <nb-icon icon="edit-outline" pack="eva"></nb-icon>
      </button>
    </ng-container>
  </div>
  <ng-content></ng-content>
  <div class="button-row">
    <button nbButton status="success"
            *ngIf="form?.enabled"
            (click)="saveClick()"
            [disabled]="saveDisabled">
      Speichern
    </button>
  </div>
</div>
