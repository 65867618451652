import { inject, signal } from '@angular/core';
import { SettingsService } from '@artemis-software/wr-api';
import { createGlobalState } from '@/utils/globalState';
import { SettingsDetailDto } from '@artemis-software/wr-api/model/settingsDetailDto';

export const useSettings = createGlobalState(() => {
  const settings = signal<SettingsDetailDto | undefined>(undefined);

  inject(SettingsService).getSettings().subscribe((s) => {
    settings.set(s);
  });

  return settings;
});
