import { Component, forwardRef } from '@angular/core';
import { BuildingDetailDto } from '@artemis-software/wr-api';
import { AbstractFormControl } from '../abstract-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'wr-building-type-select',
  templateUrl: './building-type-select.component.html',
  styleUrls: ['./building-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BuildingTypeSelectComponent),
      multi: true,
    },
  ],
})
export class BuildingTypeSelectComponent extends AbstractFormControl<BuildingDetailDto.BuildingTypeEnum> {

  readonly BuildingType = Object.values(BuildingDetailDto.BuildingTypeEnum);

}
