import { BehaviorSubject, Subscription } from 'rxjs';
import { AttachmentDetailDto, AttachmentMergeDto, AttachmentReferenceDto } from '@artemis-software/wr-api';
import { EventEmitter } from '@angular/core';

type OptionalKeys = 's3key' | 'presignedUrl';

export type Attachment = AttachmentDetailDto & { deleted: boolean };

export type PendingAttachment = {
  [K in OptionalKeys]?: Attachment[K];
} & {
  [K in Exclude<keyof Attachment, OptionalKeys>]: Attachment[K];
};

export type AttachmentUploadJob = {
  subscription: Subscription;
  attachment: PendingAttachment;
};

export type FormControlContext = {
  pendingJobs$: BehaviorSubject<AttachmentUploadJob[]>;
  attachmentChangeEventListener: EventEmitter<Attachment[]>;
};

export function attachmentFromDetail(detail: AttachmentDetailDto): Attachment;
export function attachmentFromDetail(detail: AttachmentDetailDto | undefined): Attachment | undefined;
export function attachmentFromDetail(detail: AttachmentDetailDto | undefined): Attachment | undefined {
  return detail ? {
    ...detail,
    deleted: false,
  } : undefined;
}

export function attachmentsFromDetails(details: AttachmentDetailDto[]): Attachment[] {
  return details.map(d => attachmentFromDetail(d));
}

export function attachmentToMerge(attachment: Attachment): AttachmentMergeDto;
export function attachmentToMerge(attachment: Attachment | undefined | null): AttachmentMergeDto | undefined;
export function attachmentToMerge(attachment: Attachment | undefined | null): AttachmentMergeDto | undefined {
  return attachment ? {
    s3Key: attachment.s3key,
    rotationDegrees: normalizeRotationDegrees(attachment.rotationDegrees),
    tags: attachment.tags,
    comment: attachment.comment,
    deleted: attachment.deleted,
  } : undefined;
}

export const normalizeRotationDegrees = (degrees: number): number => {
  const normalizedDegrees = degrees % 360;
  if (normalizedDegrees >= 0) {
    return normalizedDegrees;
  }
  return normalizedDegrees + 360;
};

export function attachmentDetailToAttachmentReferenceDto(detail: Attachment | undefined): AttachmentReferenceDto {
  return {
    value: attachmentToMerge(detail),
  };
}
