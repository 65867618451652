<nb-card>
  <nb-card-header>TGAs</nb-card-header>
  <nb-card-body>
    <wr-table [rows]="buildingEquipments()"
              [loading]="loading()"
              [rowLink]="getBuildingEquipmentLink">
      <ng-template
        column
        let-buildingEquipment
        [of]="buildingEquipments()"
        sort="equipmentName"
        shrink
        header="TGA">
        <div *ngIf="buildingEquipment.equipmentName">
          {{ buildingEquipment.equipmentName }}
        </div>
      </ng-template>
      <ng-template
        column
        let-buildingEquipment
        [of]="buildingEquipments()"
        sort="equipmentType"
        shrink
        header="Typ">
        <div *ngIf="buildingEquipment.equipmentType">
          {{ buildingEquipment.equipmentType | equipmentTypeTranslation }}
        </div>
      </ng-template>
      <ng-template
        column
        let-buildingEquipment
        [of]="buildingEquipments()"
        sort="quantity"
        shrink
        header="Anzahl">
        <div *ngIf="buildingEquipment.quantity">
          {{ buildingEquipment.quantity }}
        </div>
      </ng-template>
      <ng-template
        column
        let-buildingEquipment
        [of]="buildingEquipments()"
        sort="building"
        shrink
        header="Gebäudeteil">
        <div *ngIf="buildingEquipment.sectionText">
          {{ buildingEquipment.sectionText }}
        </div>
      </ng-template>
      <wr-table-pagination [totalRows]="count()"
                           [pageSize]="pageSize()"
                           (pageSizeChange)="pageSize.set($event)"
                           [page]="page()"
                           (pageChange)="page.set($event)"></wr-table-pagination>
    </wr-table>
  </nb-card-body>
</nb-card>
